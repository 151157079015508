import { makeAutoObservable } from 'mobx'
import { IStorage } from '@/api/interfaces'
import { RootStore } from '@/services'
import { isUndefined } from '@/utils'
import { EMPTY_CONSTS } from '@/const'

export class StorageListStore {
  storages: IStorage[] | undefined
  rootStore: RootStore

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.storages = EMPTY_CONSTS.UNDEFINED

    makeAutoObservable(this)
  }

  public setStorageList = (
    data: IStorage[] | undefined
  ): void => {
    this.storages = data
  }

  public updateStorageStatusById = (
    id: IStorage['id'],
    status: IStorage['storage_status']
  ): void => {
    if (!isUndefined(this.storages)) {
      this.storages = this.storages.map((storage) =>
        storage.id === id
          ? {
              ...storage,
              storage_status: status
            }
          : storage
      )
    }
  }
}
