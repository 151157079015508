export const USERS_GROUP_KEYS = {
  TITLE: 'title',
  CREATE_AT: 'created_at',
  USERS: 'users'
} as const

export const QUERIES = {
  small: '(max-height: 1024px)',
  medium: '(min-height: 1025px) and (max-height: 1199px)',
  large: '(min-height: 1200px) and (max-height: 1399px)',
  extra: '(min-height: 1400px)'
}