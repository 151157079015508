import React, { ReactNode } from 'react'

import { GxTooltip } from '@garpix/garpix-web-components-react'
import { GxTooltipCustomEvent, JSX } from '@garpix/garpix-web-components'

import style from './style/index.module.scss'

interface IProps extends JSX.GxTooltip {
  children: ReactNode
}
/**
 * @view
 * * Вью тултипа (плашка которая появляется при наведении)
 * @param param0
 * @returns
 */
const Tooltip: React.FC<IProps> = ({ children, ...props }) => {
  const onHide = (
    e: GxTooltipCustomEvent<HTMLGxTooltipElement>
  ): void => {
    e.stopPropagation()
  }
  return (
    <GxTooltip
      className={style.tooltip__default}
      onGx-hide={onHide}
      {...props}
    >
      {children}
    </GxTooltip>
  )
}

export default Tooltip
