import React from 'react'
import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import BaseContentViews from '../BaseContentViews'

import style from './styles/index.module.scss'

export interface IUserDeleteProps {
  title: string
  color: string
  isDisabled: boolean
  handleDelete: () => void
  closeModal: () => void
}
/**
 * @view
 * * Вью маодлаки удаления группы пользователей
 */
const UserGroupDeleteView: React.FC<IUserDeleteProps> = ({
  title,
  color,
  isDisabled,
  closeModal,
  handleDelete,
}) => {
  const intl = useIntl()
  return (
    <BaseContentViews.Wrapper>
      <BaseContentViews.Header
        colorIconWrapper="red"
        iconSlot={<Icon src="delete" size="small-xl" />}
      >
        <p className={style.container__header_lable}>
          {intl.formatMessage({
            id: 'deleteGroupUser',
            defaultMessage:
              'Вы уверены, что хотите удалить группу пользователей?',
          })}
        </p>
        <p className={style.container__header_sub}>
          {intl.formatMessage({
            id: 'kansfklnafn',
            defaultMessage: `После удаления группы пользователей, привязанное оборудование к группе будет отвязано.`,
          })}
        </p>
      </BaseContentViews.Header>
      <BaseContentViews.BannerContent
        className={style.container__group}
      >
        <div className={style.container__group__body}>
          <div
            className={style.container__group__color}
            style={{ backgroundColor: color }}
          />
          <h2 className={style.container__group_title}>
            {title}
          </h2>
        </div>
      </BaseContentViews.BannerContent>
      <BaseContentViews.ButtonWrapper>
        <Button
          className={style.container__buttons_cancel}
          onClick={closeModal}
          height={44}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Отмена',
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color="black"
          onClick={handleDelete}
          disabled={isDisabled}
          height={44}
        >
          {intl.formatMessage({
            id: 'common.delete',
            defaultMessage: 'Удалить',
          })}
        </Button>
      </BaseContentViews.ButtonWrapper>
    </BaseContentViews.Wrapper>
  )
}

export default UserGroupDeleteView
