import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { Input } from '@/views/input';
import { Select } from '@/views/select';
import { IReturnHookEditUsetForm } from '@/hooks/useEditUserForm';

type IProps = Omit<
  IReturnHookEditUsetForm,
  'isLoading' | 'handleSubmit' | 'actualInitials'
>;

const fields = {
  role: 'user_role',
  first_name: 'first_name',
  last_name: 'last_name',
  patronymic: 'patronymic',
};

/**
 * @info обертка центрирует контент
 *       в блок определенной максимальной ширины
 * @param param0
 * @returns
 */
const Fields: FC<IProps> = ({
  values,
  errors,
  roles,
  handleInput,
  handleRoleChange,
}) => {
  const intl = useIntl();
  // const { messages } = intl

  // const labels = {
  //   [fields.role]: messages['profile.form.role'] as string,
  //   [fields.first_name]: messages['profile.form.first_name'] as string,
  //   [fields.last_name]: messages['profile.form.last_name'] as string,
  //   [fields.patronymic]: messages['profile.form.patronymic'] as string,
  // }

  const labels = {
    [fields.role]: intl.formatMessage({
      id: 'profile.form.role',
      defaultMessage: 'Роль',
    }),
    [fields.first_name]: intl.formatMessage({
      id: 'profile.form.first_name',
      defaultMessage: 'Имя',
    }),
    [fields.last_name]: intl.formatMessage({
      id: 'profile.form.last_name',
      defaultMessage: 'Фамилия',
    }),
    [fields.patronymic]: intl.formatMessage({
      id: 'profile.form.patronymic',
      defaultMessage: 'Отчество',
    }),
  };

  return (
    <>
      <Select
        id={fields.role}
        name={fields.role}
        data-testid={fields.role}
        label={labels[fields.role]}
        onChange={handleRoleChange}
        options={roles}
        value={values.user_role}
        isSearchable={false}
      />
      <Input
        id={fields.first_name}
        name={fields.first_name}
        data-testid={fields.first_name}
        label={labels[fields.first_name]}
        onChange={handleInput}
        value={values?.first_name}
        error={Boolean(errors?.first_name)}
        errorMessage={errors?.first_name}
        autocomplete="off"
        required
      />
      <Input
        id={fields.last_name}
        name={fields.last_name}
        data-testid={fields.last_name}
        label={labels[fields.last_name]}
        onChange={handleInput}
        value={values?.last_name}
        error={Boolean(errors?.last_name)}
        errorMessage={errors?.last_name}
        autocomplete="off"
        required
      />
      <Input
        id={fields.patronymic}
        name={fields.patronymic}
        data-testid={fields.patronymic}
        label={labels[fields.patronymic]}
        onChange={handleInput}
        value={values?.patronymic}
        error={Boolean(errors?.patronymic)}
        errorMessage={errors?.patronymic}
        autocomplete="off"
      />
    </>
  );
};

export default memo(Fields);
