import React from 'react'
import { useIntl } from 'react-intl'
import { Field, useFormikContext } from 'formik'
import { isNull, isUndefined } from '@/utils'
import { EQUIPMENT_EDIT_FIELDS } from '@/apps/Modals/const'
import { IEquipmentEdit } from '@/api/interfaces'
import { Input } from '@/views/input'
import EditStatus from '@/views/EditStatus'
import Textarea from '@/views/Textarea'
import TypeSelect from '@/apps/Modals/ModalContent/EquipmentEdit/TypeSelect'
import { EquipmentStatusNumber } from '@/components/EquipmentStatusIcon'
import Users from '@/apps/Modals/ModalContent/EquipmentEdit/Users'
import StorageSelect from '@/apps/Modals/ModalContent/EquipmentEdit/StorageSelect'
import Marks from '../../../ModalContent/EquipmentEdit/Marks'
import { Button } from '@/views/button'
import Icon from '@/components/Icon'
import FieldContainer from '@/apps/Modals/views/EquipmentEditView/FieldContainer'

import styles from './styles/index.module.scss'

/**
 * Вьюха формы создания оборудования
 * @view
 * @constructor
 */
const EquipmentEditView: React.FC<{
  isSubmitting: boolean
  closeModal: () => void
}> = ({ isSubmitting, closeModal: handleClose }) => {
  const intl = useIntl()
  const { values } = useFormikContext<IEquipmentEdit>()
  return (
    <div className={styles.form}>
      <header className={styles.form__header}>
        <EditStatus
          status={values.status}
          icon='equipment'
          label={
            !isUndefined(values.id)
              ? intl.formatMessage({
                id: 'editEquipment',
                defaultMessage:
                    'Редактировать оборудования'
              })
              : intl.formatMessage({
                id: 'newEquipment',
                defaultMessage: 'Новое оборудование'
              })
          }
        />
        <Button variant='text' color='gray' onClick={handleClose}>
          <Icon src='close' />
        </Button>
      </header>
      <main className={styles.form__main}>
        <div className={styles.form__main_primary}>
          <FieldContainer>
            <TypeSelect />
          </FieldContainer>
          <FieldContainer>
            <Field name={EQUIPMENT_EDIT_FIELDS.TITLE}>
              {({ field, form, meta }) => (
                <Input
                  name={field.name}
                  value={field.value}
                  error={
                    Boolean(meta.touched) &&
                    Boolean(meta.error)
                  }
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  errorMessage={meta.error}
                  label={intl.formatMessage({
                    id: 'name',
                    defaultMessage: 'Название'
                  })}
                  required
                />
              )}
            </Field>
          </FieldContainer>
          <FieldContainer>
            <div className={styles.form__field_flex}>
              <Field
                name={EQUIPMENT_EDIT_FIELDS.SERIAL_NUMBER}
              >
                {({ field, form, meta }) => (
                  <Input
                    name={field.name}
                    value={field.value}
                    error={
                      Boolean(meta.touched) &&
                      Boolean(meta.error)
                    }
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    errorMessage={meta.error}
                    label={intl.formatMessage({
                      id: 'serialNumber',
                      defaultMessage: 'Серийный номер'
                    })}
                  />
                )}
              </Field>
              <Field
                name={
                  EQUIPMENT_EDIT_FIELDS.INVENTORY_NUMBER
                }
              >
                {({ field, form, meta }) => (
                  <Input
                    name={field.name}
                    value={field.value}
                    error={
                      Boolean(meta.touched) &&
                      Boolean(meta.error)
                    }
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    errorMessage={meta.error}
                    label={intl.formatMessage({
                      id: 'inventoryNumber',
                      defaultMessage: 'Инвентарный номер'
                    })}
                  />
                )}
              </Field>
            </div>
          </FieldContainer>
          <FieldContainer
            className={styles.form__field_max}
          >
            <Field name={EQUIPMENT_EDIT_FIELDS.DESCRIPTION}>
              {({ field, form, meta }) => (
                <Textarea
                  name={field.name}
                  value={field.value}
                  error={
                    Boolean(meta.touched) &&
                    Boolean(meta.error)
                  }
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  errorMessage={meta.error}
                  label={intl.formatMessage({
                    id: 'description',
                    defaultMessage: 'Описание'
                  })}
                  className={styles.form__field_description}
                />
              )}
            </Field>
          </FieldContainer>
          <Marks />
        </div>
        <div className={styles.form__main_secondary}>
          <FieldContainer
            className={styles.form__storage_wrapper}
          >
            <StorageSelect />
            {!isNull(values.cell_number) && (
              <div className={styles.form__storage_status}>
                <EquipmentStatusNumber
                  type={values.status}
                  value={String(values.cell_number)}
                />
              </div>
            )}
          </FieldContainer>
          <Users />
        </div>
      </main>
      <footer>
        {!isUndefined(values.id)
          ? (
            <Button
              type='submit'
              color='black'
              disabled={isSubmitting}
            >
              {intl.formatMessage({
                id: 'common.save',
                defaultMessage: 'Сохранить'
              })}
            </Button>
            )
          : (
            <Button
              type='submit'
              color='black'
              disabled={isSubmitting}
            >
              <Icon src='plus' />
              {intl.formatMessage({
                id: 'common.add',
                defaultMessage: 'Добавить'
              })}
            </Button>
            )}
      </footer>
    </div>
  )
}
export default EquipmentEditView
