import {
  makeObservable,
  observable,
  _autoAction
} from 'mobx'

import { PubSub, PubSubProps } from '../PubSub'
import { RootStore } from './Root'

import { MODAL_TYPES } from '@/const'
import { ISocketExelCreateMessage } from '@/interfaces/Socket'
/**
 * @class
 * * Класс, реализующий возможность подписываться на
 * * событя сокетов EXEL
 */
export class ExelEventsObserver extends PubSub {
  _ctx: RootStore

  constructor (ctx: RootStore) {
    super()

    this._ctx = ctx

    makeObservable(this, {
      ...PubSubProps,
      _ctx: observable,
      onCreateExel: _autoAction
    })
  }

  public onCreateExel = (
    message: ISocketExelCreateMessage
  ): void => {
    this._ctx.modalStore.open(
      MODAL_TYPES.SUCCESS_UPLOAD_EXCEL
    )
    this.broadcast(message)
  }
}
