import React, { useEffect, useState } from 'react'
import { FormikErrors, useFormik } from 'formik'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'
import useCurrentUser from './useCurrentUser'
import { useStores } from './useStores'
import { IRole, TUserRole } from '@/api/interfaces'
import { ProfileEditSheme } from '@/utils/ValidateSchemes'
import { EMPTY_CONSTS, MODAL_TYPES } from '@/const'

export interface IRoleOption {
  value: string
  label: string
}

export interface IFormEditUserValues {
  first_name: string
  last_name: string
  patronymic: string
  email: string
  card_hid_number: string
  user_role: IRoleOption
}

export type IFormAddNewWifiErrors =
  FormikErrors<IFormEditUserValues>

export interface IActualInitials {
  first_name: string
  last_name: string
  patronymic: string
  email: string
}

export interface IReturnHookEditUsetForm {
  values: IFormEditUserValues
  errors: IFormAddNewWifiErrors
  roles: IRoleOption[]
  isLoading: boolean
  actualInitials: IActualInitials
  handleSubmit: (
    e?: React.FormEvent<HTMLFormElement> | undefined
  ) => void
  handleRoleChange: (value: IRoleOption) => void
  handleInput: (
    event:
    | React.ChangeEvent<HTMLGxInputElement>
    | GxInputCustomEvent<HTMLGxInputElement>
  ) => void
}

export const fields = {
  first_name: 'first_name',
  email: 'email',
  last_name: 'last_name',
  patronymic: 'patronymic',
  role: 'user_role',
  card_hid_number: 'card_hid_number'
}

const initialValues = {
  [fields.first_name]: EMPTY_CONSTS.STR,
  [fields.last_name]: EMPTY_CONSTS.STR,
  [fields.patronymic]: EMPTY_CONSTS.STR,
  [fields.role]: {
    value: EMPTY_CONSTS.STR,
    label: EMPTY_CONSTS.STR
  },
  [fields.email]: EMPTY_CONSTS.STR,
  [fields.card_hid_number]: EMPTY_CONSTS.STR
} as unknown as IFormEditUserValues

/**
 * Сериализация ролей из формата Бэка в тот что принимает select
 * @param roles : IRole[]
 * @returns [{value: str, label: str}]
 */
const serializeRoles = (roles: IRole[]): IRoleOption[] => {
  return roles.map((role) => ({
    value: role.backend,
    label: role.value
  }))
}

/**
 * Найти текущее значение роли в сериализованом массиве по value
 * @param roles : string
 * @returns {value: str, label: str}
 */
const getCurrentRoleOptions = (
  role: string,
  roles: IRoleOption[]
): IRoleOption => {
  return roles.find(
    (el) => el.value === role
  ) as IRoleOption
}

/**
 * Хук реализующий основные возможности взаимодействия с формой Изменения профиля
 */
const useEditUserForm = (): IReturnHookEditUsetForm => {
  const { api, modalStore } = useStores()
  const user = useCurrentUser()
  const [isLoading, setIsLoading] = useState(
    EMPTY_CONSTS.FALSE
  )
  const [actualInitials, setActualInitials] = useState<IActualInitials>(user)
  const [roles, setRoles] = useState<IRoleOption[]>(
    EMPTY_CONSTS.ARR
  )

  /**
   * Функция выполняющаяся перед запросом
   */
  const beforeSubmit = (): void => {
    setIsLoading(EMPTY_CONSTS.TRUE)
  }

  /**
   * Функция выполняющаяся после успешного запроса
   */
  const afterSuccess = (values: IFormEditUserValues): void => {
    modalStore.open(MODAL_TYPES.SUCCESS_EDIT_PROFILE, {})
    setActualInitials(values)
  }

  /**
   * Функция выполняющаяся после запроса
   */
  const afterFinally = (): void => {
    setIsLoading(EMPTY_CONSTS.FALSE)
  }

  /**
   * Запрос на отправку формы
   */
  const formikSubmit = (
    values: IFormEditUserValues
  ): void => {
    beforeSubmit()
    const requestValues = {
      ...values,
      user_role: values.user_role.value as TUserRole
    }
    api.user
      .changeUser(user.id, requestValues)
      .then(() => {
        afterSuccess(values)
      })
      .finally(() => {
        afterFinally()
      })
  }

  /**
   * Обработчик onInput изменения поля
   */
  const handleInput = (
    e: GxInputCustomEvent<any>
  ): void => {
    const newValue = e.target.value
    const name = e.target.name
    void setFieldValue(name, newValue)
  }

  /**
   * Обработчик изменения Select Role
   */
  const handleRoleChange = (value: IRoleOption): void => {
    void setFieldValue(fields.role, value)
  }

  const {
    handleSubmit,
    setFieldValue,
    setValues,
    values,
    errors
  } = useFormik<IFormEditUserValues>({
    initialValues,
    onSubmit: formikSubmit,
    validationSchema: ProfileEditSheme,
    validateOnMount: EMPTY_CONSTS.FALSE,
    validateOnChange: EMPTY_CONSTS.FALSE,
    validateOnBlur: EMPTY_CONSTS.TRUE
  })

  /**
   * Функция формирующая начальные параметры для формы
   */
  const getInitvalues = (): IFormEditUserValues => {
    const currRole = getCurrentRoleOptions(
      user?.role ?? EMPTY_CONSTS.STR,
      roles
    )
    const restValeus = {
      first_name: user?.first_name,
      last_name: user?.last_name,
      card_hid_number: user?.card_hid_number,
      patronymic: user?.patronymic,
      email: user?.email
    }
    return {
      user_role: currRole,
      ...restValeus
    }
  }

  /**
   * Если Есть список всех ролей то сформировать из и засунуть в форму
   */
  useEffect(() => {
    if (roles == null || roles.length === 0) return
    const initVales = getInitvalues()
    void setValues(initVales)
  }, [roles])

  /**
   * Запрос на получение списка всех ролей с Бэка
   */
  useEffect(() => {
    void api.user.getRoles().then((data) => {
      const roles = serializeRoles(data)
      setRoles(roles)
    })
  }, [])

  return {
    values,
    errors,
    roles,
    isLoading,
    actualInitials,
    handleSubmit,
    handleRoleChange,
    handleInput
  }
}

export default useEditUserForm
