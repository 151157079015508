import React from 'react'
import AuthLayout from '@/apps/layout/components/AuthLayout'
import RecoveryPassword from '@/apps/RecoveryPassword'

const UserPasswordRecoveryPage = (): React.ReactElement => {
  return (
    <AuthLayout>
      <RecoveryPassword />
    </AuthLayout>
  )
}

export default UserPasswordRecoveryPage
