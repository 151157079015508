import { useIntl, MessageDescriptor, IntlShape } from 'react-intl'

export type TMessage = MessageDescriptor | string

/**
 * * хук для переаода текста
 * @hook
 * @returns {
 *  translate - метод для перервода текста обычного либо обьекта intl
 *  intl - инстанс хука useIntl
 * }
 */

const useTranslate = (): {
  intl: IntlShape
  translate: (msg: TMessage) => string
} => {
  const intl = useIntl()
  const translate = (msg: TMessage | string): string => {
    try {
      return intl.formatMessage(msg as MessageDescriptor)
    } catch {
      return String(msg)
    }
  }

  return { translate, intl }
}

export default useTranslate
