import React from 'react'

import { useIntl } from 'react-intl'

import { isUndefined } from '@/utils'
import { EMPTY_CONSTS } from '@/const'
import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import Badge from '@/views/Badge'

import styles from './styles/index.module.scss'

export interface INotificationHead {
  handleClose: () => void
  handleDelete?: () => void
  handleRead?: () => void
  count?: number
}

/**
 * Шапка списка уведомлений
 * @view
 * @param handleClose - метод для закрытия списка уведомлений
 * @param handleDelete - метод для очистки уведомлений
 * @param handleRead - метод прочитать все уведомления
 * @param count - количество непрочитанных уведомлений
 */
const NotificationHead: React.FC<INotificationHead> = ({
  handleClose,
  handleDelete,
  handleRead,
  count
}) => {
  const intl = useIntl()
  const disabled =
    isUndefined(count) || count === EMPTY_CONSTS.ZERO
  return (
    <div className={styles.head__root}>
      <Button
        variant='text'
        color='gray'
        onClick={handleClose}
      >
        <Icon src='arrowBack' size='xs' />
      </Button>
      <Typography
        size={32}
        weight={700}
        className={styles.head__title}
      >
        {intl.formatMessage({
          id: 'notifications',
          defaultMessage: 'Уведомления'
        })}
      </Typography>
      {!isUndefined(handleDelete) && (
        <Button
          variant='text'
          color='gray'
          onClick={handleDelete}
        >
          <Icon src='delete' />
        </Button>
      )}
      {!isUndefined(count) && (
        <Button
          variant='text'
          color='transparent'
          onClick={handleRead}
          disabled={disabled}
        >
          <Badge count={count} />
        </Button>
      )}
    </div>
  )
}

export default NotificationHead
