import React, { useState } from 'react'

import { GxDropdown } from '@garpix/garpix-web-components-react'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import UserMenu from '../../components/UserMenu'
import { Button } from '@/views/button'

import style from './style/index.module.scss'
import { Typography } from '@/views/typography'

interface IProps {
  initials: string
  firstName: string
}
/**
 * @view
 * Вью дропдауна пользователя (переход на стр профиля)
 * @param initials
 * @param firstName
 */
const UserEditDropdown: React.FC<IProps> = ({ initials, firstName }) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)

  return (
    <GxDropdown
      className={style.dropdown}
      open={open}
      hoist
      placement='right-start'
      onGx-hide={handleClose}
      onGx-show={handleOpen}
    >
      <Button
        color='transparent'
        slot='trigger'
        className={style.button}
      >
        <div className={style['initial-container']}>
          <div className={style.initialfullname}>
            {initials}
          </div>
          <Typography size={14} weight={500}>
            {firstName}
          </Typography>
        </div>
      </Button>
      <CSSTransitionGroup>
        <UserMenu handleCloseMenu={handleClose} />
      </CSSTransitionGroup>
    </GxDropdown>
  )
}

export default UserEditDropdown
