import React, { FC } from 'react'

import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import FilterButton from '@/views/FilterButton'
import SearchInput from '@/views/SearchInput'

import { useStores } from '@/hooks'

import { downloadFile } from '@/utils'

import {
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES,
  TABLE_PAGE,
  THEME_MODAL,
} from '@/const'
import { CUSTOM_DATA_ATTR_STYLES } from '@/apps/Modals/const'

import { IEquipmentPanel } from '../interfaces'

import styles from '../style/index.module.scss'

/**
 * @component EquipmentPanel
 * * компонент верхней панели поиска, фильтрации, добавления оборудования
 *
 * @param params              текущие query параметры
 * @param handleParams        установить дополнительные параметры к текущим query параметрам
 */
const EquipmentPanel: FC<IEquipmentPanel> = ({
  handleParams,
  params = EMPTY_CONSTS.OBJ,
  refetch,
}) => {
  const intl = useIntl()
  const { modalStore, api } = useStores()

  /**
   * метод для скачивания ексель шаблона пользователя
   */
  const handleDownload = (): void => {
    api.equipment
      .getExcelSample()
      .then(downloadFile)
      .catch(() =>
        modalStore.open(
          MODAL_TYPES.CUSTOM,
          MODAL_CUSTOM_CONTENT.DOWNLOAD_FILE_ERROR,
        ),
      )
  }

  /**
   * метод открытия модалки загрузки ексель
   */
  const handleUpload = (): void => {
    modalStore.setCustomDataStyles(
      CUSTOM_DATA_ATTR_STYLES.NOTIFY_PADDING,
    )
    modalStore.open(MODAL_TYPES.UPLOAD_EQUIPMENT_EXCEL, {
      refetch,
    })
  }

  /**
   * * метод для открытя модалки создания оборудлования
   * @func handleOpenCreateModal
   */
  const handleOpenCreateModal = (): void => {
    modalStore.open(MODAL_TYPES.EQUIPMENT_EDIT, {
      theme: THEME_MODAL.LARGE,
      refetch,
    })
  }

  return (
    <div className={styles.panel}>
      <Typography size={30} className={styles.panel__title}>
        {intl.formatMessage({
          id: 'header.equipment',
          defaultMessage: 'Ваше оборудование',
        })}
      </Typography>
      <div className={styles.panel__search}>
        <div className={styles.panel__search_inputwrap}>
          <SearchInput
            className={styles.panel__search_input}
            handleParams={handleParams}
            size={44}
          />
          <FilterButton
            type={TABLE_PAGE.EQUIPMENT}
            params={params}
            handleParams={handleParams}
            className={styles.panel__search_filter}
          />
        </div>
        <Button
          height={44}
          className={styles.panel__search_download}
          onClick={handleDownload}
        >
          <Icon size="small" src="exelIcon" />
          {intl.formatMessage({
            id: 'button.excel',
            defaultMessage: 'Excel шаблон',
          })}
        </Button>
        <Button
          height={44}
          className={styles.panel__search_upload}
          onClick={handleUpload}
        >
          <Icon size="small" src="file" />
          {intl.formatMessage({
            id: 'button.upload',
            defaultMessage: 'Загрузить файл',
          })}
        </Button>
        <Button
          height={44}
          color="black"
          className={styles.panel__search_add}
          onClick={handleOpenCreateModal}
        >
          <Icon size="small" src="plus" />
          {intl.formatMessage({
            id: 'button.add_equipment',
            defaultMessage: 'Добавить оборудование',
          })}
        </Button>
      </div>
    </div>
  )
}

export default EquipmentPanel
