import React from 'react'
import { StepsRestorePassword } from '@garpix/cms'
import SuccessPassword from './components/SuccessPassword'
import CheckCode from './components/CheckCode'
import SendCode from './components/SendCode'
import NewPassword from './components/NewPassword'
import useCustomRestorePassword from './hooks'
import AuthWrap from '@/views/AuthWrap'

/**
 * @component RecoveryPassword
 * * Главный компонент страницы Восстановления пароля
 * (обьединяет формы отправки кода, проверки кода, установки нового пароля, страницы успешной смены пароля)
 */
const RecoveryPassword = (): React.ReactElement => {
  const {
    onSubmit,
    handleUsernameValue,
    handlePasswordValue,
    handleCodeValue,
    resendCode,
    backToLogin,
    handleBack,
    username,
    error,
    step
  } = useCustomRestorePassword()

  const stepsContent = {
    [StepsRestorePassword.sendCode]:
  <SendCode
    handleUsernameValue={handleUsernameValue}
    onSubmit={onSubmit}
    handleBack={backToLogin}
    error={error}
    username={username}
  />,
    [StepsRestorePassword.checkCode]:
  <CheckCode
    handleCodeValue={handleCodeValue}
    onSubmit={onSubmit}
    handleBack={handleBack}
    resendCode={resendCode}
    username={username}
    error={error}
  />,
    [StepsRestorePassword.setPassword]:
  <NewPassword
    username={username}
    handlePasswordValue={handlePasswordValue}
    onSubmit={onSubmit}
    error={error}
  />,
    [StepsRestorePassword.finish]: <SuccessPassword />
  }

  return (
    <AuthWrap>{stepsContent[step]}</AuthWrap>
  )
}

export default RecoveryPassword
