import React, { ReactNode } from 'react'
import cn from 'classnames'

import { TStorageCellStatus } from '@/api/interfaces'

import style from './style/index.module.scss'

interface IProps {
  children: ReactNode
  isBlocked: boolean
  cellStatus: TStorageCellStatus
}
/**
 * @view
 * * вью для карточки ячейки показывающий ее статус и порядковый номре
 * @param cellStatus - статус ячейки
 * @returns
 */
const CellStatus: React.FC<IProps> = ({
  children,
  cellStatus,
  isBlocked
}) => {
  return (
    <div
      className={cn({
        [style['cell-status']]: true,
        [style['cell-status--blocked']]: isBlocked
      })}
      data-status={cellStatus}
    >
      {children}
    </div>
  )
}

export default CellStatus
