import React from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import UserPagesGeneral from "@/views/UserPagesGeneral";
import UserList from '@/apps/UserList'

const UserListPage: React.FC = () => {
  return (
    <MainLayout>
      <UserPagesGeneral.ContentWrap>
        <UserPagesGeneral.Navigation />
        <UserList />
      </UserPagesGeneral.ContentWrap>
    </MainLayout>
  )
}

export default UserListPage
