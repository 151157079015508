import {
  ArrowBackIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  CalendarIcon,
  CardIcon,
  ClockIcon,
  CloseIcon,
  DeleteIcon,
  DoubleArrowBackIcon,
  DoubleArrowRightIcon,
  DownloadIcon,
  EditIcon,
  EquipmentIcon,
  EquipmentBlueIcon,
  EquipmentMiniOffIcon,
  EquipmentMiniOnIcon,
  ExitIcon,
  FileIcon,
  FilterIcon,
  HelpIcon,
  HiddenPasswordIcon,
  LinkOffIcon,
  LinkOnIcon,
  LockerIcon,
  LogoIcon,
  MailIcon,
  MarkIcon,
  MinusIcon,
  MoreIcon,
  OkIcon,
  PlusIcon,
  ProblemIcon,
  SearchIcon,
  ShutDownIcon,
  SortDownIcon,
  SortUpIcon,
  StatusBrokenIcon,
  StatusConnectedIcon,
  StatusNotConnectedIcon,
  StatusRepairIcon,
  StatusResolvedIcon,
  StatusSynchronizationIcon,
  StorageIcon,
  StorageLogo,
  SuccessStatusIcon,
  SynchronizationIcon,
  UserIcon,
  VisiblePasswordIcon,
  ErrorExclamation,
  WarningIcon,
  NotificationIcon,
  ErrorStatusIcon,
  SettingsIcon,
  LastConnectIcon,
  UnlockIcon,
  FileXLSIcon,
  ArrowAccordion,
} from '../../../assets/icon'

export const IconType = {
  arrAccordion: ArrowAccordion,
  exelIcon: FileXLSIcon,
  arrowBack: ArrowBackIcon,
  arrowDown: ArrowDownIcon,
  arrowRight: ArrowRightIcon,
  arrowUp: ArrowUpIcon,
  calendar: CalendarIcon,
  card: CardIcon,
  clock: ClockIcon,
  close: CloseIcon,
  delete: DeleteIcon,
  doubleArrowBack: DoubleArrowBackIcon,
  doubleArrowRight: DoubleArrowRightIcon,
  download: DownloadIcon,
  edit: EditIcon,
  equipment: EquipmentIcon,
  equipmentBlue: EquipmentBlueIcon,
  equipmentMiniOff: EquipmentMiniOffIcon,
  equipmentMiniOn: EquipmentMiniOnIcon,
  exit: ExitIcon,
  file: FileIcon,
  filter: FilterIcon,
  help: HelpIcon,
  hiddenPassword: HiddenPasswordIcon,
  linkOff: LinkOffIcon,
  linkOn: LinkOnIcon,
  locker: LockerIcon,
  logo: LogoIcon,
  mail: MailIcon,
  mark: MarkIcon,
  minus: MinusIcon,
  more: MoreIcon,
  ok: OkIcon,
  plus: PlusIcon,
  problem: ProblemIcon,
  search: SearchIcon,
  shutDown: ShutDownIcon,
  sortDown: SortDownIcon,
  sortUp: SortUpIcon,
  statusBroken: StatusBrokenIcon,
  statusConnected: StatusConnectedIcon,
  statusNotConnected: StatusNotConnectedIcon,
  statusRepair: StatusRepairIcon,
  statusResolved: StatusResolvedIcon,
  statusSynchronization: StatusSynchronizationIcon,
  storage: StorageIcon,
  storageLogo: StorageLogo,
  successStatus: SuccessStatusIcon,
  errorStatus: ErrorStatusIcon,
  synchronization: SynchronizationIcon,
  user: UserIcon,
  visiblePassword: VisiblePasswordIcon,
  error: ErrorExclamation,
  warning: WarningIcon,
  notification: NotificationIcon,
  settings: SettingsIcon,
  lastconnect: LastConnectIcon,
  unlock: UnlockIcon,
}

export type TIcon = keyof typeof IconType

export type TIconSize =
  | 'xxs'
  | 'xs'
  | 'small'
  | 'medium'
  | 'large'
  | 'small-xl'
  | 'xl'
  | 'small-xxl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl'
  | 'medium-adaptive'
  | 'medium-28'

export interface IIconProps {
  src: TIcon
  size?: TIconSize
  className?: string
}
