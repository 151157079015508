import React from 'react'
import { useIntl } from 'react-intl'

import Pagination from '@/components/Pagination'
import { RenderSortButton } from '@/components/SortButton/RenderSortButton'
import Table from '@/apps/Table/component/Table'
import TableWrapper from '@/views/TableWrapper'
import UsersAvatar from '../UsersAvatar'
import GroupTitle from '../GroupTitle'
import EditWithTime from '@/apps/StorageList/views/EditWithTime'
import GroupDropdown from '../GroupDropdown'

import { useStores } from '@/hooks'

import { IColumn, TOnRow } from '@/apps/Table/interfaces'
import {
  IGroupList,
  IGroupUserQueryParams,
  IGroupUser,
} from '@/api/interfaces/GroupUsers'
import {
  DEFAULT_ERROR_ARRAY,
  EMPTY_CONSTS,
  TABLE_PAGE,
  TABLE_WRAPPER_ICON_SRC,
} from '@/const'
import { RIGHT_SIDEBAR_TYPES } from '@/apps/RightSidebar/const'
import { USERS_GROUP_KEYS } from '../../consts'

import { tableStyles } from './styles/tableStyles'
import styles from './styles/index.module.scss'

interface IProps {
  params: IGroupUserQueryParams
  handleParams: (param: IGroupUserQueryParams) => void
  groupUsers?: IGroupList[]
  isLoading: boolean
  currentPage?: number
  handlePage: (page: number) => void
  total?: number
  error: string[] | null | undefined
  refetch: () => void
}
/**
 * * Въюха таблицы групп пользователей
 * @view
 */
const GroupUsersTable: React.FC<IProps> = ({
  refetch,
  params,
  groupUsers,
  isLoading,
  currentPage,
  handlePage,
  total,
  handleParams,
  error = DEFAULT_ERROR_ARRAY,
}) => {
  const { rightSideBarStore } = useStores()
  const intl = useIntl()
  const { ordering } = params

  const handleOpenGroupSidebar = (groupId: number) => {
    rightSideBarStore.open(
      RIGHT_SIDEBAR_TYPES.USER_GROUP_SIDEBAR,
      { groupId, refetch },
    )
  }

  const onRow: TOnRow = (rowData) => ({
    onClick: () => handleOpenGroupSidebar(rowData.id),
  })

  const columns: IColumn[] = [
    {
      key: USERS_GROUP_KEYS.TITLE,
      contentHeader: intl.formatMessage({
        id: 'name',
        defaultMessage: 'Название',
      }),
      headerCellProps: {
        className: styles.table__head_title,
        render: (title) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            'title',
          ),
      },
      bodyCellProps: {
        render: (_, groupUserProps) => (
          <GroupTitle
            title={groupUserProps?.title}
            color={groupUserProps?.color}
          />
        ),
      },
    },
    {
      key: USERS_GROUP_KEYS.CREATE_AT,
      contentHeader: intl.formatMessage({
        id: 'createAt',
        defaultMessage: 'Создание',
      }),
      headerCellProps: {
        render: (title) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            'created_at',
          ),
      },
      bodyCellProps: {
        className: styles.table__body_create,
        render: (date: string) => (
          <EditWithTime date={date} showIcon={false} />
        ),
      },
    },
    {
      key: USERS_GROUP_KEYS.USERS,
      contentHeader: intl.formatMessage({
        id: 'users',
        defaultMessage: 'Пользователи',
      }),
      headerCellProps: {
        className: styles.table__head_users,
      },
      bodyCellProps: {
        render: (_, groupUserProps) => (
          <UsersAvatar
            users={groupUserProps?.users as IGroupUser[]}
          />
        ),
      },
    },
    {
      key: EMPTY_CONSTS.STR,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        className: styles.table__body_edit,
        render: (_, groupUserProps) => (
          <GroupDropdown
            refetch={refetch}
            groupUser={
              groupUserProps as unknown as IGroupList
            }
          />
        ),
      },
    },
  ]

  return (
    <TableWrapper
      iconSrc={TABLE_WRAPPER_ICON_SRC.STORAGE}
      search={params.search}
      isLoading={isLoading}
      type={TABLE_PAGE.USER}
      error={error}
      length={groupUsers?.length}
    >
      <div className={styles.container__table}>
        <div className={styles.container__table__content}>
          <Table
            onRow={onRow}
            columns={columns}
            rowsData={groupUsers}
            styles={tableStyles}
          />
        </div>
        <Pagination
          currentPage={currentPage}
          handleChange={handlePage}
          total={total}
          pageSize={params.page_size}
        />
      </div>
    </TableWrapper>
  )
}

export default GroupUsersTable
