import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { FormikProps } from 'formik'
import { GetOptionValue } from 'react-select'

import {
  IOptions,
  TLoadOptions,
} from '@/apps/Modals/ModalContent/interfaces'
import {
  IStorageSerializedOption,
  IStorageSettings,
} from '@/api/interfaces'
import { TStoragePanels } from '../../interfaces'

import { useStores } from '@/hooks'
import { isUndefined } from '@/utils'
import { EMPTY_CONSTS } from '@/const'

import { STORAGE_FIELDS } from '@/apps/MainSidebar/const'
import PaginateAsyncSelect from '@/components/AsyncSelect/PaginateAsyncSelect'
import StorageLayoutView from '@/apps/MainSidebar/views/StorageLayoutView'
import { Button } from '@/views/button'
import Header from './Header'
import { Switch } from '@/views/switch'

import styles from './styles/index.module.scss'
import { Typography } from '@/views/typography'

export interface IStorageSettingsProps {
  handleClose: () => void
  showingPanel: TStoragePanels
  formik: FormikProps<IStorageSettings>
  loadEquipmentOptions: TLoadOptions
  loadUserOptions: TLoadOptions
  showUnlockBtn: boolean
}

interface IDefaultSelectsValues {
  user: Partial<IOptions>
  equipment: Partial<IOptions>
}

/**
 * Вьюха настроек шкафа
 * @view
 * @param handleClose - метод для закртытия настроек
 */

const StorageSettingsView: React.FC<
  IStorageSettingsProps
> = ({
  handleClose,
  formik,
  showUnlockBtn,
  loadEquipmentOptions,
  loadUserOptions,
  showingPanel,
}) => {
  const intl = useIntl()

  const defaultEquipmentOption = useMemo(() => {
    return {
      id: formik.values.equipment_selection_mode,
      label: intl.formatMessage({
        id: `equipment_selection_mode.${formik.values.equipment_selection_mode}`,
      }),
    }
  }, [formik.values.equipment_selection_mode])

  const defaultUserOption = useMemo(() => {
    return {
      id: formik.values.user_selection_mode,
      label: intl.formatMessage({
        id: `user_selection_mode.${formik.values.user_selection_mode}`,
      }),
    }
  }, [formik.values.user_selection_mode])

  const [defaultSelectsValues, setDefaultSelectsValues] =
    useState<IDefaultSelectsValues>({
      user: defaultUserOption,
      equipment: defaultEquipmentOption,
    })

  const { api } = useStores()
  const handleCellReservation = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    void formik.setFieldValue(
      STORAGE_FIELDS.IS_CELL_RESERVATION,
      e.target.checked,
    )
  }
  const handleHandOver = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    void formik.setFieldValue(
      STORAGE_FIELDS.IS_HAND_OVER,
      e.target.checked,
    )
  }

  const getOptionValue: GetOptionValue<IOptions> = (
    option,
  ) => option.id
  const getOptionLabel: GetOptionValue<IOptions> = (
    option,
  ) => option.label

  const handleChangeEquipmentOption = (
    option: IOptions,
  ): void => {
    setDefaultSelectsValues((prev) => ({
      ...prev,
      equipment: option,
    }))
    void formik.setFieldValue(
      STORAGE_FIELDS.EQUIPMENT_SELECTION_MODE,
      option.id,
    )
  }

  const handleChangeUserOption = (
    option: IOptions,
  ): void => {
    setDefaultSelectsValues((prev) => ({
      ...prev,
      user: option,
    }))
    void formik.setFieldValue(
      STORAGE_FIELDS.USER_SELECTION_MODE,
      option.id,
    )
  }
  /**
   * * Метод для получения утсановленных значений в селектах
   * @function getSelectsInitials
   * @param storageSerializedOption
   */
  const getSelectsInitials = (
    storageSerializedOptions: Array<{
      results: IStorageSerializedOption[]
    }>,
  ): Array<Partial<IOptions>> =>
    storageSerializedOptions.map((el, i) => {
      const currentSelectedMode = el.results.find((el) => {
        if (i === 0) {
          return (
            el.id === formik.values.equipment_selection_mode
          )
        } else {
          return el.id === formik.values.user_selection_mode
        }
      })

      return {
        id: currentSelectedMode?.id,
        label: currentSelectedMode?.title,
      }
    })

  useEffect(() => {
    if (!isUndefined(formik.errors.is_cell_reservation)) {
      void formik.setFieldValue(
        STORAGE_FIELDS.IS_CELL_RESERVATION,
        EMPTY_CONSTS.FALSE,
      )
    }
  }, [formik.errors.is_cell_reservation])

  /**
   * * устанавливаем значения с сервера в наши селекты
   */
  useEffect(() => {
    void Promise.all([
      api.storage.getEquipmentSelection(),
      api.storage.getUserSelection(),
    ]).then((response) => {
      const findedInitials = getSelectsInitials(response)

      if (
        !isUndefined(findedInitials[0]?.id) &&
        !isUndefined(findedInitials[1]?.id)
      ) {
        setDefaultSelectsValues({
          equipment: findedInitials[0],
          user: findedInitials[1],
        })
      }
    })
  }, [])

  return (
    <StorageLayoutView
      theme="settings"
      showingPanel={showingPanel}
      data-unlock={showUnlockBtn}
      className={styles.animate}
    >
      <form
        className={styles.settings__form}
        onSubmit={formik.handleSubmit}
      >
        <Header handleClose={handleClose} />
        <div className={styles.settings__main}>
          <div className={styles.settings__block}>
            <div className={styles.settings__row}>
              <Switch
                handleChange={handleHandOver}
                checked={formik.values.is_hand_over}
              >
                <Typography size={14} weight={400}>
                  {intl.formatMessage({
                    id: 'handOver',
                    defaultMessage:
                      'Возможность сдать временно',
                  })}
                </Typography>
              </Switch>
            </div>
            <div className={styles.settings__row}>
              <Switch
                handleChange={handleCellReservation}
                checked={formik.values.is_cell_reservation}
              >
                <Typography size={14} weight={400}>
                  {intl.formatMessage({
                    id: 'cellReservation',
                    defaultMessage:
                      'Возможность резервирования ячеек',
                  })}
                </Typography>
              </Switch>
            </div>
          </div>
          <div className={styles.settings__select}>
            <PaginateAsyncSelect
              loadOptions={loadEquipmentOptions}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              value={defaultSelectsValues?.equipment}
              onChange={handleChangeEquipmentOption}
              label={intl.formatMessage({
                id: 'storageCellMode',
                defaultMessage:
                  'Режим выбора ячеек при получении',
              })}
            />
          </div>
          <div className={styles.settings__select}>
            <PaginateAsyncSelect
              loadOptions={loadUserOptions}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onChange={handleChangeUserOption}
              value={defaultSelectsValues?.user}
              label={intl.formatMessage({
                id: 'storageViewMode',
                defaultMessage:
                  'Режим отображения оборудования при получении',
              })}
            />
          </div>
        </div>
        <div className={styles.settings__footer}>
          <Button
            type="submit"
            color="black"
            disabled={formik.isSubmitting}
          >
            {intl.formatMessage({
              id: 'common.save',
              defaultMessage: 'Сохранить',
            })}
          </Button>
          {/* <Button color='stroke_red'> */}
          {/*   <Icon src='shutDown' /> */}
          {/*   {intl.formatMessage({ */}
          {/*     id: 'switchOff', */}
          {/*     defaultMessage: 'Выключить' */}
          {/*   })} */}
          {/* </Button> */}
        </div>
      </form>
    </StorageLayoutView>
  )
}

export default StorageSettingsView
