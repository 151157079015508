import React, { useState } from 'react'
import { GetOptionValue } from 'react-select'
import { useFormikContext } from 'formik'
import { useStores, useTranslate } from '@/hooks'
import { usePaginateSelect } from '@/apps/Modals/hook'
import { IOptions } from '@/apps/Modals/ModalContent/interfaces'
import { EMPTY_CONSTS } from '@/const'
import { EQUIPMENT_EDIT_FIELDS } from '@/apps/Modals/const'
import PaginateAsyncSelect from '@/components/AsyncSelect/PaginateAsyncSelect'
import { IEquipment } from '@/api/interfaces'

/**
 * Компонет селекта для выбора типа оборудования
 * @component
 */
const TypeSelect: React.FC = () => {
  const { intl, translate } = useTranslate()
  const {
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors
  } = useFormikContext<IEquipment>()
  const [value, setValue] = useState<IOptions | null>(
    () => {
      if (values.equipment_type === '') {
        return EMPTY_CONSTS.NULL
      }
      return {
        id: String(values.equipment_type),
        label: String(values.equipment_type)
      }
    }
  )
  const { api } = useStores()
  const { loadOptions } = usePaginateSelect(
    api.equipment.getEquipmentTypes
  )
  const handleChange = (
    option: IOptions
  ): void => {
    void setFieldValue(
      EQUIPMENT_EDIT_FIELDS.EQUIPMENT_TYPE,
      option.label
    )
    setValue(option)
  }
  const handleBlur = (): void => {
    void setFieldTouched(
      EQUIPMENT_EDIT_FIELDS.EQUIPMENT_TYPE,
      true
    )
  }
  const getOptionValue: GetOptionValue<IOptions> = (option) => option.id
  const getOptionLabel: GetOptionValue<IOptions> = (option) => option.label
  const error =
    Boolean(touched.equipment_type) &&
    Boolean(errors.equipment_type) &&
    errors.equipment_type !== undefined
      ? translate(errors.equipment_type)
      : EMPTY_CONSTS.STR
  const messages = {
    label: intl.formatMessage({
      id: 'equipmentType',
      defaultMessage: 'Тип оборудования'
    }),
    placeholder: intl.formatMessage({
      id: 'select',
      defaultMessage: 'Выберите'
    })
  }
  return (
    <PaginateAsyncSelect
      name={EQUIPMENT_EDIT_FIELDS.EQUIPMENT_TYPE}
      isSearchable
      error={error}
      value={value}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      loadOptions={loadOptions}
      onChange={handleChange}
      onBlur={handleBlur}
      label={messages.label}
      placeholder={messages.placeholder}
      required
    />
  )
}

export default TypeSelect
