import React from 'react'
import { Field, useField } from 'formik'
import { Input } from '@/views/input'
import { Switch } from '@/views/switch'
import { useTranslate } from '@/hooks'
import {
  BOTTOM_FIELDS,
  DEFAULT_INTEGRATION_FIELDS,
} from '@/apps/Integration/const'

import styles from '../style/index.module.scss'
/**
 * @view
 * * нижние поля интеграции скуда
 * * логин и пароль
 */
const BottomFields = (): React.ReactElement => {
  const { translate } = useTranslate()

  const [{ value }] = useField(
    DEFAULT_INTEGRATION_FIELDS.is_need_auth,
  )
  return (
    <>
      <Field name={DEFAULT_INTEGRATION_FIELDS.is_need_auth}>
        {({ field }) => (
          <Switch
            handleChange={field.onChange}
            checked={field.value}
            name={field.name}
            className={styles.switch}
          >
            {translate({
              id: 'secondaryAuthorization',
              defaultMessage: 'Вторичная авторизация',
            })}
          </Switch>
        )}
      </Field>
      <div className={styles.animation} data-show={value}>
        <div className={styles.auth}>
          {BOTTOM_FIELDS.map(
            ({ id, name, label, type, clearable }) => (
              <Field name={name} key={id}>
                {({ field, meta }) => (
                  <Input
                    {...field}
                    outerStyles={styles.field}
                    type={type}
                    clearable={clearable}
                    error={
                      Boolean(meta.error) &&
                      Boolean(meta.touched)
                    }
                    errorMessage={meta.error}
                    label={translate(label)}
                  />
                )}
              </Field>
            ),
          )}
        </div>
      </div>
    </>
  )
}

export default BottomFields
