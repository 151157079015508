import React, { forwardRef } from 'react'

import { Components } from '@garpix/garpix-web-components'
import { GxTabGroup } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'

import style from './styles/index.module.scss'

/**
 * * компонент группирующий табы
 * @vcomponent TabGroup
 *
 * @returns
 */
const TabGroup = forwardRef<Components.GxTabGroup, any>(
  (props, ref): React.ReactElement => {
    const { children = '', className = '', theme } = props
    return (
      <GxTabGroup
        ref={ref}
        {...props}
        data-theme={theme}
        className={classNames({
          [style.tabgroup]: true,
          [className]: Boolean(className),
        })}
        resize="auto"
      >
        {children}
      </GxTabGroup>
    )
  },
)
export default TabGroup
