import React, { useState } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import Profile from '@/views/Profile'
import useEditUserForm from '@/hooks/useEditUserForm'
import { TShowingPanel } from '@/views/Profile/interfaces'
import { USER_SHOWING_PANELS } from '@/const'

const UserProfilePage: React.FC = () => {
  const { actualInitials, ...editUserData } =
    useEditUserForm()
  const [currentShowingPanel, setCurrentShowingPanel] =
    useState<TShowingPanel>(USER_SHOWING_PANELS.ASIDE_LEFT)

  return (
    <MainLayout>
      <Profile.CenterWrap>
        <Profile.AsideLeft
          currentShowingPanel={currentShowingPanel}
          setCurrentShowingPanel={setCurrentShowingPanel}
          initials={actualInitials}
        />
        <Profile.SectionRight
          currentShowingPanel={currentShowingPanel}
          setCurrentShowingPanel={setCurrentShowingPanel}
          actualInitials={actualInitials}
          {...editUserData}
        />
      </Profile.CenterWrap>
    </MainLayout>
  )
}

export default UserProfilePage
