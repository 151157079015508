import { ISocketUpdateCell, ISocketUpdateCellData, ISocketUpdateEquipment } from '@/interfaces/Socket'
import { makeObservable, observable, _autoAction } from 'mobx'

import { PubSub, PubSubProps } from '../PubSub'

import { RootStore } from './Root'

export class EquipmentEventsObserver extends PubSub {
  _ctx: RootStore
  private updateCellCallback?: (data: ISocketUpdateCellData) => void

  constructor(ctx: RootStore) {
    super()

    this._ctx = ctx

    makeObservable(this, {
      ...PubSubProps,
      _ctx: observable,
      onUpdateCell: _autoAction
    })
  }

  public onUpdateCell = ({ data }: ISocketUpdateCell): void => {
    if (this._ctx.currentStorageStore.checkIsStoragePage(data.storage_id)) {
      this.broadcast(data.storage_id)
      this.updateCellCallback?.(data)
    }
  }

  public onUpdateEquipment = ({ data }: ISocketUpdateEquipment): void => {
    this.broadcast(data)
  }

  public setCellCallBack = (fn?: (data: ISocketUpdateCellData) => void) => {
    this.updateCellCallback = fn
  }
}
