import React from 'react'

import { useIntl } from 'react-intl'
import { useStores } from '@/hooks'

import { isUndefined } from '@/utils'
import { getStatusEditStorage } from '../../utils'
import { TStorageStatus } from '@/interfaces/consts'
import { STORAGE_STATUSES } from '@/apps/StorageList/const'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { Typography } from '@/views/typography'

import style from './style/index.module.scss'

interface IProps {
  error: boolean
  projectId?: number
  closeModal: () => void
  hasChangeImportantFields: boolean
}
/**
 * * вью для реализаци хедера модалки создания/редактирования шкафа
 * * в зависимости от текущего состояния фото с шкафом меняет цвет
 * @view
 *
 * @param projectId                id текущего шкафа
 * @param hasChangeImportantFields флаг показывающий изменениы ли поля для переподкл шкафа
 * @param error                    флаг показывающий наличие ошибок в форме
 */

const EditStatus: React.FC<IProps> = ({
  error,
  projectId,
  closeModal,
  hasChangeImportantFields
}) => {
  const {
    currentStorageStore: { storage }
  } = useStores()
  const intl = useIntl()
  const status = getStatusEditStorage({
    id: projectId,
    hasChangeImportantFields,
    error,
    storageStatus:
      storage?.storage_status ??
      (STORAGE_STATUSES.SYNCHRONIZATION_FRONTEND as TStorageStatus)
  })

  const titleText = !isUndefined(projectId)
    ? {
        id: 'storage.edit',
        defaultMessage: 'Редактировать шкаф'
      }
    : {
        id: 'storage.new',
        defaultMessage: 'Новый шкаф'
      }

  return (
    <div className={style['storage-status']}>
      <div
        data-status={status}
        className={style['storage-status__identifier']}
      >
        <Icon
          className={style['storage-status__icon']}
          src='storage'
          size='xl'
        />
      </div>
      <Typography
        className={style['storage-status__title']}
        size={32}
        weight={700}
      >
        {intl.formatMessage(titleText)}
      </Typography>
      <Button
        className={style['storage-status__close']}
        variant='text'
        onClick={closeModal}
      >
        <Icon src='close' />
      </Button>
    </div>
  )
}

export default EditStatus
