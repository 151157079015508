import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import { IValue } from '@/components/AsyncSelect/interfaces'
import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import SearchInput from '@/views/SearchInput'
import { Select } from '@/views/select'

import { useStores } from '@/hooks'

import { IStoragePanel } from '../../interfaces'
import { CUSTOM_DATA_ATTR_STYLES } from '@/apps/Modals/const'
import {
  FILTER_KEY,
  MODAL_TYPES,
  STORAGE_STATUS_FIELDS,
} from '@/const'

import styles from './styles/index.module.scss'

/**
 * @component StoragePanel
 * * компонент верхней панели поиска, фильтрации, добавления оборудования
 *
 * @param params              текущие query параметры
 * @param handleParams        установить дополнительные параметры к текущим query параметрам
 * @param refetch             метод для обновление списка шкафов
 */
const StoragePanel: FC<IStoragePanel> = ({
  handleParams,
  refetch,
  params,
}) => {
  const intl = useIntl()
  const { modalStore } = useStores()
  const storageStatusInitValue = STORAGE_STATUS_FIELDS.find(
    (o) => o.value === params.storage_status,
  )

  /**
   * * метод для открытя модалки создания шкафа
   * @func handleOpenModalEditStorage
   * @returns
   */
  const handleOpenModalCreateStorage = (): void => {
    modalStore.setCustomDataStyles(
      CUSTOM_DATA_ATTR_STYLES.SCROLL,
    )
    modalStore.open(MODAL_TYPES.STORAGE_EDIT, { refetch })
  }

  /**
   * * метод, изменяющий значения фильтра шкафов по статусу
   * @func handleStatusChange
   * @returns
   */
  const handleStatusChange = (el: IValue): void => {
    handleParams({ [FILTER_KEY.STORAGE_STATUS]: el.value })
  }

  return (
    <div className={styles.panel}>
      <Typography size={30} className={styles.panel__title}>
        {intl.formatMessage({
          id: 'header.storage',
          defaultMessage: 'Шкафы',
        })}
      </Typography>
      <div className={styles.panel__search}>
        <SearchInput
          handleParams={handleParams}
          className={styles.panel__search_input}
          size={44}
        />
        <Select
          label={intl.formatMessage({
            id: 'filter.connection_status',
            defaultMessage: 'Статус подключения',
          })}
          onChange={handleStatusChange}
          value={storageStatusInitValue}
          options={STORAGE_STATUS_FIELDS}
          className={styles.panel__search_select}
          placeholder={intl.formatMessage({
            id: 'input.all',
            defaultMessage: 'All',
          })}
        />
        <Button
          color="black"
          className={styles.panel__search_add}
          data-cy="addlocker"
          onClick={handleOpenModalCreateStorage}
        >
          <Icon size="xs" src="plus" />
          {intl.formatMessage({
            id: 'button.add_storage',
            defaultMessage: 'Добавить шкаф',
          })}
        </Button>
      </div>
    </div>
  )
}

export default StoragePanel
