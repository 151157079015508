import React from 'react'

import { useTranslate, useStores } from '@/hooks'

import { FormikProps } from 'formik'
import { TStorageGetValue } from '@/api/interfaces'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'

import { isUndefined } from '@/utils'
import { STORAGE_EDIT_CORE_FIELDS } from '@/const'

import { Typography } from '@/views/typography'
import Textarea from '@/views/Textarea'
import { Input } from '@/views/input'
import Icon from '@/components/Icon'

import style from '../style/index.module.scss'
import { STORAGE_STATUSES } from '@/apps/StorageList/const'

interface IProp {
  formikTools: FormikProps<TStorageGetValue>
  hasChangeImportantFields: boolean
  handleChangedImportantFields: (
    e: GxInputCustomEvent<HTMLGxInputElement>
  ) => void
  projectId?: number
}

const CoreFields: React.FC<IProp> = ({
  handleChangedImportantFields,
  formikTools,
  hasChangeImportantFields,
  projectId
}) => {
  const { currentStorageStore } = useStores()
  const storage = currentStorageStore?.storage
  const showInfoAboutChangedImportantFields =
    hasChangeImportantFields &&
    storage?.storage_status === STORAGE_STATUSES.SUCCES

  const { translate } = useTranslate()
  const {
    handleBlur,
    values,
    handleChange,
    touched,
    errors
  } = formikTools

  const isStorageEdit = !isUndefined(projectId)
  const secretKeyLable = isStorageEdit
    ? { id: 'newSecretKey', defaultMessage: 'Новый secret key' }
    : 'Secret key'
  return (
    <div>
      <div className={style['storage-edit__top']}>
        <Input
          label={translate({
            id: 'storage.serialNumber',
            defaultMessage: 'Название'
          })}
          name='serial_number'
          type='text'
          value={values.serial_number}
          onChange={handleChange}
          onBlur={handleChangedImportantFields}
          error={
            Boolean(touched.serial_number) &&
            Boolean(errors.serial_number)
          }
          errorMessage={errors.serial_number}
          required
        />
        <Input
          label={translate(secretKeyLable)}
          name='storage_secret_key'
          type='password'
          value={values.storage_secret_key}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            Boolean(touched.storage_secret_key) &&
            Boolean(errors.storage_secret_key)
          }
          errorMessage={errors.storage_secret_key}
          required={!isStorageEdit}
        />
      </div>
      <div className={style['storage-edit__mb']}>
        {STORAGE_EDIT_CORE_FIELDS.map(
          ({ id, label, name, required }) => {
            return (
              <Input
                key={id}
                name={name}
                label={translate(label)}
                value={values[name]}
                onChange={handleChange}
                onBlur={handleChangedImportantFields}
                error={
                  Boolean(touched[name]) &&
                  Boolean(errors[name])
                }
                errorMessage={errors[name]}
                outerStyles={style['storage-edit__input']}
                required={required}
              />
            )
          }
        )}
        <Textarea
          name='description'
          label='Описание'
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {showInfoAboutChangedImportantFields && (
          <div
            className={
              style['storage-edit__changed-fields']
            }
          >
            <Icon src='statusNotConnected' size='medium' />
            <Typography weight={400} size={14}>
              {translate({
                id: 'storage.changedRequiredFields',
                defaultMessage:
                  'Были изменены важные поля, необходимо переподключить шкаф'
              })}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default CoreFields
