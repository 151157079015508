import React, { FC } from 'react'

import { useFormikContext } from 'formik'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'

import { useTranslate } from '@/hooks'

import { Input } from '@/views/input'
import { ISelectValue, Select } from '@/views/select'
import MultiTypeSelect from '../MultiTypeSelect'

import {
  EMPTY_CONSTS,
  PROBLEM_KEY,
  PROBLEM_TYPE_SELECT_FIELDS
} from '@/const'

import { IProblemEdit } from '@/api/interfaces'

import style from '../style/index.module.scss'

/**
 * * основной компонент с полями для создания/редактирования проблемы
 * @component CoreFields
 */
const CoreFields: FC = () => {
  const { values, handleChange, errors, setErrors } = useFormikContext<IProblemEdit>()
  const { translate } = useTranslate()
  const problemType = PROBLEM_TYPE_SELECT_FIELDS.find(o => o.value === values.type)

  const handleTitleChange = (
    e: React.ChangeEvent<any> | GxInputCustomEvent<any>
  ): void => {
    setErrors(EMPTY_CONSTS.OBJ)
    handleChange(e)
  }

  const handleSelectChange = ({ value }: ISelectValue): void => {
    setErrors(EMPTY_CONSTS.OBJ)
    handleChange({ target: { name: PROBLEM_KEY.TYPE, value } })
  }

  return (
    <div className={style.problem__fields}>
      <Input
        name={PROBLEM_KEY.TITLE}
        label={translate({ id: 'name', defaultMessage: 'Название' })}
        value={values.title}
        onChange={handleTitleChange}
        error={Boolean(errors.title)}
        errorMessage={errors.title}
        outerStyles={style.problem__input}
        required={EMPTY_CONSTS.TRUE}
      />
      <Select
        label={translate({ id: 'problem.type', defaultMessage: 'Тип проблемы' })}
        placeholder={translate({ id: 'select', defaultMessage: 'Выберите' })}
        onChange={handleSelectChange}
        name={PROBLEM_KEY.TYPE}
        value={problemType}
        options={PROBLEM_TYPE_SELECT_FIELDS}
        required
      />
      <MultiTypeSelect />
    </div>
  )
}

export default CoreFields
