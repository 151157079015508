import React from 'react'

import { OptionProps, components } from 'react-select'
import Checkbox from '../Checkbox'

import style from './styles/index.module.scss'
/**
 * @component
 * AsyncSelect Option custom
 */
const Option = (props: OptionProps): React.ReactElement => {
  if (props.isMulti) {
    return (
      <components.Option {...props}>
        <Checkbox checked={props.isSelected} />
        <label className={style.async__option_label}>
          {props.label}
        </label>
      </components.Option>
    )
  }

  return <components.Option {...props} />
}

export default Option
