import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { GarpixCMSProvider } from '@garpix/cms';
import { IntlProvider } from 'react-intl';
import Combine from './pages/Combine';
import { PATHS } from './const';
import {
  defaultLanguage,
  htmlLang,
  messages,
} from './i18n';
import useLocale from './hooks/useLocale';
import { useStores } from './hooks';

const App = ({ context }: any): React.ReactElement => {
  const { locale } = useLocale();
  const store = useStores();
  console.log('context', context);

  return (
    <GarpixCMSProvider
      isAutoGetSessionToken={false}
      baseUrl={store.URL}
      language={htmlLang[locale]}
    >
      <IntlProvider
        messages={messages[locale]}
        locale={locale}
        defaultLocale={defaultLanguage}
      >
        <Routes>
          <Route
            path={PATHS.ALL.path}
            element={
              <Combine
                staticContext={context}
                {...PATHS.ALL}
              />
            }
          />
        </Routes>
      </IntlProvider>
    </GarpixCMSProvider>
  );
};

export default App;
