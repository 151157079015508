import styles from '../styles/index.module.scss'

export const tableStyles = {
  classNameTable: styles.table,
  classNameBodyRow: styles.table__body_row,
  classNameHeaderRow: styles.table__head_row,
}

export const STORAGE_STATUSES = {
  SUCCES: 'SUCCESS',
  SYNCHRONIZATION_BACKEND: [
    'NEW',
    'WAIT',
    'START_SYNCHRONIZATION',
    'SYNCHRONIZATION',
  ],
  SYNCHRONIZATION_FRONTEND: 'SYNCHRONIZATION',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
}

export const QUERIES = {
  small: '(max-height: 1024px)',
  medium: '(min-height: 1025px) and (max-height: 1199px)',
  large: '(min-height: 1200px) and (max-height: 1399px)',
  extra: '(min-height: 1400px)',
}
