import { useState } from 'react'

import { observable, ObservableMap } from 'mobx'

interface IReturnData<T, K> {
  observableMap: ObservableMap<T, K>
  setObservableMap: (key: T, value: K) => void
  clearObservableMap: () => void
  deleteValueObservableMap: (key: T) => void
}
/**
 * @hook
 * * хук для создания наблюдаемой Map
 */
const useObservableMap = <T, K>(): IReturnData<T, K> => {
  const [observableMap] = useState<ObservableMap<T, K>>(() => observable.map())
  /**
   * метод для установки значения по какому то ключу
   * @func setObservableMap
   * @param key   - ключ для установки
   * @param value - значене
   */
  const setObservableMap = (key: T, value: K): void => {
    observableMap.set(key, value)
  }
  /**
   * метод для удаления значения по какому то ключу
   * @func deleteValueObservableMap
   * @param key   - ключ для удаления
   */
  const deleteValueObservableMap = (key: T): void => {
    observableMap.delete(key)
  }
  /**
   * метод для полной отчистки Map
   * @func clearObservableMap
   */
  const clearObservableMap = (): void => {
    observableMap.clear()
  }

  return {
    observableMap,
    setObservableMap,
    clearObservableMap,
    deleteValueObservableMap
  }
}

export default useObservableMap
