import * as yup from 'yup'
import { ACCOUNT_TYPE, INTL_DEFAULT_MESSAGE } from '@/const'
import { REGEX } from '../validation'

const UserEditSheme = yup.object().shape({
  account_type: yup
    .string()
    .oneOf([ACCOUNT_TYPE.USER, ACCOUNT_TYPE.EMPLOYEE])
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  last_name: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  first_name: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  email: yup
    .string()
    .trim()
    .matches(REGEX.email, {
      message: INTL_DEFAULT_MESSAGE.UNCORRECT_EMAIL
    })
    .when('account_type', {
      is: ACCOUNT_TYPE.EMPLOYEE,
      then: (shema) =>
        shema.required(INTL_DEFAULT_MESSAGE.REQUIRED),
      otherwise: (shema) => shema.optional()
    }),
  card_hid_number: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
})

export default UserEditSheme
