import React, {
  useCallback,
  useMemo,
  useState
} from 'react'

import classNames from 'classnames'
import Creatable from 'react-select/creatable'
import { withAsyncPaginate } from 'react-select-async-paginate'
import getCustomSelectStyles from '@/components/AsyncSelect/styles'

import IndicatorsContainer from './IndicatorsContainer'
import Option from './Option'

import { useTranslate } from '@/hooks'

import { TAsyncPaginateCreatable } from '@/components/AsyncSelect/interfaces'

import { EMPTY_CONSTS } from '@/const'

import style from './styles/index.module.scss'

/**
 * Селект с пагинаций и возможность создания
 */
const CreatableAsyncPaginate = withAsyncPaginate(
  Creatable
) as TAsyncPaginateCreatable

const PaginateAsyncSelect: TAsyncPaginateCreatable = (
  props
) => {
  const {
    error = EMPTY_CONSTS.STR,
    label,
    labelClassName = EMPTY_CONSTS.STR,
    labelLeft = EMPTY_CONSTS.FALSE,
    loadOptions,
    required,
    disabled,
    noOptionsText = {
      id: 'select.no_option',
      defaultMessage: 'Нет вариантов'
    },
    ...restProps
  } = props as unknown as any
  const [open, setOpen] = useState(false)
  const closeDropdown = (): void => {
    setOpen(false)
  }
  const openDropdown = (): void => {
    setOpen(true)
  }
  const { translate } = useTranslate()

  const getNoOptionsMessage = useCallback(() => translate(noOptionsText), [translate])

  const getLoadingMessage = useCallback(
    () =>
      translate({
        id: 'select.loading',
        defaultMessage: 'Загрузка'
      }),
    [translate]
  )

  const getCreatableLabel = useCallback(
    (inputValue: string): string =>
      `${translate({
        id: 'create',
        defaultMessage: 'Создать'
      })}: ${inputValue}`,
    [translate]
  )

  const styles = useMemo(
    () => getCustomSelectStyles(Boolean(error)) as any,
    [error]
  )

  return (
    <label
      className={classNames({
        [style.async__label]: true,
        [style.error]: Boolean(error),
        [style['async__label--left']]: labelLeft,
        [labelClassName]: Boolean(labelClassName)
      })}
    >
      <span
        className={classNames({
          [style.required]: required,
          [style.async__label_text]: true
        })}
      >
        {label}
      </span>
      <CreatableAsyncPaginate
        isSearchable={false}
        debounceTimeout={500}
        createOptionPosition='first'
        isDisabled={disabled}
        {...restProps}
        noOptionsMessage={getNoOptionsMessage}
        onMenuClose={closeDropdown}
        onMenuOpen={openDropdown}
        loadOptions={loadOptions}
        styles={styles}
        components={{
          Option,
          IndicatorsContainer: () => (
            <IndicatorsContainer
              open={open}
              error={error}
              disabled={disabled}
            />
          )
        }}
        loadingMessage={getLoadingMessage}
        formatCreateLabel={getCreatableLabel}
      />
      {error !== EMPTY_CONSTS.STR
        ? (
          <span className={style.async__label_error}>
            {error}
          </span>
          )
        : null}
    </label>
  )
}

export default PaginateAsyncSelect
