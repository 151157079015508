import React, { RefObject, ReactNode } from 'react'

import { observer } from 'mobx-react'
import { EMPTY_CONSTS } from '@/const'

import { Spinner } from '@/views/spinner'

import EmptySearch from '@/views/EmptySearch'

import style from './style/index.module.scss'

export interface IScrollWrapProps {
  dataCount: number
  children?: ReactNode
  isLoading: boolean
  listRef: RefObject<HTMLUListElement> | null
  scrollEvent: () => void
}
/**
 * @view
 * * вьюха для реализации блока с ленивой подгрузкой
 *
 * @param dataCount - колличество подгруженных элементов
 * @param isLoading - флаг говорящий о загрузке
 * @param listRef - ссылка на блок реализующий скролл
 * @param scrollEvent - ивент для блока ленивой подгрузки
 * @returns
 */
const ScrollWrap: React.FC<IScrollWrapProps> = ({
  dataCount,
  listRef,
  isLoading,
  scrollEvent,
  children
}) => {
  if (isLoading && dataCount === EMPTY_CONSTS.ZERO) {
    return <Spinner className={style.loader} />
  }

  return (
    <ul
      ref={listRef}
      onScroll={scrollEvent}
      className={style['scroll-wrap']}
    >
      {dataCount !== EMPTY_CONSTS.ZERO
        ? (<> {children} </>)
        : (<EmptySearch className={style.empty} />)}
    </ul>
  )
}

export default observer(ScrollWrap)
