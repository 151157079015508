import React from 'react'

import { useIntl } from 'react-intl'

import { Select } from '@/views/select'

import {
  EMPTY_STATUS,
  FILTER_KEY,
  USER_ACCOUNT_TYPE_FIELDS,
  USER_EQUIPMENT_STATUS_FIELDS,
  USER_ROLE_FIELDS
} from '@/const'

import { IValue } from '@/components/AsyncSelect/interfaces'

/**
 * @comp
 * * Компонент фильтров пользователя в деталке шкафа
 * ! Будет доделано в отдельной таске
 */
const StorageUserFilterField: React.FC<any> = ({
  currentFilter,
  handleParamsChange
}) => {
  const intl = useIntl()
  const {
    user_role: userRole = EMPTY_STATUS,
    account = EMPTY_STATUS,
    equipment_status: equipmentStatus = EMPTY_STATUS
  } = currentFilter
  const userEquipInitStatus = USER_EQUIPMENT_STATUS_FIELDS.find(o => o.value === equipmentStatus?.value)
  const accountTypeInitValue = USER_ACCOUNT_TYPE_FIELDS.find(o => o.value === account?.value)
  const userRoleInitValue = USER_ROLE_FIELDS.find(o => o.value === userRole?.value)
  const placeholder = intl.formatMessage({
    id: 'select',
    defaultMessage: 'Выберите...'
  })

  /**
   * @function handleChangeUserRole
   * * метод для смены фильтра роли пользователя
   * @param el
   */
  const handleChangeUserRole = (el: IValue): void => {
    handleParamsChange(FILTER_KEY.USER_ROLE, el)
  }
  /**
   * @function handleChangeUserRole
   * * метод для смены фильтра статуса оборудования
   * @param el
   */
  const handleStatusChange = (el: IValue): void => {
    handleParamsChange(FILTER_KEY.EQUIPMENT_STATUS, el)
  }

  /**
   * @function handleChangeUserRole
   * * метод для смены фильтра роли пользователя
   * @param el
   */
  const handleChangeAccountType = (el: IValue): void => {
    handleParamsChange(FILTER_KEY.ACCOUNT_TYPE, el)
  }

  return (
    <>
      <Select
        label={intl.formatMessage({
          id: 'filter.user_account_type',
          defaultMessage: 'Учетная запись'
        })}
        value={accountTypeInitValue}
        onChange={handleChangeAccountType}
        options={USER_ACCOUNT_TYPE_FIELDS}
        placeholder={placeholder}
      />
      <Select
        label={intl.formatMessage({
          id: 'filter.user_role',
          defaultMessage: 'Роль пользователя'
        })}
        value={userRoleInitValue}
        onChange={handleChangeUserRole}
        options={USER_ROLE_FIELDS}
        placeholder={placeholder}
      />
      <Select
        label={intl.formatMessage({
          id: 'filter.equipment_status',
          defaultMessage: 'Статус оборудования'
        })}
        onChange={handleStatusChange}
        value={userEquipInitStatus}
        options={USER_EQUIPMENT_STATUS_FIELDS}
        placeholder={placeholder}
      />
    </>
  )
}

export default StorageUserFilterField
