import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'

import { useStores } from '@/hooks'
import { Select } from '@/views/select'
import AsyncSelect from '@/components/AsyncSelect/AsyncSelect'

import {
  EMPTY_CONSTS,
  EQUIPMENT_STATUS_FIELDS,
  FILTER_KEY
} from '@/const'
import { IFilterFields } from '../interfaces'
import { IValue, TAsyncSelectHandleChange } from '@/components/AsyncSelect/interfaces'

/**
 * * основной компонент с полями для фильтра оборудования
 * @component EquipmentFilterFields
 *
 * @param  currentFilter           текущие параметры фильтра
 * @param  handleParamsChange      функция для изменения текущих параметров
 * @param  setError                функция, задающая ошибку
 */
const EquipmentFilterFields: FC<IFilterFields> = ({
  isStorageEquipment = EMPTY_CONSTS.FALSE,
  currentFilter,
  handleParamsChange,
  handleFetchError,
  storage
}) => {
  const {
    status = EMPTY_CONSTS.NULL,
    equipment_type: equipmentType
  } = currentFilter
  const intl = useIntl()
  const { api } = useStores()
  const storageValue = intl.formatMessage({ id: 'select', defaultMessage: 'Выберите' })
  const equipStatusInitValue = EQUIPMENT_STATUS_FIELDS.find(o => o.value === status?.value)
  const [currentEquipmentType, setCurrentEquipmentType] = useState<IValue | undefined>(equipmentType)
  const [currentStorage, setCurrentStorage] = useState<IValue | undefined>(storage)

  const handleStorageChange: TAsyncSelectHandleChange = (el) => {
    const storage = el ?? EMPTY_CONSTS.UNDEFINED
    handleFetchError(EMPTY_CONSTS.NULL)
    setCurrentStorage(storage)
    handleParamsChange(FILTER_KEY.STORAGE_ID, el)
  }

  const handleEquipTypeChange: TAsyncSelectHandleChange = (el) => {
    const equipment = el ?? EMPTY_CONSTS.UNDEFINED
    handleFetchError(EMPTY_CONSTS.NULL)
    setCurrentEquipmentType(equipment)
    handleParamsChange(FILTER_KEY.EQUIPMENT_TYPE, el)
  }

  const handleStatusChange: TAsyncSelectHandleChange = (el) => {
    handleParamsChange(FILTER_KEY.STATUS, el)
  }

  return (
    <>
      {
        !isStorageEquipment &&
          <AsyncSelect
            handleChange={handleStorageChange}
            loadFunction={api.storage.getStorages}
            value={currentStorage}
            label={intl.formatMessage({
              id: 'storage',
              defaultMessage: 'Шкаф'
            })}
            placeholder={storageValue}
            handleFetchError={handleFetchError}
          />
      }
      <AsyncSelect
        handleChange={handleEquipTypeChange}
        loadFunction={api.equipment.getEquipmentTypes}
        value={currentEquipmentType}
        label={intl.formatMessage({
          id: 'equipmentType',
          defaultMessage: 'Тип оборудования'
        })}
        placeholder={storageValue}
        handleFetchError={handleFetchError}
      />
      <Select
        label={intl.formatMessage({ id: 'filter.status', defaultMessage: 'Статус' })}
        onChange={handleStatusChange}
        value={equipStatusInitValue}
        options={EQUIPMENT_STATUS_FIELDS}
        placeholder={storageValue}
      />
    </>
  )
}

export default EquipmentFilterFields
