import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { usePage, useStores } from '@/hooks'
import {
  EMPTY_CONSTS,
  MODAL_TYPES,
  PAGE_TYPE,
} from '@/const'
import {
  IModal,
  IOptions,
} from '@/apps/Modals/ModalContent/interfaces'
import UploadExcelView from '@/apps/Modals/views/UploadExcelView'
import { usePaginateSelect } from '@/apps/Modals/hook'
import { isNull, isUndefined } from '@/utils'
import { IHidDefaultValue } from '@/api/interfaces'

/**
 * Компонент модалки загрузки ексель
 *
 * @param closeModal
 * @constructor
 */

const UploadExcel: React.FC<IModal> = ({ closeModal }) => {
  const intl = useIntl()
  const {
    api,
    modalStore,
    currentStorageStore: { storage },
  } = useStores()
  const { error, loadOptions } = usePaginateSelect(
    api.storage.getStorages
  )
  const { page } = usePage()
  const haveInitialValues =
    page.page_model === PAGE_TYPE.storagePage &&
    !isNull(storage)

  const initialSelectValue = {
    label: storage?.title ?? EMPTY_CONSTS.STR,
    id: String(storage?.id) ?? EMPTY_CONSTS.STR,
  }

  const [selectValues, setSelectValues] = useState<
    IOptions | IOptions[] | null
  >(
    haveInitialValues
      ? initialSelectValue
      : EMPTY_CONSTS.NULL
  )

  const [hidSelectValue, setHidSelectValue] =
    useState<IHidDefaultValue | null>(null)

  const [key, setKey] = useState(Date.now())
  const ref = useRef<HTMLInputElement>(EMPTY_CONSTS.NULL)

  const handleChange = (payload: IOptions): void => {
    setSelectValues(payload)
  }

  const handleAccept = (): void => {
    ref.current?.click()
  }
  /**
   * Метод для отправки ексель и выбранных шкафов
   */
  const handleLoad = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let storageId: string | undefined
    const files = e.target.files

    if (Array.isArray(selectValues)) {
      storageId =
        selectValues != null
          ? selectValues.map((el) => el.id).join()
          : undefined
    } else {
      storageId = selectValues?.id
    }
    if (!isNull(files)) {
      const hidFormat = !isNull(hidSelectValue) ? hidSelectValue.value : EMPTY_CONSTS.UNDEFINED
      
      api.user
        .createExcel({
          file: files[0],
          storage: storageId,
          hid_format: hidFormat
        })
        .then(() => closeModal())
        .catch(() => {
          modalStore.open(MODAL_TYPES.FILE_UPLOAD_ERROR)
        })
        .finally(() => {
          setKey(Date.now())
        })
    }
  }

  const label = intl.formatMessage({
    id: 'attachToCupboard',
    defaultMessage: 'Привязать к шкафу',
  })

  const selectProps = {
    loadOptions,
    onChange: handleChange,
    error,
    value: selectValues,
    closeMenuOnSelect: false,
    isMulti: !haveInitialValues,
  }

  const hidSelectProps = {
    handleChange: setHidSelectValue as (e: IHidDefaultValue | null) => void,
    value: hidSelectValue as IHidDefaultValue,
  }
  /**
   * * подстановка начального значения с бэка
   */
  useEffect(() => {
    api.user.getHidFormats().then((response) => {
      setHidSelectValue(
        !isUndefined(response.defaultValue)
          ? response.defaultValue
          : EMPTY_CONSTS.NULL
      )
    })
  }, [])

  return (
    <UploadExcelView
      label={label}
      selectProps={selectProps}
      handleLoad={handleLoad}
      handleAccept={handleAccept}
      handleClose={closeModal}
      inputRef={ref}
      inputKey={String(key)}
      hidSelectProps={hidSelectProps}
    />
  )
}

export default UploadExcel
