import React from 'react'

import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'
import { ObservableMap } from 'mobx'
import { observer } from 'mobx-react'
import { getInitials } from '@/utils'

import Checkbox from '@/components/Checkbox'
import Badge from '@/components/Badge'
import { Typography } from '@/views/typography'

import style from '../style/index.module.scss'

interface IProps {
  id: number
  userIdList: ObservableMap<number, number>
  firstName: string
  lastName: string
  handleChangeUserList: (e: GxCheckboxCustomEvent<HTMLGxCheckboxElement>) => void
  fullName: string
}
/**
 * @view
 * * Вью плашки с юзером (Checlbox, превью и полное имя)
 * @param param0
 * @returns
 */
const UserItem: React.FC<IProps> = ({
  id,
  fullName,
  firstName,
  lastName,
  userIdList,
  handleChangeUserList
}) => {
  const isChecked = Boolean(userIdList.get(id))
  const title = getInitials(firstName, lastName)

  return (
    <li className={style['user-binding__item']}>
      <Checkbox
        data-id={id}
        checked={isChecked}
        onGx-change={handleChangeUserList}
        className={style['user-binding__item_checkbox']}
      >
        <Badge title={title} />
        <Typography className={style['user-binding__item_title']} weight={400} size={14}>
          {fullName}
        </Typography>
      </Checkbox>
    </li>
  )
}

export default observer(UserItem)
