import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'
import { THEME_MODAL } from '@/const'

// todo: рефакторинг поведения модалок [LOCKER-367]
export class ModalStore {
  variant: string | null
  rootStore: RootStore
  modalProps: any
  isOpen: boolean
  customDataStyles: string | null

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.isOpen = false
    this.variant = null // это вариант контента
    this.modalProps = {
      theme: THEME_MODAL.DEFAULT // это пресет со стилями для саммой модалки
    }
    this.customDataStyles = null // дата атрибут для кастомных стилей
    makeAutoObservable(this)
  }

  get theme (): string {
    return this.modalProps.theme
  }

  get currentVariant (): string | null {
    return this.variant
  }

  setCustomDataStyles = (prop: string) => {
    this.customDataStyles = prop
  }

  setModalProps = (props: any): void => {
    this.modalProps = props
  }

  setTheme = (theme: string): string => {
    this.modalProps.theme = theme
    return this.modalProps.theme
  }

  open = (name: string, modalProps?: unknown): void => {
    this.variant = name
    this.modalProps = modalProps
    this.isOpen = true
  }

  close = (): void => {
    this.variant = null
    this.isOpen = false
    this.modalProps = null
    this.customDataStyles = null
  }
}
