import React, { useState } from 'react'
import { useMutation, useStores } from '@/hooks'
import {
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES
} from '@/const'
import { isUndefined } from '@/utils'
import { IDownloadReportParams } from '@/api/interfaces'

export type TUseDatePicker = <
  T extends (params: IDownloadReportParams) => Promise<any>
>(
  query: T,
  id: number
) => {
  id: number
  startDate: string | undefined
  endDate: string | undefined
  handleEndChange: React.Dispatch<
  React.SetStateAction<string>
  >
  handleStartChange: React.Dispatch<
  React.SetStateAction<string>
  >
  handleDownload: () => void
  isLoading: boolean
}
const useDatePicker: TUseDatePicker = (
  query,
  id
) => {
  const { modalStore } = useStores()
  const [start, setStart] = useState<string | undefined>(
    EMPTY_CONSTS.UNDEFINED
  )
  const [end, setEnd] = useState<string | undefined>(
    EMPTY_CONSTS.UNDEFINED
  )

  const { mutation, isLoading } =
    useMutation({
      mutation: async () =>
        await query({
          id,
          date_end: end,
          date_start: start
        }),
      onError: (error) => {
        if (
          !isUndefined(error.response?.data?.date_end) ||
          !isUndefined(error.response?.data?.date_start)
        ) {
          modalStore.open(
            MODAL_TYPES.CUSTOM,
            MODAL_CUSTOM_CONTENT.DATE_REPORT_ERROR
          )
        } else {
          modalStore.open(
            MODAL_TYPES.CUSTOM,
            MODAL_CUSTOM_CONTENT.DATE_REPORT_ERROR
          )
        }
      }
    })
  return {
    id,
    startDate: start,
    endDate: end,
    handleEndChange: setEnd,
    handleStartChange: setStart,
    handleDownload: mutation,
    isLoading
  }
}

export default useDatePicker
