import React, { FC } from 'react'
import classNames from 'classnames'
import { Pagination as PaginationComponent } from '@garpix/pagination/react'
import { IPagination } from '@garpix/pagination/core'
import Icon from '../Icon'
import { ICustomPagination } from './interfaces'
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE, DEFAULT_TOTAL_PAGES } from '@/const'
import style from './styles/index.module.scss'

/**
 * @component Pagination
 * * компонент пагинации страниц
 * @param {ICustomPagination}        общий интерфейс входных параметров Pagination
 *
 * @param currentPage         текущая страница
 * @param total               общее количество пагинируемых элементов
 * @param pageSize            количество отображаемых эелементов на странице
 * @param handleChange        функция передающаяся в пагинацию для изменения текущей страницы
 * @param currentPage         текущая страница
 * @param className           дополнительные стили
 */
const Pagination: FC<ICustomPagination> = ({
  currentPage = DEFAULT_CURRENT_PAGE,
  total = DEFAULT_TOTAL_PAGES,
  pageSize = DEFAULT_PAGE_SIZE,
  handleChange,
  className = ''
}) => {
  const classes = classNames({
    [style.pagination]: true,
    [className]: Boolean(className)
  })

  return (
    <PaginationComponent
      currentPage={currentPage}
      total={total}
      pageSize={pageSize}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage
      }: IPagination) => {
        if (total > pageSize) {
          return (
            <div className={classes}>
              <button
                className={style.pagination__backicon}
                disabled={!hasPreviousPage}
                onClick={() => handleChange(currentPage - 1)}
              >
                <Icon src='arrowBack' size='xs' />
              </button>
              {pages.map((item, i) => {
                if (typeof item === 'string') {
                  return <span key={`dots_${i}`} className={style.pagination__points}>...</span>
                }
                return (
                  <button
                    className={classNames(style.pagination__button,
                      { [style.pagination__active]: Boolean(item === currentPage) }
                    )}
                    onClick={() => handleChange(item)}
                    key={item}
                  >
                    {item}
                  </button>
                )
              })}
              <button
                className={style.pagination__nexticon}
                disabled={!hasNextPage}
                onClick={() => handleChange(currentPage + 1)}
              >
                <Icon src='arrowRight' size='xs' />
              </button>
            </div>
          )
        }
      }}
    </PaginationComponent>
  )
}

export default Pagination
