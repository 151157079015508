import React, { ReactNode } from 'react'

import styles from './styles/index.module.scss'

const EquipmentLayout: React.FC<{ children: ReactNode }> = (props) => {
  return (
    <div className={styles.layout}>
      {props.children}
    </div>
  )
}

export default EquipmentLayout
