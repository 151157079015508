import React, { useState } from 'react'
import classNames from 'classnames'
import Icon from '@/components/Icon'
import styles from './styles/index.module.scss'

interface IAccordionProps {
  head: React.ReactNode | string
  children: React.ReactNode | string
  open?: boolean
  className?: string
  theme?: 'problem'
}

const Accordion = ({
  head,
  children,
  open = false,
  className = '',
  theme,
}: IAccordionProps): React.ReactElement => {
  const [opened, setOpened] = useState(open)

  const handleToggleOpened = (): void => {
    setOpened((prev) => !prev)
  }
  return (
    <div
      data-open={opened}
      className={classNames({
        [className]: Boolean(className),
        [styles[`accordion__${theme}` ?? '']]:
          Boolean(theme),
      })}
    >
      <button
        data-open={opened}
        className={classNames({
          [styles.accordion__head]: true,
        })}
        onClick={handleToggleOpened}
      >
        {head}

        <Icon
          src="arrAccordion"
          className={classNames({
            [styles.accordion__icon]: true,
            [styles['accordion__icon--opened']]: !!opened,
          })}
        />
      </button>
      <div
        className={classNames({
          [styles.accordion__body]: true,
          [styles['accordion__body--opened']]: !!opened,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
