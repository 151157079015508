import React, { ReactNode } from 'react'

import SeoWrapper from '@/components/SeoWrapper'

import style from '../style/index.module.scss'

interface IProps {
  children: ReactNode
}

/**
 * @view
 * * Обертка для стр ошибок
 */

const ErrorLayout: React.FC<IProps> = ({ children }) => {
  return (
    <SeoWrapper>
      <main className={style.error}>
        <section className={style.error__section}>{children}</section>
      </main>
    </SeoWrapper>
  )
}

export default ErrorLayout
