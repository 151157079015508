import React from 'react'
import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import BaseContentViews from '../BaseContentViews'
import {
  FullNameWithBadgeView,
  HidView,
  FullNameWithHidView,
} from './ContentsForBanner'
import { Button } from '@/views/button'
import { Typography } from '@/views/typography'

import { UserDataPropsType } from './types'

import { getIntegrationErrContentVariant } from '../../utils'

import { INTEGRATION_ERR_CONTENT_KEYS } from '../../const'

import styles from './styles/index.module.scss'

const contentVariants = {
  [INTEGRATION_ERR_CONTENT_KEYS.FULL_NAME]:
    FullNameWithBadgeView,
  [INTEGRATION_ERR_CONTENT_KEYS.HID]: HidView,
  [INTEGRATION_ERR_CONTENT_KEYS.HID_AND_FULL_NAME]:
    FullNameWithHidView,
}

interface IIntegrationSyncErrorViewProps {
  message: string
  closeModal: () => void
  userData?: UserDataPropsType
}
/**
 * @view
 * * Вьюха модалки с ошибкой инеграции
 */
const IntegrationSyncErrorView: React.FC<
  IIntegrationSyncErrorViewProps
> = ({ message, closeModal, userData }) => {
  const intl = useIntl()

  const CurrentContent =
    contentVariants[
      getIntegrationErrContentVariant(userData)
    ]

  return (
    <BaseContentViews.Wrapper
      className={styles['sync-error']}
    >
      <BaseContentViews.Header
        colorIconWrapper="red"
        iconSlot={<Icon src="statusNotConnected" />}
      >
        <div className={styles['sync-error__header_text']}>
          <Typography size={20} weight={700}>
            {intl.formatMessage({
              id: 'modal.title.sync_skud',
              defaultMessage: 'Синхронизация',
            })}
          </Typography>
          {message && <p>{message}</p>}
        </div>
      </BaseContentViews.Header>
      <BaseContentViews.BannerContent
        className={styles['sync-error__banner']}
      >
        <CurrentContent userData={userData} />
      </BaseContentViews.BannerContent>
      <BaseContentViews.ButtonWrapper>
        <Button
          onClick={closeModal}
          height={44}
          color="black"
        >
          {intl.formatMessage({
            id: 'common.its_ok',
            defaultMessage: 'Понятно',
          })}
        </Button>
      </BaseContentViews.ButtonWrapper>
    </BaseContentViews.Wrapper>
  )
}

export default IntegrationSyncErrorView
