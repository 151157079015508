import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import BaseAsyncSelect from '../AsyncSelect/BaseAsyncSelect'

import useDebounce from '@/hooks/useDebounce'

import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SELECT_PAGE_SIZE,
  EMPTY_CONSTS
} from '@/const'

import { IAsyncSelect, TAsyncCallback } from './interfaces'

/**
 * @component AsyncSelect
 * * компонент селекта с асинхронной загрузкой данных для выбора
 *
 * @param loadFunction      функция для загрузки данных с сервера
 * @param handleChange      функция срабатывающая при изменении значения селекта
 * @param label             надпись над инпутом селекта (лейбл)
 * @param placeholder       значение контента пустого инпута селекта
 * @param handleFetchError  функция, задающая ошибку, полученную при попытке запроса на сервер
 */
const AsyncSelect = ({
  loadFunction,
  handleChange,
  value,
  label,
  placeholder,
  handleFetchError,
  withAllValue = true,
  ...props
}: IAsyncSelect): ReactElement => {
  const intl = useIntl()
  const [searchValue, setSearchValue] = useState('')
  const debouncedValue = useDebounce(searchValue)
  const fetchDataCallbackRef = useRef<TAsyncCallback>(EMPTY_CONSTS.FUNC)

  /**
   * * функция для отправки запроса на сервер
   * @func fetchData
   *
   * @param callback          коллбек функция запроса на сервер
   * @param debouncedValue    значение с хука useDebounce (значение инпута селекта)
   *
   */
  const fetchData = (callback: TAsyncCallback, debouncedValue: string): void => {
    loadFunction({
      page: DEFAULT_CURRENT_PAGE,
      page_size: DEFAULT_SELECT_PAGE_SIZE,
      search: debouncedValue
    })
      .then((paginatedObject) => {
        if (Array.isArray(paginatedObject?.results)) {
          if (paginatedObject?.results.length >= 1 && withAllValue) {
            paginatedObject.results.unshift({
              id: EMPTY_CONSTS.STR,
              title: intl.formatMessage({ id: 'input.all', defaultMessage: 'All' })
            })
          }
          callback(
            paginatedObject.results.map((el) => ({ value: el?.id, label: el?.title }))
          )
        }
      })
      .catch((e) => {
        handleFetchError(e)
      })
      .finally(() => {
        fetchDataCallbackRef.current = EMPTY_CONSTS.FUNC
      })
  }

  /**
   * * функция, задающая параметры запроса, и записывающая коллбек запроса в ref
   * @func loadOptions
   *
   * @param inputValue      значение инпута
   * @param callback        коллбек функция запроса на сервер
   */
  const loadOptions = (
    inputValue: string,
    callback: (options: any) => void
  ): any => {
    setSearchValue(inputValue)
    handleFetchError(EMPTY_CONSTS.NULL)
    fetchDataCallbackRef.current = callback
  }

  /**
   * *  @info   useEffect
   * отправляет повторный запрос на каждое изменение
   * значения с хука useDebounce (значение инпута селекта)
   *
   * @param
   */
  useEffect(() => {
    fetchData(fetchDataCallbackRef.current, debouncedValue)
  }, [debouncedValue])

  return (
    <BaseAsyncSelect
      {...props}
      label={label}
      value={value}
      placeholder={placeholder}
      handleChange={handleChange}
      loadOptions={loadOptions}
    />
  )
}

export default AsyncSelect
