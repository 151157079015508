import React from 'react'

import { observer } from 'mobx-react'
import { useStores } from '@/hooks'

import { TLSidebarShowBlock } from '@/services/store/LefsSidebar'
import { ChangeLayersIcon } from '@/assets/icon'
import { SHOWING_BLOCK_TYPE } from '../../const'

import { Button } from '@/views/button'

import styles from './styles/index.module.scss'
/**
 * @view
 * * вью реализующий кнопку смена дисплея - для адаптива
 */
const ButtonToggleDisplay = (): React.ReactElement => {
  const { leftSidebarStore } = useStores()

  const handleToggleDisplay = (): void => {
    const display =
      leftSidebarStore.currentShowingBlock === SHOWING_BLOCK_TYPE.STORAGE
        ? SHOWING_BLOCK_TYPE.SIDEBAR
        : SHOWING_BLOCK_TYPE.STORAGE
    leftSidebarStore.changeShowBlock(display as TLSidebarShowBlock)
  }
  return (
    <Button
      className={styles['button-toggle']}
      color='red'
      onClick={handleToggleDisplay}
    >
      <img
        className={styles['button-toggle__img']}
        src={ChangeLayersIcon}
        alt='toggle display'
        height='40'
        width='40'
        loading='lazy'
      />
    </Button>
  )
}

export default observer(ButtonToggleDisplay)
