import BaseApi from '@garpix/base-api'
import { AuthorizationStorage } from '@garpix/cms'
import { QueryParams } from '@garpix/base-api/types'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { TLanguage } from '@/interfaces/Lang'
import { deleteAllCookies, getCookie, setCookie } from '@/utils/cookie'
import { GX_ACCESS_TOKEN, GX_ACCESS_TOKEN_EXPIRES, GX_CREATE_USER_SESSION } from '@/const'
import { IRefresh } from '../interfaces/Page'

type TRequest = <TRequest = unknown, TResponse = TRequest>(
  url: string,
  params?: QueryParams<TRequest>,
  axiosParams?: AxiosRequestConfig,
  failFetchCallback?: (err: any) => void
) => Promise<AxiosResponse<TResponse>>

abstract class AbsractApi extends BaseApi {
  language: TLanguage
  AUTH_TOKEN_KEY: string
  SESSION_TOKEN: string
  authStorage: AuthorizationStorage

  constructor (MAIN_URL: string, language: TLanguage) {
    super(MAIN_URL)
    this.language = language
    this.AUTH_TOKEN_KEY = GX_ACCESS_TOKEN
    this.SESSION_TOKEN = GX_CREATE_USER_SESSION
    this.authStorage = new AuthorizationStorage()
  }

  getLanguage = (): string => this.language

  postWithErrorHandler: TRequest = (
    url,
    params,
    axiosParams,
    failFetchCallback
  ) => {
    try {
      return this.post(url, params ?? {}, axiosParams)
        .then((res) => res)
        .catch((err) => {
          if (failFetchCallback != null) {
            failFetchCallback(err)
          }
          return err
        })
    } catch (err) {
      return err
    }
  }

  getWithErrorHandler: TRequest = (
    url,
    params,
    axiosParams,
    failFetchCallback
  ) => {
    try {
      return this.get(url, params ?? {}, axiosParams)
        .then((res) => res)
        .catch((err) => {
          if (failFetchCallback != null) {
            failFetchCallback(err)
          }
          return err
        })
    } catch (err) {
      return err
    }
  }

  getSerialNumber = (): string => 'token'

  // errorHandler = (axios) => {
  //   axios.interceptors.response.use(
  //     (response: any) => {
  //       return response;
  //     },
  //     (error: {
  //       response: { data: any; status: number };
  //       message: any;
  //     }) => {
  //       debugger;
  //       // console.log('sddsdsdssdddsdssdds', error);
  //
  //       if (error.response && error.response.data) {
  //         if (error.response.status === STATUS_CODES._404) {
  //           return console.log('404');
  //         }
  //         if (error.response.status === STATUS_CODES._500) {
  //           return console.log('500');
  //         }
  //       }
  //       return Promise.reject(error.message);
  //     }
  //   );
  // };

  refresh = (axios, refreshToken): void => {
    axios.interceptors.response.use(
      async () => {
        await this.post<IRefresh>(
          '/garpix_user/refresh/',
          { refresh_token: refreshToken }
        ).then((res) => {
          if (typeof res?.data?.access_token === 'string') {
            const date = new Date()
            const expirationDate = date.setMilliseconds(date.getMilliseconds() + res?.data?.access_token_expires)
            setCookie(GX_ACCESS_TOKEN, res?.data?.access_token)
            setCookie(GX_ACCESS_TOKEN_EXPIRES, expirationDate.toString())
            axios.defaults.headers.common.Authorization = `Bearer ${res?.data?.access_token}`
          }
        }).catch(() => deleteAllCookies())
      }
    )
  }

  axiosOverride = (axios): any => {
    const token = this.authStorage.accessToken
    const refreshToken = this.authStorage.refreshToken
    const expirationDate = this.authStorage.accessTokenExpires
    const sessionToken = getCookie(GX_CREATE_USER_SESSION)
    const isExpired = expirationDate > 0 ? (new Date() < new Date(expirationDate)) : false

    if (typeof sessionToken === 'string') {
      axios.defaults.headers.common['user-session-token'] = sessionToken
    }

    if (typeof token === 'string' && !isExpired) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    } else if (typeof refreshToken === 'string') {
      this.refresh(axios, refreshToken)
    } else {
      deleteAllCookies()
    }
    // this.errorHandler(axios);
    axios.defaults.headers.common['Accept-Language'] =
      this.getLanguage()
    // axios.defaults.headers.common['Accept-Serial-number'] =
    //   this.getSerialNumber()
    return axios
  }
}

export default AbsractApi
