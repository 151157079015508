import React, { FC } from 'react'

import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'

import { IWarningErrorModal } from '../interfaces'

import style from '../styles/index.module.scss'

/**
 * * компонент модального окна c предупреждением об ошибке
 * @component WarningError
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const WarningError: FC<IWarningErrorModal> = ({
  modalProps,
  closeModal
}) => {
  const { error } = modalProps
  const intl = useIntl()

  return (
    <div className={style.unlink}>
      <div className={style.unlink__icons}>
        <Icon src='warning' size='xl' />
        <Icon
          src='close'
          className={style.unlink__icons_close}
          onClick={closeModal}
        />
      </div>
      <div className={style.unlink__status}>
        <Typography
          className={style.unlink__status_text}
          weight={700}
          size={32}
        >
          {intl.formatMessage({
            id: 'common.warning',
            defaultMessage: 'Предупреждение'
          })}
        </Typography>
        {
          error.map((el) => {
            return (
              <Typography weight={500} size={14} key={el}>
                {el}
              </Typography>
            )
          })
        }
      </div>
      <Button
        className={style.unlink__button}
        color='black'
        onClick={closeModal}
      >
        {intl.formatMessage({
          id: 'common.its_ok',
          defaultMessage: 'Понятно'
        })}
      </Button>
    </div>
  )
}

export default WarningError
