import React, { useEffect } from "react";

import { ERROR_PAGE_TYPE } from "@/apps/Errors/const";

import StatusErrorBlock from "@/apps/Errors/views//StatusErrorBlockView";
import ErrorLayout from "@/apps/layout/components/ErrorLayout";
import { deleteAllCookies } from "@/utils/cookie";
import { useNavigate } from "react-router-dom";
import { DEFAULT_LINKS } from "@/const";

interface IProps {
  type?: string;
  clearCookie?: boolean;
}

/**
 * @view
 * * Универсальная страница ошибок
 * @see TErrorsPageType
 *
 * @param type - тип стр с ошибкой
 */

const Error: React.FC<IProps> = ({
  type = ERROR_PAGE_TYPE.FORBIDDEN,
  clearCookie = false
}) => {
  const nav = useNavigate();
  useEffect(() => {
    if (clearCookie) {
      deleteAllCookies();
      nav(DEFAULT_LINKS.userAuth)
    }
  }, []);

  return (
    <ErrorLayout>
      <StatusErrorBlock type={type} />
    </ErrorLayout>
  );
};

export default Error;
