import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@/views/button'
import Icon from '@/components/Icon'
import styles from '../styles/index.module.scss'
import classNames from 'classnames'

/**
 * @component SuccessRecoveryEmail
 * * компонент формы успешного востановления email
 */
const SuccessRecoveryEmail: React.FC<any> = ({
  closeModal,
}): React.ReactElement => {
  return (
    <div className={styles.container_block}>
      <div
        className={classNames(
          styles.container,
          styles['container__recovery-password'],
          styles['container__recovery-password--success'],
        )}
      >
        <Button
          className={styles.container__close}
          onClick={closeModal}
          variant="text"
          color="gray"
        >
          <Icon src="close" />
        </Button>
        <Icon
          className={
            styles['container__recovery-password_icon']
          }
          size="xxxxl"
          src="successStatus"
        />
        <span
          className={
            styles['container__recovery-password_title']
          }
        >
          <FormattedMessage
            id="profile.modal.succesEditEmail"
            defaultMessage="Ваша почта успешно изменена!"
          />
        </span>
      </div>
    </div>
  )
}
export default SuccessRecoveryEmail
