import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { TError } from '@/interfaces/Common'
import { DEFAULT_ERROR_ARRAY, FILTER_KEY } from '@/const'
import { getErrorsArrayFromObj } from '@/utils'
import { IAxiosConfig } from '@/api/interfaces'

/** хук для запросов на сервер
 * @hook useFetch
 * @returns {
 *  isLoading           статус выполнения запроса
 *  error               ошибка запроса
 *  data                данные запроса
 *  handleParams        функция, задающая query-параметры
 *  params              текущие query-параметры
 *  handlePage          функция, задающая номер текущей страницы
 *  refetch             функция для повторного запроса с текущими query-параметрами
 *  handleError         функция обработки ошибки
 * }
 */
const useFetch = <T extends (param: any, config?: IAxiosConfig) => Promise<any>>(
  fetcher: T,
  initialParams: Parameters<T>[0] = {}
): {
  isLoading: boolean
  error: string[] | null | undefined
  data: Awaited<ReturnType<T>> | undefined
  handleParams: (param: Parameters<T>[0]) => void
  params: Parameters<T>[0]
  handlePage: (page: number) => void
  refetch: () => void
  setData: React.Dispatch<
    React.SetStateAction<Awaited<ReturnType<T>> | undefined>
  >
  handleFetchError: (err: TError) => void
} => {
  let controller
  const intl = useIntl()
  const [error, setError] = useState<string[] | null | undefined>(
    DEFAULT_ERROR_ARRAY
  )
  const [data, setData] = useState<Awaited<ReturnType<T>>>()
  const [isLoading, setIsLoading] = useState(false)
  const [params, setParams] = useState(initialParams)

  /**
   * * функция для отправки запроса на сервер
   * @func sendRequest
   *
   * @param params              текущие query параметры
   */
  const sendRequest = async (
    params?: Parameters<T>[0]
  ): Promise<void> => {
    setIsLoading(true)
    try {
      controller = new AbortController()
      const res = await fetcher(params, {
        signal: controller.signal
      })
      setData(res)
    } catch (err) {
      handleFetchError(err)
    } finally {
      setIsLoading(false)
    }
  }
  /**
   * @func deletePageProp
   * * метод для удаления параметра стр если его
   * * нет в новых параметрах
   * @param prev - текеущие значения стейта
   * @param param - новые значения
   * @returns
   */
  const deletePageProp = (
    prev: React.SetStateAction<any>, //* хак
    param: Parameters<T>[0]
  ): void => {
    if (param?.page === undefined && FILTER_KEY.PAGE in prev) {
      delete prev.page
    }
    return { ...prev, ...param }
  }

  /**
   * * функция, задающая query-параметры
   * @func handleParams
   *
   * @param param              query параметр\ы
   */
  const handleParams = (param: Parameters<T>[0]): void => {
    setError(DEFAULT_ERROR_ARRAY)
    setParams((prev) => deletePageProp(prev, param))
  }

  /**
   * * функция, задающая номер текущей страницы
   * @func handlePage
   *
   * @param page              номер страницы
   */
  const handlePage = (page: number): void => {
    handleParams({
      ...params,
      page
    })
  }

  /**
   * * функция для повторного запроса с текущими query-параметрами
   * @func sendRequest
   *
   * @param
   */
  const refetch = (): void => {
    void sendRequest(params)
  }

  /**
   * * функция для обработки и записи ошибки
   * @func handleFetchError
   *
   * @param err       олбьект ошибки
   */
  const handleFetchError = (err: TError): void => {
    const error = getErrorsArrayFromObj(intl, err)
    setError(error)
  }

  /**
   * *  @info   useEffect
   * отправляет повторный запрос на каждое изменения
   * query-параметра
   *
   * @param
   */
  useEffect(() => {
    refetch()
    return () => {
      controller.abort()
    }
  }, [params])

  return {
    isLoading,
    error,
    data,
    handleParams,
    params,
    handlePage,
    refetch,
    handleFetchError,
    setData
  }
}

export default useFetch
