import React, { FC } from 'react'
import classNames from 'classnames'
import { GxCheckbox } from '@garpix/garpix-web-components-react'
import Icon from '../Icon'
import style from './styles/index.module.scss'
import { EMPTY_CONSTS } from '@/const'

interface ICheckbox extends React.PropsWithChildren<any> {
  children?: React.ReactNode | string
  className?: string
  error?: boolean
  type?: 'minus' | 'ok'
  checked?: boolean
  theme?: 'select_row'
}

/**
 * @component Checkbox
 * * компонент чекбокс
 *
 * @param error                            изменение поведения при ошибке
 * @param children                         дочерние компоненты (контент)
 * @param className                        дополнительные стили
 * @param checked                          установлен флажок (true/false)
 * @param type                             иконка minus или ок
 */
const Checkbox: FC<ICheckbox> = ({
  children = '',
  className = '',
  error = false,
  checked = false,
  type = 'ok',
  theme = EMPTY_CONSTS.STR,
  ...props
}) => {
  return (
    <GxCheckbox
      checked={checked}
      className={classNames({
        [style.checkbox]: true,
        [style.error]: Boolean(error),
        [style[`checkbox__${theme}`]]: Boolean(theme),
        [className]: Boolean(className),
      })}
      {...props}
    >
      <Icon slot="checked-icon" src={type} />
      {children}
    </GxCheckbox>
  )
}

export default Checkbox
