import React, { ReactNode } from 'react'

import styles from './styles/index.module.scss'
import classNames from 'classnames'

/**
 * Контейнер для правого сайдбара
 * @view
 *
 * @param [props.children]
 */
const SidebarContainer: React.FC<{
  children: ReactNode
  className?: string
}> = ({ children, className = '' }) => {
  return (
    <div
      className={classNames(
        styles['sidebar-container'],
        className,
      )}
    >
      {children}
    </div>
  )
}
export default SidebarContainer
