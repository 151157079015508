import React from 'react'
import { useIntl } from 'react-intl'
import { IGroupUsersCreate } from '@/api/interfaces/GroupUsers'

import Accordion from '@/views/Accordion'

import styles from './styles/index.module.scss'

export type TPanelTopProps = Partial<IGroupUsersCreate>
/**
 * @view
 * * Вюю панели группы пользователя
 */
const PanelTop: React.FC<TPanelTopProps> = ({
  color,
  title,
  description,
}) => {
  const intl = useIntl()
  return (
    <header className={styles.panel}>
      <div className={styles.panel__top}>
        <div className={styles.panel__top_wrap}>
          <div
            className={styles.panel__color_group}
            style={{ backgroundColor: color }}
          ></div>
          <p>
            {intl.formatMessage({
              id: 'groupUsers',
              defaultMessage: 'Группа пользователей',
            })}
          </p>
        </div>
        <h3 className={styles.panel__title}>{title}</h3>
      </div>
      <div className={styles.panel__bottom}>
        <Accordion
          open
          head={intl.formatMessage({
            id: 'description',
            defaultMessage: 'Описание',
          })}
        >
          {description}
        </Accordion>
      </div>
    </header>
  )
}

export default PanelTop
