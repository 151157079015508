import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import ErrorsBlock from '@/components/ErrorsBlock'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { Input } from '@/views/input'
import { prevDef } from '@/utils'
import styles from '../styles/index.module.scss'

const fields = {
  username: 'username',
  email: 'email'
}

export interface ISetNewMailValues {
  username: string
}

/**
 * @component SetNewMail
 * * компонент формы установки и отправки нового пароля пользователя
 * @param {INewPassword}                   общий интерфейс входных параметров NewPassword
 *
 * @param {function}  handleEmailValue  функция для изменения email вводимого в инпут
 * @param {function}  onSubmit             функция для отправки нового пароля на сервер
 * @param {IError}    errors              обьект ошибок
 */
const SetNewMail: React.FC<any> = ({
  modalProps,
  closeModal
}): React.ReactElement => {
  const intl = useIntl()
  const {
    handleEmailValue,
    onSubmit,
    errors
  } = modalProps

  const labels = useMemo(() => ({
    [fields.username]: intl.formatMessage({
      id: 'profile.modal.newEmail',
      defaultMessage: 'Новый email'
    })
  }), [])

  const texts = useMemo(() => ({
    title: intl.formatMessage({
      id: 'profile.modal.editMail',
      defaultMessage: 'Редактировать почту'
    }),
    desription: intl.formatMessage({
      id: 'profile.modal.editMail.description',
      defaultMessage: 'Введите новую почту, с помощью которой вы будете авторизоваться в систему.'
    })
  }), [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    prevDef(e)
    void onSubmit()
  }

  return (
    <div className={classNames(styles.container, styles['container__recovery-password'])}>
      <Button
        className={styles.container__close}
        onClick={closeModal}
        variant='text'
        color='gray'
      >
        <Icon src='close' />
      </Button>
      <span className={styles['container__recovery-password_title']}>
        {texts.title}
      </span>
      <span className={styles['container__recovery-password_discription']}>
        {texts.desription}
      </span>
      <form
        className={styles['container__recovery-password_form']}
        onSubmit={handleSubmit}
      >
        <Input
          name={fields.username}
          data-testid={fields.username}
          labelStyles={styles.form__field_label}
          label={labels[fields.username]}
          onChange={handleEmailValue}
          outerStyles={
              classNames(styles.form__field,
                styles.form__field_input)
            }
          error={
              Boolean(errors?.validate_errors?.[fields.username] !== undefined ||
                errors?.response_errors?.[fields.email] !== undefined)
            }
          errorMessage={errors?.validate_errors?.[fields.username]}
        />
        <div className={styles['container__recovery-password_buttons']}>
          <Button
            className={styles.form__button}
            onClick={closeModal}
          >
            <FormattedMessage id='common.cancel' defaultMessage='Отмена' />
          </Button>
          <Button
            type='submit'
            color='black'
            className={styles.form__button}
          >
            <FormattedMessage id='common.save' defaultMessage='Save' />
          </Button>
        </div>
      </form>
      <ErrorsBlock
        networkError={errors?.response_errors?.network_error}
        errorsArray={
            errors?.response_errors?.non_field_errors ??
            errors?.response_errors?.[fields.email] ?? null
          }
      />
    </div>
  )
}

export default SetNewMail
