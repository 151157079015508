import { AxiosError } from 'axios'
import { useState } from 'react'

interface IMutation<
  T extends (...args: any[]) => Promise<any>
> {
  mutation: T
  onSuccess?: (data: Awaited<ReturnType<T>>) => void
  onError?: (error: AxiosError) => void
  onSettled?: () => void
}

/**
 * Хук для мутаций
 * @function
 * @param [config.mutation] api запрос
 * @param [config.onSuccess] колбек срабатывающий при успешном запросе
 * @param [config.onError] колбек срабатывающий при ошибки
 * @param [config.osSettled] колбек срабатывающий после success или error
 */
const useMutation = <
  T extends (...args: any[]) => Promise<any>
>(
    config: IMutation<T>
  ): {
    isLoading: boolean
    data: Awaited<ReturnType<T>> | undefined
    error: AxiosError | null
    mutation: (...args: Parameters<T>) => void
  } => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<Awaited<ReturnType<T>>>()
  const [error, setError] = useState<AxiosError | null>(
    null
  )
  const mutation = (...args: Parameters<T>): void => {
    setLoading(true)
    config
      .mutation(...args)
      .then((res) => {
        setData(res)
        setError(null)
        if (config.onSuccess !== undefined) {
          config.onSuccess(res)
        }
      })
      .catch((e: AxiosError) => {
        setError(e)
        if (config.onError !== undefined) {
          config.onError(e)
        }
      })
      .finally(() => {
        setLoading(false)
        if (config.onSettled !== undefined) {
          config.onSettled()
        }
      })
  }
  return {
    isLoading,
    mutation,
    error,
    data
  }
}

export default useMutation
