import React from 'react'
import { MAX_LENGTH_TYPES } from '@/apps/ProblemList/const'
import styles from './styles/index.module.scss'

const EquipmentTypesCell = (types: string[]): React.ReactNode => {
  const showTypes = [...types]
  showTypes.length = MAX_LENGTH_TYPES
  return (
    <div className={styles.types}>
      {showTypes.map((type, index) => (
        <div key={index}>{type}</div>
      ))}
      {types.length > MAX_LENGTH_TYPES && (
        <div>+{types.length - MAX_LENGTH_TYPES}</div>
      )}
    </div>
  )
}

export default EquipmentTypesCell
