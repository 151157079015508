import React from 'react'

import { GxModal } from '@garpix/garpix-web-components-react'
import { observer } from 'mobx-react'

import { THEME_MODAL } from '@/const'

import Portal from '@/components/Portal'

import style from './styles/index.module.scss'

interface BaseModalProps {
  handleClose?: () => void
  theme?: string
  children: React.ReactNode
  open: boolean | undefined
  id?: string
}

const BaseModal: React.FC<BaseModalProps> = ({
  children,
  id,
  theme = THEME_MODAL.DEFAULT,
  ...props
}) => {
  return (
    <Portal>
      <GxModal {...props} id={id} className={style[theme]}>
        {children}
      </GxModal>
    </Portal>
  )
}

export default observer(BaseModal)
