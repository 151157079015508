import React from 'react'

import { getInitials } from '@/utils'
import { TUserRole } from '@/api/interfaces'

import Badge from '@/components/Badge'

import style from './style/index.module.scss'
import { useIntl } from 'react-intl'
import Tooltip from '@/views/Tooltip'

interface IProps {
  fullName: string
  lastName: string
  firstName: string
  userRole: TUserRole
}
/**
 * @view
 * * Вью данных о пользователе с Bage (Имя и роль)
 * @param fullName    польное имя
 * @param lastName    фамилия
 * @param userRole    роль юзера
 * @param firstName   имя
 */
const FullNameBadge: React.FC<IProps> = ({
  fullName,
  lastName,
  userRole,
  firstName
}) => {
  const intl = useIntl()
  const title = getInitials(firstName, lastName)

  return (
    <Tooltip content={fullName} hoist placement='left'>
      <div className={style['full-name']}>
        <Badge title={title} />
        <div className={style['full-name__info']}>
          <p className={style['full-name__role']}>
            {intl.formatMessage({
              id: `user.user_role.${userRole}`,
              defaultMessage: 'Роль'
            })}
          </p>
          <p className={style['full-name__name']}>
            {fullName}
          </p>
        </div>
      </div>
    </Tooltip>
  )
}

export default FullNameBadge
