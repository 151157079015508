import React from 'react'

import { useIntl } from 'react-intl'

import { Button } from '@/views/button'

import style from '../style/index.module.scss'

export interface IFooterUnbindModal {
  closeModal: () => void
  isDisabled: boolean
}
/**
 * @view
 * * Вью футера в модалке привязки пользователей
 *
 * @param closeModal  метод закрытия модалки
 * @param isDisabled  флаг для выкл кнопки сабмита
 */
const Footer: React.FC<IFooterUnbindModal> = ({ closeModal, isDisabled }) => {
  const intl = useIntl()
  return (
    <footer className={style['user-binding__btns']}>
      <Button onClick={closeModal} color='gray'>
        {intl.formatMessage({
          id: 'common.cancel',
          defaultMessage: 'Отмена'
        })}
      </Button>
      <Button
        disabled={isDisabled}
        type='submit'
        color='black'
      >
        {intl.formatMessage({
          id: 'common.bind',
          defaultMessage: 'Привязать'
        })}
      </Button>
    </footer>
  )
}

export default Footer
