import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import FilterButton from '@/views/FilterButton'
import SearchInput from '@/views/SearchInput'

import { useStores } from '@/hooks'

import { downloadFile } from '@/utils'
import { IUserPanel } from '../interfaces'
import { CUSTOM_DATA_ATTR_STYLES } from '@/apps/Modals/const'
import {
  MODAL_TYPES,
  MODAL_CUSTOM_CONTENT,
  TABLE_PAGE,
} from '@/const'

import styles from '../style/index.module.scss'

/**
 * @component UserPanel
 * * компонент верхней панели поиска, фильтрации, добавления пользователя
 *
 * @param params              текущие query параметры
 * @param handleParams        установить дополнительные параметры к текущим query параметрам
 * @function refetch          функция для обновления данных
 */
const UserPanel: FC<IUserPanel> = ({
  handleParams,
  params,
  refetch,
}) => {
  const intl = useIntl()
  const { modalStore, api } = useStores()

  /**
   * метод для скачивания ексель шаблона пользователя
   */
  const handleDownload = (): void => {
    api.user
      .getExcelSample()
      .then(downloadFile)
      .catch(() =>
        modalStore.open(
          MODAL_TYPES.CUSTOM,
          MODAL_CUSTOM_CONTENT.DOWNLOAD_FILE_ERROR,
        ),
      )
  }

  /**
   * метод открытия модалки загрузки ексель
   */
  const handleUpload = (): void => {
    modalStore.setCustomDataStyles(
      CUSTOM_DATA_ATTR_STYLES.NOTIFY_PADDING,
    )
    modalStore.open(MODAL_TYPES.UPLOAD_USER_EXCEL)
  }

  /**
   * * метод для открытя модалки создания пользователя
   * @func handleOpenModalCreateUser
   */
  const handleOpenModalCreateUser = (): void => {
    modalStore.open(MODAL_TYPES.USER_EDIT, { refetch })
  }

  return (
    <div className={styles.panel}>
      <div className={styles.panel__search}>
        <div className={styles.panel__search_inputwrap}>
          <SearchInput
            className={styles.panel__search_input}
            handleParams={handleParams}
          />
          <FilterButton
            type={TABLE_PAGE.USER}
            params={params}
            handleParams={handleParams}
            className={styles.panel__search_filter}
          />
        </div>
        <Button
          className={styles.panel__search_download}
          onClick={handleDownload}
        >
          <Icon src="download" />
          {intl.formatMessage({
            id: 'button.excel',
            defaultMessage: 'Excel example',
          })}
        </Button>
        <Button
          className={styles.panel__search_upload}
          onClick={handleUpload}
        >
          <Icon src="file" />
          {intl.formatMessage({
            id: 'button.upload',
            defaultMessage: 'Upload file',
          })}
        </Button>
        <Button
          color="black"
          className={styles.panel__search_add}
          onClick={handleOpenModalCreateUser}
        >
          <Icon src="plus" />
          {intl.formatMessage({
            id: 'button.add_user',
            defaultMessage: 'Add user',
          })}
        </Button>
      </div>
    </div>
  )
}

export default UserPanel
