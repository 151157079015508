import React from 'react'
import Badge, { IBadge } from '@/components/Badge'
import classNames from 'classnames'
import { EMPTY_CONSTS } from '@/const'
import {
  Typography,
  ITypography
} from '@/views/typography'

import styles from './styles/index.module.scss'

interface IBadgeProps extends Pick<IBadge, 'title'>, ITypography {
  reverse?: boolean
}

/**
 * Баджик с инициалами + имя
 */
const UserBadge: React.FC<IBadgeProps> = ({
  title = EMPTY_CONSTS.STR,
  size,
  weight,
  className = EMPTY_CONSTS.STR,
  children,
  reverse = EMPTY_CONSTS.FALSE
}) => {
  return (
    <div
      className={classNames(styles['user-badge'], {
        [styles['user-badge--reverse']]: reverse,
        [className]: Boolean(className)
      })}
    >
      <Badge title={title} />
      <Typography size={size} weight={weight}>
        {children}
      </Typography>
    </div>
  )
}

export default UserBadge
