import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'

export class NotificationStore {
  rootStore: RootStore
  isOpen: boolean
  count?: number

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.isOpen = false
    this.count = undefined
    makeAutoObservable(this)
  }

  open = (): void => {
    this.isOpen = true
  }

  close = (): void => {
    this.isOpen = false
  }

  setCount = (count: number | undefined): void => {
    this.count = count
  }

  addCount = (): void => {
    const prevCount = this.count ?? 0
    this.count = prevCount + 1
  }
}
