import { usePage as usePageCms } from '@garpix/cms'
import { IUser } from '@/interfaces/ObjectPage'
import { EMPTY_CONSTS } from '@/const'

const useCurrentUser = (): IUser => {
  const value = usePageCms()
  const defaultUserData: IUser = {
    id: -1,
    role: EMPTY_CONSTS.UNDEFINED,
    card_hid_number: EMPTY_CONSTS.STR,
    first_name: EMPTY_CONSTS.STR,
    last_name: EMPTY_CONSTS.STR,
    patronymic: EMPTY_CONSTS.STR,
    username: EMPTY_CONSTS.STR,
    email: EMPTY_CONSTS.STR
  }
  const userData: IUser | undefined =
    value?.page?.init_state?.global?.user

  if (userData === EMPTY_CONSTS.UNDEFINED) {
    return defaultUserData
  }
  return userData
}

export default useCurrentUser
