import React from 'react'

import cn from 'classnames'

import TooltipView from '@/components/Tooltip/view'
import { Button } from '@/views/button'

import { useStores } from '@/hooks'

import { EMPTY_CONSTS } from '@/const'

import {
  TStorageCellStatus,
  TStorageCellFilterStatus,
  ICellFilterParams,
} from '@/api/interfaces'
import style from '../style/index.module.scss'

interface IProps {
  type: string
  id: string | number
  field: string
  filterKey: TStorageCellStatus
  params: ICellFilterParams
  tooltipData?: any
  handleChangeCellStausFilter: (
    value: TStorageCellFilterStatus,
  ) => void
}
/**
 * @view
 * * Вьюха с интерфейсом конпки кол-ва ячеек
 * * в табе ячеек
 * @param filterKey                     значение для фильтра
 * @param type                          тип ячейки - для стилей
 * @param field                         поле в сущности currentStorage из которого мы забираем значения ячеек
 * @param params                        текущие фильтра в запросе на ячейки
 * @param handleChangeCellStausFilter   метод для установки фильтра статуса ячеек
 * @returns
 */
const CellCount: React.FC<IProps> = ({
  filterKey,
  type,
  field,
  tooltipData,
  params,
  handleChangeCellStausFilter,
}) => {
  const {
    currentStorageStore: { storage },
  } = useStores()
  const isActiveFilter = params?.cell_status === filterKey

  /**
   * @function
   * * метод для смены фильтра в зависимости от отого
   * * установлен он или нет
   */
  const handleSetAndCheckCellStatusFilter = (): void => {
    if (params?.cell_status === filterKey) {
      handleChangeCellStausFilter(EMPTY_CONSTS.UNDEFINED)
    } else {
      handleChangeCellStausFilter(filterKey)
    }
  }
  return (
    <li>
      <TooltipView contetnt={tooltipData}>
        <Button
          color="transparent"
          className={cn({
            [style['cells-count__item']]: EMPTY_CONSTS.TRUE,
            [style['cells-count__item--active']]:
              isActiveFilter,
          })}
          onClick={handleSetAndCheckCellStatusFilter}
          data-type={type}
        >
          {storage?.count_cells[field] ?? '-'}
        </Button>
      </TooltipView>
    </li>
  )
}

export default CellCount
