import React from 'react'
import { useIntl } from 'react-intl'
import { Field } from 'formik'

import { Input } from '@/views/input'
import { ISelectValue, Select } from '@/views/select'

import {
  DEFAULT_INTEGRATION_FIELDS,
  INTEGRATION_OPTIONS,
} from '@/apps/Integration/const'

import styles from '../style/index.module.scss'

/**
 * @view
 * * вверхние поля интеграции скуда
 * * адресс и селект
 */
const TopFields = (): React.ReactElement => {
  const intl = useIntl()
  return (
    <>
      <Field name={DEFAULT_INTEGRATION_FIELDS.acs_type}>
        {({ field, form }) => {
          const initialValue = INTEGRATION_OPTIONS.find(
            (o) => o.value === field.value,
          )
          const handleChange = (
            selectedOption: ISelectValue,
          ): void => {
            form.setFieldValue(
              field.name,
              selectedOption.value,
            )
          }
          return (
            <Select
              label={intl.formatMessage({
                id: 'Integration',
                defaultMessage: 'Интеграция',
              })}
              value={initialValue}
              onChange={handleChange}
              options={INTEGRATION_OPTIONS}
            />
          )
        }}
      </Field>
      <Field name={DEFAULT_INTEGRATION_FIELDS.api_link}>
        {({ field, meta }) => (
          <Input
            {...field}
            outerStyles={styles.field}
            label={intl.formatMessage({
              id: 'addres',
              defaultMessage: 'Адрес',
            })}
            clearable
            error={
              Boolean(meta.error) && Boolean(meta.touched)
            }
            errorMessage={meta.error}
          />
        )}
      </Field>
    </>
  )
}

export default TopFields
