import React from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

import IntegrationStatus from '../IntegrationStatus'
import { Typography } from '@/views/typography'
import { Switch } from '@/views/switch'

import { TAccesControlIntegrationValues } from '@/api/interfaces/Integration'
import { DEFAULT_INTEGRATION_FIELDS } from '../../const'

import styles from './style/index.module.scss'
/**
 * @view
 * * шапка скуда
 */
const Header = (): React.ReactElement => {
  const intl = useIntl()
  const { handleChange, values } =
    useFormikContext<TAccesControlIntegrationValues>()

  const switchText = values.is_active
    ? {
        id: 'on',
        defaultMessage: 'Вкл',
      }
    : {
        id: 'off',
        defaultMessage: 'Выкл',
      }
  return (
    <header className={styles.header}>
      <div className={styles.header__status}>
        <IntegrationStatus />
      </div>
      <Typography
        className={styles.header__title}
        weight={700}
        size={24}
      >
        {intl.formatMessage({
          id: 'integrationWithScud',
          defaultMessage: 'Интеграция со СКУД',
        })}
      </Typography>
      <Switch
        checked={values.is_active}
        handleChange={handleChange}
        name={DEFAULT_INTEGRATION_FIELDS.is_active}
        className={styles.header__switch}
      >
        {intl.formatMessage(switchText)}
      </Switch>
    </header>
  )
}

export default Header
