import React from 'react'

import {
  Equipments,
  Storages,
  Users,
} from '@/apps/RightSidebar/components/UserGroupSidebar/TabsComponents'
import TabGenerator from '@/views/TabGenerator'

import {
  USER_GROUP_TABS,
  USER_GROUP_PANEL_VARIANTS,
} from '@/apps/RightSidebar/const'

interface IProps {
  groupId: number
}
/**
 * @view
 * * Вьха табов деталки группы пользователей
 */

type TPanelVariant =
  (typeof USER_GROUP_PANEL_VARIANTS)[keyof typeof USER_GROUP_PANEL_VARIANTS]

const TabsPanel: React.FC<IProps> = ({ groupId }) => {
  const panels = [
    {
      idPanel: 0,
      name: USER_GROUP_PANEL_VARIANTS.USERS,
      Component: Users,
    },
    {
      idPanel: 1,
      name: USER_GROUP_PANEL_VARIANTS.EQUIPMENTS,
      Component: Equipments,
    },
    {
      idPanel: 2,
      name: USER_GROUP_PANEL_VARIANTS.STORAGES,
      Component: Storages,
    },
  ]

  return (
    <TabGenerator<TPanelVariant>
      propsToComps={{
        groupId,
      }}
      tabs={USER_GROUP_TABS}
      panels={panels}
    />
  )
}

export default TabsPanel
