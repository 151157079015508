import React from 'react'

import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'
import { useStores } from '@/hooks'

import style from './style/index.module.scss'
import { isNull } from '@/utils'
/**
 * @view
 * * компонент шапки деталки шкафа показывающий количесво ячеек
 * * при статусе succes
 */
const AllCells = (): React.ReactElement => {
  const intl = useIntl()
  const { currentStorageStore } = useStores()

  return (
    <div className={style.cells}>
      {!isNull(currentStorageStore.allCells) && (
        <span>{currentStorageStore.allCells}</span>
      )}
      <span>
        {intl.formatMessage({
          id: 'storage.cells',
          defaultMessage: 'ячеек'
        })}
      </span>
    </div>
  )
}

export default observer(AllCells)
