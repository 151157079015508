import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useStores, usePage } from '@/hooks'
import { MODAL_CUSTOM_CONTENT, MODAL_TYPES } from '@/const'
import { IModal } from '../interfaces'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'

import style from '../styles/index.module.scss'

/**
 * * компонент модального окна для выхода из системы
 * @component LogOut
 *
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const LogOut: FC<IModal> = ({ closeModal }) => {
  const intl = useIntl()
  const { api, modalStore } = useStores()
  const navigate = useNavigate()
  const { baseSlug } = usePage()
  const [isLoading, setLoading] = useState<boolean>(false)
  /**
   * @func onSubmit
   * * функция реализующая логику выхода из системы
   */
  const onSubmit = (): void => {
    setLoading(true)
    void api.user
      .logout()
      .then(() => {
        closeModal()
        navigate(baseSlug.userAuthPpage)
      })
      .catch(() => {
        modalStore.open(
          MODAL_TYPES.CUSTOM,
          MODAL_CUSTOM_CONTENT.ERROR
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className={style.container}>
      <div className={style.container__header}>
        <Icon
          src='exit'
          size='xl'
          className={style.container__header_icon_exit}
        />
        <h2 className={style.title}>
          {intl.formatMessage({
            id: 'modal.title.logout',
            defaultMessage:
              'Are you sure you want to log out?'
          })}
        </h2>
      </div>
      <div className={style.container__buttons}>
        <Button
          className={style.container__buttons_cancel}
          onClick={closeModal}
          disabled={isLoading}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Cancel'
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color='black'
          onClick={onSubmit}
          disabled={isLoading}
        >
          {intl.formatMessage({
            id: 'logout',
            defaultMessage: 'Log out'
          })}
        </Button>
      </div>
    </div>
  )
}

export default LogOut
