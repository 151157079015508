import React, {
  useRef,
  useState,
  SyntheticEvent,
} from 'react'

import { observer } from 'mobx-react'
import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'

import { useObservableMap } from '../../../hook'
import { useLazyFetch } from '@/hooks'
import { getErrorsArrayFromObj } from '@/utils'
import { DEFAULT_PAGE_LAZY_SIZE } from '@/const'

import ErrorsBlock from '@/components/ErrorsBlock'
import UserBindingView, {
  TUserValue,
} from '../../../views/UserBindingViews'
import {
  IPaginationList,
  IQueryParams,
} from '@/api/interfaces'
import { runInAction } from 'mobx'

type TUserList = IPaginationList & {
  results: TUserValue[]
}

interface IProps {
  closeModal: () => void
  handleFetchUserList: (
    params: IQueryParams,
  ) => Promise<TUserList>
  handleBindUsers: (idList: number[]) => Promise<void>
  refetch: () => void
}

/**
 * @component
 * * Компонент реализующий логику модлки по добавлению пользователей к шкафу
 * @param closeModal метод закрытия модалки
 * @param modalProps пропсы для текущей модалки
 */
const UserBinding: React.FC<IProps> = ({
  closeModal,
  handleFetchUserList,
  handleBindUsers,
  refetch,
}) => {
  const [isDisabled, setIsDisabled] =
    useState<boolean>(false)
  const [fetchErrors, setFetchErrors] = useState<string[]>()

  const {
    observableMap: userIdList,
    setObservableMap,
    clearObservableMap,
    deleteValueObservableMap,
  } = useObservableMap<number, number>()

  const listRef = useRef<HTMLUListElement>(null)

  const {
    error: lazyLoadError,
    tableScroll: scrollEvent,
    lazyLoadedData: data = [],
    isLoading,
    handleParams,
    isScrollEnd,
  } = useLazyFetch(listRef, handleFetchUserList, {
    page_size: DEFAULT_PAGE_LAZY_SIZE,
  })

  /**
   * * метод для привязки пользователей к шкафу
   * @func handleSubmit
   * @param e
   */
  const handleSubmit = (
    e: SyntheticEvent<HTMLFormElement>,
  ): void => {
    e.preventDefault()
    setIsDisabled(true)
    const userIdArr = Array.from(userIdList, ([key]) => key)

    handleBindUsers(userIdArr)
      .then(() => {
        closeModal()
        refetch()
      })
      .catch((err) => {
        setFetchErrors(getErrorsArrayFromObj(err))
      })
      .finally(() => {
        setIsDisabled(false)
      })
  }
  /**
   * * метод для добавления/удаления id пользователей из Map
   * @func handleSubmit
   * @param e
   */
  const handleChangeUserList = (
    e: GxCheckboxCustomEvent<HTMLGxCheckboxElement>,
  ): void => {
    runInAction(() => {
      const userId = Number(e.target.dataset.id)
      if (e.target.checked) {
        setObservableMap(userId, userId)
      } else {
        deleteValueObservableMap(userId)
      }
    })
  }

  const scrollWrapProps = {
    dataCount: data.length,
    listRef,
    isLoading,
    scrollEvent,
  }

  const checkLazyErr =
    lazyLoadError !== null &&
    lazyLoadError !== undefined &&
    lazyLoadError.length !== 0

  if (checkLazyErr) {
    return <ErrorsBlock networkError />
  }

  const footerProps = {
    closeModal,
    isDisabled,
  }

  return (
    <UserBindingView
      scrollWrapProps={scrollWrapProps}
      footerProps={footerProps}
      users={data}
      fetchErrors={fetchErrors}
      userIdList={userIdList}
      handleParams={handleParams}
      handleChangeUserList={handleChangeUserList}
      handleClearUserList={clearObservableMap}
      handleSubmit={handleSubmit}
      isScrollEnd={isScrollEnd}
    />
  )
}

export default observer(UserBinding)
