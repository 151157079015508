import React, { FC, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import ErrorsBlock from '@/components/ErrorsBlock'
import FilterTitle from './FilterTitle'
import ActionButtons from './ActionButtons'
import SelectFields from './SelectFields'

import { ISelectValue } from '@/views/select'

import { getErrorsArrayFromObj } from '@/utils'

import { IFilterView } from './interfaces'
import { TFilterParams } from '../../ModalContent/interfaces'
import { TError } from '@/interfaces/Common'
import { IValue } from '@/components/AsyncSelect/interfaces'

import {
  DEFAULT_ERROR_ARRAY,
  EMPTY_FILTER_PARAMS
} from '@/const'

import style from './style/index.module.scss'

/** главный компонент фильтра
 * @component FilterView
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @param filterType - тип фильтра (для пользователей, шкафов или оборудования)
 * @returns
 */
const FilterView: FC<IFilterView> = ({
  filterType,
  modalProps,
  closeModal
}) => {
  const intl = useIntl()
  const { handleParams, params, isStorageEquipment } = modalProps
  const { storage_id: storageId } = params
  const FilterFields = SelectFields[filterType]
  const [currentFilter, setCurrentFilter] = useState<TFilterParams>({ ...params })
  const [error, setError] = useState<string[] | undefined>(DEFAULT_ERROR_ARRAY)
  const storage = useRef<IValue | null>(storageId as IValue)
  /**
   * * функция для обработки и записи ошибки
   * @func handleFetchError
   *
   * @param err       олбьект ошибки
   */
  const handleFetchError = (err: TError): void => {
    const error = getErrorsArrayFromObj(intl, err)
    setError(error)
  }

  /** функция реализующая логику сохранения параметров фильтра
   * @func handleSave
   */
  const handleSave = (): void => {
    handleParams({ ...currentFilter })
    closeModal()
  }

  /** функция реализующая логику сброса параметров фильтра
   * @func handleReset
   */
  const handleReset = (): void => {
    handleParams({ ...EMPTY_FILTER_PARAMS })
    closeModal()
  }

  /** функция реализующая логику изменения параметра фильтра
   * @func handleReset
   */
  const handleParamsChange = (type: string, el?: ISelectValue | null | string): void => {
    setCurrentFilter({ ...currentFilter, [type]: el })
  }

  return (
    <div className={style.filter}>
      <div className={style.filter__mb}>
        <FilterTitle closeModal={closeModal} />
      </div>
      <FilterFields
        currentFilter={currentFilter}
        handleParamsChange={handleParamsChange}
        storage={storage.current}
        isStorageEquipment={isStorageEquipment}
        handleFetchError={handleFetchError}
      />
      <ErrorsBlock errorsArray={error} />
      <ActionButtons
        handleReset={handleReset}
        handleSave={handleSave}
      />
    </div>
  )
}

export default FilterView
