import React, { memo } from 'react'
import MainMenu from '@/apps/mainMenu'
import style from './style/index.module.scss'
import SeoWrapper from '../SeoWrapper'

interface TProp {
  children: JSX.Element | JSX.Element[]
}

export const MainLayout: React.FC<TProp> = ({
  children
}) => {
  return (
    <SeoWrapper>
      <div className={style.container}>
        <MainMenu />
        {children}
      </div>
    </SeoWrapper>
  )
}

export default memo(MainLayout)
