import React from 'react'

import classNames from 'classnames'

import styles from '../styles/index.module.scss'
interface IBaseContentProps {
  className?: string
}
/**
 * @view
 * Универсальная обертка для модалок
 */
const BaseContentWrapper = ({
  children,
  className = '',
}: React.PropsWithChildren<IBaseContentProps>): React.ReactElement => {
  return (
    <div
      className={classNames(styles['base-modal'], {
        [className]: Boolean(className),
      })}
    >
      {children}
    </div>
  )
}

export default BaseContentWrapper
