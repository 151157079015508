import React from 'react'
import classNames from 'classnames'

import styles from './styles/index.module.scss'

interface IProps {
  className?: string
}

const TableWrap = ({
  className = '',
  children,
}: React.PropsWithChildren<IProps>): React.ReactElement => {
  return (
    <div
      className={classNames(styles['table-wrap'], {
        [className]: Boolean(className),
      })}
    >
      {children}
    </div>
  )
}

export default TableWrap
