import React from 'react'
import { IProps } from './interfaces'
import { Helmet } from 'react-helmet-async'

const Seo = ({
  title,
  seoTitle,
  description,
  seoDescription,
  seoKeywords,
  seoImage,
  seoOgType,
  seoAuthor,
}: IProps): React.ReactElement => {
  const currentTitle = seoTitle === '' ? title : seoTitle

  return (
    <Helmet>
      <title>{`${currentTitle ?? ''}`}</title>
      <meta
        name='description'
        content={seoDescription ?? description ?? ''}
      />
      <meta name='keywords' content={seoKeywords} />
      <meta
        property='og:description'
        content={seoDescription}
      />
      <meta property='og:image' content={seoImage} />
      <meta
        property='og:title'
        content={seoTitle ?? title}
      />
      <meta
        property='og:type'
        content={seoOgType ?? 'website'}
      />
      <meta
        property='og:article:author'
        content={seoAuthor}
      />
    </Helmet>
  )
}

export { Seo }
