import React, { FC } from 'react'

import { useIntl } from 'react-intl'

import SortButton from '@/components/SortButton'
import Icon from '@/components/Icon'
import SearchInput from '@/views/SearchInput'
import { Typography } from '@/views/typography'

import { IControlPanel } from '../interfaces'

import style from '../style/index.module.scss'

/**
 * * вью для реализаци панели изменения параметров
 * поиска, сортировки, фильтрации оборудования, прикрепляемых
 * к пользователю
 * @view ControlPanel
 *
 * @func handleEquipment             функция изменения оборудования
 * @func handleParams                функция изменения query параметров
 * @param ordering                   query сортировки
 * @param equipments                 оборудование
 */
const ControlPanel: FC<IControlPanel> = ({
  handleCheckedEquipment,
  handleParams,
  checkedEquipments,
  ordering
}) => {
  const intl = useIntl()

  /**
   * * функция для сброса выбранного оборудования
   * @func   handleResetSelectedEquipments
   * @param
   */
  const handleResetSelectedEquipments = (): void => {
    handleCheckedEquipment([])
  }

  return (
    <div className={style.panel}>
      <SearchInput
        handleParams={handleParams}
        className={style.panel_input}
      />
      <div className={style.panel__control}>
        <SortButton
          handleParams={handleParams}
          ordering={ordering}
          field='title'
          title={intl.formatMessage({
            id: 'common.equipment',
            defaultMessage: 'Equipment'
          })}
        />
        <div className={style.reset}>
          <Typography size={14} weight={400}>
            {checkedEquipments.length}
          </Typography>
          <Icon
            size='xs'
            src='close'
            className={style.reset_icon}
            onClick={handleResetSelectedEquipments}
          />
        </div>
      </div>
    </div>
  )
}

export default ControlPanel
