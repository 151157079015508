import React, { useRef, useState } from 'react'
import { isNull, isUndefined } from '@/utils'
import {
  useLazyFetch,
  useMutation,
  useStores
} from '@/hooks'
import { IEquipment, IEquipmentProblemsParams } from '@/api/interfaces'
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_LAZY_SIZE,
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES,
  SCROLL_TO
} from '@/const'
import ProblemsPanelView from '@/apps/RightSidebar/views/EquipmentSideBarView/ProblemsPanel'

interface IEquipmentProblemsPanelProps {
  id: number
  equipmentData: IEquipment
}

/**
 * Компонет панели проблем деталки оборудования
 * @constructor
 * @param id              - id оборудования
 * @param equipmentData   - оборудование
 */
const ProblemsPanel: React.FC<
IEquipmentProblemsPanelProps
> = ({ id, equipmentData }) => {
  /**
   * Реф на скролящийся список
   */
  const scrollableListRef = useRef<HTMLUListElement>(
    EMPTY_CONSTS.NULL
  )

  const { api, modalStore } = useStores()

  const [date, setDate] = useState<{
    start: string | undefined
    end: string | undefined
  }>({
    start: EMPTY_CONSTS.UNDEFINED,
    end: EMPTY_CONSTS.UNDEFINED
  })
  const {
    params,
    lazyLoadedData: data,
    isLoading,
    error,
    tableScroll: handleScroll,
    handleParams,
    isScrollEnd
  } = useLazyFetch(
    scrollableListRef,
    api.equipment.getProblems,
    {
      id,
      page_size: DEFAULT_PAGE_LAZY_SIZE
    }
  )

  /**
   * Фетч по параметрам для фильтра и поиска
   * @param params
   */
  const handleFetch = (
    params: IEquipmentProblemsParams
  ): void => {
    if (!isNull(scrollableListRef.current)) {
      scrollableListRef.current.scrollTo(
        SCROLL_TO.SMOOTH_TOP
      )
    }
    return handleParams({
      ...params,
      page: DEFAULT_CURRENT_PAGE
    })
  }

  /**
   * Сеттер даты start
   */
  const handleStartChange = (date: string): void => {
    setDate((prev) => ({
      ...prev,
      start: date
    }))
    handleParams({
      ...params,
      date_start: date,
      page: DEFAULT_CURRENT_PAGE
    })
  }

  /**
   * Сеттер даты end
   */

  const handleEndChange = (date: string): void => {
    setDate((prev) => ({
      ...prev,
      end: date
    }))
    handleParams({
      ...params,
      date_end: date,
      page: DEFAULT_CURRENT_PAGE
    })
  }

  /**
   * Скачивание истории проблем
   */
  const { mutation, isLoading: isReportLoading } =
    useMutation({
      mutation: async () =>
        await api.equipment.downloadProblemHistory({
          id,
          date_end: date.end,
          date_start: date.start
        }),
      onError: (error) => {
        if (
          !isUndefined(error.response?.data?.date_end) ||
          !isUndefined(error.response?.data?.date_start)
        ) {
          modalStore.open(
            MODAL_TYPES.CUSTOM,
            MODAL_CUSTOM_CONTENT.DATE_REPORT_ERROR
          )
        } else {
          modalStore.open(
            MODAL_TYPES.CUSTOM,
            MODAL_CUSTOM_CONTENT.DATE_REPORT_ERROR
          )
        }
      }
    })

  const props = {
    listRef: scrollableListRef,
    startDate: date.start,
    endDate: date.end,
    data,
    isLoading,
    isReportLoading,
    error,
    handleScroll,
    handleFetch,
    handleStartChange,
    handleEndChange,
    handleDownload: mutation,
    params,
    equipmentData,
    isScrollEnd
  }

  return <ProblemsPanelView {...props} />
}

export default ProblemsPanel
