import React, { useEffect } from 'react'

import { useFetch, useStores } from '@/hooks'

import WithMediaPageSize from '@/components/WithMediaPageSize'
import GroupUsersTable from './views/GroupUsersTable'
import GroupPanel from './views/GroupPanel'

import { isUndefined } from '@/utils'
import { QUERIES } from './consts'
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '@/const'

import styles from './views/styles/index.module.scss'

const UserGroupList: React.FC<{pageSize?: number}> = ({pageSize}) => {
  const { api } = useStores()
  const { 
    data, 
    params, 
    handleParams, 
    isLoading, 
    handlePage,
    error,
    refetch
  } = useFetch(
    api.groupUsers.getGroupUsers, {
      page_size: pageSize ?? DEFAULT_PAGE_SIZE
    }
  )

  useEffect(() => {
    if (
      !isUndefined(pageSize) &&
      pageSize !== params.page_size
    ) {
      handleParams({ page_size: pageSize })
    }
  }, [pageSize])

  return (
    <div className={styles.container}>
      <GroupPanel refetch={refetch} handleParams={handleParams} />
      <GroupUsersTable
        refetch={refetch}
        params={params}
        groupUsers={data?.results}
        handleParams={handleParams}
        isLoading={isLoading}
        handlePage={handlePage}
        currentPage={params.page}
        total={data?.count}
        error={error}
      />
    </div>
  )
}

const EnhancedGroupList: React.FC = () => {
  return (
    <WithMediaPageSize queries={QUERIES} sizes={PAGE_SIZES}>
      {(pageSize) => <UserGroupList pageSize={pageSize} />}
    </WithMediaPageSize>
  )
}

export default EnhancedGroupList
