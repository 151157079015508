import AsideLeft from './AsideLeft'
import CenterWrap from './CenterWrap'
import Form from './Form'
import GridWrap from './GridContent'
import SectionRight from './SectionRight'
import Fields from './Fields'
import InfoFields from './InfoFields'

export default {
  AsideLeft,
  CenterWrap,
  Form,
  GridWrap,
  SectionRight,
  Fields,
  InfoFields
}
