import React, { useEffect } from 'react'

import { useFetch, useMutation, useStores } from '@/hooks'
import { IPaginatedParams, IProblem } from '@/api/interfaces'

import { isUndefined } from '@/utils'
import {
  DEFAULT_PAGE_SIZE,
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES,
  PAGE_SIZES,
  TABLE_PAGE,
  TABLE_WRAPPER_ICON_SRC
} from '@/const'

import { QUERIES } from '@/apps/ProblemList/const'
import TableWrapper from '@/views/TableWrapper'
import Pagination from '@/components/Pagination'
import Panel from '@/apps/ProblemList/views/Panel'
import Layout from '@/apps/ProblemList/views/Layout'
import Table from '@/apps/ProblemList/views/Table'
import WithMediaPageSize from '@/components/WithMediaPageSize'

/**
 * Компонент списка проблем
 * @component
 * @param pageSize - размер страницы
 */

const ProblemList: React.FC<{ pageSize?: number }> = ({
  pageSize
}) => {
  const { api, modalStore } = useStores()
  const {
    data,
    handleParams,
    params = EMPTY_CONSTS.OBJ as IPaginatedParams,
    isLoading,
    handlePage,
    error,
    refetch
  } = useFetch(api.problem.getProblems, {
    page_size: pageSize ?? DEFAULT_PAGE_SIZE
  })

  const { mutation } = useMutation({
    mutation: api.problem.deleteProblem,
    onSuccess: refetch,
    onError: () => {
      modalStore.open(MODAL_TYPES.CUSTOM, MODAL_CUSTOM_CONTENT.ERROR)
    }
  })

  const handleAddProblem = (): void => {
    modalStore.open(MODAL_TYPES.PROBLEM_EDIT, { refetch })
  }

  const handleEditProblem = (problem: IProblem): void => {
    modalStore.open(MODAL_TYPES.PROBLEM_EDIT, {
      problem,
      refetch
    })
  }

  useEffect(() => {
    if (
      !isUndefined(pageSize) &&
      pageSize !== params.page_size
    ) {
      handleParams({ page_size: pageSize })
    }
  }, [pageSize])

  return (
    <Layout>
      <Panel
        handleParams={handleParams}
        handleAddProblem={handleAddProblem}
      />
      <TableWrapper
        iconSrc={TABLE_WRAPPER_ICON_SRC.PROBLEM}
        search={params.search}
        isLoading={isLoading}
        type={TABLE_PAGE.PROBLEM}
        error={error}
        length={data?.results.length}
      >
        <Table
          data={data?.results}
          handleDelete={mutation}
          handleEditProblem={handleEditProblem}
        >
          <Pagination
            currentPage={params.page}
            handleChange={handlePage}
            total={data?.count}
            pageSize={params.page_size}
          />
        </Table>
      </TableWrapper>
    </Layout>
  )
}

const EnhancedProblemList: React.FC = () => {
  return (
    <WithMediaPageSize queries={QUERIES} sizes={PAGE_SIZES}>
      {(pageSize) => <ProblemList pageSize={pageSize} />}
    </WithMediaPageSize>
  )
}

export default EnhancedProblemList
