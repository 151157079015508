import React, { ReactNode } from 'react'

import cn from 'classnames'
import { GxTextarea } from '@garpix/garpix-web-components-react'
import { EMPTY_CONSTS } from '@/const'

import style from './style/index.module.scss'
import { GxTextareaCustomEvent } from '@garpix/garpix-web-components'

interface ITextarea {
  children?: ReactNode
  className?: string
  label?: string
  errorMessage?: string
  value: string
  name: string
  onChange?: (e: GxTextareaCustomEvent<HTMLGxTextareaElement>) => void
  onBlur?: (e: GxTextareaCustomEvent<HTMLGxTextareaElement>) => void
  error?: boolean
}

const Textarea: React.FC<ITextarea> = ({
  value = EMPTY_CONSTS.STR,
  label = EMPTY_CONSTS.STR,
  error = EMPTY_CONSTS.FALSE,
  errorMessage = EMPTY_CONSTS.STR,
  onChange = EMPTY_CONSTS.FUNC,
  name = EMPTY_CONSTS.STR,
  onBlur = EMPTY_CONSTS.FUNC,
  children,
  className
}) => {
  return (
    <GxTextarea
      label={label}
      name={name}
      data-error={error}
      className={cn(style.textarea, className)}
      onGx-input={onChange}
      onGx-blur={onBlur}
      value={value ?? ''}
      size='medium'
    >
      {children}
      {Boolean(error) && (
        <div className={style.error_text} slot='help-text'>
          {errorMessage}
        </div>
      )}
    </GxTextarea>
  )
}

export default Textarea
