import React, { memo, useRef } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import type SwiperCore from 'swiper/core'
import classNames from 'classnames'

import { IMenuItem } from '@/interfaces/Menus'

import MenuItem from '../../components/Menu/MenuItem'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'

import styles from './style/index.module.scss'

interface IProps {
  menu: IMenuItem[]
}
/**
 * @view
 * * главная навигация в виде слайдера (mobile)
 * @param menu - параметры меню
 */
const SliderMenu: React.FC<IProps> = ({ menu }) => {
  const swiperRef = useRef<SwiperCore | null>(null)

  const handleNext = (): void => {
    swiperRef.current?.slideNext()
  }

  const handlePrev = (): void => {
    swiperRef.current?.slidePrev()
  }

  return (
    <div className={styles.container}>
      <Swiper
        touchEventsTarget='container'
        slidesPerView={3}
        spaceBetween={15}
        freeMode
        observer
        className={styles.slider}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
        }}
        breakpoints={{
          450: {
            slidesPerView: 4,
            spaceBetween: 25
          }
        }}
      >
        {menu.map((el) => (
          <SwiperSlide key={el.id}>
            <MenuItem {...el} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Button
        variant='text'
        className={classNames(
          styles.paginate,
          styles.paginate__left
        )}
        onClick={handlePrev}
      >
        <Icon src='arrowRight' />
      </Button>
      <Button
        variant='text'
        className={classNames(
          styles.paginate,
          styles.paginate__right
        )}
        onClick={handleNext}
      >
        <Icon src='arrowRight' />
      </Button>
    </div>
  )
}

export default memo(SliderMenu)
