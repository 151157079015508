import AbsractApi from '@/api/abstract'
import {
  IStorageList,
  ITiedEquipmentList,
  IUserApi,
  IUserCreateValue,
  IUserGetValue,
  IUserList,
  IUserQueryParams,
  IUserExcelSample,
  TCreateExcel,
  IRole,
  INotTiedStorageList,
  ITieStorageToUserValue,
  IChangePassword,
  INotTiedEquipmentList,
  ITieEquipmentToUserValue,
  ICheckCodeRecoveryPassword,
  ISetNewPassworRecovery,
  ISendCodeEditEmail,
  IConfirmCodeEmail,
  IUserEquipmentStatuses,
  IDownloadReportParams,
  IPaginatedParams,
  IUserHistoryList,
  IAxiosConfig,
  IHidFormatsList,
  IHidFormatsSerializedList,
} from '@/api/interfaces'
import {
  ICreateUserSession,
  ILogout,
} from '@/api/interfaces/Page'
import {
  deleteAllCookies,
  setCookie,
  getCookie,
} from '@/utils/cookie'
import {
  CREATE_EXCEL,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_LAZY_SIZE,
  DEFAULT_PAGE_SIZE,
  EMPTY_CONSTS,
  GX_CREATE_USER_SESSION,
} from '@/const'
import { hidFormatsSerializer } from './serializers'
import { isString, isUndefined } from '@/utils'

class UserApi implements IUserApi {
  ctx: AbsractApi

  constructor(ctx: AbsractApi) {
    this.ctx = ctx
  }

  changePassword = async (
    data: IChangePassword,
  ): Promise<IChangePassword> => {
    const res = await this.ctx.post(
      '/garpix_user/change_password/',
      data,
    )
    return res.data
  }

  getRoles = async (): Promise<IRole[]> => {
    const roles =
      await this.ctx.get<IRole[]>('/users/roles/')
    return roles.data
  }

  createUserSession = async (): Promise<void> => {
    const userSessionToken = getCookie(
      GX_CREATE_USER_SESSION,
    )
    if (isString(userSessionToken)) {
      await this.ctx
        .post<ICreateUserSession>(
          '/garpix_user/user_session/create_user_session/',
          {},
        )
        .then((res) => {
          const sessionToken =
            res?.data?.session_user?.token_number
          if (isString(sessionToken)) {
            setCookie(GX_CREATE_USER_SESSION, sessionToken)
          }
        })
    }
  }

  logout = async (): Promise<ILogout> => {
    const res = await this.ctx.post<ILogout>(
      '/garpix_user/logout/',
      {},
    )
    deleteAllCookies()
    return res.data
  }

  sendCodeEditEmail = async (
    params: ISendCodeEditEmail,
  ): Promise<void> => {
    await this.ctx.post(
      '/garpix_user/confirm_email/send_code/',
      params,
    )
  }

  confirmCodeEmail = async (
    params: IConfirmCodeEmail,
  ): Promise<void> => {
    await this.ctx.post(
      '/garpix_user/confirm_email/check_code/',
      params,
    )
  }

  sendCodeRestorePassword = async (params: {
    username: string
  }): Promise<void> => {
    await this.ctx.post(
      '/garpix_user/restore_password/send_code/',
      params,
    )
  }

  checkCodeRestorePassword = async (
    params: ICheckCodeRecoveryPassword,
  ): Promise<void> => {
    await this.ctx.post(
      '/garpix_user/restore_password/check_code/',
      params,
    )
  }

  setPasswordRestorePassword = async (
    params: ISetNewPassworRecovery,
  ): Promise<void> => {
    await this.ctx.post(
      '/garpix_user/restore_password/set_password/',
      params,
    )
  }

  public getUsers = async (
    param?: IUserQueryParams,
    config?: IAxiosConfig,
  ): Promise<IUserList> => {
    const res = await this.ctx.get<IUserList>(
      '/users/',
      {
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size: DEFAULT_PAGE_SIZE,
        ...param,
      },
      config,
    )
    return res.data
  }

  public getUser = async (
    id: number,
  ): Promise<IUserGetValue> => {
    const { data } = await this.ctx.get<IUserGetValue>(
      `/users/${id}/`,
    )
    return data
  }

  public createUser = async (
    value: IUserCreateValue,
  ): Promise<IUserGetValue> => {
    const { data } = await this.ctx.post<IUserGetValue>(
      '/users/',
      value,
    )
    return data
  }

  public changeUser = async (
    id: number,
    value: IUserCreateValue,
  ): Promise<IUserGetValue> => {
    const { data } = await this.ctx.patch<IUserGetValue>(
      `/users/${id}/`,
      value,
    )
    return data
  }

  public deleteUser = async (
    id: number,
  ): Promise<IUserGetValue> => {
    const { data } = await this.ctx.delete<IUserGetValue>(
      `/users/${id}/`,
    )
    return data
  }

  public getExcelSample = async (): Promise<string> => {
    const res = await this.ctx.get<IUserExcelSample>(
      '/users/excel_sample/',
    )
    return res.data.user_file
  }

  public getStoragesTiedToUser = async (
    id: number,
    param?: IUserQueryParams,
  ): Promise<IStorageList> => {
    const res = await this.ctx.get<IStorageList>(
      `/users/${id}/tied_to_storages/`,
      {
        ...param,
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size:
          param?.page_size ?? DEFAULT_PAGE_LAZY_SIZE,
      },
    )
    return res.data
  }

  public getEquipmentsTiedToUser = async (
    id: number,
    param?: IUserQueryParams,
  ): Promise<ITiedEquipmentList> => {
    const res = await this.ctx.get<ITiedEquipmentList>(
      `/users/${id}/tied_to_equipments/`,
      {
        ...param,
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size:
          param?.page_size ?? DEFAULT_PAGE_LAZY_SIZE,
      },
    )
    return res.data
  }

  public createExcel: TCreateExcel = async ({
    file,
    storage,
    hid_format,
  }) => {
    const fd = new FormData()
    fd.append(CREATE_EXCEL.FILE, file)
    if (!isUndefined(storage)) {
      fd.append(CREATE_EXCEL.STORAGE_ID, storage)
    }
    if (!isUndefined(hid_format)) {
      fd.append(CREATE_EXCEL.HID_FORMAT, hid_format)
    }
    await this.ctx.post<FormData>(
      '/users/create_from_excel/',
      fd,
    )
  }

  public getStoragesNotTiedToUser = async (
    id: number,
    param?: IUserQueryParams,
  ): Promise<INotTiedStorageList> => {
    const res = await this.ctx.get<INotTiedStorageList>(
      `/users/${id}/not_tied_to_storages/`,
      {
        ...param,
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size:
          param?.page_size ?? DEFAULT_PAGE_LAZY_SIZE,
      },
    )
    return res.data
  }

  public tieStorageToUser = async (
    id: number,
    value: ITieStorageToUserValue,
  ): Promise<void> => {
    await this.ctx.post(
      `/users/${id}/tied_to_storages/`,
      value,
    )
  }

  public getEquipmentsNotTiedToUser = async (
    id: number,
    param?: IUserQueryParams,
  ): Promise<INotTiedEquipmentList> => {
    const res = await this.ctx.get<INotTiedEquipmentList>(
      `/users/${id}/not_tied_to_equipments/`,
      {
        ...param,
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size:
          param?.page_size ?? DEFAULT_PAGE_LAZY_SIZE,
      },
    )
    return res.data
  }

  public tieEquipmentToUser = async (
    id: number,
    value: ITieEquipmentToUserValue,
  ): Promise<void> => {
    await this.ctx.post(
      `/users/${id}/tied_to_equipments/`,
      value,
    )
  }

  public getUserEquipmentStatuses = async (
    param?: IUserQueryParams,
  ): Promise<IUserEquipmentStatuses> => {
    const res = await this.ctx.get<IUserEquipmentStatuses>(
      '/users/get_equipments_statuses/',
      param,
    )
    return res.data
  }

  public getHistory = async ({
    id,
    ...param
  }: IDownloadReportParams &
    IPaginatedParams): Promise<IUserHistoryList> => {
    const res = await this.ctx.get<IUserHistoryList>(
      `/users/${id}/history/`,
      {
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size: DEFAULT_PAGE_SIZE,
        ...param,
      },
    )
    return res.data
  }

  public downloadHistory = async ({
    id,
    ...param
  }: IDownloadReportParams): Promise<void> => {
    await this.ctx.post(
      `/users/${id}/history/report/`,
      param,
    )
  }

  public getHidFormats =
    async (): Promise<IHidFormatsSerializedList> => {
      const res = await this.ctx.get<IHidFormatsList>(
        '/users/get_hid_formats/',
      )

      return hidFormatsSerializer(res.data)
    }

  public unlockAllUsers = async (): Promise<void> => {
    await this.ctx.post(
      '/users/unblock_all/',
      EMPTY_CONSTS.OBJ,
    )
  }
}

export default UserApi
