import { useMemo } from 'react'
import { usePage as usePageCms } from '@garpix/cms'
import { DEFAULT_LINKS } from '@/const'

interface TServerLinks {
  [key: string]: string
}

const usePage = (): any => {
  const value = usePageCms()
  const serverLinks: TServerLinks = value.page.init_state.global.config.links

  const baseSlug = useMemo(() => ({
    equipmentListPage: serverLinks?.equipment_list_page ?? DEFAULT_LINKS.equipmentList,
    storageListPage: serverLinks?.storage_list_page ?? DEFAULT_LINKS.root,
    userAuthPpage: serverLinks?.user_auth_page ?? DEFAULT_LINKS.userAuth,
    userListPage: serverLinks?.user_list_page ?? DEFAULT_LINKS.userList,
    userProfilePage: serverLinks?.user_profile_page ?? DEFAULT_LINKS.profile,
    userRecoveryPage: serverLinks?.user_recovery_page ?? DEFAULT_LINKS.userPasswordRecovery,
    groupUserPage: serverLinks?.group_user_page ?? DEFAULT_LINKS.groupUserPage
  }), [value])

  return {
    page: value?.page,
    baseSlug
  }
}

export default usePage
