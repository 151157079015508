import { useCallback, useLayoutEffect, useRef } from 'react'

/**
 * Хук для мемоизации калбека
 * @param fn
 */
const useEvent = <T extends (...args: any[]) => any>(
  fn: T
): ((...args: Parameters<T>) => any) => {
  const fnRef = useRef(fn)
  useLayoutEffect(() => {
    fnRef.current = fn
  }, [fn])

  const eventCb = useCallback(
    (...args: Parameters<T>) => {
      return fnRef.current.apply(null, args)
    },
    [fnRef]
  )

  return eventCb
}

export default useEvent
