import React from 'react'
import classNames from 'classnames'

import BottomFields from './BottomFields'
import TopFields from './TopFields'
import SyncUsers from './SyncUsers'
import EmailNotify from './EmailNotify'

import styles from './style/index.module.scss'
/**
 * @view
 * * вьюха с главными полями интеграции
 */
const CoreForm = (): React.ReactElement => {
  return (
    <div className={styles.wrap}>
      <div
        className={classNames(
          styles.wrap__block,
          styles.wrap__block_top_field,
        )}
      >
        <TopFields />
      </div>
      <div className={styles.wrap__block}>
        <BottomFields />
      </div>
      <div className={styles.wrap__block}>
        <SyncUsers />
      </div>
      <div className={styles.wrap__block}>
        <EmailNotify />
      </div>
    </div>
  )
}

export default CoreForm
