import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import { Select } from '@/views/select'

import { EMPTY_STATUS, FILTER_KEY, STORAGE_STATUS_FIELDS } from '@/const'
import { IStorageFilterFileds } from '../interfaces'
import { TAsyncSelectHandleChange } from '@/components/AsyncSelect/interfaces'

/**
 * * основной компонент с полями для фильтра шкафов
 * @component StorageFilterFields
 *
 * @param  currentFilter           текущие параметры фильтра
 * @param  handleParamsChange      функция для изменения текущих параметров
 */
const StorageFilterFields: FC<IStorageFilterFileds> = ({
  currentFilter,
  handleParamsChange
}) => {
  const {
    storage_status: storageStatus = EMPTY_STATUS
  } = currentFilter
  const intl = useIntl()
  const storageStatusInitValue = STORAGE_STATUS_FIELDS.find(o => o.value === storageStatus)

  const handleStatusChange: TAsyncSelectHandleChange = (el) => {
    handleParamsChange(FILTER_KEY.STORAGE_STATUS, el)
  }

  return (
    <Select
      label={intl.formatMessage({
        id: 'filter.status',
        defaultMessage: 'Статус'
      })}
      onChange={handleStatusChange}
      value={storageStatusInitValue}
      options={STORAGE_STATUS_FIELDS}
    />
  )
}

export default StorageFilterFields
