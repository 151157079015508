import React from 'react'
import MenuItem from './MenuItem'
import { useGetContextMenus } from '@/hooks'
import { CONTEXT_MENUS } from '../consts'
import style from './styles/index.module.scss'
import Media from 'react-media'
import { MEDIA_QUERY } from '@/const'
import SliderMenu from '../../view/SliderMenu'

/**
 * ** Component  -  компонент логотипа для меню
 * ? подойдет только для этой апп
 */
const Menu: React.FC = () => {
  const menu = useGetContextMenus(CONTEXT_MENUS.HEAD_MENU)
  return (
    <div className={style.container}>
      <Media
        queries={{
          phone: MEDIA_QUERY.LARGE
        }}
      >
        {(matches) => {
          return matches.phone
            ? (
              <>
                {menu.map((el) => {
                  return <MenuItem key={el.id} {...el} />
                })}
              </>
              )
            : (
              <SliderMenu menu={menu} />
              )
        }}
      </Media>
    </div>
  )
}

export default Menu
