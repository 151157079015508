import React from 'react'

import style from './style/index.module.scss'

interface IProps {
  children: React.ReactNode
}
/**
 * @view
 * * вьюха обретака для даты изменения в табл история
 */
const HistoryTime: React.FC<IProps> = ({ children }) => {
  return (
    <div className={style.history}>{children}</div>
  )
}

export default HistoryTime
