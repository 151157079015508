import React, { useMemo, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { useIntl } from 'react-intl'
import Select from 'react-select/dist/declarations/src/Select'

import { GxIcon } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'

import { ArrowBackIcon } from '@/assets/icon'

import { IBaseAsyncSelect } from './interfaces'

import style from './styles/index.module.scss'
import getCustomSelectStyles from './styles'

const BaseAsyncSelect = React.forwardRef<Select, IBaseAsyncSelect>(
  (
    {
      placeholder,
      label,
      isMulti = false,
      labelLeft = false,
      selectClassName = '',
      labelClassName = '',
      handleChange,
      loadOptions,
      value,
      required = false,
      error = '',
      disabled = false,
      ...props
    },
    ref
  ): React.ReactElement => {
    const intl = useIntl()
    const [open, setOpen] = useState(false)

    const closeDropdown = (): void => {
      setOpen(false)
    }

    const openDropdown = (): void => {
      setOpen(true)
    }

    const noOptionsMessage = intl.formatMessage({
      id: 'select.no_options',
      defaultMessage: 'No options'
    })

    const loadingMessage = intl.formatMessage({
      id: 'select.loading',
      defaultMessage: 'Loading'
    })

    const styles = useMemo(() => getCustomSelectStyles(Boolean(error)), [error])

    return (
      <label
        className={classNames({
          [style.async__label]: true,
          [style.error]: Boolean(error),
          [style['async__label--left']]: labelLeft,
          [labelClassName]: Boolean(labelClassName)
        })}
      >
        <span
          className={classNames({
            [style.required]: required,
            [style.async__label_text]: true
          })}
        >
          {label}
        </span>
        <AsyncSelect
          {...props}
          ref={ref}
          value={value}
          onMenuClose={closeDropdown}
          onMenuOpen={openDropdown}
          styles={styles}
          placeholder={placeholder}
          components={{
            IndicatorsContainer: () => (
              <GxIcon
                src={ArrowBackIcon}
                className={classNames(style.async__icon, {
                  [style.async__icon_open]: open
                })}
              />
            )
          }}
          onChange={handleChange}
          isMulti={isMulti}
          noOptionsMessage={() => noOptionsMessage}
          loadingMessage={() => loadingMessage}
          cacheOptions
          defaultOptions
          required={required}
          isDisabled={disabled}
          loadOptions={loadOptions}
          className={selectClassName}
        />
        {error !== ''
          ? (
            <span className={style.async__label_error}>{error.toString()}</span>
            )
          : null}
      </label>
    )
  }
)

export default BaseAsyncSelect
