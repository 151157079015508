import React, { FC } from 'react'

import ErrorsBlock from '@/components/ErrorsBlock'
import EditStatus from './EditStatus'
import ActionButtons from './ActionButtons'
import CoreFields from './CoreFields'
import Form from '@/views/Form'

import { getInitials } from '@/utils'

import { EMPTY_CONSTS } from '@/const'

import { IUserEditView } from './interfaces'

import style from './style/index.module.scss'

const UserEditView: FC<IUserEditView> = ({
  formikTools,
  projectId,
  closeModal,
  error
}) => {
  const disabled = formikTools.isSubmitting
  const { values } = formikTools
  const {
    last_name: lastName = EMPTY_CONSTS.STR,
    first_name: firstName = EMPTY_CONSTS.STR
  } = values
  const title = getInitials(firstName, lastName)
  const errors = error ?? EMPTY_CONSTS.ARR

  return (
    <Form onSubmit={formikTools.handleSubmit}>
      <div className={style['user-edit']}>
        <EditStatus
          title={title}
          closeModal={closeModal}
          projectId={projectId}
        />
        <CoreFields formikTools={formikTools} />
        <div className={style['user-edit__err_wrap']}>
          <ErrorsBlock errorsArray={errors} />
        </div>
        <ActionButtons
          projectId={projectId}
          handleCancel={closeModal}
          disabled={disabled}
        />
      </div>
    </Form>
  )
}

export default UserEditView
