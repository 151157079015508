import { useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'

import { StepsRestorePassword } from '@garpix/cms'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'
import useCustomAuth from '@/apps/Login/hooks'
import {
  useCurrentUser,
  useSiteConfig,
  useStores
} from '@/hooks'

import {
  NEW_PASSWORD_SCHEMA,
  CODE_PASSWORD
} from '@/utils/validation'
import {
  DEFAULT_LINKS,
  EMPTY_CONSTS,
  MODAL_TYPES
} from '@/const'

const fields = {
  password: 'password',
  new_password: 'new_password',
  code: 'code',
  restore_password_confirm_code:
    'restore_password_confirm_code',
  username: 'username'
}

const delayReLogin = 1500

interface IFields {
  password: string
  new_password: string
}

const START_STEP = 2

const modalsAccordSteps = {
  2: MODAL_TYPES.RECOVERY_PASSWORD,
  3: MODAL_TYPES.NEW_PASSWORD,
  4: MODAL_TYPES.SUCCES_EDIT_PASSWORD
}

/**
 * @hook   useCustomRestorePassword
 * * Хук для восстановления пароля пользователя (оборачивает хук useRestorePassword из @garpix/cms)
 *
 * @returns {
 *  onSubmit                     функция для отправки запроса на сервер (вид запроса определяется из номер шага step)
 *  handlePasswordValue          функция для изменения пароля вводимого в инпут
 *  handleCodeValue              функция для изменения кода вводимого в инпут
 *  resendCode                   функция повторной отправки проверочного кода на почту
 *  username                     логин (почта) пользователя
 *  error                        обьект ошибок
 * }
 */
const useCustomRestorePassword = (): any => {
  const intl = useIntl()
  const user = useCurrentUser()
  const { email } = user
  const store = useStores()
  const config = useSiteConfig()

  const [step, setStep] = useState(START_STEP)
  const [code, setCode] = useState(EMPTY_CONSTS.STR)
  const [errors, setErrors] = useState<{
    response_errors?: Record<string, string | string[]>
    validate_errors?: Record<string, string | string[]>
  } | null>(EMPTY_CONSTS.NULL)
  const [passwords, setPasswords] = useState<IFields>({
    password: EMPTY_CONSTS.STR,
    new_password: EMPTY_CONSTS.STR
  })

  const codeRef = useRef(code)
  const passwordsRef = useRef(passwords)
  const stepRef = useRef(step)

  const linkToRoot =
    config?.links?.profile ?? DEFAULT_LINKS.profile

  const { handleAuthSubmit } = useCustomAuth({
    successLink: linkToRoot
  })

  /**
   * * функция для проброса новых пропсов в modalStore
   * @param data объект ошибок
   */
  const provideErrorsToModalProps = (
    data: {
      response_errors?: Record<string, string | string[]>
      validate_errors?: Record<string, string | string[]>
    } | null
  ): void => {
    setErrors(data)
    store.modalStore.setModalProps({
      ...store.modalStore.modalProps,
      errors: data
    })
  }

  /**
   * * функция для изменения кода вводимого в инпут (внутри вызывается setError для очистки тела ошибок)
   * @param e  event, в котором содержится значение инпута
   */
  const handleCodeValue = (
    e: GxInputCustomEvent<any>
  ): void => {
    setErrors(EMPTY_CONSTS.NULL)
    setCode(e.target.value)
  }

  /**
   * * функция для изменения пароля вводимого в инпут
   * внутри вызывается setError для очистки тела ошибок, и setPasswords для записи 2 паролей с инпутов
   * @param e  event, в котором содержится значение инпута
   */
  const handlePasswordValue = (
    e: GxInputCustomEvent<any>
  ): void => {
    const name = e.target.name
    setErrors(EMPTY_CONSTS.NULL)
    setPasswords({
      ...passwordsRef.current,
      [name]: e.target.value
    })
  }

  /**
   * * функция достающая ошибки из ответа сервера и установка этих ошибок (в модалку и локально)
   * @param e  event, в котором содержится информация об ответе сервера
   */
  const proccessResponseEror = (e: any): void => {
    const err = e.response?.data
    // проверка на null & undefind
    if (err !== EMPTY_CONSTS.NULL) {
      provideErrorsToModalProps({ response_errors: err })
    }
  }

  /**
   * * функция достающая ошибки из ответа валидатора и установка этих ошибок (в модалку и локально)
   * достает по одной (первой ошибке) из каждого поля и переводит ее
   * @param e  event, в котором содержится информация об ответе валидатора
   */
  const proccessErorValidate = (e: any): void => {
    const errEntr = e.errors.reduce((sum, error) => {
      const messageEntries = Object.entries(error)[0]
      const isExist = sum.findIndex(el => el[0] === messageEntries[0])
      if (isExist === -1) {
        const translatedVal = intl.formatMessage({
          id: messageEntries[1] as string,
          defaultMessage: 'Ошибка'
        })
        return [...sum, [messageEntries[0], translatedVal]]
      }
      return sum
    }, [])
    provideErrorsToModalProps({ validate_errors: Object.fromEntries(errEntr) })
  }

  /**
   * * Функция повторной отправки проверочного кода на почту.
   * * Ошибки, полученные при запросе, записываются в обьект ошибок error.
   */
  const resendCode = async (): Promise<void> => {
    void store.api.user
      .sendCodeRestorePassword({ username: email })
      .then(() => {
        provideErrorsToModalProps({})
      })
      .catch((e) => {
        const data = e?.response?.data
        // проверка на null & undefind
        if (data !== EMPTY_CONSTS.NULL && data !== EMPTY_CONSTS.UNDEFINED) {
          provideErrorsToModalProps({ response_errors: data })
        }
      })
  }

  /**
   * * Функция отерывает первую модалку с которой начинается пайплайн востановления пароля
   */
  const openFirstModal = (): void => {
    store.modalStore.open(MODAL_TYPES.RECOVERY_PASSWORD, {
      onSubmit,
      handleCodeValue,
      resendCode,
      username: email,
      errors,
      handlePasswordValue,
      closeModal: store.modalStore.close
    })
  }

  /**
   * * Функция отправляет проверочный код и открывает первую модалку
   * * При наличии ошибки модалка всеравно откроется (случай когда код был отправлен до этого и код еще активен)
   */
  const handleRecoveryPassword = (): void => {
    store.api.user
      .sendCodeRestorePassword({ username: email })
      .then(() => {
        openFirstModal()
      })
      .catch((e) => {
        const data = e?.response?.data
        // проверка на null & undefind
        if (data !== EMPTY_CONSTS.NULL && data !== EMPTY_CONSTS.UNDEFINED) {
          provideErrorsToModalProps(data)
          openFirstModal()
        }
      })
  }

  /**
   * * функция для определяющая submit в зависимости от текущего шага
   */
  const handleSubmit = async (): Promise<void> => {
    if (
      stepRef.current === StepsRestorePassword.checkCode
    ) {
      return await store.api.user.checkCodeRestorePassword({
        restore_password_confirm_code: codeRef.current,
        username: email
      })
    } else if (
      stepRef.current === StepsRestorePassword.setPassword
    ) {
      return await store.api.user.setPasswordRestorePassword(
        {
          restore_password_confirm_code: codeRef.current,
          username: email,
          new_password: passwordsRef.current.new_password
        }
      )
    }
  }

  /**
   * * Общая функция отправки данных (включает валидацию полей) и (обработку ошибок)
   * * так же меняет шаг и открывает новую модалку при успешной отправке
   * @param event  событие отправки формы
   */
  const onSubmit = async (): Promise<void> => {
    try {
      if (
        stepRef.current === StepsRestorePassword.checkCode
      ) {
        await CODE_PASSWORD.validate({
          [fields.code]: codeRef.current
        })
      }
      if (
        stepRef.current === StepsRestorePassword.setPassword
      ) {
        await NEW_PASSWORD_SCHEMA.validate({
          ...passwordsRef.current
        }, { abortEarly: false })
      }
    } catch (e) {
      proccessErorValidate(e)
      return
    }

    void handleSubmit()
      .then(() => {
        store.modalStore.open(
          modalsAccordSteps[stepRef.current + 1],
          {
            onSubmit,
            handleCodeValue,
            resendCode,
            username: email,
            errors,
            handlePasswordValue,
            closeModal: store.modalStore.close
          }
        )
        setStep(stepRef.current + 1)
      })
      .catch((e) => {
        proccessResponseEror(e)
      })
  }

  /**
   * * авто перезаход в аккаунт после смены пароля
   */
  useEffect(() => {
    if (step === StepsRestorePassword.finish) {
      const { password } = passwords
      const timeout = setTimeout(() => {
        handleAuthSubmit({ password, username: email })
      }, delayReLogin)
      return () => {
        // проверка на null и undefined
        if (timeout !== EMPTY_CONSTS.NULL && timeout !== EMPTY_CONSTS.UNDEFINED) { clearTimeout(timeout) }
      }
    }
  }, [step])

  /**
   * * Уход от синхронности useState(из за вызова событий из модалок потеря актуальных состояний)
   */
  useEffect(() => {
    codeRef.current = code
  }, [code])

  /**
   * * Уход от синхронности useState(из за вызова событий из модалок потеря актуальных состояний)
   */
  useEffect(() => {
    passwordsRef.current = passwords
  }, [passwords])

  /**
   * * Уход от синхронности useState(из за вызова событий из модалок потеря актуальных состояний)
   */
  useEffect(() => {
    stepRef.current = step
  }, [step])

  return {
    handleRecoveryPassword
  }
}

export default useCustomRestorePassword
