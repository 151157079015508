import React from 'react'
import { formatDateForFrontend } from '@/utils'
import { DATE_MASKS } from '@/const'
import { Typography } from '@/views/typography'

import styles from './styles/index.module.scss'

export interface IProblemHeadProps {
  createdAt: string
  title: string
  firstName: string
  lastName: string
}

/**
 * Шапка для Акордиона проблемы в списке проблем деталки оборудования
 * @param createdAt - когда обновлено
 * @param title - тайтл проблемы
 * @param firstName - имя пользователя
 * @param lastName - фамилия пользователя
 * @constructor
 */

const ProblemHeadView: React.FC<IProblemHeadProps> = ({
  createdAt,
  title,
  firstName,
  lastName,
}) => {
  const formatedUpdatedAt = formatDateForFrontend(
    createdAt,
    DATE_MASKS.DATE_WITH_TIME,
  )

  return (
    <div className={styles.head}>
      <div className={styles.head__root}>
        <div className={styles.head__problem}>
          <span className={styles.head__problem_user}>
            {`${lastName} ${firstName}`}
          </span>
          <Typography size={14} weight={400} color="b-60">
            {formatedUpdatedAt}
          </Typography>
        </div>
      </div>
      <Typography size={15} weight={400} color="black">
        {title}
      </Typography>
    </div>
  )
}

export default ProblemHeadView
