import React from 'react'
import { useIntl } from 'react-intl'
import {
  IDownloadReport,
  INotificationProps
} from '@/apps/Toast/interfaces'
import { EMPTY_CONSTS } from '@/const'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'

import styles from './styles/index.module.scss'

/**
 * Уведомление для скачивания отчета
 * @param link - ссылка на отчет
 * @param onClose - метод для закрытия
 * @param title - тайтл отчета
 * @param subtitle - сабтайтл отчета
 * @constructor
 */

interface IDownloadReportProps
  extends INotificationProps,
  Omit<IDownloadReport, 'type'> {}

const DownloadEquipmentReport: React.FC<
IDownloadReportProps
> = ({
  link,
  onClose,
  title = EMPTY_CONSTS.STR,
  subtitle = EMPTY_CONSTS.STR
}) => {
  const intl = useIntl()
  const handleClick = (): void => {
    onClose()
  }
  return (
    <div className={styles.notify__root}>
      <Icon src='successStatus' size='xl' />
      <div className={styles.notify__info}>
        <Typography size={18} weight={700}>
          {title}
        </Typography>
        <Typography size={14} weight={400}>
          {subtitle}
        </Typography>
      </div>
      <a
        color='transparent'
        className={styles.notify__link}
        onClick={handleClick}
        href={link}
      >
        <Icon src='download' size='small-xl' />
        {intl.formatMessage({
          id: 'download',
          defaultMessage: 'Скачать'
        })}
      </a>
    </div>
  )
}

export default DownloadEquipmentReport
