import React from 'react'

import { GxIcon } from '@garpix/garpix-web-components-react'

import { Button } from '@/views/button'

import { useStores } from '@/hooks'

import { randomId } from '@/utils'

import {
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES,
  NOTIFICATION_TYPES,
} from '@/const'
import { CopyIcon } from '@/assets/icon'

import styles from './styles/index.module.scss'

interface IProps {
  text: string
}

/**
 * @view Кнопка копирования какого либо текста
 */
const CopyButton: React.FC<IProps> = ({ text }) => {
  const { notifyStore, modalStore } = useStores()

  const handleCopyText = (): void => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notifyStore.createNotification({
          id: randomId(5),
          type: NOTIFICATION_TYPES.COPY_TAG,
          autoClose: true,
          variant: 'info',
          showCloseBtn: false,
        })
      })
      .catch(() => {
        modalStore.open(
          MODAL_TYPES.CUSTOM,
          MODAL_CUSTOM_CONTENT.ERROR,
        )
      })
  }

  return (
    <div className={styles.wrap}>
      <Button
        onClick={handleCopyText}
        className={styles['copy-btn']}
        color="transparent"
      >
        <GxIcon src={CopyIcon} />
      </Button>
    </div>
  )
}

export default CopyButton
