import React from 'react'

import { observer } from 'mobx-react'
import { CloseIcon } from '@/assets/icon'

import { Button } from '../button'
import { Typography } from '../typography'

import style from './style/index.module.scss'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { EMPTY_CONSTS } from '@/const'

interface IProps {
  count: number
  handleClick: () => void
}

const ResetCounter = ({ count, handleClick }: IProps): React.ReactElement => {
  const isActive = count > EMPTY_CONSTS.ZERO

  return (
    <div data-active={isActive} className={style.reset}>
      <Typography className={style.reset__count} size={14} weight={400}>
        {count}
      </Typography>
      <Button onClick={handleClick} className={style.reset__btn} color='transparent'>
        <GxIcon
          src={CloseIcon}
          className={style.reset__close}
        />
      </Button>
    </div>
  )
}

export default observer(ResetCounter)
