import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import styles from './style/index.module.scss'

interface ILinkButton {
  href: string
  children: React.ReactNode
  size: 'large' | 'medium'
  className?: string
  disabled?: boolean
}

/**
 * @component LinkButton
 * * компонент кпопки при клике на которую происходит переход на другую страницу
 * @param {ILinkButton}                    общий интерфейс входных параметров LinkButton
 *
 * @param href                             ссылка на которую перейдет пользователь при клике по LinkButton
 * @param children                         дочерние компоненты (контент)
 * @param size                             размер
 * @param className                        дополнительные стили
 * @param disabled                         кнопка включена\отключена
 */
const LinkButton: FC<ILinkButton> = ({
  children,
  href,
  className,
  size,
  disabled = false,
  ...props
}) => {
  const navigate = useNavigate()

  /**
   * * функция для перехода на другую страницу
   * @func   handleClick
   * @param
   */
  const handleClick = (e): void => {
    e.preventDefault()
    navigate(href)
  }

  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames(
        styles.link,
        styles[`link__${size}`],
        className
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}

export default LinkButton
