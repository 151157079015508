import React, { FC } from 'react'
import FilterView from '../../views/FilterView'
import { IFilterModal } from '../interfaces'

/**
 * * компонент фильтра оборудования
 * @component EquipmentFilter
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const EquipmentFilter: FC<IFilterModal> = ({
  modalProps,
  closeModal
}) => (
  <FilterView
    filterType='equipment'
    modalProps={modalProps}
    closeModal={closeModal}
  />
)

export default EquipmentFilter
