import React from 'react'

import style from './style/index.module.scss'

interface IProps {
  children: React.ReactNode
  theme?: 'cells' | 'default'
}
/**
 * @view
 * * контейнер для контента на деталке шкафа который лежит в табах
 */
const ContentContainer: React.FC<IProps> = ({
  children,
  theme = 'default'
}) => {
  return (
    <div data-type={theme} className={style.container}>{children}</div>
  )
}

export default ContentContainer
