import React from 'react'
import classNames from 'classnames'
import { EMPTY_CONSTS } from '@/const'
import styles from './styles/index.module.scss'

/**
 * Контейнер для полей оборудования
 * @param children
 * @param className
 * @constructor
 */
const FieldContainer: React.FC<{
  children: React.ReactNode
  className?: string
}> = ({ children, className = EMPTY_CONSTS.STR }) => {
  return (
    <div
      className={classNames(styles.container__root, {
        [className]: Boolean(className)
      })}
    >
      {children}
    </div>
  )
}

export default FieldContainer
