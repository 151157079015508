import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import ErrorsBlock from '@/components/ErrorsBlock'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { Input } from '@/views/input'
import { prevDef } from '@/utils'
import styles from '../styles/index.module.scss'

const fields = {
  password: 'password',
  new_password: 'new_password'
}

export interface INewPasswordValues {
  new_password: string
  password: string
}

/**
 * @component NewPassword
 * * компонент формы установки и отправки нового пароля пользователя
 * @param {INewPassword}                   общий интерфейс входных параметров NewPassword
 *
 * @param {function}  handlePasswordValue  функция для изменения пароля вводимого в инпут
 * @param {function}  onSubmit             функция для отправки нового пароля на сервер
 * @param {string}    username             логин (почта) пользователя
 * @param {IError}    errors              обьект ошибок
 */
const NewPassword: React.FC<any> = ({
  modalProps,
  closeModal
}): React.ReactElement => {
  const intl = useIntl()
  const {
    handlePasswordValue,
    onSubmit,
    errors
  } = modalProps

  const labels = useMemo(() => ({
    [fields.new_password]: intl.formatMessage({
      id: 'profile.modal.newPassword',
      defaultMessage: 'Новый пароль'
    }),
    [fields.password]: intl.formatMessage({
      id: 'profile.modal.repeatPassword',
      defaultMessage: 'Повторите пароль'
    })
  }), [])

  const texts = useMemo(() => ({
    desription: intl.formatMessage({
      id: 'profile.modal.newPassword.description',
      defaultMessage: 'Придумайте новый пароль. Надежный пароль включает цифры, буквы и символы.'
    })
  }), [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    prevDef(e)
    void onSubmit()
  }

  return (
    <div className={classNames(styles.container, styles['container__recovery-password'])}>
      <Button
        className={styles.container__close}
        onClick={closeModal}
        variant='text'
        color='gray'
      >
        <Icon src='close' />
      </Button>
      <span className={styles['container__recovery-password_title']}>
        <FormattedMessage
          id='profile.modal.newPassword'
          defaultMessage='Новый пароль'
        />
      </span>
      <span className={styles['container__recovery-password_discription']}>
        {texts.desription}
      </span>
      <form
        className={styles['container__recovery-password_form']}
        onSubmit={handleSubmit}
      >
        <Input
          type='password'
          name={fields.new_password}
          data-testid={fields.new_password}
          labelStyles={styles.form__field_label}
          outerStyles={classNames(styles.form__field, styles.form__field_input)}
          label={labels[fields.new_password]}
          onChange={handlePasswordValue}
          error={Boolean(errors?.validate_errors?.[fields.new_password] !== undefined ||
              errors?.response_errors?.[fields.new_password] !== undefined)}
          errorMessage={errors?.validate_errors?.[fields.new_password]}
        />
        <Input
          type='password'
          name={fields.password}
          data-testid={fields.password}
          labelStyles={styles.form__field_label}
          outerStyles={styles.form__field}
          label={labels[fields.password]}
          onChange={handlePasswordValue}
          error={Boolean(errors?.validate_errors?.[fields.password] !== undefined ||
              errors?.response_errors?.[fields.password] !== undefined)}
          errorMessage={errors?.validate_errors?.[fields.password]}
        />
        <div className={styles['container__recovery-password_buttons']}>
          <Button
            className={styles.form__button}
            onClick={closeModal}
          >
            <FormattedMessage id='common.cancel' defaultMessage='Отмена' />
          </Button>
          <Button
            type='submit'
            color='black'
            className={styles.form__button}
          >
            <FormattedMessage id='common.save' defaultMessage='Save' />
          </Button>
        </div>
      </form>
      <ErrorsBlock
        networkError={errors?.response_errors?.network_error}
        errorsArray={
            errors?.response_errors?.non_field_errors ??
            errors?.response_errors?.[fields.new_password] ??
            errors?.response_errors?.[fields.password] ?? null
          }
      />
    </div>
  )
}

export default NewPassword
