import * as yup from 'yup'
import { INTL_DEFAULT_MESSAGE } from '@/const'
import { REGEX } from '../validation'

const LoginSсheme = yup.object().shape({
  username: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
    .matches(
      REGEX.email,
      { message: INTL_DEFAULT_MESSAGE.UNCORRECT_EMAIL }
    ),
  password: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
})

export default LoginSсheme
