import React from 'react'
import styles from '../styles/index.module.scss'

const UserLayout: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <div className={styles['user-sidebar__layout']}>
      {children}
    </div>
  )
}

export default UserLayout
