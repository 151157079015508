import React from 'react'

import styles from './styles/index.module.scss'

const Layout: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return <div className={styles.layout}>{children}</div>
}

export default Layout
