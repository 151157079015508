import React, { useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import useFetch from './useFetch';
import {
  getLastPageNumber,
  isNull,
  isUndefined,
} from '@/utils';
import {
  IPaginatedParams,
  IPaginationList,
  IQueryParams,
} from '@/api/interfaces';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_LAZY_SIZE,
  EMPTY_CONSTS,
  MAX_PERCENT,
  SCROLL_END_PERCENT,
  SCROLL_TO,
} from '@/const';

/** хук для запросов на сервер для lazy loading
 * @hook useLazyFetch
 * @returns {
 *
 *  isLoading           статус выполнения запроса
 *  error               ошибка запроса
 *  data                данные запроса
 *  handleParams        функция, задающая query-параметры
 *  params              текущие query-параметры
 *  tableScroll         функция, которая вешается на скролл (onScroll)
 *  lazyLoadedData      обработанные данные запроса
 *  isScrollEnd         флаг о том доскроллен контейнер до самого низа
 * }
 */
const useLazyFetch = <
  T extends (params: IPaginatedParams) => Promise<
    IPaginationList & {
      results: any[];
    }
  >,
>(
  refContainer: React.RefObject<HTMLElement> | null = EMPTY_CONSTS.NULL,
  fetcher: T,
  initialParams: Parameters<T>[0] = {},
): {
  isLoading: boolean;
  refetch: () => void;
  error: string[] | undefined | null;
  params: Parameters<T>[0];
  handleFetch: (params: IQueryParams) => void;
  tableScroll: () => void;
  lazyLoadedData:
    | Awaited<ReturnType<T>>['results']
    | undefined;
  handleParams: (param: Parameters<T>[0]) => void;
  isScrollEnd: boolean;
} => {
  const { data, isLoading, handleParams, params, error } =
    useFetch(fetcher, initialParams);
  const currentPage = params.page ?? DEFAULT_CURRENT_PAGE;
  const pageSize =
    params.page_size ?? DEFAULT_PAGE_LAZY_SIZE;
  const dataResults = data?.results;
  const [lazyLoadedData, setLazyLoadedData] = useState<
    Awaited<ReturnType<T>>['results'] | undefined
  >(dataResults);
  const [currentParams, setCurrentSearch] =
    useState<IQueryParams>(EMPTY_CONSTS.OBJ);
  const [isRefetch, setIsRefetch] = useState<boolean>(
    EMPTY_CONSTS.FALSE,
  );
  const [isScrollEnd, setIsScrollEnd] = useState(
    EMPTY_CONSTS.FALSE,
  );
  const lastPage = useMemo(
    () =>
      data?.count !== undefined
        ? getLastPageNumber(data.count, pageSize)
        : DEFAULT_CURRENT_PAGE,
    [data],
  );

  const getWithoutPageParam = (): Omit<
    IQueryParams,
    'page'
  > => {
    const copyParams = { ...params };
    delete copyParams.page;
    return copyParams;
  };

  /**
   * *  @func  tableScroll
   * функция, которая реагирует, дошел ли скролл
   * до низа наблюдаемого контейнера
   *
   * @param
   */
  const tableScroll = (): void => {
    if (
      !isUndefined(refContainer?.current) ||
      !isNull(refContainer?.current)
    ) {
      const { scrollHeight, scrollTop, clientHeight } =
        refContainer?.current as HTMLElement;
      const scrollPercent = Math.floor(
        (scrollTop / (scrollHeight - clientHeight)) *
          MAX_PERCENT,
      );

      const isAtEnd =
        Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      if (isAtEnd) {
        setIsScrollEnd(EMPTY_CONSTS.FALSE);
      } else {
        setIsScrollEnd(EMPTY_CONSTS.TRUE);
      }

      if (currentPage < lastPage) {
        if (
          scrollPercent > SCROLL_END_PERCENT &&
          !isLoading
        ) {
          const currentPage =
            params.page ?? DEFAULT_CURRENT_PAGE;
          handleParams({
            ...params,
            page: currentPage + 1,
          });
        }
      }
    }
  };

  /**
   * * функция для повторного запроса с текущими query-параметрами
   * @func refetch
   *
   * @param
   */
  const refetch = (): void => {
    setIsRefetch(EMPTY_CONSTS.TRUE);
    handleFetch(params);
  };

  /**
   * Фетч по параметрам для фильтра и поиска
   * @param params
   */
  const handleFetch = (params: IPaginatedParams): void => {
    refContainer?.current?.scrollTo(SCROLL_TO.SMOOTH_TOP);
    handleParams({
      ...params,
      page: DEFAULT_CURRENT_PAGE,
    });
  };

  /**
   * *  @info   useEffect
   * перезаписывает данные в lazyLoadedData, в зависимости от query
   * параметра search
   *
   * @param
   */
  useEffect(() => {
    if (Array.isArray(dataResults) && !isLoading) {
      const params = getWithoutPageParam();
      let newData =
        lazyLoadedData != null ? [...lazyLoadedData] : [];
      if (!isEqual(params, currentParams) || isRefetch) {
        newData = [...dataResults];
        setIsRefetch(EMPTY_CONSTS.FALSE);
      } else {
        newData = [...newData, ...dataResults];
      }
      setCurrentSearch(params);
      setLazyLoadedData(newData);
    }
  }, [isLoading]);

  return {
    isLoading,
    error,
    handleParams,
    params,
    tableScroll,
    lazyLoadedData,
    refetch,
    handleFetch,
    isScrollEnd,
  };
};

export default useLazyFetch;
