export const DEFAULT_PAGE_STRUCT = {
  id: 0,
  seo_title: '',
  title: '[FATAL_ERROR] - NOT PAGE DATA',
  seo_description: '',
  description: '',
  seo_keywords: '',
  seo_image: '',
  seo_author: '',
  seo_og_type: '',
  cell_number: 0,
  error: 0,
  is_active: false,
  display_on_sitemap: false,
  slug: '',
}

const errorSerializer = (): any => {
  const data = {
    init_state: {
      object: {
        title: '500',
        seo_title: '500',
        error: 500,
      },
    },
  }
  return data
}

const defaultSerializer = (data: any): any => data

const notFoundSerializer = (data: any): any => {
  data.init_state.object = {
    title: '404',
    seo_title: '404',
    error: 404,
  }
  return data
}

const permissionDeniedSerializer = (data: any): any => {
  data.init_state.object = {
    title: '403',
    seo_title: '403',
    error: 403,
  }
  return data
}

const unauthorizedSerializer = (): any => {
  const data = { init_state: { object: {} } }
  data.init_state.object = {
    title: '401',
    seo_title: '401',
    error: 401,
  }
  return data
}

export {
  errorSerializer,
  defaultSerializer,
  notFoundSerializer,
  permissionDeniedSerializer,
  unauthorizedSerializer,
}
