import React from 'react'
import { useStores } from '@/hooks'

import { IUserQueryParams } from '@/api/interfaces'
import { IEquipmentFilterParams } from '@/apps/Modals/ModalContent/interfaces'
import {
  EMPTY_CONSTS,
  MODAL_TYPES,
  TABLE_PAGE,
  THEME_MODAL
} from '@/const'

import SearchPanel from '../../views/SearchPanel'

interface IProps {
  handleParams: (
    params?: IUserQueryParams | IEquipmentFilterParams
  ) => void
  params: IEquipmentFilterParams | IUserQueryParams
  refetch: () => void
}

/**
 * @comp
 * * компонент с логикой панели таба оборудования
 * @param handleParams метод для изменеия параметров сортировки
 * @param params       параметры сортировки
 */
const EquipmentPanel: React.FC<IProps> = ({
  handleParams,
  params,
  refetch
}) => {
  const { modalStore } = useStores()

  const handleOpenBindEquipment = (): void => {
    modalStore.open(MODAL_TYPES.EQUIPMENT_BIND, { refetch })
  }
  /**
   * @function handleAddEquip
   * * метод для открытия модалки создания оборудования
   */
  const handleAddEquip = (): void => {
    modalStore.open(MODAL_TYPES.EQUIPMENT_EDIT, {
      theme: THEME_MODAL.LARGE,
      refetch
    })
  }
  /**
   * @function handleUploadEquipExel
   * * метод для открытия модалки загрузки оборудования
   */
  const handleUploadEquipExel = (): void => {
    modalStore.open(MODAL_TYPES.UPLOAD_EQUIPMENT_EXCEL, {
      refetch
    })
  }

  const addBtnProps = {
    tooltipContent: {
      id: 'tieEquipment',
      defaultMessage: 'Привязать оборудование'
    },
    onClick: handleOpenBindEquipment
  }

  const filterBtnProps = {
    type: TABLE_PAGE.EQUIPMENT,
    params,
    isStorageEquipment: EMPTY_CONSTS.TRUE
  }

  return (
    <SearchPanel
      filterBtnProps={filterBtnProps}
      addBtnProps={addBtnProps}
      handleParams={handleParams}
      handleCreateBtn={handleAddEquip}
      handleUpload={handleUploadEquipExel}
      createBtnTooltipContent={{
        id: 'button.add_equipment',
        defaultMessage: 'Добавить оборудование'
      }}
    />
  )
}

export default EquipmentPanel
