import React from 'react'

import { EquipmentStatusIcon } from '@/components/EquipmentStatusIcon'
import Icon from '@/components/Icon'
import UnlinkDropdown from '@/views/UnlinkDropdown'

import { useStores } from '@/hooks'

import {
  EQUIPMENT_STATUS,
  EQUIPMENT_STATUS_ICON,
  MODAL_TYPES,
} from '@/const'

import { ITiedEquipment } from '@/api/interfaces'
import { TUnlinkEquipment } from '../../interfaces'

import styles from './styles/index.module.scss'
import { TIcon } from '@/components/Icon/interfaces'

type ITitleProps = Pick<
  ITiedEquipment,
  'title' | 'availability_status' | 'equipment_type'
> & {
  showIcon?: boolean
}
/**
 * @component Title
 * * компонент названия оборудования
 * @param
 */
export const Title = ({
  title,
  equipment_type: type,
  availability_status: status,
  showIcon = false,
}: ITitleProps): JSX.Element => {
  const iconType =
    status === EQUIPMENT_STATUS.ON_HANDS
      ? EQUIPMENT_STATUS_ICON.ON_HANDS
      : EQUIPMENT_STATUS_ICON.ABSENT

  return (
    <div className={styles.body__content}>
      {showIcon && (
        <Icon
          src={iconType as TIcon}
          className={styles.body__content_icon}
        />
      )}
      <div className={styles.body__title}>
        <span className={styles.body__title_sub}>
          {type}
        </span>
        {title}
      </div>
    </div>
  )
}

/**
 * @component Status
 * * компонент статуса оборудования
 * @param
 */
export const Status = (el: string): JSX.Element => {
  return (
    <div className={styles.body__status}>
      <EquipmentStatusIcon type={el} />
    </div>
  )
}

/**
 * @component UnlinkEquipment
 * * компонент дропдауна отвязки оборудования
 * @param equipmentData         оборудование
 * @param handleUnbindRequest   метод отвязуи чего либо
 * @param refetch               обновление списка прикепленных пользователей после отвязки
 *                              от оборудования
 */
export const UnlinkEquipment: TUnlinkEquipment = ({
  refetch,
  equipmentData,
  handleUnbindRequest,
}) => {
  const { modalStore } = useStores()

  /**
   * метод для открытия модального окна для
   * отвязывания оборудования от пользователя
   */
  const handleOpenModal = (): void => {
    modalStore.open(MODAL_TYPES.EQUIPMENT_UNBIND, {
      handleUnbindRequest,
      equipmentData,
      refetch,
    })
  }

  return <UnlinkDropdown onClick={handleOpenModal} />
}
