import React from "react";
import MainLayout from "@/components/mainLayout/MainLayout";
import UserPagesGeneral from "@/views/UserPagesGeneral";
import UserGroupList from "@/apps/UserGroupList";

const GroupUserPage = () => {
  return (
    <MainLayout>
      <UserPagesGeneral.ContentWrap>
        <UserPagesGeneral.Navigation />
        <UserGroupList />
      </UserPagesGeneral.ContentWrap>
    </MainLayout>
  )
}

export default GroupUserPage