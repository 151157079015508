import React, { FormEventHandler } from 'react'
import { observer } from 'mobx-react'
import { ObservableMap } from 'mobx'

import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'
import { IEquipmentsHoCanBeTied } from '@/api/interfaces'

import Header from '../UserBindingViews/Header'
import Footer, {
  IFooterUnbindModal,
} from '../UserBindingViews/Footer'
import FilterPanel, {
  IFilterEquipmentPanel,
} from './FilterPanel'
import ScrollWrap, { IScrollWrapProps } from '../ScrollWrap'
import ErrorsBlock from '@/components/ErrorsBlock'
import EquipmentItem from './EquipmentItem'

import style from './style/index.module.scss'

interface IEquipItemProps {
  selectedEquipmentId: ObservableMap<number, number>
  handleChangeEquipList: (
    e: GxCheckboxCustomEvent<HTMLGxCheckboxElement>,
  ) => void
}

interface IProps {
  equipments: IEquipmentsHoCanBeTied[]
  handleSubmit: FormEventHandler<HTMLFormElement>
  equipmentItemProps: IEquipItemProps
  footerProps: IFooterUnbindModal
  scrollWrapProps: IScrollWrapProps
  filterPanelProps: IFilterEquipmentPanel
  fetchErrors?: string[]
}
/**
 * @view
 * вью для отрисовки модалки привязки оборудования к шкафу
 * @param equipments -        оборудования которое можно привязать
 * @param handleSubmit        метод для привязки выбранного оборудования
 * @param equipmentItemProps  пропсы для компоненты реализующией отображения 1го оборудования
 * @param footerProps         пропсы для футера модалки
 * @param scrollWrapProps     пропсы для обертки реализующей скролл при ленивой подгрузке
 * @param filterPanelProps    пропсы для панели фильтров
 * @param fetchErrors         массив ошибок после handleSubmit
 */
const EquipmentBindingViews: React.FC<IProps> = ({
  equipments,
  handleSubmit,
  equipmentItemProps,
  filterPanelProps,
  scrollWrapProps,
  footerProps,
  fetchErrors,
}): React.ReactElement => {
  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <Header
        title={{
          id: 'equipment.equipmentBinding',
          defaultMessage: 'Привязка оборудования',
        }}
        handleClose={footerProps.closeModal}
      />
      <div role="dialog">
        <FilterPanel {...filterPanelProps} />
        <ScrollWrap {...scrollWrapProps}>
          {equipments.map(
            ({
              id,
              title,
              equipment_type: equipmentType,
            }) => {
              return (
                <EquipmentItem
                  key={id}
                  id={id}
                  title={title}
                  equipmentType={equipmentType}
                  {...equipmentItemProps}
                />
              )
            },
          )}
        </ScrollWrap>
        <Footer {...footerProps} />
        <ErrorsBlock errorsArray={fetchErrors} />
      </div>
    </form>
  )
}

export default observer(EquipmentBindingViews)
