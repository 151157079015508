import React from 'react'

import { observer } from 'mobx-react'
import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'

import Checkbox from '@/components/Checkbox'

import style from './style/index.module.scss'
import { ObservableMap } from 'mobx'

interface IProps {
  id: number
  title: string
  equipmentType: string
  selectedEquipmentId: ObservableMap<number, number>
  handleChangeEquipList: (e: GxCheckboxCustomEvent<HTMLGxCheckboxElement>) => void
}
/**
 * @view
 * вью для отоброжения "полоски" с данными о непривязанном обородувании
 * @param id                    id текущего оборудования
 * @param equipmentType         тип текущего оборудования
 * @param selectedEquipmentId   Map выбранного оборудования
 * @param title                 название текущего оборудования
 * @param handleChangeEquipList метод для добавления/удаления оборудования из Map
 */
const EquipmentItem: React.FC<IProps> = ({
  id,
  equipmentType,
  selectedEquipmentId,
  title,
  handleChangeEquipList
}) => {
  const isChecked = Boolean(selectedEquipmentId.get(id))
  return (
    <li>
      <Checkbox
        checked={isChecked}
        className={style.item}
        onGx-change={handleChangeEquipList}
        data-id={id}
      >
        <span className={style.item__label}>{title}</span>
        <span className={style.item__type}>
          {equipmentType}
        </span>
      </Checkbox>
    </li>
  )
}

export default observer(EquipmentItem)
