import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useTranslate } from '@/hooks'
import { isUndefined } from '@/utils'
import { IEquipmentEdit } from '@/api/interfaces'
import { EMPTY_CONSTS, KEYBOARD_EVENTS } from '@/const'
import { EQUIPMENT_EDIT_FIELDS } from '@/apps/Modals/const'
import MarksView from '@/apps/Modals/views/EquipmentEditView/Marks'

/**
 * Компонент создания меток для оборудования
 * @component
 *
 */
const Marks: React.FC = () => {
  const { translate } = useTranslate()
  const {
    setFieldValue,
    setFieldError,
    values,
    errors,
    handleChange
  } = useFormikContext<IEquipmentEdit>()
  const error = translate(errors.label ?? EMPTY_CONSTS.STR)
  const value = values.label ?? EMPTY_CONSTS.STR

  /**
   * Обработчик на Enter для добавление метки в пул
   * @param event
   */
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLGxInputElement>
  ): void => {
    if (
      event.key === KEYBOARD_EVENTS.ENTER &&
      error === EMPTY_CONSTS.STR
    ) {
      handleAccept()
    }
  }
  /**
   * Метод на нажатие кнопки добавить / показать инпут
   */
  const handleAddBtn = (): void => {
    handleAccept()
  }

  /**
   * Метод для добавление меток в пул
   */
  const handleAccept = (): void => {
    const newValue = value.trim()
    if (
      newValue !== EMPTY_CONSTS.STR &&
      error === EMPTY_CONSTS.STR
    ) {
      void setFieldValue(
        EQUIPMENT_EDIT_FIELDS.LABEL_NUMBER,
        [
          ...values.label_number.filter(
            (el) => el !== newValue
          ),
          newValue
        ]
      )
      void setFieldValue(
        EQUIPMENT_EDIT_FIELDS.LABEL,
        EMPTY_CONSTS.STR
      )
    }
  }

  /**
   * Метод для удаление меток на крестик елемента и удаления из пула
   * @param id - id метки
   */
  const handleRemoveElement = (label: string): void => {
    void setFieldValue(
      EQUIPMENT_EDIT_FIELDS.LABEL_NUMBER,
      values.label_number.filter((el) => el !== label)
    )
  }

  const handlers = {
    handleRemoveElement,
    handleAddBtn,
    handleChange,
    handleKeyPress
  }

  const params = {
    value,
    marks: values.label_number,
    handlers,
    error
  }

  useEffect(() => {
    if (!isUndefined(errors.label_number)) {
      const error = Array.isArray(errors.label_number)
        ? errors.label_number[0]
        : errors.label_number
      setFieldError(
        EQUIPMENT_EDIT_FIELDS.LABEL,
        translate(error)
      )
    }
  }, [errors.label_number])

  return <MarksView {...params} />
}

export default Marks
