import React from 'react'
import { languages, defaultLanguage } from '@/i18n'
import { format, parse } from 'date-fns'
import isEqual from 'react-fast-compare'
import {
  DATE_MASKS,
  DEFAULT_ERROR_ARRAY,
  DEFAULT_PAGE_SIZE,
  FILTER_PARAMS_KEYS_ARRAY,
  MIN_LENGTH,
  MOCKS_DATA,
  NETWORK_ERROR,
  DAY_MILLISECONDS,
  EMPTY_CONSTS,
  HTTP_RESPONSE_TYPES,
} from '@/const'

import {
  TStorageGetValue,
  TStorageCreateValue,
  TImportantStorageFields,
  IQueryParams,
} from '@/api/interfaces'
import { TGetErrorsArrayFromObj } from '@/interfaces/Common'
import { T_HTTP_RESPONSE_TYPES_VALUE } from '@/interfaces/consts'
import { TMessage } from '@/hooks/useTranslate'

export const getLocaleByPathname = (
  pathname: string,
): string => {
  const language = pathname.split('/')[1]
  return languages.includes(language)
    ? language
    : defaultLanguage
}

export const isNull = (value: unknown): value is null => {
  return value === null
}

export const isUndefined = (
  value: unknown,
): value is undefined => {
  return value === undefined
}

export const debounce = (
  fn: (...params: any[]) => any,
  n: number,
  immed = false,
): any => {
  let timer: NodeJS.Timer
  return function (this: any, ...args: any[]) {
    if (timer === undefined && immed) {
      fn.apply(this, args)
    }
    if (timer !== undefined) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => fn.apply(this, args), n)
    return timer
  }
}

export const prevDef = (e: React.SyntheticEvent): void => {
  e.preventDefault()
}

export const isString = (value): boolean =>
  typeof value === 'string' || value instanceof String

/**
 *
 */
export function formatDateForBackend(date: string): string {
  return format(
    parse(date, 'dd/MM/yyyy', new Date()),
    'yyyy-MM-dd',
  )
}

/**
 * метод для скачивания файла по ссылке
 * @function downloadFile
 * @param url - ссылка для скачивания
 */
export const downloadFile = (url: string): void => {
  const linkUrl = document.createElement('a')

  linkUrl.download = url.substring(
    url.lastIndexOf('/') + 1,
    url.length,
  )
  linkUrl.href = url
  document.body.appendChild(linkUrl)
  linkUrl.setAttribute('target', '_blank')
  linkUrl.click()
  document.body.removeChild(linkUrl)
}

/**
 * * метод для форматирования даты
 * @function formatDateForFrontend
 * @param date
 *
 */

/**
 *
 */
export function formatDateForFrontend(
  date: string | Date,
  formatMask: string = DATE_MASKS.DEFAULT,
): string {
  try {
    const unformattedDate =
      typeof date === 'string' ? new Date(date) : date
    return format(unformattedDate, formatMask)
  } catch {
    return EMPTY_CONSTS.STR
  }
}

/**
 * ПРосто сериалайзер для обработки контекстов
 * @param data  - context
 * @param page  - ключ для объекта с расширениями контекста
 * @returns
 */
export const mockContext = (data, page): any => {
  const context = data
  context.mock = MOCKS_DATA[page]
  return context
}

type IEventScroll = React.UIEvent<HTMLDivElement> & {
  target: {
    scrollTop: number
    clientHeight: number
    scrollHeight: number
  }
}
/**
 *
 * @param event  - native scroll event
 */
export const isScrollEnd = (
  event: IEventScroll,
): boolean => {
  const container = event.target
  const offsetTop = Number(
    (
      container.scrollTop + container.clientHeight
    ).toFixed(),
  )
  if (container.scrollHeight - offsetTop < 50) {
    // Доскролили до конца блока
    return true
  }
  return false
}

/**
 * Создание объекта с ошибками из запроса для вывода в форме
 */
export const getObjectErrorsFormik = (e: {
  response: {
    data: ArrayLike<unknown> | { [s: string]: unknown }
  }
}): Record<string, string> => {
  return Object.entries(e.response.data).reduce(
    (accumulator, [key, values]) => ({
      ...accumulator,
      [key]: Array.isArray(values)
        ? values.join('. ')
        : values,
    }),
    {},
  )
}
/**
 * утилита для преоразования обьекта ошибки в строку
 * @param e ошибка с сервера
 * @returns
 */
export const convertErrorToString = (e: {
  response: {
    data: ArrayLike<unknown> | { [s: string]: unknown }
  }
}): TMessage => {
  if (e.response) {
    if (Array.isArray(e.response.data)) {
      return e.response.data.join(', ')
    } else {
      return Object.values(e.response.data).join(', ')
    }
  } else {
    return {
      id: 'didntWork',
      defaultMessage: 'Не получилось',
    }
  }
}

/**
 * Функция проверяющая является ли элемент null | undefined
 * @param targetElement Сравниваемый элемент
 * @returns Если да то true | если нет  false
 */
export const isNullOrUndefined = (
  value: unknown,
): boolean => {
  return value === null || value === undefined
}

/**
 * Функция проверяющая есть ли такой же элемент в массиве
 * @param targetElement Сравниваемый элемент
 * @param comparisonElements Массив элементов с котрыми надо сравнивать
 * @returns Если есть то true | если нет  false
 */
export const isEqualWith = (
  targetElement: unknown,
  comparisonElements: unknown[],
): boolean => {
  for (const comraredElement of comparisonElements) {
    if (isEqual(targetElement, comraredElement)) {
      return true
    }
  }
  return false
}

/**
 * Функция превращяющяя любой объект в квери строку
 * @param obj - любой объект
 * @returns string
 * @example - `&params=values?params2=values2`
 */
export const objToQs = (
  obj: { [s: string]: unknown } | ArrayLike<unknown>,
): string => {
  const queryParams = Object.entries(obj)
    .map(
      ([key, value]: any) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          value,
        )}`,
    )
    .join('&')

  return `?${queryParams}`
}

/**
 * @func getFormatedStorageValue
 * * метод для получения полей объекта шкафа
 * * которые при изменении вызывают переподкл шкафа
 * @returns
 */

const getFormatedStorageValue = (
  obj: TStorageGetValue | TStorageCreateValue,
): TImportantStorageFields => {
  return {
    serial_number: obj.serial_number,
    storage_url: obj.storage_url,
    title: obj.title,
  }
}

/**
 * @func checkChangedStorageFields
 * * метод для проверки изменения начальных и измененых
 * * полей шкафа которые вызывают его переподключение
 */

export const checkChangedStorageFields = (
  defaultValue: TStorageGetValue,
  changedValue: TStorageCreateValue,
): boolean => {
  const formatedDefaultValue =
    getFormatedStorageValue(defaultValue)
  const formatedChangedValue =
    getFormatedStorageValue(changedValue)

  return isEqual(formatedDefaultValue, formatedChangedValue)
}

/**
 * Функция проверяющая есть ли активный параметр в фильтре
 * @func checkHasFilter
 *
 * @predicat filter    обьект фильтра с параметрами
 * @returns
 */
export const checkHasFilter = (
  filter: IQueryParams,
): boolean => {
  let isHasFilterParams = false
  for (const param of FILTER_PARAMS_KEYS_ARRAY) {
    if (filter[param] !== undefined) {
      isHasFilterParams = true
      break
    }
  }
  return isHasFilterParams
}

/**
 * Составит Инициалы из имя-отчество
 * @returns str
 */
export const getInitials = (
  name?: string | null,
  surname?: string | null,
): string => {
  const nameInitial =
    name != null && name !== undefined
      ? name.substring(0, 1).toUpperCase()
      : ''
  const surnameInitial =
    surname != null && surname !== undefined
      ? surname.substring(0, 1).toUpperCase()
      : ''
  return `${surnameInitial}${nameInitial}`
}

export const getFullName = (
  name?: string | null,
  surname?: string | null,
): string => {
  const nameInitial =
    name != null && name !== undefined ? name : ''
  const surnameInitial =
    surname != null && surname !== undefined ? surname : ''
  return `${surnameInitial}${nameInitial}`
}

/**
 * * функция обработки ошибки (object => [ 'error' ])
 * @func getErrorsArrayFromObj
 *
 * @param err             обьект ошибки
 */
export const getErrorsArrayFromObj: TGetErrorsArrayFromObj =
  (intl, err) => {
    const errors = err?.response?.data
    if (err?.message === NETWORK_ERROR) {
      return [
        intl.formatMessage({
          id: 'common.network_error',
          defaultMessage:
            'Ошибка сети. Проверьте своё подключение к интернету.',
        }),
      ]
    } else if (typeof errors === 'object') {
      const error: string[] = Object.values(errors).map(
        (el) => String(el),
      )
      if (error?.length > 0) {
        return error
      }
    } else {
      return DEFAULT_ERROR_ARRAY
    }
  }

/**
 * Функция, проверяющая есть ли ошибки в формате ['error', 'error']
 * @func checkHasError
 *
 * @param error             ошибка
 * @returns boolean
 */
export const checkHasError = (
  error: string[] | null | undefined,
): boolean => {
  let errorsArr: string[] = EMPTY_CONSTS.ARR
  if (Array.isArray(error)) {
    errorsArr = error.filter(
      (err) =>
        !isNull(err) &&
        !isUndefined(err) &&
        err !== EMPTY_CONSTS.STR,
    )
  }

  return errorsArr.length >= MIN_LENGTH.ERROR
}

/**
 * Функция, вычисляющая номер последней страницы
 * @func getLastPageNumber
 *
 * @param count           всего элементов
 * @param pageSize        размер страницы
 *
 * @return number
 */
export const getLastPageNumber = (
  count: number | undefined = DEFAULT_PAGE_SIZE,
  pageSize: number | undefined = DEFAULT_PAGE_SIZE,
): number => {
  return Math.ceil(count / pageSize)
}

/**
 * Функция, считающая сумму всех полей в обьекте
 * @func sumObjKeys
 *
 * @param obj  произвольный обьект
 */
type TSumObjKeys = (obj: Record<string, any>) => number

export const sumObjKeys: TSumObjKeys = (obj) => {
  let sum = 0
  for (const value of Object.values(obj)) {
    sum = sum + Number(value)
  }
  return sum
}
/**
 * * функция для получения измененных полей
 * @func getChangedFields
 * @param originalObj - изначальный обьект
 * @param changedObj  - обьект с измененными полями
 */
export const getChangedFields = (
  originalObj: object,
  changedObj: object,
): object => {
  const changedFields: object = {}

  for (const key in changedObj) {
    if (originalObj[key] !== changedObj[key]) {
      changedFields[key] = changedObj[key]
    }
  }

  return changedFields
}

/**
 * от фильтров приходят значения {label, value}
 * a handleParams принимает от каждого филтьтра только value
 * @param newParams
 */
export const serializeFilterParams = (
  newParams: any,
): object => {
  const serialized = Object.entries(newParams).reduce(
    (all, [key, val]: any) => {
      if (typeof val === 'object') {
        return {
          ...all,
          [key]: val?.value,
        }
      }
      return {
        ...all,
        [key]: val,
      }
    },
    {},
  )

  return serialized
}

export const getDifferenceDay = (
  date: string | Date,
): number => {
  return Math.round(
    (new Date(date).getTime() - new Date().getTime()) /
      DAY_MILLISECONDS,
  )
}

/**
 * Получение разницы между датой текущей и датой из параметров в секундах
 * @param date - дата
 */
export const subDateNow = (date: string | Date): number => {
  const timestamp =
    new Date().getTime() +
    new Date().getTimezoneOffset() * 60000
  const oldTimestamp = new Date(date).getTime()
  return Math.floor(timestamp - oldTimestamp) / 1000
}

/**
 * Перевод секунд в значения для intl
 * @param difference
 */
export const serializeDifferenceSecond = (
  difference: number,
): {
  value: number
  unit: 'second' | 'minute' | 'hour' | 'day'
} => {
  if (difference < 60) {
    return {
      value: Math.floor(difference),
      unit: 'second',
    }
  } else if (difference < 3600) {
    return {
      value: Math.floor(difference / 60),
      unit: 'minute',
    }
  } else if (difference < 86400) {
    return {
      value: Math.floor(difference / 3600),
      unit: 'hour',
    }
  } else {
    return {
      value: Math.floor(difference / 86400),
      unit: 'day',
    }
  }
}

export const serializeStorageOptions = (
  options: Array<{ value: string; backend: string }>,
): Array<{
  id: string
  title: string
}> => {
  return options.map((option) => ({
    id: option.backend,
    title: option.value,
  }))
}

/**
 * просто собирает ссылку
 * @param param0
 * @returns
 */
export const collectApiUrl = ({
  protocol = 'http',
  domain = 'unknown',
  path = '/',
}: {
  [key: string]: string
}): string => {
  return `${protocol}://${domain}/${path}`
}

/**
 * сборщик домена
 * @param isLocal - var from env
 * @param domain  - string from env
 * @param hostname - reqired hostname
 * @returns
 */
export const collectDomain = (
  isLocal = 'false',
  domain: string,
  hostname?: string | undefined,
): string => {
  // debuggers
  console.log('domain', domain)
  const DEFAULT_HOST = global?.location?.hostname
  if (isLocal === 'true') {
    if (!isUndefined(hostname)) {
      return hostname
    }
    return DEFAULT_HOST
  }
  return domain
}
/**
 * * метод для создания рандомного id
 * @function randomId
 * @param length длинна id
 */
export const randomId = (length = 10): string => {
  return Math.random().toString(36).substring(2, length)
}

export const generateRandomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`

/**
 * * метод получения тип результата http запроса
 * @function getHttpRequestStatusType
 * @param statusCode код статуса http запроса
 * @returns 1 | 2 | 3 | 4 | 5 | 6 | -1
 */
export const getHttpRequestStatusType = (
  statusCode: number,
): T_HTTP_RESPONSE_TYPES_VALUE => {
  const statusCategories = {
    1: {
      start: 100,
      end: 200,
      typeRequest: HTTP_RESPONSE_TYPES.INFORMATIONAL,
    },
    2: {
      start: 200,
      end: 300,
      typeRequest: HTTP_RESPONSE_TYPES.SUCCESS,
    },
    3: {
      start: 300,
      end: 400,
      typeRequest: HTTP_RESPONSE_TYPES.REDIRECT,
    },
    4: {
      start: 400,
      end: 500,
      typeRequest: HTTP_RESPONSE_TYPES.CLIENT_ERROR,
    },
    5: {
      start: 500,
      end: 600,
      typeRequest: HTTP_RESPONSE_TYPES.SERVER_ERROR,
    },
  } as const
  let result = HTTP_RESPONSE_TYPES.UNCORRECT // По умолчанию, если не найдено соответствие
  for (const category in statusCategories) {
    const { start, end, typeRequest } =
      statusCategories[category]
    if (statusCode >= start && statusCode < end) {
      result = typeRequest
      break
    }
  }

  return result
}
