import React, { RefObject } from 'react'
import { TUser } from '@/api/interfaces'
import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'
import { getInitials } from '@/utils'
import Checkbox from '@/components/Checkbox'
import Badge from '@/components/Badge'
import { Typography } from '@/views/typography'

import styles from './styles/index.module.scss'

interface IUserListProps {
  listRef: RefObject<HTMLUListElement>
  usersPull: Map<number, number>
  handleScroll: () => void
  data: TUser[]
  handleCheck: (
    e:
    | React.ChangeEvent<HTMLGxCheckboxElement>
    | GxCheckboxCustomEvent<HTMLGxCheckboxElement>
  ) => void
}

/**
 * Вьюха Списка пользователей
 * @param listRef - ссылка на елемент списка
 * @param usersPull - список выбранных пользователей
 * @param handleScroll - обработчик на скролл
 * @param data - список пользователей
 * @param handleCheck - метод на обработку чекбокса
 */

const UserList: React.FC<IUserListProps> = ({ data, usersPull, listRef, handleScroll, handleCheck }) => {
  return (
    <ul
      className={styles.users__list}
      ref={listRef}
      onScroll={handleScroll}
    >
      {
        data.map((user) => {
          const checked = usersPull.has(user.id)
          const initials = getInitials(
            user.first_name,
            user.last_name
          )
          return (
            <li key={user.id}>
              <Checkbox
                checked={checked}
                data-user-id={user.id}
                onGx-change={handleCheck}
                className={styles.users__item}
              >
                <Badge title={initials} />
                <Typography className={styles.users__lable} size={14} weight={400}>
                  {user.full_name}
                </Typography>
              </Checkbox>
            </li>
          )
        })
      }
    </ul>
  )
}

export default UserList
