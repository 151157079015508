import React, { FC, useRef, JSX } from 'react'

import * as UserStorageViews from '@/apps/RightSidebar/views/UserStorageTableView/UserStorageViews'
import UserStorageTableView from '../../views/UserStorageTableView'

import { useStores, useLazyFetch } from '@/hooks'

import {
  DEFAULT_ERROR_ARRAY,
  EMPTY_CONSTS,
  MODAL_TYPES,
  TABLE_WRAPPER_ICON_SRC,
  TABLE_WRAPPER_TYPE,
} from '@/const'
import { STORAGE_TABLE_KEYS } from '../../const'

import { TGroupStorage } from '@/api/interfaces/GroupUsers'
import { IDetailsUserTable } from '../../interfaces'

import styles from './styles/index.module.scss'

/**
 * @component UserStorageTable
 * * компонент таблицы шкафов в деталке пользователя
 *
 * @param id              id пользователя
 */
const UserStorageTable: FC<IDetailsUserTable> = ({
  id,
}) => {
  const { api, modalStore } = useStores()
  const containerRef = useRef<HTMLDivElement>(
    EMPTY_CONSTS.NULL,
  )
  const {
    handleFetch,
    params,
    isLoading,
    error = DEFAULT_ERROR_ARRAY,
    lazyLoadedData,
    tableScroll,
    refetch,
    isScrollEnd,
  } = useLazyFetch(
    containerRef,
    async () =>
      await api.user.getStoragesTiedToUser(id, params),
  )
  const { search } = params

  const renderIdCell = (
    storageId: number,
    storageData: TGroupStorage,
  ): JSX.Element => {
    const handleUnbindUserInStorage = () => {
      return api.storage.unbindUserStorage(storageId, {
        user_id: id,
      })
    }
    return UserStorageViews.UnlinkStorage({
      handleUnbindMethod: handleUnbindUserInStorage,
      refetch,
      storageData,
    })
  }

  const columns = [
    {
      key: STORAGE_TABLE_KEYS.COUNT_CELLS,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: UserStorageViews.CountCells,
        className: styles.table__body_count,
      },
    },
    {
      key: STORAGE_TABLE_KEYS.TITLE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: UserStorageViews.Title,
        className: styles.table__body_title,
      },
    },
    {
      key: STORAGE_TABLE_KEYS.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: renderIdCell,
        className: styles.table__body_unlink,
      },
    },
  ]

  /**
   * * функция открытия модалки привязывания
   * шкафов к пользователю
   * @function handleOpenBindUserStorages
   * @returns
   */
  const handleOpenBindUserStorages = (): void => {
    modalStore.open(MODAL_TYPES.USER_STORAGES_BIND, {
      id,
      refetch,
    })
  }

  const searchPanelProps = {
    handleParams: handleFetch,
    handleOpen: handleOpenBindUserStorages,
    dataLength: lazyLoadedData?.length,
    search,
  }

  const tableWrapProps = {
    search,
    error,
    length: lazyLoadedData?.length,
    handleClick: handleOpenBindUserStorages,
    iconSrc: TABLE_WRAPPER_ICON_SRC.STORAGE,
    type: TABLE_WRAPPER_TYPE.STORAGE_USER_TAB,
  }

  return (
    <UserStorageTableView
      isScrollEnd={isScrollEnd}
      searchPanelProps={searchPanelProps}
      tableWrapProps={tableWrapProps}
      tableScroll={tableScroll}
      containerRef={containerRef}
      lazyLoadedData={lazyLoadedData}
      columns={columns}
      isLoading={isLoading}
    />
  )
}

export default UserStorageTable
