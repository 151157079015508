import { useState, useEffect } from 'react'

/**
 * @hook useDebounce
 * * хук дебаунс (для отложенной записи значения)
 *
 * @param value        значение
 * @param delay        значение задержки присвоения записи (мс)
 */
export default function useDebounce (value: string, delay = 500): string {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
