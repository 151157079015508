import React from 'react'
import { HexColorPicker } from 'react-colorful'

import { Dropdown } from '@/components/Dropdown'
import { Button } from '@/views/button'

import styles from './styles/index.module.scss'

interface IProps {
  color?: string
  setColor?: ((newColor: string) => void)
}

const ColorPicker: React.FC<IProps> = ({ color, setColor }) => {
  return (
    <Dropdown full>
      <Button 
        className={styles['color-picker__btn']} 
        color='transparent' 
        slot='trigger'
      >
        <div
          className={styles['color-picker__circle']} 
          style={{
            backgroundColor: color,
          }}
        />
        <span>{color}</span>
      </Button>
      <div className={styles['color-picker']}>
        <HexColorPicker
          color={color}
          onChange={setColor}
        />
      </div>
    </Dropdown>
  )
}

export default ColorPicker
