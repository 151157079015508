import React from 'react'

import styles from './style/index.module.scss'

interface IProps {
  children: React.ReactNode
}

const CenterLayout: React.FC<IProps> = ({ children }) => (
  <section className={styles.center}>
    <div className={styles.center__wrap}>
      {children}
    </div>
  </section>
)

export default CenterLayout
