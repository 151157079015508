import React from 'react'

import { useIntl } from 'react-intl'

import Table from '@/apps/Table/component/Table'
import { RenderSortButton } from '@/components/SortButton/RenderSortButton'
import Pagination from '@/components/Pagination'
import TableWrapper from '@/views/TableWrapper'
import CopyButton from './CopyButton'

import { TABLE_PAGE, TABLE_WRAPPER_ICON_SRC } from '@/const'

import { TAGS_HISTORY_KEYS } from '../../const'

import { tagsTableStyles } from './style/tableStyles'
import styles from './style/index.module.scss'
import { IHistoryScan } from '@/api/interfaces/Storage'
import { IQueryParams } from '@/api/interfaces'

interface IProps {
  tagsList?: IHistoryScan[]
  isLoading: boolean
  error?: string[] | null
  handlePage: (page: number) => void
  handleParams: (param: any) => void
  params: IQueryParams
  total?: number
}
/**
 * * вьюха таблицы истории меток
 * @comp TagsHistoryViews
 */
const TagsHistoryViews: React.FC<IProps> = ({
  tagsList,
  params,
  isLoading,
  error,
  handlePage,
  handleParams,
  total,
}) => {
  const intl = useIntl()

  const column = [
    {
      key: TAGS_HISTORY_KEYS.CARD_NUMBER,
      contentHeader: intl.formatMessage({
        id: 'tag',
        defaultMessage: 'Метка',
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            params.ordering,
            handleParams,
            TAGS_HISTORY_KEYS.CARD_NUMBER,
          ),
      },
      bodyCellProps: {
        render: (text: string) => (
          <div className={styles['card-number']}>
            {text}
          </div>
        ),
        className: styles.table__body_tag,
      },
    },
    {
      key: TAGS_HISTORY_KEYS.CREATE_AT,
      contentHeader: intl.formatMessage({
        id: 'date',
        defaultMessage: 'Дата',
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            params.ordering,
            handleParams,
            TAGS_HISTORY_KEYS.CREATE_AT,
          ),
      },
      bodyCellProps: {
        className: styles.table__body_date,
      },
    },
    {
      key: TAGS_HISTORY_KEYS.SCAN_TIME,
      contentHeader: intl.formatMessage({
        id: 'time',
        defaultMessage: 'Время',
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            params.ordering,
            handleParams,
            TAGS_HISTORY_KEYS.CREATE_AT,
          ),
      },
    },
    {
      key: TAGS_HISTORY_KEYS.ID,
      contentHeader: '',
      bodyCellProps: {
        render: (_, tag) => (
          <CopyButton text={tag.card_number} />
        ),
        className: styles.table__body_copy,
      },
    },
  ]

  return (
    <TableWrapper
      iconSrc={TABLE_WRAPPER_ICON_SRC.EQUIPMENT}
      search={params?.search}
      isLoading={isLoading}
      type={TABLE_PAGE.EQUIPMENT}
      error={error}
      length={tagsList?.length}
    >
      <Table
        columns={column}
        rowsData={tagsList}
        styles={tagsTableStyles}
      />
      <Pagination
        currentPage={params?.page}
        handleChange={handlePage}
        total={total}
        pageSize={params.page_size}
      />
    </TableWrapper>
  )
}

export default TagsHistoryViews
