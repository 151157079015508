import React, { memo } from 'react'
import MenuComponents from './components'
import styles from './styles/index.module.scss'

const MainMenu: React.FC = () => {
  return (
    <div className={styles.container}>
      <MenuComponents.Logo />
      <MenuComponents.Menu />
      <MenuComponents.User />
    </div>
  )
}

export default memo(MainMenu)
