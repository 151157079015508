import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import Icon from '../Icon'
import { EMPTY_CONSTS, EQUIPMENT_ACCESSIBILITY_STATUS, EQUIPMENTS_STATUS } from '@/const'

import style from './styles/index.module.scss'

interface IEquipmentStatusIconProps {
  type: string
  className?: string
}

/**
 * @component EquipmentStatusIcon
 * * компонент иконок статуса оборудования
 *
 * @param type         тип иконки (и соответственно текст внутри иконки)
 * @param className    дополнительные стили
 */

const EquipmentStatusIcon: React.FC<
IEquipmentStatusIconProps
> = ({ type, className = EMPTY_CONSTS.STR }) => {
  const intl = useIntl()
  const status = type === EMPTY_CONSTS.STR ? EQUIPMENTS_STATUS.NEW : type

  const isEquipmentType =
    status === EQUIPMENT_ACCESSIBILITY_STATUS.EQUIPMENT_ON_HANDS ||
    status === EQUIPMENT_ACCESSIBILITY_STATUS.EQUIPMENT_ABSENT

  return (
    <div
      className={classNames({
        [style.icon]: true,
        [style[`icon--${status}`]]: Boolean(status),
        [className]: Boolean(className)
      })}
    >
      {isEquipmentType && (
        <Icon
          src='equipment'
          size='small'
          className={style[`icon__${status}`]}
        />
      )}
      <p className={style.icon__text}>
        {intl.formatMessage({
          id: `icon.equipment_status.${status}`,
          defaultMessage: 'Статус оборудования'
        })}
      </p>
    </div>
  )
}

export default EquipmentStatusIcon
