import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

import { useStores, useFetch } from '@/hooks'

import { IStorageCell } from '@/api/interfaces'
import { ITabCompProps } from '../../interfaces'

import { isUndefined } from '@/utils'

import CellsView from '../../views/CellsView'
import { ISocketUpdateCellData } from '@/interfaces/Socket'
/**
 * @component
 * * компонент реализовывающий логику отрисовки и сортировки ячеек
 */

const StorageCells: React.FC<ITabCompProps> = ({ storageId }) => {
  const { api, StorageEventsObserver, EquipmentEventsObserver } = useStores()
  const {
    data,
    isLoading,
    handleParams,
    params,
    refetch,
    handlePage,
    setData: setCellList,
    error
  } = useFetch(async (param) => await api.storage.getStorageCells(+storageId, param))
   /**
   * * метод для мутации списка ячеек (сокеты)
   * * чтоб не было лоадера
   * @function updateCellList
   * @param cellId 
   */
  const mutateCellList = (changedCellEquip: IStorageCell) => {
    setCellList((prevState) => {
      if (isUndefined(prevState)) return undefined

      const newCellList = prevState.results.map((el) => {
        if (changedCellEquip.id === el.id) {
          return changedCellEquip
        } else {
          return el
        }
      })

      return {
        ...prevState,
        results: newCellList
      }
    })
  }
  /**
   * * метод для апдейта списка ячеек (сокеты)
   * @function updateCellList
   * @param cellId 
   */
  const updateCellList = ({ cell_id: cellId }: ISocketUpdateCellData) => {
    api.storage.getStorageCells(storageId).then((response) => {
      const changedCellEquip = response?.results.find((el) => el.id === cellId)
      if (!isUndefined(changedCellEquip)) {
        mutateCellList(changedCellEquip)
      }
    })
  }

  useEffect(() => {
    StorageEventsObserver.subscribe(refetch)
    EquipmentEventsObserver.setCellCallBack(updateCellList)
    return () => {
      StorageEventsObserver.unsubscribe(refetch)
      EquipmentEventsObserver.setCellCallBack(undefined)
    }
  }, [])

  return (
    <CellsView
      params={params}
      isLoading={isLoading}
      error={error}
      handleParams={handleParams}
      handlePage={handlePage}
      refetch={refetch}
      cells={data?.results}
      total={data?.count}
      currentPage={params.page}
    />
  )
}

export default observer(StorageCells)
