import React from 'react'
import { EQUIPMENT_STATUS } from '@/const'
import Icon from '@/components/Icon'

import styles from './styles/index.module.scss'

export interface IEquipmentStatus {
  status: (typeof EQUIPMENT_STATUS)[keyof typeof EQUIPMENT_STATUS]
}

/**
 * Вюха для иконки статуса оборудования
 * @param status
 * @constructor
 */
const EquipmentStatus: React.FC<IEquipmentStatus> = ({
  status,
}) => {
  return (
    <div className={styles.equipment} data-status={status}>
      <Icon src="equipment" size="small" />
    </div>
  )
}

export default EquipmentStatus
