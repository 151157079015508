import React from 'react'

import { observer } from 'mobx-react'
import { useStores } from '@/hooks'

import {
  IHandlersFooter,
  TStoragePanels,
} from '../../interfaces'
import { STORAGE_SIDEBAR_COUNTERS } from '../../const'

import FooterButtons from './FooterButtons'
import Description from './Description'
import Title from './Title'
import Counter from './Counter'
import { Button } from '@/views/button'
import Icon from '@/components/Icon'
import StorageLayout from '@/apps/MainSidebar/views/StorageLayoutView'

import style from './style/index.module.scss'

interface IProps {
  showUnlockBtn: boolean
  handleShowSettings: () => void
  showingPanel: TStoragePanels
  handlersFooter: IHandlersFooter
  handleShutDownStorage?: () => void // ! TODO - след релиз добавить кнопку выкл
}

/**
 * @view
 * * вью для реализации интерфейса шкафа
 * @param handlersFooter       методы для нижних кнопок шкафа
 * @param handleShowSettings открыть меню настроек
 * @func handleShutDownStorage метод для принудительного выкл шкафа
 */
const StorageDetailsView: React.FC<IProps> = ({
  handlersFooter,
  showUnlockBtn,
  handleShowSettings,
  showingPanel,
}): React.ReactElement => {
  const {
    currentStorageStore: { storage },
  } = useStores()

  return (
    <StorageLayout
      data-unlock={showUnlockBtn}
      showingPanel={showingPanel}
      theme="edit"
    >
      <div className={style['storage-detail__top']}>
        <div className={style['storage-detail__head']}>
          <Title />
          <Button
            color="gray"
            variant="text"
            onClick={handleShowSettings}
          >
            <Icon src="settings" />
          </Button>
        </div>
        <Description />
        {storage !== null && (
          <div>
            {STORAGE_SIDEBAR_COUNTERS.map(
              ({
                id,
                title,
                iconName,
                count,
                ...props
              }) => (
                <Counter
                  {...props}
                  key={id}
                  title={title}
                  iconName={iconName}
                  count={storage[count] ?? '-'}
                />
              ),
            )}
          </div>
        )}
      </div>
      <FooterButtons
        status={storage?.storage_status}
        handlersFooter={handlersFooter}
      />
    </StorageLayout>
  )
}

export default observer(StorageDetailsView)
