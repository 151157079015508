import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '@/hooks'

import { isNull, isUndefined } from '@/utils'
import { EMPTY_CONSTS, THEME_MODAL } from '@/const'
import { RIGHT_SIDEBAR_TYPES } from './const'

import { NoSSR } from '@/components/NoSSR'
import BaseModal from '../Modals/Base'

import {
  UserSideBar,
  EquipmentSidebar,
  UserGroupSidebar,
} from './components'

const content = {
  [RIGHT_SIDEBAR_TYPES.EMPTY]: () => EMPTY_CONSTS.NULL,
  [RIGHT_SIDEBAR_TYPES.USER_SIDEBAR]: UserSideBar,
  [RIGHT_SIDEBAR_TYPES.EQUIPMENT_SIDEBAR]: EquipmentSidebar,
  [RIGHT_SIDEBAR_TYPES.USER_GROUP_SIDEBAR]:
    UserGroupSidebar,
}
/**
 * @component
 * * Компонент правого сайдбара, реализующий обертку для
 * * вашего контента
 * * Аналогичен Modal
 * @see Modal
 */
const RightSidebar: React.FC = observer(() => {
  const { rightSideBarStore } = useStores()

  const afterHideSidebar = (): void =>
    rightSideBarStore.close()

  const closeSidebar = (): void => rightSideBarStore.close()

  const CurrentContent =
    content[
      rightSideBarStore.content ?? RIGHT_SIDEBAR_TYPES.EMPTY
    ]

  if (
    isNull(CurrentContent) ||
    isUndefined(CurrentContent)
  ) {
    return EMPTY_CONSTS.NULL
  }

  return (
    <NoSSR>
      <BaseModal
        handleClose={closeSidebar}
        open={rightSideBarStore.isOpen}
        theme={THEME_MODAL.RIGHT_SIDEBAR}
        onGx-after-hide={afterHideSidebar}
        id="rightSidebar"
      >
        <CurrentContent
          handleClose={closeSidebar}
          contentProps={rightSideBarStore.contentProps}
        />
      </BaseModal>
    </NoSSR>
  )
})

export default RightSidebar
