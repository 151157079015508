import React from 'react'

import cn from 'classnames'

import { Spinner } from '@/views/spinner'

import style from './style/index.module.scss'

/**
 * @view
 * * вью заглушка на загрузку двнных о шкафе
 */
export const Loading = (): React.ReactElement => {
  return (
    <Spinner
      className={cn(
        style.status__icon,
        style.status__icon_sync,
      )}
      size="medium"
    />
  )
}
