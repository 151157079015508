import React from 'react'

import { ERROR_PAGE_TYPE } from '@/apps/Errors/const'

import Error from '@/pages/ErrorsPage'

interface IState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<any, IState> {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (): void {
    this.setState({ hasError: true })
  }

  render (): any {
    if (this.state.hasError) {
      return <Error type={ERROR_PAGE_TYPE.CUSTOM_ERROR} />
    }
    return this.props.children
  }
}

export default ErrorBoundary
