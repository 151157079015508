import React, { useEffect, useRef, useState } from 'react'
import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'
import { observer } from 'mobx-react'
import { useFormikContext } from 'formik'
import { IEquipment } from '@/api/interfaces'
import { useLazyFetch, useStores } from '@/hooks'
import { DEFAULT_PAGE_LAZY_SIZE } from '@/const'
import UsersView from '@/apps/Modals/views/EquipmentEditView/Users'
import {
  autorun,
  observable,
  ObservableMap,
  toJS,
} from 'mobx'
import { EQUIPMENT_EDIT_FIELDS } from '@/apps/Modals/const'

/**
 * Компонент списка пользователей с поиском и фильтром
 * @component
 *
 */

const Users: React.FC = observer(() => {
  const { setFieldValue, values } =
    useFormikContext<IEquipment>()
  const listRef = useRef<HTMLUListElement>(null)
  const { api } = useStores()
  const {
    params,
    tableScroll: handleScroll,
    lazyLoadedData: data = [],
    isLoading,
    handleFetch,
    isScrollEnd,
  } = useLazyFetch(listRef, api.user.getUsers, {
    page_size: DEFAULT_PAGE_LAZY_SIZE,
  })

  /**
   * Выбранные пользователи
   */
  const [usersPull] = useState<
    ObservableMap<number, number>
  >(() =>
    observable.map(values.user_ids.map((id) => [id, id])),
  )

  /**
   * Метод для обработки выбронных пользователей
   * @param e
   */
  const handleCheck = (
    e:
      | React.ChangeEvent<HTMLGxCheckboxElement>
      | GxCheckboxCustomEvent<HTMLGxCheckboxElement>,
  ): void => {
    const userId = Number(e.target.dataset.userId)
    if (e.target.checked) {
      usersPull.set(userId, userId)
    } else {
      usersPull.delete(userId)
    }
  }

  /**
   * Чистка выбранных пользователей
   */
  const handleClear = (): void => {
    usersPull.clear()
  }

  useEffect(() => {
    autorun(() => {
      const serializedPull = [...usersPull].map(
        ([key]) => key,
      )
      void setFieldValue(
        EQUIPMENT_EDIT_FIELDS.USER_IDS,
        serializedPull,
      )
    })
  }, [])

  const props = {
    params,
    handleFetch,
    listRef,
    handleScroll,
    usersPull: toJS(usersPull),
    isLoading,
    data,
    handleCheck,
    handleClear,
    isScrollEnd,
  }

  return <UsersView {...props} />
})

export default Users
