import { STORAGE_STATUSES } from '../const'

export const getCurrentStatus = (
  storageStatus: string,
): string => {
  if (storageStatus === STORAGE_STATUSES.SUCCES) {
    return STORAGE_STATUSES.SUCCES
  } else if (
    STORAGE_STATUSES.SYNCHRONIZATION_BACKEND.includes(
      storageStatus,
    )
  ) {
    return STORAGE_STATUSES.SYNCHRONIZATION_FRONTEND
  } else if (STORAGE_STATUSES.LOADING === storageStatus) {
    return STORAGE_STATUSES.LOADING
  } else {
    return STORAGE_STATUSES.ERROR
  }
}
