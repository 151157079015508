import React from 'react'

import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import StorageStatus from '@/apps/StorageList/views/StorageStatus'
import { Button } from '@/views/button'
import { Typography } from '@/views/typography'
import BaseContentViews from '../BaseContentViews'

import { USER_STORAGE_UNBIND_VARIANT } from '../../const'
import {
  DEFAULT_STORAGE_STATUS,
  DEFAULT_USER_ROLE,
  EMPTY_CONSTS,
} from '@/const'

import {
  IStorage,
  IUserGetValue,
  TUserRole,
} from '@/api/interfaces'
import { IUserStorageUnbindVariant } from '../../ModalContent/interfaces'

import style from './style/index.module.scss'

const content = {
  [USER_STORAGE_UNBIND_VARIANT.STORAGE_UNBIND]: ({
    storageData,
  }) => {
    const {
      title = EMPTY_CONSTS.STR,
      storage_status:
        storageStatus = DEFAULT_STORAGE_STATUS,
      count_cells: countCells = EMPTY_CONSTS.ZERO,
    } = storageData
    return (
      <BaseContentViews.BannerContent
        className={style.container__body_storage}
      >
        <StorageStatus
          countCells={countCells}
          storageStatus={storageStatus}
          className={style.container__status}
        />
        <Typography
          weight={700}
          size={18}
          className={style.container__body_storage_text}
        >
          {title}
        </Typography>
      </BaseContentViews.BannerContent>
    )
  },
  [USER_STORAGE_UNBIND_VARIANT.USER_UNBIND]: ({
    userData,
  }) => {
    const intl = useIntl()
    const {
      full_name: fullName,
      user_role: userRole = DEFAULT_USER_ROLE,
    } = userData
    return (
      <BaseContentViews.BannerContent
        className={style.container__user}
      >
        <Typography
          weight={700}
          size={18}
          className={style.container__user_text}
        >
          {fullName ?? '-'}
        </Typography>
        <span>
          {intl.formatMessage({
            id: `user.user_role.${userRole as TUserRole}`,
            defaultMessage: 'Роль',
          })}
        </span>
      </BaseContentViews.BannerContent>
    )
  },
}

interface IProps {
  storageData: IStorage
  modalType: IUserStorageUnbindVariant
  closeModal: () => void
  handleSubmit: () => void
  isDisabled?: boolean
  userData: IUserGetValue
}
/**
 * @view
 * * вью для отрисовки модалки отвязки пользователя от шкафа
 * * в зависимости от ее типа
 * @param modalType       - тип отоброжаемого контента
 * @param storageData     - текущий шкаф
 * @param userData        - текущий пользователь
 * @param closeModal      - метод закрытия модалкти
 * @param handleSubmit    - метод для какого либо запроса
 * @param isDisabled      - флаг для выкл кнопки сабмита
 */
const UserStorageUnbindView: React.FC<IProps> = ({
  modalType,
  storageData,
  userData,
  closeModal,
  handleSubmit,
  isDisabled,
}) => {
  const intl = useIntl()
  const isUserUnbind =
    modalType === USER_STORAGE_UNBIND_VARIANT.USER_UNBIND
  const titleText = isUserUnbind
    ? {
        id: 'modal.title.user_unbind',
        defaultMessage:
          'Вы уверены, что хотите отвязать пользователя?',
      }
    : {
        id: 'modal.title.storage_unbind',
        defaultMessage:
          'Вы уверены, что хотите отвязать шкаф?',
      }

  return (
    <BaseContentViews.Wrapper>
      <BaseContentViews.Header
        className={style.container__header}
        colorIconWrapper="red"
        iconSlot={
          <Icon
            src="linkOff"
            size="medium-28"
            data-type={modalType}
          />
        }
      >
        <p className={style.container__header_lable}>
          {intl.formatMessage(titleText)}
        </p>
      </BaseContentViews.Header>
      {content[modalType]({ userData, storageData })}
      <BaseContentViews.ButtonWrapper>
        <Button
          className={style.container__button}
          onClick={closeModal}
          height={44}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          className={style.container__button}
          color="black"
          onClick={handleSubmit}
          disabled={isDisabled}
          height={44}
        >
          {intl.formatMessage({
            id: 'common.unbind',
            defaultMessage: 'Отвязать',
          })}
        </Button>
      </BaseContentViews.ButtonWrapper>
    </BaseContentViews.Wrapper>
  )
}

export default UserStorageUnbindView
