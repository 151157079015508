import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'

import { NotificationItem } from '@/apps/Notifications/views/Item'
import { INotify } from '@/api/interfaces'
import {
  useFetch,
  useLazyFetch,
  useMutation,
  useStores
} from '@/hooks'
import { isUndefined } from '@/utils'
import {
  DEFAULT_PAGE_LAZY_SIZE,
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES
} from '@/const'
import Loader from '@/views/Loader'
import NotificationList from '@/apps/Notifications/views/List'
import NotificationHead from '@/apps/Notifications/views/Head'
import Empty from '@/apps/Notifications/views/Empty'

/**
 * Панель списка уведомлений
 * @component
 */

export interface INotificationsPanel {
  closeModal: () => void
}

const NotificationsPanel: React.FC<INotificationsPanel> =
  observer(({ closeModal: handleClose }) => {
    const { api, notificationStore, modalStore } =
      useStores()
    const scrollableListRef = useRef<HTMLDivElement>(
      EMPTY_CONSTS.NULL
    )
    const [notifications, setNotifications] = useState<
    INotify[] | undefined
    >(EMPTY_CONSTS.UNDEFINED)
    const {
      lazyLoadedData: data,
      tableScroll: handleScroll,
      refetch
    } = useLazyFetch(
      scrollableListRef,
      api.notification.getNotification,
      {
        page_size: DEFAULT_PAGE_LAZY_SIZE
      }
    )
    const { data: countData } = useFetch(
      api.notification.getNotification,
      { is_read: false }
    )

    const readAllMutation = useMutation({
      mutation: api.notification.readAllNotification,
      onSuccess: () => {
        refetch()
        notificationStore.setCount(EMPTY_CONSTS.ZERO)
      }
    })

    const deleteAllMutation = useMutation({
      mutation: api.notification.deleteAllNotification,
      onSuccess: () => {
        setNotifications(EMPTY_CONSTS.ARR)
        notificationStore.setCount(EMPTY_CONSTS.ZERO)
      },
      onError: () => {
        modalStore.open(
          MODAL_TYPES.CUSTOM,
          MODAL_CUSTOM_CONTENT.ERROR
        )
      }
    })
    const handleDelete = (): void => {
      if (
        !isUndefined(notifications) &&
        Boolean(notifications.length)
      ) {
        deleteAllMutation.mutation()
      }
    }

    const handleRead = (): void => {
      if (
        !isUndefined(countData?.count) ||
        notificationStore.count !== EMPTY_CONSTS.ZERO
      ) {
        readAllMutation.mutation()
      }
    }

    useEffect(() => {
      setNotifications(data)
    }, [data])

    useEffect(() => {
      notificationStore.setCount(countData?.count)
    }, [countData])

    if (
      isUndefined(notifications) ||
      deleteAllMutation.isLoading
    ) {
      return (
        <Loader
          size='large'
          theme='center'
          color='orange'
        />
      )
    }

    if (
      !isUndefined(notifications) &&
      notifications.length === EMPTY_CONSTS.ZERO
    ) {
      return <Empty handleClose={handleClose} />
    }

    return (
      <div>
        <NotificationHead
          count={notificationStore.count}
          handleClose={handleClose}
          handleDelete={handleDelete}
          handleRead={handleRead}
        />
        <NotificationList
          listRef={scrollableListRef}
          handleScroll={handleScroll}
        >
          {notifications.map((notify) => (
            <NotificationItem key={notify.id} {...notify} />
          ))}
        </NotificationList>
      </div>
    )
  })

export default NotificationsPanel
