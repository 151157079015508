import React from 'react'

import UserBinding from '../Core'

import { useStores } from '@/hooks'

import { IModal } from '../../interfaces'
import { IQueryParams } from '@/api/interfaces'
interface IProps extends IModal {
  modalProps: {
    groupId: number
    refetch: () => void
  }
}
/**
 * @component
 * * модалка для привязывания юзера к группе
 */
const UserBindingToGroupUser: React.FC<IProps> = ({
  closeModal,
  modalProps,
}) => {
  const { groupId, refetch } = modalProps
  const { api } = useStores()

  const handleFetchUserList = async (
    params: IQueryParams,
  ) => await api.groupUsers.getUntiedUsers(groupId, params)

  const handleBindUserToStorage = async (
    userIdArr: number[],
  ) => await api.groupUsers.tiedUsers(groupId, userIdArr)

  return (
    <UserBinding
      closeModal={closeModal}
      handleFetchUserList={handleFetchUserList}
      handleBindUsers={handleBindUserToStorage}
      refetch={refetch}
    />
  )
}

export default UserBindingToGroupUser
