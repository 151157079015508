import React from 'react'
import { useIntl } from 'react-intl'
import Accordion from '@/views/Accordion'
import { SidebarContainer } from '@/apps/RightSidebar/views/SidebarContainer'

import styles from './styles/index.module.scss'
import { Typography } from '@/views/typography'

const Row: React.FC<{
  name: string
  value: string
}> = (props: { name: string; value: string }) => {
  return (
    <div>
      <span className={styles['equipment-info__name']}>
        {props.name}
      </span>
      <span className={styles['equipment-info__value']}>
        {props.value}
      </span>
    </div>
  )
}

/**
 * Блок с информацие о детелке оборудования
 * @param [props.type] - тип оборудования
 * @param [props.labelNumber] - номер метки
 * @param [props.serialNumber] - серийный номер
 * @param [props.inventoryNumber] - инвентарный номер
 * @param [props.description] - описание
 * @view
 */

interface IEquipmentInfo {
  type: string
  labelNumber: string[]
  serialNumber: string
  inventoryNumber: string
  description: string
}

const EquipmentInfo: React.FC<IEquipmentInfo> = (props) => {
  const intl = useIntl()
  return (
    <div className={styles['equipment-info__root']}>
      <SidebarContainer>
        {props.labelNumber.length !== 0 && (
          <ul
            className={styles['equipment-info__label_list']}
          >
            {props.labelNumber.map((label) => (
              <li
                key={label}
                className={
                  styles['equipment-info__label_item']
                }
              >
                {label}
              </li>
            ))}
          </ul>
        )}
        <div className={styles['equipment-info__grid']}>
          <Row
            name={intl.formatMessage({
              id: 'type',
              defaultMessage: 'Тип',
            })}
            value={props.type}
          />
          <Row
            name={intl.formatMessage({
              id: 'serialNumber',
              defaultMessage: 'Серийный номер',
            })}
            value={props.serialNumber}
          />
          <Row
            name={intl.formatMessage({
              id: 'inventoryNumber',
              defaultMessage: 'Инвентарный номер',
            })}
            value={props.inventoryNumber}
          />
          <Accordion
            head={intl.formatMessage({
              id: 'description',
              defaultMessage: 'Описание',
            })}
          >
            <Typography size={14} weight={400}>
              {props.description === ''
                ? intl.formatMessage({
                    id: 'noDescriptionYet',
                    defaultMessage: 'Пока нет описания',
                  })
                : props.description}
            </Typography>
          </Accordion>
        </div>
      </SidebarContainer>
    </div>
  )
}

export default EquipmentInfo
