import * as yup from 'yup'
import {
  EQUIPMENT_SELECTION_MODE_VARIANTS,
  USER_SELECTION_MODE_VARIANTS
} from '@/apps/MainSidebar/const'
import { INTL_DEFAULT_MESSAGE } from '@/const'

const StorageSettingsScheme = yup.object().shape({
  user_selection_mode: yup
    .string()
    .oneOf([
      USER_SELECTION_MODE_VARIANTS.RANDOM,
      USER_SELECTION_MODE_VARIANTS.TIED_EQUIPMENT
    ]),
  equipment_selection_mode: yup
    .string()
    .oneOf([
      EQUIPMENT_SELECTION_MODE_VARIANTS.MANUALLY,
      EQUIPMENT_SELECTION_MODE_VARIANTS.RANDOM
    ]),
  is_cell_reservation: yup
    .boolean()
    .when('equipment_selection_mode', {
      is: EQUIPMENT_SELECTION_MODE_VARIANTS.MANUALLY,
      then: (shema) =>
        shema.oneOf(
          [false],
          INTL_DEFAULT_MESSAGE.COMBINATION_NOT_AVAILABLE
        )
    }),
  is_hand_over: yup.boolean()
})

export default StorageSettingsScheme
