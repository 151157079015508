import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { usePage, useStores } from '@/hooks'
import { EMPTY_CONSTS, MODAL_TYPES, PAGE_TYPE } from '@/const'
import {
  IOptions,
  IUploadExcel
} from '@/apps/Modals/ModalContent/interfaces'
import UploadExcelView from '@/apps/Modals/views/UploadExcelView'
import { usePaginateSelect } from '@/apps/Modals/hook'
import { isNull } from '@/utils'
import { observer } from 'mobx-react'

/**
 * Компонент модалки загрузки ексель оборудованияя
 *
 * @param closeModal
 * @constructor
 */

const UploadExcel: React.FC<IUploadExcel> = ({ closeModal, modalProps }) => {
  const intl = useIntl()
  const { refetch } = modalProps
  const { api, modalStore, currentStorageStore: { storage } } = useStores()
  const { error, loadOptions } = usePaginateSelect(api.storage.getStorages)
  const { page } = usePage()
  const haveInitialValues = page.page_model === PAGE_TYPE.storagePage && !isNull(storage)

  const initialSelectValue = {
    label: storage?.title ?? EMPTY_CONSTS.STR,
    id: String(storage?.id) ?? EMPTY_CONSTS.STR
  }

  const [selectValue, setSelectValue] = useState<IOptions | null>(
    haveInitialValues ? initialSelectValue : EMPTY_CONSTS.NULL
  )

  const [key, setKey] = useState(Date.now())
  const ref = useRef<HTMLInputElement>(EMPTY_CONSTS.NULL)

  const handleChange = (payload: IOptions): void => {
    setSelectValue(payload)
  }

  const handleAccept = (): void => {
    ref.current?.click()
  }

  /**
   * Метод для отправки ексель и выбранных шкафов
   */
  const handleLoad = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const files = e.target.files
    const storageId = selectValue?.id
    if (files != null) {
      api.equipment
        .createExcel({
          file: files[0],
          storage: storageId
        })
        .then(() => {
          refetch()
          closeModal()
        })
        .catch(() => {
          modalStore.open(MODAL_TYPES.FILE_UPLOAD_ERROR)
        })
        .finally(() => {
          setKey(Date.now())
        })
    }
  }

  const label = intl.formatMessage({
    id: 'attachToCupboard',
    defaultMessage: 'Привязать к шкафу'
  })

  const selectProps = {
    loadOptions,
    onChange: handleChange,
    error,
    value: selectValue
  }

  return (
    <UploadExcelView
      label={label}
      selectProps={selectProps}
      handleLoad={handleLoad}
      handleAccept={handleAccept}
      handleClose={closeModal}
      inputRef={ref}
      inputKey={String(key)}
    />
  )
}

export default observer(UploadExcel)
