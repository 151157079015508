import React, { RefObject } from 'react'

import { IUserHistory } from '@/api/interfaces'
import { EMPTY_CONSTS } from '@/const'
import { isNull } from '@/utils'

import Table from '@/apps/Table/component/Table'
import { USER_HISTORY_COL } from '@/apps/RightSidebar/const'

import styles from './styles/index.module.scss'
import CellHistoryView from '../../CellHistoryView'

const tableStyles = {
  classNameTable: styles.table,
  classNameBodyRow: styles.table__body_row
}

export interface IUserHistoryTableProps {
  data: IUserHistory[] | undefined
  handleScroll: () => void
  listRef: RefObject<HTMLDivElement>
}

/**
 * Список пользовтаельской истории
 * @view
 * @param data - список истории
 * @param handleScroll - обработчик скрола
 * @param listRef - ссылка на список
 *
 */
const UserHistoryTableView: React.FC<
IUserHistoryTableProps
> = ({ data, handleScroll, listRef }) => {
  const columns = [
    {
      key: USER_HISTORY_COL.EQUIPMENT_TYPE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (type: string) => <div>{type}</div>
      }
    },
    {
      key: USER_HISTORY_COL.TITLE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        className: styles.table__body_title,
        render: (title: string) => <div>{title}</div>
      }
    },
    {
      key: USER_HISTORY_COL.ACTION,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (action: string) => <div>{action}</div>
      }
    },
    {
      key: USER_HISTORY_COL.CELL_NUMBER,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        className: styles.table__body_number,
        render: (cell: string | null) =>
          !isNull(cell)
            ? (
              <CellHistoryView>{cell}</CellHistoryView>
              )
            : (
                EMPTY_CONSTS.NULL
              )
      }
    },
    {
      key: USER_HISTORY_COL.DATE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        className: styles.table__body_date,
        render: (date: string) => <div>{date}</div>
      }
    }
  ]
  return (
    <div
      className={styles.scroll}
      ref={listRef}
      onScroll={handleScroll}
    >
      <Table
        columns={columns}
        rowsData={data}
        styles={tableStyles}
      />
    </div>
  )
}

export default UserHistoryTableView
