import React, { FC } from 'react'
import { observer } from 'mobx-react'

import Checkbox from '@/components/Checkbox'
import ControlPanel from '../ControlPanel'

import { CHECKBOX_THEMES, EMPTY_CONSTS } from '@/const'

import { IStorageContainer } from '../interfaces'

import style from '../style/index.module.scss'

/**
 * * вью для реализаци основной части модалки прикрепления
 * шкафа к пользователю
 * @view StorageContainer
 *
 * @func handleStorage               функция изменения шкафов
 * @func handleParams                функция изменения query параметров
 * @param params                     query параметры
 * @param storages                   шкафы
 * @param isScrollEnd                флаг доскроллено ли до самого низа контейнера
 */
const StorageContainer: FC<IStorageContainer> = ({
  handleChangeStorageList,
  handleClearSelectedStorages,
  storageIdList,
  handleParams,
  params,
  storages = EMPTY_CONSTS.ARR,
  refContainer,
  tableScroll,
  isScrollEnd,
}) => {
  const { ordering } = params

  return (
    <div className={style.container}>
      <div
        className={style.container__content}
        data-scrollend={isScrollEnd}
      >
        <ControlPanel
          countCheckedStorages={storageIdList.size}
          handleParams={handleParams}
          handleClearSelectedStorages={
            handleClearSelectedStorages
          }
          ordering={ordering}
        />
        <div
          ref={refContainer}
          onScroll={tableScroll}
          className={style.container__content__storages}
        >
          {storages?.length >= 1
            ? storages.map(({ id, title }) => {
                const isChecked = Boolean(
                  storageIdList.get(id),
                )

                return (
                  <Checkbox
                    key={id}
                    name={id}
                    data-id={id}
                    checked={isChecked}
                    onGx-change={handleChangeStorageList}
                    theme={CHECKBOX_THEMES.SELECT_ROW}
                    className={
                      style.container__content__checkbox
                    }
                  >
                    {title}
                  </Checkbox>
                )
              })
            : null}
        </div>
      </div>
    </div>
  )
}

export default observer(StorageContainer)
