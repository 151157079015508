import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'

import { Input } from '@/views/input'

import { EMPTY_CONSTS, MAX_SEARCH_LENGTH } from '@/const'

import { ISearchInput } from './interfaces'

import useDebounced from '@/hooks/useDebounced'
import styles from './styles/index.module.scss'

/**
 * @view SearchInput
 * * вью строки поиска
 *
 * @param handleParams        установить параметры поиска
 * @param className           дополнительные стили
 */
const SearchInput: React.FC<ISearchInput> = ({
  handleParams,
  className = EMPTY_CONSTS.STR,
  size,
}) => {
  const intl = useIntl()
  const debouncedHandleParams = useDebounced(
    handleParams,
    300,
  )

  /**
   * * метод для записи текущего параметра строки поиска
   * @func handleSearch
   *
   * @param e         event инпута, в котором содержится текущее значение,
   *                  введенное пользователем
   */
  const handleSearch = (
    e:
      | React.ChangeEvent<HTMLGxInputElement>
      | GxInputCustomEvent<HTMLGxInputElement>,
  ): void => {
    debouncedHandleParams({ search: e.target.value })
  }

  return (
    <Input
      type="search"
      placeholder={intl.formatMessage({
        id: 'input.search',
        defaultMessage: 'Search',
      })}
      outerStyles={classNames({
        [styles['search-input']]: true,
        [styles[`search-input__size--${size}`]]:
          Boolean(size),
        [className]: Boolean(className),
      })}
      onChange={handleSearch}
      maxlength={MAX_SEARCH_LENGTH}
      clearable
    />
  )
}

export default React.memo(SearchInput)
