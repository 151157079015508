import React, { forwardRef } from 'react'

import { Components } from '@garpix/garpix-web-components'
import { GxTabPanel } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'

import style from './styles/index.module.scss'

/**
 * * компонент панели таба
 * (где отображается контент текущего таба)
 * @vcomponent TabPanel
 *
 * @returns
 */
const TabPanel = forwardRef<Components.GxTabGroup, any>(
  (props, ref): React.ReactElement => {
    const {
      children = '',
      className = '',
      name = '',
    } = props
    return (
      <GxTabPanel
        {...props}
        name={name}
        ref={ref}
        className={classNames({
          [style.tabpannel]: true,
          [className]: Boolean(className),
        })}
      >
        {children}
      </GxTabPanel>
    )
  },
)
export default TabPanel
