import Wrapper from './Wrapper'
import Header from './Header'
import ButtonWrapper from './ButtonWrapper'
import BannerContent from './BannerContent'

export default {
  Wrapper,
  Header,
  BannerContent,
  ButtonWrapper,
}
