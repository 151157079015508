import React, { JSX } from 'react'

import { useIntl } from 'react-intl'
import cn from 'classnames'

import { useStores } from '@/hooks'

import { EquipmentStatusIcon } from '@/components/EquipmentStatusIcon'
import Badge from '@/components/Badge'

import {
  DEFAULT_USER_EQUIPMENT_STATUS,
  MODAL_TYPES,
} from '@/const'

import EditDropdown from '@/views/EditDropdown'

import { getInitials } from '@/utils'
import { TUser } from '@/api/interfaces'
import styles from '../style/index.module.scss'
import { CUSTOM_DATA_ATTR_STYLES } from '@/apps/Modals/const'

/**
 * @component FullName
 * * компонент ФИО пользователя
 * @param
 */
export const FullName = (
  el: string,
  {
    last_name: lastName,
    first_name: firstName,
  }: Required<TUser>,
  className?: string,
): JSX.Element => {
  const title = getInitials(firstName, lastName)
  return (
    <div className={cn(styles.body__fio, className)}>
      <Badge title={title} />
      <span className={styles.body__fio_text}>{el}</span>
    </div>
  )
}

/**
 * @component UserRole
 * * компонент роли пользователя
 * @param
 */
export const UserRole = (el: string): JSX.Element => {
  const intl = useIntl()
  return (
    <>
      {intl.formatMessage({
        id: `user.user_role.${el}`,
        defaultMessage: 'role',
      })}
    </>
  )
}

/**
 * @component EquipStatus
 * * компонент статуса оборудования (на руках, не на руках у пользователя)
 * @param
 */
export const EquipStatus = (
  el: string = DEFAULT_USER_EQUIPMENT_STATUS,
): JSX.Element => {
  return (
    <div className={styles.body__content}>
      {' '}
      <EquipmentStatusIcon type={`equipment_${el}`} />
    </div>
  )
}

/**
 * @component UserId
 * * компонент дропдауна пользователя
 * @param
 */
export const UserId = (
  user: TUser,
  refetch: () => void,
): JSX.Element => {
  const { modalStore } = useStores()
  /**
   * @function handleOpenUserEditModal
   * * метод открытия модалки редактирования пользователя
   */
  const handleOpenUserEditModal = (): void => {
    modalStore.open(MODAL_TYPES.USER_EDIT, {
      id: user.id,
      refetch,
    })
  }
  /**
   * @function handleOpenDeleteUserModal
   * * метод открытия модалки удаления пользователя
   */
  const handleOpenDeleteUserModal = (): void => {
    modalStore.setCustomDataStyles(
      CUSTOM_DATA_ATTR_STYLES.NOTIFY_PADDING,
    )
    modalStore.open(MODAL_TYPES.USER_DELETE, {
      ...user,
      refetch,
    })
  }

  return (
    <EditDropdown
      hadnleEdit={handleOpenUserEditModal}
      handleDelete={handleOpenDeleteUserModal}
    />
  )
}
