import React, { RefObject } from 'react'

import styles from './styles/index.module.scss'

export interface INotificationListProps {
  children?: React.ReactNode
  listRef: RefObject<HTMLDivElement>
  handleScroll: () => void
}

/**
 * Список обертка для уведомлений
 * @view
 * @param children
 * @param listRef
 * @param handleScroll
 */
const NotificationList: React.FC<
INotificationListProps
> = ({ children, listRef, handleScroll }) => {
  return (
    <div
      ref={listRef}
      className={styles.list__root}
      onScroll={handleScroll}
    >
      {children}
    </div>
  )
}

export default NotificationList
