import * as yup from 'yup'
import { INTL_DEFAULT_MESSAGE } from '@/const'

const ProblemEditScheme = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  type: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  equipment_types: yup
    .array()
})

export default ProblemEditScheme
