import React from 'react'
import { useStores } from '@/hooks'
import { useIntl } from 'react-intl'

import { BADGE_THEME } from '@/const'
import { ILinksLinkProps } from '../../interfaces'

import { Button } from '@/views/button'
import Icon from '@/components/Icon'
import Badge from '@/views/Badge'
import style from '../style/index.module.scss'
/**
 * @view
 * * Лист с "ссылками" куда либло
 * * (так же выход из профиля, открытие уведомлений)
 */
const LinkList: React.FC<ILinksLinkProps> = ({
  handleOpenLogoutModal,
  handleOpenNotificationModal
}) => {
  const { notificationStore } = useStores()
  const intl = useIntl()

  return (
    <ul className={style.link__list}>
      <li>
        <Button
          className={style.link__item}
          variant='text'
          onClick={handleOpenNotificationModal}
        >
          <span className={style.link__icon_notification}>
            <Icon src='notification' />
            {Boolean(notificationStore?.count) && (
              <Badge
                count={notificationStore.count}
                className={style.link__badge}
                theme={BADGE_THEME.ICON}
              />
            )}
          </span>
          <span>
            {intl.formatMessage({
              id: 'notifications',
              defaultMessage: 'Уведомления'
            })}
          </span>
        </Button>
      </li>
      <li>
        <Button
          variant='text'
          onClick={handleOpenLogoutModal}
          className={style.link__item}
        >
          <Icon src='exit' />
          <span>
            {intl.formatMessage({
              id: 'button.exit',
              defaultMessage: 'Выйти'
            })}
          </span>
        </Button>
      </li>
    </ul>
  )
}

export default LinkList
