import React from 'react'

import Media from 'react-media'

import { MEDIA_QUERY } from '@/const'
import { TEquipmentStatus } from '@/api/interfaces'

import { EquipmentStatusIcon } from '@/components/EquipmentStatusIcon'
import Icon from '@/components/Icon'

import style from './style/index.module.scss'
/**
 * @view
 * * вью для отрисовки статуса оборудования у юзера
 * @param status - статус оборудования
 */
const StatusEquipmet = (
  status: TEquipmentStatus
): React.ReactElement => {
  return (
    <Media
      queries={{
        isAdaptive: MEDIA_QUERY.MEDIA_1200
      }}
    >
      {({ isAdaptive }) => {
        return isAdaptive
          ? (
            <div data-type={status} className={style.equipment}>
              <Icon src='equipment' size='small' />
            </div>
            )
          : (
            <EquipmentStatusIcon type={`equipment_${status}`} className={style.equipment__big} />
            )
      }}
    </Media>
  )
}

export default StatusEquipmet
