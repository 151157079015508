import React, { useRef } from 'react'
import HistoryPanelView from '@/apps/RightSidebar/views/EquipmentSideBarView/HistoryPanel'
import { useLazyFetch, useStores } from '@/hooks'
import { useDatePicker } from '@/apps/RightSidebar/hooks'
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_LAZY_SIZE
} from '@/const'

interface IEquipmentHistoryPanelProps {
  id: number
}

/**
 * Комопнент панели истории оборудования
 * @component
 * @param id
 */

const HistoryPanel: React.FC<
IEquipmentHistoryPanelProps
> = ({ id }) => {
  const { api } = useStores()
  const wrapContainerRef = useRef<HTMLDivElement>(null)
  const datePicker = useDatePicker(
    api.equipment.downloadHistory,
    id
  )
  const {
    isLoading,
    lazyLoadedData: historyData,
    params,
    tableScroll: scrollEvent,
    handleParams,
    isScrollEnd
  } = useLazyFetch(
    wrapContainerRef,
    api.equipment.getHistoryEquipment,
    {
      id,
      page_size: DEFAULT_PAGE_LAZY_SIZE
    }
  )
  const handleStartChange = (date: string): void => {
    datePicker.handleStartChange(date)
    handleParams({
      ...params,
      date_start: date,
      page: DEFAULT_CURRENT_PAGE
    })
  }
  const handleEndChange = (date: string): void => {
    datePicker.handleEndChange(date)
    handleParams({
      ...params,
      date_end: date,
      page: DEFAULT_CURRENT_PAGE
    })
  }

  const scrollWrapProps = {
    ref: wrapContainerRef,
    onScroll: scrollEvent
  }

  const listProps = {
    isLoading,
    historyData,
    params,
    handleParams,
    isScrollEnd,
    scrollWrapProps
  }

  const datePickerProps = {
    startDate: datePicker.startDate,
    endDate: datePicker.endDate,
    handleEndChange,
    handleStartChange,
    handleDownload: datePicker.handleDownload,
    isReportLoading: datePicker.isLoading
  }

  return (
    <HistoryPanelView
      listProps={listProps}
      datePickerProps={datePickerProps}
    />
  )
}

export default HistoryPanel
