import React from 'react'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'

import { useFetch, useStores } from '@/hooks'

import UserGroupEditView from '../../views/UserGroupEditView'

import { generateRandomColor, getObjectErrorsFormik, isUndefined } from '@/utils'
import { IModal } from '../interfaces'

import { GroupUsersEditScheme } from '@/utils/ValidateSchemes'
import { IGroupUsersCreate } from '@/api/interfaces/GroupUsers'
import Loader from '@/views/Loader'

const INITIAL_VALUES = {
  title: '',
  description: '',
  color: generateRandomColor()
}

interface IProps extends IModal {
  modalProps: {
    refetch: () => void
    groupId?: number
  }
}

const UserGroupEdit: React.FC<IProps> = ({
  closeModal,
  modalProps
}) => {
  const { groupId, refetch } = modalProps
  const { api } = useStores()
  const isEdit = !isUndefined(groupId)

  const { data: groupUsers, error, isLoading } = useFetch(async () => {
    if (isEdit) return await api.groupUsers.getCurrentGroupUsers(groupId)
  })

  const sendMethod = (values: IGroupUsersCreate) =>
    isEdit
      ? api.groupUsers.editCurrentGroupUsers(groupId, values)
      : api.groupUsers.createGroupUsers(values)

  const formikTools = useFormik({
    initialValues: !isUndefined(groupUsers) ? groupUsers : INITIAL_VALUES,
    validationSchema: GroupUsersEditScheme,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      action.setSubmitting(true)
      sendMethod(values)
        .then(() => {
          closeModal()
          refetch()
        })
        .catch((err) => {
          if (!isUndefined(err.response?.data)) {
            action.setErrors(getObjectErrorsFormik(err))
          }
        })
        .finally(() => {
          action.setSubmitting(false)
        })
    }
  })

  if (isLoading) {
    return (
      <Loader color='orange' theme='center' size='large' />
    )
  }

  return (
    <UserGroupEditView
      groupId={groupId}
      closeModal={closeModal}
      formikTools={formikTools}
      error={error}
    />
  )
}

export default observer(UserGroupEdit)
