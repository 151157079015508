import {
  makeObservable,
  observable,
  _autoAction,
} from 'mobx'

import { PubSub, PubSubProps } from '../PubSub'

import { RootStore } from './Root'

import {
  ISocketStartForceSyncMessage,
  ISocketStartSyncMessage,
  ISocketErrorSyncMessage,
  ISocketSuccessSyncSettingsMessage,
  ISocketIntegrityErrorSyncMessage,
  ISocketSuccessSyncMessage,
} from '@/interfaces/Socket'

import { MODAL_TYPES, STORAGE_STATUS, MODAL_CUSTOM_CONTENT } from '@/const'
/**
 * @class
 * * Класс, реализующий возможность подписываться на
 * * событя сокетов Шкафа
 */
export class StorageEventsObserver extends PubSub {
  _ctx: RootStore

  constructor(ctx: RootStore) {
    super()

    this._ctx = ctx

    makeObservable(this, {
      ...PubSubProps,
      _ctx: observable,
      onSuccesSync: _autoAction,
      onStartForceSync: _autoAction,
      onStartSync: _autoAction,
      onSuccesForceSync: _autoAction,
      onSuccessSettingsSync: _autoAction,
      onErrorSync: _autoAction,
      onIntegrityErrorSynchronization: _autoAction
    })
  }

  public onStartSync = ({
    data,
  }: ISocketStartSyncMessage): void => {
    this.broadcast(data.storage_id)
  }

  public onStartForceSync = ({
    data,
  }: ISocketStartForceSyncMessage): void => {
    this.broadcast(data.storage_id)
  }

  public onSuccesSync = ({
    data,
  }: ISocketSuccessSyncMessage): void => {
    this.broadcast(data.storage_id)
    this._ctx.storageListStore.updateStorageStatusById(
      data.storage_id,
      STORAGE_STATUS.SUCCESS
    )
  }

  public onSuccesForceSync = ({
    data,
  }: ISocketStartForceSyncMessage): void => {
    this.broadcast(data.storage_id)
  }

  public onSuccessSettingsSync = (
    message: ISocketSuccessSyncSettingsMessage
  ): void => {
    this._ctx.currentStorageStore.checkStorageEvents(
      message
    )
  }

  public onIntegrityErrorSynchronization = (
    message: ISocketIntegrityErrorSyncMessage
  ): void => {
    this._ctx.modalStore.open(MODAL_TYPES.CUSTOM,{
      ...MODAL_CUSTOM_CONTENT.ERROR_SYNC,
      title: message.data.message
    })
  }

  public onErrorSync = (
    message: ISocketErrorSyncMessage
  ): void => {
    this.broadcast(message.data.storage_id)
    this._ctx.currentStorageStore.checkStorageEvents(
      message
    )
  }
}
