import React, { FC } from 'react'
import classNames from 'classnames'
import { EMPTY_CONSTS } from '@/const'
import { ISortButton } from './interfaces'
import Icon from '@/components/Icon'
import styles from './styles/index.module.scss'
/**
 * @component SortButton
 * * компонент кнопок для установки типа сортировки (ASC, DESC)

 *
 * @param handleParams             установить сортировку { поле: тип_сортировки } к остальным query параметрам
 * @param ordering              текущая сортировка
 * @param field                 название поля для фильтра
 * @param title                 название поля рядом с сортировкой
 */
const SortButton: FC<ISortButton> = ({ ordering, handleParams, field, title }) => {
  /**
   * @function handleChangeSorting
   * * метод для установки сортировки
   */
  const handleChangeSorting = (): void => {
    switch (ordering) {
      case field:
        return handleParams({ ordering: `-${field}` })
      case `-${field}`:
        return handleParams({ ordering: EMPTY_CONSTS.UNDEFINED })
      default: handleParams({ ordering: field })
    }
  }

  return (
    <div
      className={styles.wrap}
      onClick={handleChangeSorting}
    >
      {title}
      <div className={styles.sort}>
        <Icon
          src='sortUp'
          size='xxs'
          className={classNames(styles.sort_icon,
            { [styles.sort_icon_active]: Boolean(ordering === field) }
          )}
        />
        <Icon
          src='sortDown'
          size='xxs'
          className={classNames(styles.sort_icon,
            { [styles.sort_icon_active]: Boolean(ordering === `-${field}`) }
          )}
        />
      </div>
    </div>
  )
}

export default SortButton
