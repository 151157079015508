import React, { FC } from 'react'

import { useIntl } from 'react-intl'

import SortButton from '@/components/SortButton'
import SearchInput from '@/views/SearchInput'

import { IControlPanel } from '../interfaces'

import style from '../style/index.module.scss'
import { observer } from 'mobx-react'
import ResetCouner from '@/views/ResetCouner'

/**
 * * вью для реализаци панели изменения параметров
 * поиска, сортировки, фильтрации шкафов, прикрепляемых
 * к пользователю
 * @view ControlPanel
 *
 * @func handleStorage               функция изменения шкафов
 * @func handleParams                функция изменения query параметров
 * @param ordering                   query сортировки
 * @param storages                   шкафы
 */
const ControlPanel: FC<IControlPanel> = ({
  handleParams,
  countCheckedStorages,
  handleClearSelectedStorages,
  ordering,
}) => {
  const intl = useIntl()

  return (
    <div className={style.panel}>
      <SearchInput
        handleParams={handleParams}
        className={style.panel_input}
      />
      <div className={style.panel__control}>
        <SortButton
          handleParams={handleParams}
          ordering={ordering}
          field="title"
          title={intl.formatMessage({
            id: 'common.storages',
            defaultMessage: 'Шкафы',
          })}
        />
        <ResetCouner
          count={countCheckedStorages}
          handleClick={handleClearSelectedStorages}
        />
      </div>
    </div>
  )
}

export default observer(ControlPanel)
