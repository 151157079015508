import React from 'react'

import style from './style/textarea.module.scss'
import classNames from 'classnames'

interface ITextarea
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: string
  className?: string
  label?: string
}

const Textarea: React.FC<ITextarea> = ({
  label,
  error,
  className,
  ...props
}) => {
  return (
    <div className={style.textarea}>
      <label>
        <span className={style.textarea__label}>
          {label}
        </span>
        <div className={style.textarea__wrap}>
          <textarea
            {...props}
            className={classNames(
              style.textarea__element,
              className,
            )}
          />
        </div>
      </label>
      {Boolean(error) && (
        <div className={style.error_text} slot="help-text">
          {error}
        </div>
      )}
    </div>
  )
}

export default Textarea
