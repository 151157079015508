import React, { useEffect, useState } from 'react'
import { EquipmentEditForm as EquipmentEditView } from '@/apps/Modals/views/EquipmentEditView'
import { Formik, FormikHelpers } from 'formik'
import { EquipmentEditScheme } from '@/utils/ValidateSchemes'
import { IModal } from '@/apps/Modals/ModalContent/interfaces'
import { useStores } from '@/hooks'
import { getObjectErrorsFormik, isUndefined } from '@/utils'
import { equipmentEditSerializer } from '@/apps/Modals/helpers'
import { EQUIPMENT_EDIT_FIELDS } from '@/apps/Modals/const'
import {
  EMPTY_CONSTS,
  EQUIPMENTS_STATUS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES
} from '@/const'
import {
  IEquipment,
  IEquipmentEdit
} from '@/api/interfaces'
import Loader from '@/views/Loader'

const initialValues: Partial<IEquipment> = {
  [EQUIPMENT_EDIT_FIELDS.EQUIPMENT_TYPE]: EMPTY_CONSTS.STR,
  [EQUIPMENT_EDIT_FIELDS.TITLE]: EMPTY_CONSTS.STR,
  [EQUIPMENT_EDIT_FIELDS.INVENTORY_NUMBER]:
    EMPTY_CONSTS.STR,
  [EQUIPMENT_EDIT_FIELDS.DESCRIPTION]: EMPTY_CONSTS.STR,
  [EQUIPMENT_EDIT_FIELDS.SERIAL_NUMBER]: EMPTY_CONSTS.STR,
  [EQUIPMENT_EDIT_FIELDS.USER_IDS]: EMPTY_CONSTS.ARR,
  [EQUIPMENT_EDIT_FIELDS.LABEL_NUMBER]: EMPTY_CONSTS.ARR,
  [EQUIPMENT_EDIT_FIELDS.STORAGE]: EMPTY_CONSTS.NULL,
  [EQUIPMENT_EDIT_FIELDS.STATUS]: EQUIPMENTS_STATUS.NEW,
  [EQUIPMENT_EDIT_FIELDS.CELL_NUMBER]: EMPTY_CONSTS.NULL
}

interface IEquipmentEditFormProps extends IModal {
  modalProps: {
    equipment?: IEquipment
    id?: number
    refetch: () => void
  }
}

/**
 * Модалка создания оборудования
 * @component
 * @constructor
 */
const EquipmentEditForm: React.FC<
IEquipmentEditFormProps
> = ({ closeModal, modalProps: { equipment, refetch } }) => {
  const { api } = useStores()

  const handleSubmit: (
    values: IEquipmentEdit,
    formikHelpers: FormikHelpers<IEquipmentEdit>
  ) => void = (values, action) => {
    const serializedValues = equipmentEditSerializer(values)
    const promise = !isUndefined(values.id)
      ? api.equipment.updateEquipment({
        ...serializedValues,
        id: values.id
      })
      : api.equipment.createEquipment(serializedValues)
    promise
      .then(() => {
        refetch()
        closeModal()
      })
      .catch((error) => {
        if (error?.response?.data !== undefined) {
          const formattedError =
            getObjectErrorsFormik(error)
          action.setErrors(formattedError)
        }
      })
      .finally(() => {
        action.setSubmitting(false)
      })
  }
  return (
    <Formik
      initialValues={equipment ?? initialValues}
      onSubmit={handleSubmit}
      validationSchema={EquipmentEditScheme}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <EquipmentEditView
              closeModal={closeModal}
              isSubmitting={isSubmitting}
            />
          </form>
        )
      }}
    </Formik>
  )
}

const EquipmentEdit: React.FC<IEquipmentEditFormProps> = (
  props
) => {
  const { id, equipment } = props.modalProps
  const { api, modalStore } = useStores()
  const [isLoading, setLoading] = useState<boolean>(() =>
    !isUndefined(id)
      ? EMPTY_CONSTS.TRUE
      : EMPTY_CONSTS.FALSE
  )

  useEffect(() => {
    if (!isUndefined(id) && isUndefined(equipment)) {
      void api.equipment
        .getEquipment({ id })
        .then((data) => {
          props.modalProps.equipment = data
        })
        .catch(() => {
          modalStore.open(
            MODAL_TYPES.CUSTOM,
            MODAL_CUSTOM_CONTENT.ERROR
          )
        })
        .finally(() => {
          setLoading(EMPTY_CONSTS.FALSE)
        })
    }
  }, [id])

  if (isLoading) {
    return (
      <Loader color='orange' theme='center' size='large' />
    )
  }

  return <EquipmentEditForm {...props} />
}

export default EquipmentEdit
