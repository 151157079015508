export const PROBLEM_COL = {
  ID: 'id',
  TITLE: 'title',
  TYPE: 'type',
  EQUIPMENT_TYPES: 'equipment_types'
} as const

export const MAX_LENGTH_TYPES = 4

export const PROBLEM_TYPE = {
  REQUIRED_DESCRIPTION: 'required_description',
  NOT_REQUIRED_DESCRIPTION: 'not_required_description'
}

export const QUERIES = {
  small: '(max-height:899px)',
  medium: '(min-height: 900px) and (max-height: 1199px)',
  large: '(min-height: 1200px) and (max-height: 1399px)',
  extra: '(min-height: 1400px)'
}
