import {
  IMenuItem,
  TContextMenus
} from '@/interfaces/Menus'
import { usePage as usePageCms } from '@garpix/cms'

const useGetContextMenus = (
  context: TContextMenus
): IMenuItem[] => {
  const value = usePageCms()
  return value?.page?.init_state?.global?.menus[context]
}

export default useGetContextMenus
