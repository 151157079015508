import React from 'react'
import classNames from 'classnames'
import { BADGE_THEME, EMPTY_CONSTS } from '@/const'

import styles from './styles/index.module.scss'

/**
 * Кружок с цифиркой
 * @constructor
 */

export interface IBadge {
  count?: number
  className?: string
  theme?: string
}

const Badge: React.FC<IBadge> = ({
  count = EMPTY_CONSTS.STR,
  theme = BADGE_THEME.DEFAULT,
  className = EMPTY_CONSTS.STR
}) => {
  return (
    <div
      className={classNames(
        styles.badge,
        styles[`badge--${theme}`],
        {
          [className]: Boolean(className),
          [styles['badge--empty']]: count === EMPTY_CONSTS.ZERO
        }
      )}
    >
      {count}
    </div>
  )
}

export default Badge
