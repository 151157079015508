import React from 'react'
import classNames from 'classnames'
import { GxMenuItem } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'
import { IMenuItem } from './interfaces'

/**
 * @component MenuItem
 * * компонент списка (меню)
 * @param {IMenuItem}      общий интерфейс входных параметров MenuItem
 *
 * @param children         наполнение компоненты
 * @param value            текущее значение
 * @param className        дополнительные стили
 * @param props            дополнительные параметры (пропсы)
 */
const MenuItem = ({
  children,
  value,
  className = '',
  ...props
}): React.ReactElement<IMenuItem> => {
  return (
    <GxMenuItem
      value={value}
      className={classNames({
        [style.default]: true,
        [className]: Boolean(className)
      })}
      {...props}
    >
      {children}
    </GxMenuItem>
  )
}

export default MenuItem
