import React from 'react'
import { observer } from 'mobx-react'

import MainSidebar from '@/apps/MainSidebar'
import MainLayout from '@/components/mainLayout/MainLayout'
import StorageDetails from '@/apps/StorageDetails'
import ButtonToggleDisplay from '@/apps/StorageDetails/views/ButtonToggleDisplay'

const StoragePage = (): React.ReactElement => {
  return (
    <MainLayout>
      <MainSidebar />
      <StorageDetails />
      <ButtonToggleDisplay />
    </MainLayout>
  )
}

export default observer(StoragePage)
