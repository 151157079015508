import React from 'react'

import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'

import { EMPTY_CONSTS } from '@/const'
import { STORAGE_EDIT_ACTION_BUTTON_TYPE } from '@/apps/Modals/const'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'

import style from '../style/index.module.scss'

interface IProp {
  projectId?: number
  disabled: boolean
  hasChangeImportantFields: boolean
  handleCancel: () => void
}

const buttonVariant = {
  connection: ({ disabled, intl }) => (
    <Button
      className={style['storage-edit__btn']}
      color='black'
      type='submit'
      disabled={disabled}
    >
      <Icon
        className={style['storage-edit__btn_icon']}
        src='plus'
        size='medium'
      />
      {intl.formatMessage({
        id: 'common.toPlug',
        defaultMessage: 'Подключить'
      })}
    </Button>
  ),
  edit: ({
    hasChangeImportantFields,
    disabled,
    handleCancel,
    intl
  }) => {
    const btnText =
      hasChangeImportantFields === true
        ? {
            id: 'common.toPlug',
            defaultMessage: 'Подключить'
          }
        : {
            id: 'common.save',
            defaultMessage: 'Сохранить'
          }
    return (
      <>
        <Button
          className={style['storage-edit__btn']}
          color='gray'
          onClick={handleCancel}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Отмена'
          })}
        </Button>
        <Button
          className={style['storage-edit__btn']}
          color='black'
          type='submit'
          disabled={disabled}
        >
          {intl.formatMessage(btnText)}
        </Button>
      </>
    )
  }
}

/**
 * * вью текущих кнопок в модалке создания/редактирования шкафа
 * @view
 * @param id
 * @returns
 */

const ActionButtons: React.FC<IProp> = ({
  projectId,
  disabled,
  handleCancel,
  hasChangeImportantFields
}) => {
  const status =
    projectId !== EMPTY_CONSTS.UNDEFINED
      ? STORAGE_EDIT_ACTION_BUTTON_TYPE.EDIT
      : STORAGE_EDIT_ACTION_BUTTON_TYPE.CONNECTION
  const CurrentVariant = buttonVariant[status]
  const intl = useIntl()

  return (
    <div className={style['storage-edit__footer']}>
      <CurrentVariant
        intl={intl}
        disabled={disabled}
        handleCancel={handleCancel}
        hasChangeImportantFields={hasChangeImportantFields}
      />
    </div>
  )
}

export default observer(ActionButtons)
