import { DEFAULT_INTEGRATION_FIELDS } from '@/apps/Integration/const'
import { INTL_DEFAULT_MESSAGE } from '@/const'
import * as yup from 'yup'
import { REGEX } from '../validation'

const IntegrationScheme = yup.object().shape({
  api_link: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  is_need_auth: yup.boolean().optional(),
  login: yup
    .string()
    .trim()
    .when(DEFAULT_INTEGRATION_FIELDS.is_need_auth, {
      is: true,
      then: (shema) =>
        shema.required(INTL_DEFAULT_MESSAGE.REQUIRED),
      otherwise: (shema) => shema.optional(),
    }),
  password: yup
    .string()
    .trim()
    .when(DEFAULT_INTEGRATION_FIELDS.is_need_auth, {
      is: true,
      then: (shema) =>
        shema.required(INTL_DEFAULT_MESSAGE.REQUIRED),
      otherwise: (shema) => shema.optional(),
    }),
  email: yup
    .string()
    .trim()
    .when(DEFAULT_INTEGRATION_FIELDS.is_email_alerts, {
      is: true,
      then: (shema) =>
        shema
          .required(INTL_DEFAULT_MESSAGE.REQUIRED)
          .matches(REGEX.email, {
            message: INTL_DEFAULT_MESSAGE.UNCORRECT_EMAIL,
          }),
      otherwise: (shema) => shema.optional(),
    }),
})

export default IntegrationScheme
