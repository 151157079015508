import React, { FC, ReactNode } from 'react'

import cn from 'classnames'

import style from '../styles/index.module.scss'

interface IProp {
  children: ReactNode
  theme?: 'body' | 'head'
  view?: 'thead' | 'tbody'
}

/**
 * * Вью для отрисовки обертки (tbody) строк
 * @view
 */
const WrapRow: FC<IProp> = ({ children, theme, view: Tag = 'tbody' }) => {
  return (
    <Tag
      className={cn(style.table__wrap_row, {
        [style[`table__wrap_row--${theme ?? 'body'}`]]: Boolean(theme)
      })}
    >{children}
    </Tag>
  )
}

export default WrapRow
