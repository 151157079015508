import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'

import { isUndefined } from '@/utils'

import { PROBLEM_EDIT_TYPE } from '@/apps/Modals/const'

import { IActionButtons } from '../interfaces'

import style from '../style/index.module.scss'

const buttonVariant = {
  create: ({ disabled, intl }) => (
    <Button
      className={style.problem__btn}
      color='black'
      type='submit'
      disabled={disabled}
    >
      <Icon
        className={style.problem__btn_icon}
        src='plus'
        size='medium'
      />
      {intl.formatMessage({ id: 'common.add', defaultMessage: 'Добавить' })}
    </Button>
  ),
  edit: ({
    disabled,
    handleCancel,
    intl
  }) => {
    return (
      <>
        <Button
          className={style.problem__btn}
          color='gray'
          onClick={handleCancel}
        >
          {intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Отмена' })}
        </Button>
        <Button
          className={style.problem__btn}
          color='black'
          type='submit'
          disabled={disabled}
        >
          {intl.formatMessage({ id: 'common.save', defaultMessage: 'Сохранить' })}
        </Button>
      </>
    )
  }
}

/**
 * * вью текущих кнопок в модалке создания/редактирования проблемы
 * @view
 * @param id
 * @returns
 */
const ActionButtons: FC<IActionButtons> = ({
  id,
  disabled,
  handleCancel
}) => {
  const status =
    !isUndefined(id)
      ? PROBLEM_EDIT_TYPE.EDIT
      : PROBLEM_EDIT_TYPE.CREATE
  const CurrentVariant = buttonVariant[status]
  const intl = useIntl()

  return (
    <div className={style.problem__footer}>
      <CurrentVariant
        intl={intl}
        disabled={disabled}
        handleCancel={handleCancel}
      />
    </div>
  )
}

export default React.memo(ActionButtons)
