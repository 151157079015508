import React, { useRef } from 'react'

import UserTableTab from '@/apps/RightSidebar/views/UserTableTab'

import { useLazyFetch, useStores } from '@/hooks'

import { isNull } from '@/utils'
import {
  DEFAULT_ERROR_ARRAY,
  MODAL_TYPES,
  TABLE_WRAPPER_ICON_SRC,
  TABLE_WRAPPER_TYPE,
} from '@/const'

interface IProps {
  groupId: number
}
/**
 * @component
 * * Компонент таблицы привязвнных юзеров к группе пользоватедлей
 */
const Users: React.FC<IProps> = ({ groupId }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { api, modalStore, rightSideBarStore } = useStores()

  const {
    handleFetch,
    params,
    isLoading,
    error = DEFAULT_ERROR_ARRAY,
    lazyLoadedData,
    tableScroll,
    isScrollEnd,
    refetch: refetchUsersTiedList,
  } = useLazyFetch(
    containerRef,
    async (params) =>
      await api.groupUsers.getTiedUsers(groupId, params),
  )
  /**
   * * Обновление всех моделей связанных с привязанными юзерами
   * @function refetchAllModels
   */
  const refetchAllModels = () => {
    refetchUsersTiedList()
    if (!isNull(rightSideBarStore.contentProps)) {
      // метод для обновления списка груп пользователей
      rightSideBarStore.contentProps?.refetch()
    }
  }

  const { search } = params

  const handleOpenBindUsersToGroupModal = () => {
    modalStore.open(MODAL_TYPES.USER_GROUP_BIND_USER, {
      groupId,
      refetch: refetchAllModels,
    })
  }

  const handleUnbindUserInGroupUser = async (
    idUser: number,
  ) => await api.groupUsers.unbindUser(groupId, idUser)

  const searchPanelProps = {
    handleParams: handleFetch,
    handleOpen: handleOpenBindUsersToGroupModal,
    dataLength: lazyLoadedData?.length as number,
    search,
  }

  const tableWrapProps = {
    search,
    error,
    length: lazyLoadedData?.length,
    handleClick: handleOpenBindUsersToGroupModal,
    iconSrc: TABLE_WRAPPER_ICON_SRC.USER,
    type: TABLE_WRAPPER_TYPE.USER_GROUP_USER_TAB,
  }

  return (
    <UserTableTab
      isScrollEnd={isScrollEnd}
      searchPanelProps={searchPanelProps}
      tableWrapProps={tableWrapProps}
      containerRef={containerRef}
      tableScroll={tableScroll}
      lazyLoadedData={lazyLoadedData}
      refetch={refetchAllModels}
      isLoading={isLoading}
      handleUnbindUser={handleUnbindUserInGroupUser}
    />
  )
}

export default Users
