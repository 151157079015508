import React, { useState } from 'react'

import {
  Tab,
  TabGroup,
  TabPanel,
} from '@/components/TabGroup'
import useTranslate, {
  TMessage,
} from '@/hooks/useTranslate'

import styles from './styles/index.module.scss'

interface IProps<T> {
  panels: Array<{
    idPanel: number
    name: T
    Component: React.FC
  }>
  tabs: Array<{
    idTab: number
    title: TMessage
    panel: T
  }>
  firstTab?: T
  propsToComps?: any
}

const TabGenerator = <T extends string>({
  panels,
  tabs,
  firstTab,
  propsToComps,
}: IProps<T>): React.ReactElement => {
  const [activeTab, setActiveTab] = useState(
    firstTab || tabs.at(0)?.panel,
  )
  const { translate } = useTranslate()

  return (
    <TabGroup className={styles['tab-group']}>
      {tabs.map(({ idTab, title, panel }) => {
        const isActiveTab = panel === activeTab
        const handleChangeTab = (): void => {
          setActiveTab(panel)
        }
        return (
          <Tab
            onClick={handleChangeTab}
            active={isActiveTab}
            key={idTab}
            panel={panel}
            className={styles['tab-group__tab']}
          >
            {translate(title)}
          </Tab>
        )
      })}
      {panels.map(({ idPanel, name, Component }) => {
        const isActivePanel = activeTab === name
        return (
          <TabPanel
            key={idPanel}
            name={name}
            active={isActivePanel}
            className={styles['tab-group__panel']}
          >
            {isActivePanel && (
              <Component {...propsToComps} />
            )}
          </TabPanel>
        )
      })}
    </TabGroup>
  )
}

export default TabGenerator
