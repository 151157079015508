import * as yup from 'yup'
import { INTL_DEFAULT_MESSAGE } from '@/const'
import { REGEX } from '../validation'

const StorageEditSheme = yup.object().shape({
  serial_number: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  storage_url: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
    .matches(REGEX.url, {
      message: INTL_DEFAULT_MESSAGE.UNCORRECT_URL
    }),
  title: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
})

const secretKey = {
  storage_secret_key: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
}

const StorageCreateShema = StorageEditSheme.concat(
  yup.object().shape(secretKey)
)

export { StorageEditSheme, StorageCreateShema }
