import React from 'react'

import MainLayout from '@/components/mainLayout/MainLayout'
import CenterLayout from '@/apps/Integration/views/CenterLayout'
import Integration from '@/apps/Integration/components'

const AccessControlPage = (): React.ReactElement => {
  return (
    <MainLayout>
      <CenterLayout>
        <Integration />
      </CenterLayout>
    </MainLayout>
  )
}

export default AccessControlPage
