import React from 'react'

import { useIntl } from 'react-intl'

import styles from './style/index.module.scss'

interface IProps {
  countCells: number | null
}

/**
 * @component Succes
 * * компонент таблицы Шкафов. Реализует интерфес для Succes статуса шкафа
 * @param countCells    колл ячеек
 */

const Succes: React.FC<IProps> = ({ countCells }) => {
  const intl = useIntl()
  return (
    <>
      {countCells !== null ? (
        <span
          className={
            styles['storage-status__success_count']
          }
        >
          {countCells}
        </span>
      ) : null}
      <p className={styles['storage-status__success_cell']}>
        {intl.formatMessage({
          id: 'storage.cells',
          defaultMessage: 'cells',
        })}
      </p>
    </>
  )
}

export default Succes
