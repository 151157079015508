import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useStores } from '@/hooks'

import { TUser } from '@/api/interfaces'
import { IRowData, TOnRow } from '@/apps/Table/interfaces'
import { tableStyles } from '../const'
import { DEFAULT_USER_STORAGE_TABLE_FIELDS } from '@/apps/StorageDetails/const'
import {
  DEFAULT_ERROR_ARRAY,
  EMPTY_CONSTS,
  TABLE_PAGE,
  TABLE_WRAPPER_ICON_SRC,
} from '@/const'
import { RIGHT_SIDEBAR_TYPES } from '@/apps/RightSidebar/const'

import Table from '@/apps/Table/component/Table'
import * as UserComp from './UserTableComponent'
import { RenderSortButton } from '@/components/SortButton/RenderSortButton'
import Pagination from '@/components/Pagination'
import TableWrapper from '@/views/TableWrapper'

import { IUserTable } from '../interfaces'

import styles from '../style/index.module.scss'

/**
 * @component UserTable
 * * компонент таблицы пользователей
 *
 * @param users               массив пользователей
 * @param params              текущие query параметры
 * @param handleParams        установить дополнительные параметры к текущим query параметрам
 * @param isLoading           статус загрузки данных с сервера
 * @function refetch          функция для обновления данных
 * @param currentPage         номер текущей страницы
 * @function handlePage       функция меняющая номер страницы
 * @param total               общее количество оборудований
 * @param error               ошибки, полученные при запросе пользователей
 */
const UserTable: FC<IUserTable> = ({
  params,
  handleParams,
  users = EMPTY_CONSTS.ARR,
  isLoading = EMPTY_CONSTS.FALSE,
  currentPage,
  handlePage,
  total,
  error = DEFAULT_ERROR_ARRAY,
  refetch,
}) => {
  const intl = useIntl()
  const { rightSideBarStore } = useStores()
  const { ordering, search } = params

  /**
   * * метод для открытя модалки правого сайдбара пользователя
   * @func handleOpenUserSideBar
   */
  const handleOpenUserSideBar = (
    userData: IRowData,
  ): void => {
    rightSideBarStore.open(
      RIGHT_SIDEBAR_TYPES.USER_SIDEBAR,
      { id: userData.id, refetch },
    )
  }

  const onRow: TOnRow = (rowData) => ({
    onClick: () => handleOpenUserSideBar(rowData),
  })

  const columns = [
    {
      key: DEFAULT_USER_STORAGE_TABLE_FIELDS.FULL_NAME,
      contentHeader: intl.formatMessage({
        id: 'user.fullname',
        defaultMessage: 'ФИО',
      }),
      headerCellProps: {
        className: styles.header__fio,
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            'full_name',
          ),
      },
      bodyCellProps: { render: UserComp.FullName },
    },
    {
      key: DEFAULT_USER_STORAGE_TABLE_FIELDS.EMAIL,
      contentHeader: intl.formatMessage({
        id: 'user.email',
        defaultMessage: 'Почта',
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            'email',
          ),
      },
    },
    {
      key: DEFAULT_USER_STORAGE_TABLE_FIELDS.USER_ROLE,
      contentHeader: intl.formatMessage({
        id: 'user.role',
        defaultMessage: 'Роль',
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            'user_role',
          ),
      },
      bodyCellProps: { render: UserComp.UserRole },
    },
    {
      key: DEFAULT_USER_STORAGE_TABLE_FIELDS.EQUIPMENT_STATUS,
      contentHeader: intl.formatMessage({
        id: 'user.equipment',
        defaultMessage: 'Привязка к оборудованию',
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            'equipment_status',
          ),
      },
      bodyCellProps: { render: UserComp.EquipStatus },
    },
    {
      key: DEFAULT_USER_STORAGE_TABLE_FIELDS.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (_: number, user: TUser) =>
          UserComp.UserId(user, refetch),
      },
    },
  ]

  return (
    <TableWrapper
      iconSrc={TABLE_WRAPPER_ICON_SRC.USER}
      search={search}
      isLoading={isLoading}
      type={TABLE_PAGE.USER}
      error={error}
      length={users.length}
    >
      <div className={styles.container__table}>
        <div className={styles.container__table__content}>
          <Table
            onRow={onRow}
            columns={columns}
            rowsData={users}
            styles={tableStyles}
          />
        </div>
        <Pagination
          className={styles.container__pagination}
          currentPage={currentPage}
          handleChange={handlePage}
          total={total}
          pageSize={params.page_size}
        />
      </div>
    </TableWrapper>
  )
}

export default UserTable
