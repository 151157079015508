import React from 'react'

import StoragesBind from '../Core'
import { useStores } from '@/hooks'
import { IModal } from '@/apps/Modals/ModalContent/interfaces'
import { IUserQueryParams } from '@/api/interfaces'

const UserStoragesBind: React.FC<IModal> = ({
  closeModal,
  modalProps,
}) => {
  const { id, refetch } = modalProps
  const { api } = useStores()

  const handleFetchStorages = (
    params: IUserQueryParams,
  ) => {
    return api.user.getStoragesNotTiedToUser(id, params)
  }

  const handleBindStorages = (idList: number[]) => {
    return api.user.tieStorageToUser(id, {
      storage_ids: idList,
    })
  }

  return (
    <StoragesBind
      handleFetchStorages={handleFetchStorages}
      handleBindStorages={handleBindStorages}
      closeModal={closeModal}
      refetch={refetch}
    />
  )
}

export default UserStoragesBind
