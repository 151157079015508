import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import cn from 'classnames'
import { isNull } from '@/utils'
import {
  timeAutoClose,
  timeClose,
} from '@/apps/Toast/const'
import { EMPTY_CONSTS } from '@/const'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import styles from './styles/index.module.scss'

export interface INotificationProps {
  variant?: string
  autoClose?: boolean
  onClose: () => void
  closing?: boolean
  children?: React.ReactNode
  showCloseBtn?: boolean
}

/**
 * Контейнер для уведомлений если такой контейнер есть вовращаем  если нет то создаем и возвращаем
 */
export const createContainer = (): HTMLElement => {
  const portalId = 'notifyContainer'
  let element = document.getElementById(portalId)

  if (!isNull(element)) {
    return element
  }

  element = document.createElement('div')
  element.setAttribute('id', portalId)
  element.className = styles.container
  document.body.appendChild(element)
  return element
}

const Notification: React.FC<INotificationProps> = ({
  variant = EMPTY_CONSTS.STR,
  onClose,
  autoClose = EMPTY_CONSTS.FALSE,
  closing = EMPTY_CONSTS.FALSE,
  showCloseBtn = EMPTY_CONSTS.TRUE,
  children,
}) => {
  const [isClosing, setIsClosing] = React.useState(closing)

  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onClose, timeClose)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [isClosing, onClose])

  useEffect(() => {
    setIsClosing(closing)
  }, [closing])

  useEffect(() => {
    if (autoClose) {
      const timeoutId = setTimeout(
        () => setIsClosing(true),
        timeAutoClose,
      )

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [autoClose])

  const handleClose = (): void => setIsClosing(true)

  return createPortal(
    <div
      className={cn([
        styles.notification__container,
        {
          [styles['notification__container--shrink']]:
            isClosing,
        },
      ])}
    >
      <div
        className={cn([
          styles.notification,
          { [styles[variant]]: Boolean(variant) },
          { [styles['slide-in']]: !isClosing },
          { [styles['slide-out']]: isClosing },
        ])}
      >
        {children}
        {showCloseBtn && (
          <Button
            className={styles['close-button']}
            onClick={handleClose}
            variant="text"
            color="gray"
          >
            <Icon src="close" />
          </Button>
        )}
      </div>
    </div>,
    createContainer(),
  )
}

export default Notification
