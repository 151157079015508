import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { IntlShape } from 'react-intl'

import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import Icon from '@/components/Icon'

import { useStores, useMutation } from '@/hooks'

import { ErrorStatusIcon } from '@/assets/icon'
import { MODAL_CUSTOM_CONTENT, MODAL_TYPES } from '@/const'

import style from './style/index.module.scss'

interface IProp {
  intl: IntlShape
}
/**
 * @view
 * * вью отражающий текущий статус шкафа на стр его деталки
 */
export const Error = ({
  intl,
}: IProp): React.ReactElement => {
  const { currentStorageStore, modalStore, api } =
    useStores()
  const location = useLocation()
  const { id } = useMemo(
    () => qs.parse(location.search),
    [location.search],
  )
  const storageId = Number(id)

  const { mutation: handleStartForcedSync, isLoading } =
    useMutation({
      mutation: async () =>
        await api.storage.forcedSyncStorage(storageId),
      onSuccess: () => {
        void currentStorageStore.updateStorage(storageId)
      },
      onError: () => {
        modalStore.open(
          MODAL_TYPES.CUSTOM,
          MODAL_CUSTOM_CONTENT.ERROR,
        )
      },
    })

  return (
    <>
      <img
        src={ErrorStatusIcon}
        height="200"
        width="200"
        loading="lazy"
        alt="Error"
        className={style.status__icon}
      />
      <Typography
        className={style.status__title}
        size={24}
        weight={700}
      >
        {intl.formatMessage({
          id: 'connected.error',
          defaultMessage: 'Ошибка подключения',
        })}
      </Typography>
      <Button
        onClick={handleStartForcedSync}
        disabled={isLoading}
        className={style.error__button}
        color="black"
      >
        <Icon src="synchronization" />
        {intl.formatMessage({
          id: 'storage.reconnection',
          defaultMessage: 'Reconnection',
        })}
      </Button>
    </>
  )
}
