import React, { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'

import { useTranslate } from '@/hooks'

import Radio from '@/components/Radio'
import { Input } from '@/views/input'
import { ISelectValue, Select } from '@/views/select'
import { Typography } from '@/views/typography'

import {
  ACCOUNT_TYPE,
  USER_EDIT_CORE_FIELDS,
  USER_EDIT_RADIO_FIELDS,
  USER_EDIT_SELECT_FIELDS
} from '@/const'

import { ICoreUserFields } from '../interfaces'
import style from '../style/index.module.scss'

/**
 * * основной компонент с полями для создания/редактирования пользователя
 * @component CoreFields
 *
 * @param formikTools             компонент formik
 */
const CoreFields: FC<ICoreUserFields> = ({
  formikTools
}) => {
  const {
    values,
    handleChange,
    touched,
    errors,
    setErrors
  } = formikTools
  const haveRequiredEmail =
    values.account_type === ACCOUNT_TYPE.EMPLOYEE

  const intl = useIntl()
  const { translate } = useTranslate()
  const userRoleValue = USER_EDIT_SELECT_FIELDS.find(
    (o) => o.value === values.user_role
  )

  const handleValuesChange = (
    e: React.ChangeEvent<any> | GxInputCustomEvent<any>
  ): void => {
    setErrors({})
    handleChange(e)
  }

  const handleSelectChange = ({
    value
  }: ISelectValue): void => {
    setErrors({})
    handleChange({ target: { name: 'user_role', value } })
  }

  return (
    <div className={style['user-edit__fields']}>
      <div className={style['user-edit__fields_ac-type']}>
        <FormattedMessage
          id='user.account_type.title'
          defaultMessage='Тип учетной записи'
        />
      </div>
      <div>
        {USER_EDIT_RADIO_FIELDS.map(
          ({ id, defaultMessage, value, name }) => {
            return (
              <Radio
                key={id}
                value={value}
                name={name}
                onGx-change={handleValuesChange}
                checked={values.account_type === value}
              >
                <Typography
                  size={14}
                  weight={400}
                  className={
                    style['user-edit__fields__radio']
                  }
                >
                  <FormattedMessage
                    id={id}
                    defaultMessage={defaultMessage}
                  />
                </Typography>
              </Radio>
            )
          }
        )}
      </div>
      <Select
        label={intl.formatMessage({
          id: 'user.role',
          defaultMessage: 'Роль'
        })}
        onChange={handleSelectChange}
        name='user_role'
        value={userRoleValue}
        options={USER_EDIT_SELECT_FIELDS}
      />
      {USER_EDIT_CORE_FIELDS.map(
        ({ id, label, name, required }) => {
          return (
            <Input
              key={id}
              name={name}
              label={translate(label)}
              value={values[name]}
              onChange={handleValuesChange}
              error={Boolean(touched[name]) && Boolean(errors[name])}
              errorMessage={errors[name]}
              outerStyles={style['user-edit__input']}
              required={required}
            />
          )
        }
      )}
      <Input
        name='email'
        label={translate({
          id: 'user.field.email',
          defaultMessage: 'Почта'
        })}
        value={values.email}
        onChange={handleValuesChange}
        error={Boolean(touched.email) && Boolean(errors.email)}
        errorMessage={errors.email}
        outerStyles={style['user-edit__input']}
        required={haveRequiredEmail}
      />
      <Input
        name='card_hid_number'
        label={translate({
          id: 'user.field.card_hid_number',
          defaultMessage: 'HID номера карты'
        })}
        value={values.card_hid_number}
        onChange={handleValuesChange}
        error={
          Boolean(touched.card_hid_number) &&
          Boolean(errors.card_hid_number)
        }
        errorMessage={errors.card_hid_number}
        outerStyles={style['user-edit__input']}
        required
      />
    </div>
  )
}

export default CoreFields
