import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'

import { Select } from '@/views/select'
import AsyncSelect from '@/components/AsyncSelect/AsyncSelect'

import { useStores } from '@/hooks'

import {
  EMPTY_CONSTS,
  FILTER_KEY,
  USER_EQUIPMENT_STATUS_FIELDS,
  USER_ROLE_FIELDS
} from '@/const'
import { IFilterFields } from '../interfaces'
import { IValue, TAsyncSelectHandleChange } from '@/components/AsyncSelect/interfaces'

/**
 * * основной компонент с полями для фильтра пользователей
 * @component UserFilterFields
 *
 * @param  currentFilter           текущие параметры фильтра
 * @param  handleParamsChange      функция для изменения текущих параметров
 * @param  setError                функция, задающая ошибку
 */
const UserFilterFields: FC<IFilterFields> = ({
  currentFilter,
  handleParamsChange,
  handleFetchError,
  storage
}) => {
  const {
    equipment_status: status,
    user_role: userRole
  } = currentFilter
  const intl = useIntl()
  const { api } = useStores()
  const defaultValue = intl.formatMessage({ id: 'select', defaultMessage: 'Выберите' })
  const userEquipInitStatus = USER_EQUIPMENT_STATUS_FIELDS.find(o => o.value === status?.value)
  const userInitRole = USER_ROLE_FIELDS.find(o => o.value === userRole?.value)
  const [currentStorage, setCurrentStorage] = useState<IValue | undefined>(storage)

  const handleStorageChange: TAsyncSelectHandleChange = (el) => {
    const storage = el ?? EMPTY_CONSTS.UNDEFINED
    handleFetchError(EMPTY_CONSTS.NULL)
    setCurrentStorage(storage)
    handleParamsChange(FILTER_KEY.STORAGE_ID, el)
  }

  const handleRoleChange: TAsyncSelectHandleChange = (el) => {
    handleParamsChange(FILTER_KEY.USER_ROLE, el)
  }

  const handleStatusChange: TAsyncSelectHandleChange = (el) => {
    handleParamsChange(FILTER_KEY.EQUIPMENT_STATUS, el)
  }

  return (
    <>
      <AsyncSelect
        handleChange={handleStorageChange}
        loadFunction={api.storage.getStorages}
        value={currentStorage}
        label={intl.formatMessage({
          id: 'storage',
          defaultMessage: 'Шкаф'
        })}
        placeholder={defaultValue}
        handleFetchError={handleFetchError}
      />
      <Select
        label={intl.formatMessage({
          id: 'filter.user_role',
          defaultMessage: 'Роль пользователя'
        })}
        onChange={handleRoleChange}
        value={userInitRole}
        options={USER_ROLE_FIELDS}
        placeholder={defaultValue}
      />
      <Select
        label={intl.formatMessage({
          id: 'filter.availability',
          defaultMessage: 'Наличие на руках'
        })}
        onChange={handleStatusChange}
        value={userEquipInitStatus}
        options={USER_EQUIPMENT_STATUS_FIELDS}
        placeholder={defaultValue}
      />
    </>
  )
}

export default UserFilterFields
