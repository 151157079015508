import useDebounced from '@/hooks/useDebounced'
import { useEffect } from 'react'

const CustomVh = (): null => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  const handlerResizable = (): void => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
  const debouncedResizable = useDebounced(handlerResizable, 250)

  useEffect(() => {
    window.addEventListener('resize', debouncedResizable)
    return () => {
      window.removeEventListener('resize', debouncedResizable)
    }
  }, [])

  return null
}

export default CustomVh
