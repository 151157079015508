import React, { useEffect } from 'react'
import { FormikHelpers, useFormik } from 'formik'
import { usePage, useStores } from '@/hooks'
import useFetch from '@/hooks/useFetch'
import {
  EMPTY_CONSTS,
  EMPTY_USER,
  PAGE_TYPE
} from '@/const'
import { UserEditSheme } from '@/utils/ValidateSchemes'
import {
  IUserCreateValue,
  IUserGetValue
} from '@/api/interfaces'

import UserEditView from '../../views/UserEditView'
import { IUserEdit } from '../interfaces'
import { isNull, isUndefined } from '@/utils'

/**
 * * компонент создания - редактирования пользователя
 * @component
 *
 * @param modalProps - пропсмы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const UserEdit: React.FC<IUserEdit> = ({
  modalProps,
  closeModal
}): React.ReactElement => {
  const {
    api,
    currentStorageStore: { storage }
  } = useStores()
  const { page } = usePage()
  const { id, refetch } = modalProps

  /**
   * @func getInitialValue
   * * функция получения данных о изменяемой модалки если она есть
   * @param id - id текущего пользователя
   */
  const getInitialValue = (
    id?: number
  ): Promise<IUserGetValue> | undefined => {
    if (id !== undefined) {
      return api.user.getUser(id)
    }
  }

  const {
    data: defaultValues,
    error,
    handleFetchError
  } = useFetch(async () => await getInitialValue(id))
  /**
   * Привязка нового пользователя
   * если мы создали его в деталке шкафа
   * @function bindUserToStorage
   * @param idUser
   */
  const bindUserToStorage = async (
    idUser: number
  ): Promise<void> => {
    if (
      page.page_model === PAGE_TYPE.storagePage &&
      !isNull(storage)
    ) {
      await api.storage.bindUserToStorage(storage.id, {
        users: [idUser]
      })
    }
  }

  /**
   * @func onSubmit
   * * функция реализующая логику создание/редактирования пользователя
   * @param value - вводимые данные поьзователя
   */
  const onSubmit = async (
    value: IUserCreateValue,
    action: FormikHelpers<IUserCreateValue>
  ): Promise<void> => {
    try {
      if (!isUndefined(id)) {
        await api.user.changeUser(id, value)
      } else {
        const newUser = await api.user.createUser(value)
        await bindUserToStorage(newUser.id)
      }
      closeModal()
      refetch()
    } catch (err) {
      handleFetchError(err)
    } finally {
      action.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues:
      defaultValues !== undefined
        ? defaultValues
        : EMPTY_USER,
    validationSchema: UserEditSheme,
    enableReinitialize: true,
    onSubmit: (value, action) => {
      void onSubmit(value, action)
    }
  })

  useEffect(() => {
    handleFetchError(EMPTY_CONSTS.NULL)
  }, [formik.values])

  return (
    <UserEditView
      formikTools={formik}
      closeModal={closeModal}
      projectId={id}
      error={error}
    />
  )
}

export default UserEdit
