import React, { FC } from 'react'

import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'
import Badge from '@/components/Badge'
import { IEditStatus } from './interfaces'
import { USER_EDIT_TYPE } from '../../const'
import style from './style/index.module.scss'

const UserBadgeVariant = {
  create: () => (
    <div className={style['user-status__identifier']}>
      <Icon
        className={style['user-status__icon']}
        src='user'
        size='xl'
      />
    </div>
  ),
  edit: ({ title }) => (
    <Badge size='medium' title={title} />
  )
}

/**
 * * вью для реализаци хедера модалки создания/редактирования пользователя
 * @view
 *
 * @param projectId                id текущего шкафа
 */
const EditStatus: FC<IEditStatus> = ({
  projectId,
  closeModal,
  title = ''
}) => {
  const intl = useIntl()
  const isNewUser = projectId === undefined
  const status = isNewUser
    ? USER_EDIT_TYPE.CREATE
    : USER_EDIT_TYPE.EDIT
  const CurrentVariant = UserBadgeVariant[status]
  const titleText = isNewUser
    ? {
        id: 'user.new',
        defaultMessage: 'Новый пользователь'
      }
    : {
        id: 'user.edit',
        defaultMessage: 'Редактировать пользователя'
      }

  return (
    <div className={style['user-status']}>
      <CurrentVariant title={title} />
      <Typography
        size={32}
        weight={700}
        className={style['user-status__title']}
      >
        {intl.formatMessage(titleText)}
      </Typography>
      <Icon
        src='close'
        className={style['user-status__icon_close']}
        onClick={closeModal}
      />
    </div>
  )
}

export default EditStatus
