import React from 'react'

import EquipmentFilterFields from './EquipmentFilterFields'
import StorageFilterFields from './StorageFilterFields'
import UserFilterFields from './UserFilterFields'
import StorageUserFilterField from './StorageUserFilterField'

/**
 * * основной компонент с полями для разных типов фильтров
 * @component CoreFields
 */
const SelectFields = {
  equipment: ({ currentFilter, handleParamsChange, isStorageEquipment, handleFetchError, storage }) => (
    <EquipmentFilterFields
      currentFilter={currentFilter}
      handleParamsChange={handleParamsChange}
      isStorageEquipment={isStorageEquipment}
      handleFetchError={handleFetchError}
      storage={storage}
    />
  ),
  storage: ({ currentFilter, handleParamsChange }) => (
    <StorageFilterFields
      currentFilter={currentFilter}
      handleParamsChange={handleParamsChange}
    />
  ),
  user: ({ currentFilter, handleParamsChange, handleFetchError, storage }) => (
    <UserFilterFields
      currentFilter={currentFilter}
      handleParamsChange={handleParamsChange}
      handleFetchError={handleFetchError}
      storage={storage}
    />
  ),
  storageUser: ({ currentFilter, handleParamsChange, handleFetchError, storage }) => (
    <StorageUserFilterField
      currentFilter={currentFilter}
      handleParamsChange={handleParamsChange}
      handleFetchError={handleFetchError}
      storage={storage}
    />
  )
}

export default SelectFields
