import React from "react"

import styles from './styles/index.module.scss'

interface IProps {
  children: React.ReactNode
}

const ContentWrap: React.FC<IProps> = ({children}) => {
  return (
    <div className={styles.content}>
      {children}
    </div>
  )
}

export default ContentWrap