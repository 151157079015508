import React from 'react'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'

import SearchInput from '@/views/SearchInput'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'

import { useStores } from '@/hooks'

import { IGroupUserQueryParams } from '@/api/interfaces/GroupUsers'
import { MODAL_TYPES } from '@/const'

import styles from './styles/index.module.scss'

interface IProps {
  handleParams: (param: IGroupUserQueryParams) => void
  refetch: () => void
}

const GroupPanel: React.FC<IProps> = ({
  handleParams,
  refetch,
}) => {
  const intl = useIntl()
  const { modalStore } = useStores()

  const handleOpenGropCreateModal = () => {
    modalStore.open(MODAL_TYPES.USER_GROUP_EDIT, {
      refetch,
    })
  }

  return (
    <div className={styles.panel}>
      <SearchInput handleParams={handleParams} size={44} />
      <Button
        height={44}
        onClick={handleOpenGropCreateModal}
        className={styles.panel__btn}
        color="black"
      >
        <Icon src="plus" />
        {intl.formatMessage({
          id: 'button.add_group',
          defaultMessage: 'Добавить группу',
        })}
      </Button>
    </div>
  )
}

export default observer(GroupPanel)
