import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { GetOptionValue } from 'react-select'
import { useFormikContext } from 'formik'
import { isNull } from '@/utils'
import { usePaginateSelect } from '@/apps/Modals/hook'
import { usePage, useStores } from '@/hooks'
import { IEquipment } from '@/api/interfaces'
import { ADDITIONAL, EMPTY_CONSTS, PAGE_TYPE } from '@/const'
import { EQUIPMENT_EDIT_FIELDS } from '@/apps/Modals/const'
import { IOptions } from '@/apps/Modals/ModalContent/interfaces'
import PaginateSelect from '@/components/AsyncSelect/PaginateSelect'

/**
 * Селект выбора шкафа
 * @component
 *
 */
const StorageSelect: React.FC = () => {
  const {
    api,
    currentStorageStore: { storage }
  } = useStores()
  const { loadOptions } = usePaginateSelect(api.storage.getStorages)
  const intl = useIntl()
  const { page } = usePage()
  const { setFieldValue, values } = useFormikContext<IEquipment>()
  /**
   * * подставляем в селект шкаф еслм мы в деталке шкафа
   */
  const haveInitialStorage =
    page.page_model === PAGE_TYPE.storagePage && !isNull(storage)

  const [value, setValue] = useState<IOptions | null>(() => {
    if (haveInitialStorage) {
      const initData = {
        id: String(storage.id),
        label: storage.title
      }
      setFieldValue(EQUIPMENT_EDIT_FIELDS.STORAGE, initData)
      return initData
    }
    if (isNull(values.storage)) {
      return null
    }
    return {
      id: String(values.storage.id),
      label: values.storage.title
    }
  })
  const handleChange = (option: IOptions): void => {
    setValue(option)
    void setFieldValue(EQUIPMENT_EDIT_FIELDS.STORAGE, {
      id: Number(option.id),
      title: option.label
    })
  }

  const getOptionValue: GetOptionValue<IOptions> = (option) => option.id
  const getOptionLabel: GetOptionValue<IOptions> = (option) => option.label

  const messages = {
    label: intl.formatMessage({
      id: 'storage',
      defaultMessage: 'Шкаф'
    }),
    placeholder: intl.formatMessage({
      id: 'select',
      defaultMessage: 'Выберите'
    })
  }
  return (
    <PaginateSelect
      name={EQUIPMENT_EDIT_FIELDS.STORAGE}
      value={value}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      loadOptions={loadOptions}
      onChange={handleChange}
      label={messages.label}
      placeholder={messages.placeholder}
      disabled={Boolean(values.cell_number)}
      isSearchable={EMPTY_CONSTS.TRUE}
      additional={ADDITIONAL.PAGE}
    />
  )
}

export default StorageSelect
