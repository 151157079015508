import { TOptionsSerializer } from '@/apps/Modals/ModalContent/interfaces'
import { IEquipmentCreate, IEquipmentEdit, IProblemCreateValue, IProblemEdit } from '@/api/interfaces'
import { isNull } from '@/utils'
import { EMPTY_CONSTS } from '@/const'

export const equipmentEditSerializer = (values: IEquipmentEdit): IEquipmentCreate => {
  return {
    title: values.title,
    inventory_number: values.inventory_number,
    serial_number: values.serial_number,
    description: values.description,
    label_number: values.label_number,
    user_ids: values.user_ids,
    storage_id: !isNull(values.storage) ? values.storage.id : EMPTY_CONSTS.UNDEFINED,
    equipment_type: values.equipment_type
  }
}

export const problemEditSerializer = (values: IProblemEdit): IProblemCreateValue => {
  return {
    title: values.title,
    equipment_types: values.equipment_types,
    type: values.type
  }
}

/**
 * Метод для формирования данных для селект опций
 * @constructor optionsSerializer
 *
 * @param next - следующая страница
 * @param options - опции
 * @param additional - дополнительные свойста. в нашем случае для получения страницы
 */
export const optionsSerializer: TOptionsSerializer = ({
  additional,
  next,
  options
}) => {
  return {
    options: options.map((el) => ({
      id: String(el.id),
      label: el.title
    })),
    hasMore: Boolean(next),
    additional:
      additional !== undefined
        ? { page: additional.page + 1 }
        : undefined
  }
}
