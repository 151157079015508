import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { GxForm, GxIcon } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'

import ErrorsBlock from '@/components/ErrorsBlock'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import { Input } from '@/views/input'
import { ArrowBackIcon } from '@/assets/icon'

import {
  CONFIRM_CODE_MAX_LENGTH,
  CONFIRM_CODE_PLACEHOLDER,
  EMPTY_CONSTS
} from '@/const'

import { ICheckCode } from '../interfaces'

import styles from '../style/index.module.scss'

/**
 * @component CheckCode
 * * компонент формы проверки ввода кода из почты
 * @param {ICheckCode}                 общий интерфейс входных параметров CheckCode
 *
 * @param {function}  handleCodeValue  функция для изменения кода вводимого в инпут
 * @param {function}  onSubmit         функция для отправки проверочного кода на сервер
 * @param {function}  handleBack       функция возврата на предыдущее окно (SendCode)
 * @param {function}  resendCode       функция повторной отправки проверочного кода на почту
 * @param {string}    username         логин (почта) пользователя
 * @param {IError}    error            обьект ошибок
 */
const CheckCode: React.FC<ICheckCode> = ({
  handleCodeValue,
  onSubmit,
  handleBack,
  resendCode,
  username,
  error
}): React.ReactElement => {
  const intl = useIntl()
  return (
    <>
      <GxForm onGx-submit={onSubmit}>
        <div className={styles.container__form}>
          <h1 className={styles.container__title}>
            <GxIcon
              src={ArrowBackIcon}
              slot='icon-left'
              className={styles.container__backicon}
              onClick={handleBack}
            />
            <FormattedMessage id='titles.password_recovery' defaultMessage='Восстановление пароля' />
          </h1>
          <div className={styles.container__subform}>
            <Typography size={24} className={styles.container__subtitle}>
              {
                  intl.formatMessage({
                    id: 'recovery.check_code',
                    defaultMessage: 'На электронную почту <b>{username}</b> было отправлено письмо с кодом для восстановления пароля.'
                  }, {
                    b: chunks => <b>{chunks}</b>,
                    username
                  })
                }
            </Typography>
            <Typography size={24} className={styles.container__subtitle}>
              <FormattedMessage
                id='recovery.check_code_comment'
                defaultMessage='Если письмо не пришло во Входящие, проверьте папку Спам.'
              />
            </Typography>
            <Input
              type='text'
              label={intl.formatMessage({
                id: 'recovery.code',
                defaultMessage: 'Код'
              })}
              data-testid='restore_password_confirm_code'
              name='restore_password_confirm_code'
              placeholder={CONFIRM_CODE_PLACEHOLDER}
              maxlength={CONFIRM_CODE_MAX_LENGTH}
              labelStyles={styles.form__field_label}
              outerStyles={classNames(styles.form__field, styles.form__field_input)}
              onBlur={handleCodeValue}
              onChange={handleCodeValue}
              error={Boolean(error?.restore_password_confirm_code)}
            />
            <Button
              onClick={resendCode}
              className={styles.form__button_link}
            >
              <FormattedMessage id='recovery.resend_code' defaultMessage='Отправить письмо повторно' />
            </Button>
          </div>
          <Button
            type='submit'
            color='black'
            className={styles.form__button}
          >
            <FormattedMessage id='button.send' defaultMessage='Отправить' />
          </Button>
          <ErrorsBlock
            networkError={error?.network_error}
            errorsArray={[
              ...(error?.restore_password_confirm_code ?? EMPTY_CONSTS.STR),
              ...(error?.non_field_error ?? EMPTY_CONSTS.STR),
              ...(error?.non_field_errors ?? EMPTY_CONSTS.STR)
            ]}
          />
        </div>
      </GxForm>
    </>
  )
}

export default CheckCode
