import React, { RefObject } from 'react'
import { useIntl } from 'react-intl'
import { useStores } from '@/hooks'

import {
  TLoadOptions,
  IOptions,
} from '@/apps/Modals/ModalContent/interfaces'
import { IHidDefaultValue } from '@/api/interfaces'

import { isUndefined } from '@/utils'
import { ADDITIONAL, EMPTY_CONSTS } from '@/const'

import AsyncSelect from '@/components/AsyncSelect/AsyncSelect'
import Icon from '@/components/Icon'
import PaginateAsyncSelect from '@/components/AsyncSelect/PaginateAsyncSelect'
import BaseContentViews from '../BaseContentViews'
import { Button } from '@/views/button'

import style from './styles/index.module.scss'

/**
 * Вьюха одалки загрузки ексель
 * @view
 *
 * @param handleClose - метод для закрытия модалки
 * @param selectProps - пропсы для селекта
 * @param disabled - disabled для кнопки
 * @param handleLoad - метод для обработки загрузки ексель
 * @param inputKey - ключ для инпута
 * @param inputRef - реф на инпут
 * @param handleAccept - метод на кнопку принять
 * @param [selectProps.handleChange] - метод при изменение в селекте
 * @param [selectProps.loadOptions] - метод для загрузки опций
 * @param [selectProps.error] - значение ошибки
 */

interface ISelectProps {
  onChange: (payload: IOptions[] | IOptions) => void
  loadOptions: TLoadOptions
  error: string
}

interface IHidSelectProps {
  handleChange: (e: IHidDefaultValue | null) => void
  value?: IHidDefaultValue
}

interface IUploadExcelView {
  handleClose: () => void
  selectProps: ISelectProps
  disabled?: boolean
  handleLoad: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void
  inputKey: string
  inputRef: RefObject<HTMLInputElement>
  handleAccept: () => void
  label: string
  hidSelectProps?: IHidSelectProps
}

const UploadExcelView: React.FC<IUploadExcelView> = ({
  selectProps,
  handleClose,
  hidSelectProps,
  ...props
}) => {
  const { api } = useStores()
  const intl = useIntl()
  return (
    <>
      <BaseContentViews.Wrapper>
        <BaseContentViews.Header
          colorIconWrapper="orange"
          iconSlot={
            <Icon size="medium-28" src="download" />
          }
        >
          <div className={style.header}>
            <h2 className={style.header__title}>
              {intl.formatMessage({
                id: 'button.upload',
                defaultMessage: 'Загрузить файл',
              })}
            </h2>
            <p className={style.header__sub_title}>
              {intl.formatMessage({
                id: 'supportedFiles',
                defaultMessage:
                  'Поддерживаемые файлы .xlsx, .xls',
              })}
            </p>
          </div>
        </BaseContentViews.Header>
        <div className={style.container}>
          <PaginateAsyncSelect
            defaultOptions
            getOptionValue={(option: IOptions) => option.id}
            getOptionLabel={(option: IOptions) =>
              option.label
            }
            hideSelectedOptions={false}
            label={props.label}
            placeholder={intl.formatMessage({
              id: 'withoutCupboard',
              defaultMessage: 'Без шкафа',
            })}
            noOptionsText={{
              id: 'noStorage',
              defaultMessage: 'Шкафы отсутствуют',
            }}
            additional={ADDITIONAL.PAGE}
            {...selectProps}
          />
          {!isUndefined(hidSelectProps) && (
            <AsyncSelect
              {...hidSelectProps}
              label={intl.formatMessage({
                id: 'fileFormat',
                defaultMessage: 'Формат файла',
              })}
              isSearchable={false}
              withAllValue={false}
              handleFetchError={EMPTY_CONSTS.FUNC}
              loadFunction={api.user.getHidFormats}
            />
          )}
        </div>
        <BaseContentViews.ButtonWrapper>
          <Button
            className={style.container__button}
            onClick={handleClose}
            height={48}
          >
            {intl.formatMessage({
              id: 'common.cancel',
              defaultMessage: 'Отмена',
            })}
          </Button>
          <Button
            className={style.container__button}
            color="black"
            disabled={props.disabled}
            onClick={props.handleAccept}
            height={48}
          >
            {intl.formatMessage({
              id: 'selectFile',
              defaultMessage: 'Выбрать файл',
            })}
          </Button>
        </BaseContentViews.ButtonWrapper>
        <input
          name="file_GLS"
          type="file"
          ref={props.inputRef}
          key={props.inputKey}
          onChange={props.handleLoad}
          accept=".xlsx, .xls"
          style={{ display: 'none' }}
        />
      </BaseContentViews.Wrapper>
    </>
  )
}

export default UploadExcelView
