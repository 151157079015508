import React from 'react'

import { useStores } from '@/hooks'
import { observer } from 'mobx-react'

import { TLSidebarShowBlock } from '@/services/store/LefsSidebar'
import styles from './style/index.module.scss'

interface IProps {
  children: React.ReactNode
  type: TLSidebarShowBlock
}
/**
 * @view
 * * вью контейнера для левого сайдбара реализующий анимацию появления
 */
const StorageAnimationWrap: React.FC<IProps> = ({ children, type }) => {
  const { leftSidebarStore } = useStores()
  const { isStartClosingAnimation, isOpen, currentShowingBlock } = leftSidebarStore
  return (
    <div
      data-open={isOpen}
      data-animate={isStartClosingAnimation}
      data-show={currentShowingBlock}
      className={styles[`container__${type}`]}
    >
      {children}
    </div>
  )
}

export default observer(StorageAnimationWrap)
