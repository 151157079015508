export const RIGHT_SIDEBAR_TYPES = {
  EMPTY: 'EMPTY',
  USER_SIDEBAR: 'USER_SIDEBAR',
  EQUIPMENT_SIDEBAR: 'EQUIPMENT_SIDEBAR',
  USER_GROUP_SIDEBAR: 'USER_GROUP_SIDEBAR',
}

export const EQUIPMENT_COLUMNS = {
  FULL_NAME: 'full_name',
  USER_ROLE: 'user_role',
  EQUIPMENT_STATUS: 'equipment_status',
  ID: 'id',
}

export const EQUIPMENT_TAB = {
  USERS: 'users',
  PROBLEMS: 'problems',
  HISTORY: 'history',
} as const

export const USER_TAB_KEYS = {
  STORAGES: 'storages',
  EQUIPMENT: 'equipment',
  HISTORY: 'history',
}

export const USER_DETAILS_TAB = [
  {
    idTab: 0,
    panel: USER_TAB_KEYS.STORAGES,
    title: {
      id: 'user.tab.storages',
      defaultMessage: 'Шкафы',
    },
  },
  {
    idTab: 1,
    panel: USER_TAB_KEYS.EQUIPMENT,
    title: {
      id: 'user.tab.equipment',
      defaultMessage: 'Оборудование',
    },
  },
  {
    idTab: 2,
    panel: USER_TAB_KEYS.HISTORY,
    title: {
      id: 'user.tab.history',
      defaultMessage: 'История',
    },
  },
]

export const HISTORY_EQUIPMENT_COLUMN_KEY = {
  FULL_NAME: 'full_name',
  UPDATE_AT: 'update_at',
  CELL_NUMBER: 'cell_number',
  PREVIOUS_STATUS: 'previous_status',
  ACTION: 'action',
  CURRENT_STATUS: 'current_status',
}

export const HISTORY_EQUIPMENT_FILTER_KEY = {
  FULL_NAME: 'user__full_name',
  UPDATE_AT: 'created_at',
  ACTION: 'equipment_action',
  CURRENT_STATYS: 'status',
  CELL_NUMBER: 'cell_number',
}

export const USER_HISTORY_COL = {
  EQUIPMENT_TYPE: 'equipment_type',
  TITLE: 'title',
  ACTION: 'action',
  CELL_NUMBER: 'cell_number',
  DATE: 'date',
}

export const USER_GROUP_PANEL_VARIANTS = {
  USERS: 'users',
  STORAGES: 'storages',
  EQUIPMENTS: 'equipments',
} as const

export const USER_GROUP_TABS = [
  {
    idTab: 0,
    panel: USER_GROUP_PANEL_VARIANTS.USERS,
    title: {
      id: 'users',
      defaultMessage: 'Пользователи',
    },
  },
  {
    idTab: 2,
    panel: USER_GROUP_PANEL_VARIANTS.STORAGES,
    title: {
      id: 'user.tab.storages',
      defaultMessage: 'Шкафы',
    },
  },
  {
    idTab: 1,
    panel: USER_GROUP_PANEL_VARIANTS.EQUIPMENTS,
    title: {
      id: 'common.equipment',
      defaultMessage: 'Оборудование',
    },
  },
]

export const USER_GROUP_USERS_TABLE_KEYS = {
  FULL_NAME: 'full_name',
  USER_ROLE: 'user_role',
  ACCOUNT_TYPE: 'account_type',
  ID: 'id',
}

export const STORAGE_TABLE_KEYS = {
  COUNT_CELLS: 'count_cells',
  TITLE: 'title',
  ID: 'id',
}
