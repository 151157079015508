import React, { FC, useState } from 'react'

import UserStorageUnbindView from '../../views/UserStorageUnbindView'

import { useStores } from '@/hooks'

import { convertErrorToString, isUndefined } from '@/utils'

import { MODAL_CUSTOM_CONTENT, MODAL_TYPES } from '@/const'

import { IUserStorageUnbind } from '../interfaces'

/**
 * @component UserStorageUnbind
 * * компонент с логикой модалки отвязки пользователя от шкафа
 * @param closeModal метод закрытия модалки
 * @param modalProps данные для текущей модалки
 */
const UserStorageUnbind: FC<IUserStorageUnbind> = ({
  closeModal,
  modalProps,
}) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const {
    type,
    storageData,
    userData,
    refetch,
    handleUnbindMethod,
  } = modalProps
  const { modalStore } = useStores()

  /**
   * * метод для отвязывания пользователя от шкафа
   * @func handleSubmit
   */
  const handleSubmit = (): void => {
    setIsDisabled(true)
    void handleUnbindMethod()
      .then(() => {
        refetch()
        closeModal()
      })
      .catch((err) => {
        const error = err?.response?.data?.detail
        if (!isUndefined(error)) {
          modalStore.open(MODAL_TYPES.WARNING_ERROR, {
            error: [error],
          })
        } else {
          const errorMessage = convertErrorToString(err)
          modalStore.open(MODAL_TYPES.CUSTOM, {
            ...MODAL_CUSTOM_CONTENT.ERROR,
            title: errorMessage,
          })
        }
      })
      .finally(() => {
        setIsDisabled(false)
      })
  }

  return (
    <UserStorageUnbindView
      storageData={storageData}
      userData={userData}
      closeModal={closeModal}
      modalType={type}
      handleSubmit={handleSubmit}
      isDisabled={isDisabled}
    />
  )
}

export default UserStorageUnbind
