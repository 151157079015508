import React, { MutableRefObject } from 'react'

import UsersPanel from '../../../components/EquipmentSidebar/UsersPanel'
import ProblemsPanel from '@/apps/RightSidebar/components/EquipmentSidebar/ProblemsPanel'
import HistoryPanel from '@/apps/RightSidebar/components/EquipmentSidebar/HistoryPanel'

import { IEquipment } from '@/api/interfaces'

import { EQUIPMENT_TAB } from '@/apps/RightSidebar/const'

import TabGenerator from '@/views/TabGenerator'

const tabs = [
  {
    panel: EQUIPMENT_TAB.USERS,
    title: {
      id: 'users',
      defaultMessage: 'Пользователи',
    },
    idTab: 0,
  },
  {
    panel: EQUIPMENT_TAB.PROBLEMS,
    title: {
      id: 'problems',
      defaultMessage: 'Проблемы',
    },
    idTab: 1,
  },
  {
    panel: EQUIPMENT_TAB.HISTORY,
    title: {
      id: 'history',
      defaultMessage: 'История',
    },
    idTab: 2,
  },
]

const panels = [
  {
    Component: UsersPanel,
    name: EQUIPMENT_TAB.USERS,
    idPanel: 0,
  },
  {
    Component: ProblemsPanel,
    name: EQUIPMENT_TAB.PROBLEMS,
    idPanel: 1,
  },
  {
    Component: HistoryPanel,
    name: EQUIPMENT_TAB.HISTORY,
    idPanel: 2,
  },
]

interface IEquipmentTabsProps {
  equipmentID: number
  handleEdit: () => void
  equipmentData: IEquipment
  refetchRef: MutableRefObject<() => void>
}

/**
 * * вью табов шкафов (панель переключения
 * между Пользователи, Проблемы и История)
 * @view
 * @param equipmentID   - id оборудования
 * @function handleEdit - редактирование оборудования
 * @param equipmentData - оборудование
 * @param refetchRef    - ref на функцию обновления refetch
 */
const EquipmentTabs: React.FC<IEquipmentTabsProps> = ({
  equipmentID,
  handleEdit,
  equipmentData,
  refetchRef,
}) => {
  return (
    <TabGenerator
      tabs={tabs}
      panels={panels}
      propsToComps={{
        id: equipmentID,
        handleEdit,
        equipmentData,
        refetchRef,
      }}
    />
  )
}

export default EquipmentTabs
