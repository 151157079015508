import React from 'react';

import { useIntl } from 'react-intl';
import classNames from 'classnames';

import {
  isUndefined,
  serializeDifferenceSecond,
  subDateNow,
} from '@/utils';
import { INotify } from '@/api/interfaces';
import Icon from '@/components/Icon';

import { Typography } from '@/views/typography';
import { NOTIFICATION_STATUS } from '@/const';

import styles from './styles/index.module.scss';

type INotificationItem = INotify;

/**
 * Элемент списка уведомлений
 * @view
 */

export const NotificationItem: React.FC<
  INotificationItem
> = (props) => {
  const intl = useIntl();
  const optionsTime = serializeDifferenceSecond(
    subDateNow(props.created_at),
  );
  const time = intl.formatRelativeTime(
    -optionsTime.value,
    optionsTime.unit,
    {
      numeric: 'auto',
    },
  );
  const statusIcon =
    NOTIFICATION_STATUS.SUCCESS === props.label
      ? 'successStatus'
      : 'errorStatus';
  return (
    <div
      className={classNames(styles.item__root, {
        [styles['item__root--active']]: !props.is_read,
      })}
    >
      <div className={styles.item__info}>
        <div className={styles.item__flex}>
          <Icon src={statusIcon} size="large" />
          <div className={styles.item__text}>
            <Typography size={18} weight={700}>
              {props.message.message}
            </Typography>
            <Typography size={14} weight={400}>
              {props.message.subject}
            </Typography>
          </div>
        </div>
        {/* Нет бекенда под этот вид кнопок */}
        {/* <a href="" className={styles.item__link}> */}
        {/*   <Typography size={16} weight={700}> */}
        {/*     К шкафу */}
        {/*   </Typography> */}
        {/*   <Icon src="arrowRight" /> */}
        {/* </a> */}
        {!isUndefined(props.message.link) && (
          <a
            className={styles.item__download_link}
            href={props.message.link}
          >
            <Icon src="download" />
            {intl.formatMessage({
              id: 'download',
              defaultMessage: 'Скачать',
            })}
          </a>
        )}
      </div>
      <div className={styles.item__time}>
        <Typography size={14} weight={400} color="gray">
          {time}
        </Typography>
      </div>
    </div>
  );
};
