import React from 'react'
import { useIntl } from 'react-intl'
import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import { IModal } from '../interfaces'
import style from '../styles/index.module.scss'
import { isUndefined } from '@/utils'

interface IProps extends IModal {
  modalProps: {
    errorMessage?: string
  }
}

/**
 * * компонент модального окна с ошибкой загрузки файла
 * @component FileUploadError
 *
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const FileUploadError: React.FC<IProps> = ({
  closeModal,
  modalProps
}): React.ReactElement => {
  const intl = useIntl()
  const errorMessage = modalProps?.errorMessage
  const description = !isUndefined(errorMessage)
    ? errorMessage
    : intl.formatMessage({
        id: 'modal.subtitle.file_upload_error',
        defaultMessage:
          'A file upload error has occurred. The file was corrupted or loaded in the wrong format. Try again.'
      })
  return (
    <div className={style.container}>
      <div className={style.container__header__icons}>
        <Icon src='file' size='xl' className={style.container__header_icon} />
        <Icon
          src='close'
          className={style.container__header_icon_close}
          onClick={closeModal}
        />
      </div>
      <div className={style.container__body__status}>
        <Typography weight={700} size={30}>
          {intl.formatMessage({
            id: 'modal.title.file_upload_error',
            defaultMessage: 'File upload error'
          })}
        </Typography>
        <span className={style.container__body__status_text}>{description}</span>
      </div>
      <Button
        className={style.container__buttons_ok}
        color='black'
        onClick={closeModal}
      >
        {intl.formatMessage({ id: 'common.continue', defaultMessage: 'Continue' })}
      </Button>
    </div>
  )
}

export default FileUploadError
