import React from 'react'
import { useIntl } from 'react-intl'

import NavigationBar from '@/views/NavigationBar'

import { usePage } from '@/hooks'

const Navigation = () => {
  const { baseSlug } = usePage()
  const intl = useIntl()

  const navItem = [
    {
      title: intl.formatMessage({
        id: 'allUsers',
        defaultMessage: 'Все пользователи',
      }),
      href: baseSlug.userListPage ,
    },
    {
      title: intl.formatMessage({
        id: 'userGroups',
        defaultMessage: 'Группы пользователей',
      }),
      href: baseSlug.groupUserPage,
    },
  ]

  return <NavigationBar navItems={navItem} />
}

export default Navigation
