import React, { ReactElement } from 'react'

import Avatar from '@/components/Avatar'

import { IGroupUser } from '@/api/interfaces/GroupUsers'

import styles from './styles/index.module.scss'

interface IProps {
  users: IGroupUser[]
  max?: number
}
/**
 * * Вьюха для создания группы аваторок пользователей
 * @view
 */
const AvatarGroup = ({ users, max = 5 }: IProps) => {
  const renderAvatart = () => {
    const allAvatars: ReactElement[] = []
    const userLength = users.length
    for (let i = 0; i < userLength; i++) {
      if (i < max) {
        allAvatars.push(
          <div
            key={i}
            className={styles['users-group__item']}
          >
            <Avatar
              className={styles['users-group__avatar']}
              name={users[i].first_name}
              surname={users[i].last_name}
              size="little"
              changable={false}
            />
          </div>,
        )
      } else {
        const remainingUsers = userLength - i
        allAvatars.push(
          <div
            className={styles['users-group__item']}
            key={i}
          >
            <div className={styles['users-group__last']}>
              <span>+{remainingUsers}</span>
            </div>
          </div>,
        )
        break
      }
    }
    return allAvatars
  }

  return (
    <div className={styles['users-group']}>
      {renderAvatart()}
    </div>
  )
}

export default AvatarGroup
