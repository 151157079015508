import React from 'react'

import UserEquipmentTable from '@/apps/RightSidebar/components/UserEquipmentTable'
import UserHistoryTable from '@/apps/RightSidebar/components/UserHistoryTable'
import UserStorageTable from '@/apps/RightSidebar/components/UserStorageTable'
import TabGenerator from '@/views/TabGenerator'

import {
  USER_DETAILS_TAB,
  USER_TAB_KEYS,
} from '@/apps/RightSidebar/const'

/**
 * * вью табов пользователя (панель переключения
 * между шкафами, оборудованием и историей пользователя)
 * @view UserTabs
 * @returns
 */
const UserTabs: React.FC<{ id: number }> = ({ id }) => {
  const panel = [
    {
      idPanel: 0,
      name: USER_TAB_KEYS.STORAGES,
      Component: UserStorageTable,
    },
    {
      idPanel: 1,
      name: USER_TAB_KEYS.EQUIPMENT,
      Component: UserEquipmentTable,
    },
    {
      idPanel: 2,
      name: USER_TAB_KEYS.HISTORY,
      Component: UserHistoryTable,
    },
  ]

  return (
    <TabGenerator
      panels={panel}
      tabs={USER_DETAILS_TAB}
      propsToComps={{ id }}
    />
  )
}

export default UserTabs
