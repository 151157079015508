import React from 'react'
import cn from 'classnames'

import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'

import style from './style/index.module.scss'

interface IProps {
  className?: string
  children: React.ReactNode
}

const AuthWrap: React.FC<IProps> = ({ children, className }) => {
  return (
    <CSSTransitionGroup
      animation='fadeInBottom'
      className={style['auth-wrap']}
    >
      <div className={cn(style['auth-wrap__container'], className)}>{children}</div>
    </CSSTransitionGroup>
  )
}

export default AuthWrap
