import { MessageDescriptor } from 'react-intl'
import { useTranslate } from '@/hooks'
import { STORAGE_STATUSES } from '@/apps/StorageList/const'

export const getTitleStatusBarByStatus = (
  status: string
): string => {
  let currentText: MessageDescriptor = {
    id: 'sidebar.sync',
    defaultMessage: 'Синхронизация'
  }
  const { translate } = useTranslate()
  switch (status) {
    case STORAGE_STATUSES.SUCCES:
      currentText = {
        id: 'sidebar.connected',
        defaultMessage: 'Подключено'
      }
      break
    case STORAGE_STATUSES.ERROR:
      currentText = {
        id: 'sidebar.not_connected',
        defaultMessage: 'Не подключено'
      }
      break
    case STORAGE_STATUSES.SYNCHRONIZATION_FRONTEND:
      currentText = {
        id: 'sidebar.sync',
        defaultMessage: 'Синхронизация'
      }
      break
    default:
      currentText = {
        id: 'sidebar.connected',
        defaultMessage: 'Подключено'
      }
      break
  }

  return translate(currentText)
}
