
import { useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'

import {
  useStores
} from '@/hooks'

import {
  CODE_EMAIL,
  USERNAME_SCHEMA
} from '@/utils/validation'
import {
  EMPTY_CONSTS,
  MODAL_TYPES
} from '@/const'

const fields = {
  code: 'code',
  email: 'email',
  username: 'username',
  email_confirmation_code:
    'email_confirmation_code'
}

const modalsAccordSteps = {
  1: MODAL_TYPES.EDIT_EMAIL,
  2: MODAL_TYPES.CHECK_EMAIL_CODE,
  3: MODAL_TYPES.SUCCESS_EDIT_EMAIL
}

const START_STEP = 1

interface IReturn {
  handleEditMail: () => void
  currentEmail: string
}

/**
 * @hook   useCustomRestoreEmail
 * * Хук для восстановления пароля пользователя (оборачивает хук useRestorePassword из @garpix/cms)
 *
 * @returns {
 *  handleEditMail               функция для изменения email вводимого в инпут
 *  currentEmail                 изменненный email
 * }
 */
const useCustomRestoreEmail = (): IReturn => {
  const intl = useIntl()
  const store = useStores()
  const [step, setStep] = useState(START_STEP)
  const [code, setCode] = useState(EMPTY_CONSTS.STR)
  const [email, setEmail] = useState(EMPTY_CONSTS.STR)

  const codeRef = useRef(code)
  const emailRef = useRef(email)
  const stepRef = useRef(step)
  const newEmail = useRef(EMPTY_CONSTS.STR)

  /**
   * * функция для проброса новых ERRORS в modalStore
   * @param data объект ошибок
   */
  const provideErrorsToModalProps = (
    data: {
      response_errors?: Record<string, string | string[]>
      validate_errors?: Record<string, string | string[]>
    } | null
  ): void => {
    store.modalStore.setModalProps({
      ...store.modalStore.modalProps,
      errors: data
    })
  }

  /**
   * * функция для изменения кода вводимого в инпут
   * @param e  event, в котором содержится значение инпута
   */
  const handleCodeValue = (
    e: GxInputCustomEvent<any>
  ): void => {
    setCode(e.target.value)
  }

  /**
   * * функция для изменения email вводимого в инпут
   * @param e  event, в котором содержится значение инпута
   */
  const handleEmailValue = (
    e: GxInputCustomEvent<any>
  ): void => {
    setEmail(e.target.value)
  }

  /**
   * * функция достающая ошибки из ответа сервера и установка этих ошибок (в модалку и локально)
   * @param e  event, в котором содержится информация об ответе сервера
   */
  const proccessResponseEror = (e: any): void => {
    const err: Record<string, string | string[]> =
      e.response?.data
    // проверка на null & undefind
    if (err !== EMPTY_CONSTS.NULL) {
      provideErrorsToModalProps({ response_errors: err })
    }
  }

  /**
   * * функция достающая ошибки из ответа валидатора и установка этих ошибок (в модалку)
   * @param e  event, в котором содержится информация об ответе валидатора
   */
  const proccessErorValidate = (e: any): void => {
    const errEntr = Object.entries(e.message)
    if (errEntr.length > 0) {
      const error = errEntr[0]
      const key = error[0]
      const val = error[1] as string
      const translatedVal = intl.formatMessage({
        id: val,
        defaultMessage: 'Ошибка'
      })
      const newerrors: Record<string, string | string[]> = {
        [key]: [translatedVal]
      }
      provideErrorsToModalProps({
        validate_errors: newerrors
      })
    }
  }

  /**
   * * Функция повторной отправки проверочного кода на почту.
   * * Ошибки, полученные при запросе, записываются в обьект ошибок error.
   */
  const resendCode = async (): Promise<void> => {
    void store.api.user
      .sendCodeEditEmail({ email: emailRef.current })
      .then(() => {
        provideErrorsToModalProps({})
      })
      .catch((e) => {
        const data = e?.response?.data
        // проверка на null & undefind
        if (data !== EMPTY_CONSTS.NULL && data !== EMPTY_CONSTS.UNDEFINED) {
          provideErrorsToModalProps({ response_errors: data })
        }
      })
  }

  /**
   * * Функция отерывает первую модалку с которой начинается пайплайн востановления пароля
   */
  const openFirstModal = (): void => {
    setStep(START_STEP)
    store.modalStore.open(MODAL_TYPES.EDIT_EMAIL, {
      onSubmit,
      handleCodeValue,
      resendCode,
      username: emailRef.current,
      handleEmailValue,
      closeModal: store.modalStore.close
    })
  }

  /**
   * * функция для определяющая submit в зависимости от текущего шага
   */
  const handleSubmit = async (): Promise<void> => {
    if (stepRef.current === 1) {
      return await store.api.user.sendCodeEditEmail({ email: emailRef.current })
    } else if (stepRef.current === 2) {
      return await store.api.user.confirmCodeEmail({ email_confirmation_code: codeRef.current })
    }
  }

  /**
   * * Общая функция отправки данных (включает валидацию полей) и (обработку ошибок)
   * * так же меняет шаг и открывает новую модалку при успешной отправке
   * @param event  событие отправки формы
   */
  const onSubmit = async (): Promise<void> => {
    try {
      if (stepRef.current === 1) {
        await USERNAME_SCHEMA.validate({
          [fields.username]: emailRef.current
        })
      }
      if (stepRef.current === 2) {
        await CODE_EMAIL.validate({
          [fields.code]: codeRef.current
        })
      }
    } catch (e) {
      proccessErorValidate(e)
      return
    }

    void handleSubmit()
      .then(() => {
        store.modalStore.open(
          modalsAccordSteps[stepRef.current + 1],
          {
            onSubmit,
            handleCodeValue,
            resendCode,
            handleEmailValue,
            username: emailRef.current,
            closeModal: store.modalStore.close
          }
        )
        setStep(stepRef.current + 1)
        if (stepRef.current === 2) newEmail.current = emailRef.current
      })
      .catch((e) => {
        proccessResponseEror(e)
      })
  }

  /**
   * * Уход от синхронности useState(из за вызова событий из модалок потеря актуальных состояний)
   */
  useEffect(() => {
    codeRef.current = code
  }, [code])

  /**
   * * Уход от синхронности useState(из за вызова событий из модалок потеря актуальных состояний)
   */
  useEffect(() => {
    emailRef.current = email
  }, [email])

  /**
   * * Уход от синхронности useState(из за вызова событий из модалок потеря актуальных состояний)
   */
  useEffect(() => {
    stepRef.current = step
  }, [step])

  return {
    handleEditMail: openFirstModal,
    currentEmail: newEmail.current
  }
}

export default useCustomRestoreEmail
