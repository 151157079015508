import React, {
  useCallback,
  useMemo,
  useState
} from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import classNames from 'classnames'
import Option from './Option'
import IndicatorsContainer from './IndicatorsContainer'
import { EMPTY_CONSTS } from '@/const'
import { useTranslate } from '@/hooks'
import { TAsyncPaginate } from '@/components/AsyncSelect/interfaces'
import getCustomSelectStyles from '@/components/AsyncSelect/styles'

import style from './styles/index.module.scss'

/**
 * Селект с пагинаций
 */

const PaginateSelect: TAsyncPaginate = (props) => {
  const {
    error = EMPTY_CONSTS.STR,
    label,
    labelClassName = EMPTY_CONSTS.STR,
    labelLeft = EMPTY_CONSTS.FALSE,
    loadOptions,
    required,
    disabled,
    ...restProps
  } = props
  const [open, setOpen] = useState(false)
  const closeDropdown = (): void => {
    setOpen(false)
  }
  const openDropdown = (): void => {
    setOpen(true)
  }
  const { translate } = useTranslate()

  const getNoOptionsMessage = useCallback(
    () =>
      translate({
        id: 'noOptions',
        defaultMessage: 'Нет опций'
      }),
    [translate]
  )

  const getLoadingMessage = useCallback(
    () =>
      translate({
        id: 'select.loading',
        defaultMessage: 'Loading'
      }),
    [translate]
  )

  const styles = useMemo(
    () => getCustomSelectStyles(Boolean(error)),
    [error]
  )

  return (
    <label
      className={classNames({
        [style.async__label]: true,
        [style.error]: Boolean(error),
        [style['async__label--left']]: labelLeft,
        [labelClassName]: Boolean(labelClassName)
      })}
    >
      <span
        className={classNames({
          [style.required]: required,
          [style.async__label_text]: true
        })}
      >
        {label}
      </span>
      <AsyncPaginate<any, any, any, any>
        isSearchable={false}
        debounceTimeout={500}
        {...restProps}
        isDisabled={disabled}
        onMenuClose={closeDropdown}
        onMenuOpen={openDropdown}
        loadOptions={loadOptions}
        styles={styles}
        components={{
          Option,
          IndicatorsContainer: () => (
            <IndicatorsContainer
              open={open}
              error={error}
              disabled={disabled}
            />
          )
        }}
        noOptionsMessage={getNoOptionsMessage}
        loadingMessage={getLoadingMessage}
      />
      {error !== EMPTY_CONSTS.STR
        ? (
          <span className={style.async__label_error}>
            {error}
          </span>
          )
        : null}
    </label>
  )
}

export default PaginateSelect
