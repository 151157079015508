import { Components } from '@garpix/garpix-web-components'
import { GxRadio } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

import style from './styles/index.module.scss'

const Radio = forwardRef<Components.GxRadio, any>(
  (
    { children, className = '', ...props },
    ref,
  ): React.ReactElement => {
    return (
      <GxRadio
        ref={ref}
        {...props}
        className={classNames({
          [style.radio]: true,
          [className]: Boolean(className),
        })}
      >
        {children}
      </GxRadio>
    )
  },
)

export default Radio
