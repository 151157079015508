import React, { useEffect, useState } from 'react'

import { useStores, useFetch } from '@/hooks'

import { ISocketUpdateEquipmentData } from '@/interfaces/Socket'
import { IEquipmentFilterParams } from '../Modals/ModalContent/interfaces'
import { IEquipment } from '@/api/interfaces'

import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '@/const'
import { QUERIES } from '@/apps/EquipmentList/const'
import { isUndefined, serializeFilterParams } from '@/utils'

import EquipmentTable from './components/EquipmentTable'
import EquipmentPanel from './components/EquipmentPanel'
import WithMediaPageSize from '@/components/WithMediaPageSize'

import styles from './style/index.module.scss'

/**
 * @component EquipmentList
 * * компонент страницы списка оборудования
 */
const EquipmentList: React.FC<{
  pageSize: number | undefined
}> = ({ pageSize }): React.ReactElement => {
  const {
    api,
    ExelEventsObserver,
    EquipmentEventsObserver,
  } = useStores()
  const {
    data,
    isLoading,
    handleParams,
    params = {},
    handlePage,
    error,
    setData: setEquipmentList,
    refetch,
  } = useFetch(api.equipment.getEquipments, {
    page_size: pageSize ?? DEFAULT_PAGE_SIZE,
  })

  const [filterParams, setFilterParams] =
    useState<IEquipmentFilterParams>(
      params as IEquipmentFilterParams,
    )

  /**
   * от фильтров приходят значения {label, value}
   * a handleParams принимает от каждого филтьтра только value
   * @param newParams
   */
  const handleFilterParams = (
    newParams: IEquipmentFilterParams,
  ): void => {
    const serialized = serializeFilterParams(newParams)
    setFilterParams(newParams)
    handleParams(serialized)
  }
  /**
   * * метод для мутации списка ячеек
   * * чтоб не было лоудера
   * @function mutateEquipment
   * @param changedEquipment
   */
  const mutateEquipment = (
    changedEquipment: IEquipment,
  ): void => {
    setEquipmentList((prevState) => {
      if (isUndefined(prevState)) return undefined

      const newCellList = prevState.results.map((el) => {
        if (changedEquipment.id === el.id) {
          return changedEquipment
        } else {
          return el
        }
      })

      return {
        ...prevState,
        results: newCellList,
      }
    })
  }
  /**
   * * метод для обновления списка оборудования
   * @function updateEquipmentList
   * @param equipmentId
   */
  const updateEquipmentList = ({
    equipment_id: equipmentId,
  }: ISocketUpdateEquipmentData) => {
    api.equipment.getEquipments().then((response) => {
      const changedEquipment = response?.results.find(
        (el) => el.id === equipmentId,
      )
      if (!isUndefined(changedEquipment)) {
        mutateEquipment(changedEquipment)
      }
    })
  }

  useEffect(() => {
    if (
      !isUndefined(pageSize) &&
      pageSize !== params.page_size
    ) {
      handleParams({ page_size: pageSize })
    }
  }, [pageSize])

  useEffect(() => {
    ExelEventsObserver.subscribe(refetch)
    EquipmentEventsObserver.subscribe(updateEquipmentList)
    return () => {
      ExelEventsObserver.unsubscribe(refetch)
      EquipmentEventsObserver.unsubscribe(
        updateEquipmentList,
      )
    }
  }, [])

  return (
    <div className={styles.container}>
      <EquipmentPanel
        handleParams={handleFilterParams}
        params={filterParams}
        refetch={refetch}
      />
      <EquipmentTable
        params={params}
        equipments={data?.results}
        handleParams={handleParams}
        isLoading={isLoading}
        handlePage={handlePage}
        currentPage={params?.page}
        total={data?.count}
        error={error}
        refetch={refetch}
      />
    </div>
  )
}

const EnhancedEquipmentList: React.FC = () => {
  return (
    <WithMediaPageSize queries={QUERIES} sizes={PAGE_SIZES}>
      {(pageSize) => <EquipmentList pageSize={pageSize} />}
    </WithMediaPageSize>
  )
}

export default EnhancedEquipmentList
