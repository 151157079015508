import React, { useEffect, useState } from 'react'

import { useFetch, useStores } from '@/hooks'
import { isUndefined, serializeFilterParams } from '@/utils'
import { IUserQueryParams, TUser } from '@/api/interfaces'
import { ISocketUpdateUserData } from '@/interfaces/Socket'
import { QUERIES } from '@/apps/UserList/const'
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '@/const'

import UserTable from './components/UserTable'
import UserPanel from './components/UserPanel'
import WithMediaPageSize from '@/components/WithMediaPageSize'

import styles from './style/index.module.scss'

/**
 * @component UserList
 * * компонент страницы списка пользователей
 */
const UserList: React.FC<{ pageSize?: number }> = ({
  pageSize
}): React.ReactElement => {
  const { api, ExelEventsObserver, UserEventsObserver } = useStores()
  const {
    data,
    isLoading,
    handleParams,
    params = {},
    handlePage,
    error,
    refetch,
    setData: setUserData
  } = useFetch(api.user.getUsers, {
    page_size: pageSize ?? DEFAULT_PAGE_SIZE
  })
  const { page } = params
  const [filterParams, setFilterParams] = useState<IUserQueryParams>(params)

  /**
   * от фильтров приходят значения {label, value}
   * a handleParams принимает от каждого филтьтра только value
   * @param newParams
   */
  const handleFilterParams = (newParams: IUserQueryParams): void => {
    const serialized = serializeFilterParams(newParams)
    setFilterParams(newParams)
    handleParams(serialized)
  }
   /**
   * * метод для мутации списка пользователя 
   * @function mutateUserList
   * @param changedUser 
   */
  const mutateUserList = (changedUser: TUser): void => {
    setUserData((prevState) => {
      if (isUndefined(prevState)) return undefined

      const newUserList = prevState.results.map((el) => {
        if (changedUser.id === el.id) {
          return changedUser
        } else {
          return el
        }
      })

      return {
        ...prevState,
        results: newUserList
      }
    })
  }
  /**
   * * метод для запроса юзеров по ивенту сокетов
   * @function refetchUserSocket
   * @param user_id 
   */
  const refetchUserSocket = ({ user_id }: ISocketUpdateUserData) => {
    api.user.getUsers().then((response) => {
      const changedUser = response?.results.find((el) => el.id === user_id)
      if (!isUndefined(changedUser)) {
        mutateUserList(changedUser)
      }
    })
  }

  useEffect(() => {
    if (!isUndefined(pageSize) && pageSize !== params.page_size) {
      handleParams({ page_size: pageSize })
    }
  }, [pageSize])

  useEffect(() => {
    ExelEventsObserver.subscribe(refetch)
    UserEventsObserver.subscribe(refetchUserSocket)
    return () => {
      ExelEventsObserver.unsubscribe(refetch)
      UserEventsObserver.unsubscribe(refetchUserSocket)
    }
  }, [])

  return (
    <div className={styles.container}>
      <UserPanel
        handleParams={handleFilterParams}
        params={filterParams}
        refetch={refetch}
      />
      <UserTable
        users={data?.results}
        handleParams={handleParams}
        params={params}
        isLoading={isLoading}
        handlePage={handlePage}
        currentPage={page}
        total={data?.count}
        error={error}
        refetch={refetch}
      />
    </div>
  )
}

const EnhancedUserList: React.FC = () => {
  return (
    <WithMediaPageSize queries={QUERIES} sizes={PAGE_SIZES}>
      {(pageSize) => <UserList pageSize={pageSize} />}
    </WithMediaPageSize>
  )
}

export default EnhancedUserList
