import React from 'react'

import { useIntl } from 'react-intl'
import cn from 'classnames'

import { INFO_ERROR } from '../../const'

import ErrorsContainer from '../ErrorsContainer'
import { Typography } from '@/views/typography'

import style from './style/index.module.scss'

interface IProps {
  type: string
  errorMessage?: string
}

/**
 * @view
 * * Вью стр с ошибками в которой мы получаем контент для стр и реализуем
 * * ее интерфейс
 * @see TErrorsPageType
 *
 * @param type         тип стр с ошибкой
 * @param errorMessage строка с ошибкой
 */

const StatusErrorBlockView: React.FC<IProps> = ({
  type = '404',
  errorMessage
}) => {
  const intl = useIntl()
  const content = INFO_ERROR[type]
  const isCustomError = type === 'error'

  return (
    <ErrorsContainer>
      <Typography
        className={style.error__title}
        weight={700}
        size={32}
      >
        {intl.formatMessage(content.title)}
      </Typography>
      <Typography
        className={style.error__description}
        weight={400}
        size={16}
      >
        {errorMessage ?? intl.formatMessage(content.description, {
          br: <br />
        })}
      </Typography>
      <Typography
        className={cn({
          [style.error__status]: true,
          [style['error__status--default']]: !isCustomError,
          [style['error__status--custom']]: isCustomError
        })}
        weight={700}
        size={300}
      >
        {String(content.status)}
      </Typography>
    </ErrorsContainer>
  )
}

export default StatusErrorBlockView
