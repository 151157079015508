import React, { FC, memo } from 'react'
import styles from '../styles/index.module.scss'

interface IProps {
  children: React.ReactNode
}

/**
 * @info обертка центрирует контент
 *       в блок определенной максимальной ширины
 * @param param0
 * @returns
 */
const GridContent: FC<IProps> = ({ children }) => {
  return (
    <div className={styles.wrap__grid}>
      {children}
    </div>
  )
}

export default memo(GridContent)
