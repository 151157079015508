import React from 'react'
import { useIntl } from 'react-intl'
import cn from 'classnames'

import { isNull } from '@/utils'
import { EMPTY_CONSTS, TABLE_WRAPPER_ICON_SRC } from '@/const'
import { IEmptyTablePanel } from './interfaces'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'

import styles from './styles/index.module.scss'

/**
 * @component EmptyTablePanel
 * * компонент пустого фона на месте таблиц
 *
 * @param search            поиск пустой или нет
 * @param type              тип фона
 */
const EmptyTablePanel: React.FC<IEmptyTablePanel> = ({
  search = EMPTY_CONSTS.STR,
  type = 'equipment',
  iconSrc = TABLE_WRAPPER_ICON_SRC.EQUIPMENT,
  handleClick = EMPTY_CONSTS.FUNC
}) => {
  const intl = useIntl()
  const isTabPanel = isNull(type.match('_tab'))
  const isPanelSearch = Boolean(search) && isTabPanel
  const isSearch = isPanelSearch || Boolean(search)
  const IconSrc = search !== EMPTY_CONSTS.STR ? 'search' : iconSrc

  const IconClassName = !isTabPanel
    ? styles.table__empty_icon_mini
    : styles.table__empty_icon

  const classNameSearchText =
    (!isPanelSearch || !isSearch)
      ? styles.table__empty_type
      : styles.table__empty_search

  return (
    <div className={styles.table}>
      <div className={cn(
        styles.table__empty,
        {
          [styles.table__empty_tabsearch]: !isTabPanel && search === EMPTY_CONSTS.STR
        }
      )}
      >
        <Icon src={IconSrc} className={IconClassName} />
        <span className={cn(styles['table__search-title'], classNameSearchText)}>
          {intl.formatMessage({
            id:
              isSearch
                ? 'search.empty'
                : `${type}.empty`,
            defaultMessage: 'По данному запросу ничего не найдено'
          })}
        </span>
        {search === EMPTY_CONSTS.STR &&
        !isTabPanel
          ? (
            <Button
              color='black'
              className={styles.table_btn}
              onClick={handleClick}
            >
              <Icon src='linkOn' />
              {intl.formatMessage({
                id: 'common.bind',
                defaultMessage: 'Привязать'
              })}
            </Button>
            )
          : (
              EMPTY_CONSTS.NULL
            )}
      </div>
    </div>
  )
}

export default EmptyTablePanel
