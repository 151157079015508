import React from 'react'
import classNames from 'classnames'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'

import { useStores } from '@/hooks'

import { checkHasFilter } from '@/utils'

import {
  DEFAULT_TABLE_PAGE,
  EMPTY_CONSTS,
  MODAL_TYPES,
  TABLE_PAGE,
  THEME_MODAL,
} from '@/const'

import { IFilterButton } from './interfaces'

import styles from './styles/index.module.scss'
import { IQueryParams } from '@/api/interfaces'

/**
 * @view FilterButton
 * * вью кнопки фильтра
 *
 * @param params              текущие query параметры для фильтра
 * @param handleParams        установить параметры фильтра к остальным query параметрам
 * @param type                тип фильтра (фильтр для шкафов, оборудования, пользователей)
 * @param className           дополнительные стили
 */
const FilterButton: React.FC<IFilterButton> = ({
  params,
  handleParams,
  type = DEFAULT_TABLE_PAGE,
  className = EMPTY_CONSTS.STR,
  isStorageEquipment = EMPTY_CONSTS.FALSE,
}) => {
  const { modalStore } = useStores()
  const isFilter = checkHasFilter(params as IQueryParams)
  /**
   * * метод для открытя модалки фильтра (по передаваемому type определяется тип фильтра)
   * @func handleOpenModalFilter
   */
  const handleOpenModalFilter = (): void => {
    const modalProps = {
      handleParams,
      params,
      theme: THEME_MODAL.FILTER,
      type,
      isStorageEquipment,
    }
    switch (type) {
      case TABLE_PAGE.USER:
        modalStore.open(MODAL_TYPES.USER_FILTER, modalProps)
        break
      case TABLE_PAGE.EQUIPMENT:
        modalStore.open(
          MODAL_TYPES.EQUIPMENT_FILTER,
          modalProps,
        )
        break
      case TABLE_PAGE.STORAGE_USER:
        modalStore.open(
          MODAL_TYPES.STORAGE_USER_FILTER,
          modalProps,
        )
        break
      default:
        modalStore.open(
          MODAL_TYPES.STORAGE_FILTER,
          modalProps,
        )
    }
  }

  return (
    <Button
      className={classNames({
        [styles.filter]: true,
        [className]: Boolean(className),
      })}
      color="white-soft"
      onClick={handleOpenModalFilter}
    >
      <Icon size="small" src="filter" />
      {isFilter ? (
        <div className={styles.filter_red} />
      ) : null}
    </Button>
  )
}

export default React.memo(FilterButton)
