import React, { FC, JSX } from 'react'
import classNames from 'classnames'
import { useTranslate } from '@/hooks'
import { EMPTY_CONSTS } from '@/const'
import { ICustomModal } from '../interfaces'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import style from '../styles/index.module.scss'
import { isUndefined } from '@/utils'

/**
 * * компонент кастомного модального окна
 * @component Custom
 *
 * @func  closeModal - функция закрытия модалки
 * @param [modalProps.icon] - тип иконки
 * @param [modalProps.title] - заголовок ошибки
 * @param [modalProps.description] - описание ошибки
 * @param [modalProps.accept] - лейбл кнопки принятия
 * @param [modalProps.decline] - лейбл кнопки отмены
 * @param [modalProps.handleAccept] - метод для кнопки принятия
 * @param [modalProps.handleDecline] - метод для кнопки отмены
 * @returns
 */
const Custom: FC<ICustomModal> = ({
  closeModal,
  className = EMPTY_CONSTS.STR,
  modalProps
}) => {
  const { translate } = useTranslate()
  const handleAccept = (): void => {
    modalProps.handleAccept != null
      ? modalProps.handleAccept()
      : closeModal()
  }
  const handleDecline = (): void => {
    modalProps.handleDecline != null
      ? modalProps.handleDecline()
      : closeModal()
  }

  const renderTitle = (): JSX.Element | null => {
    return modalProps.title !== undefined
      ? (
        <h2 className={style.title}>
          {translate(modalProps.title)}
        </h2>
        )
      : null
  }
  const renderDescription = (): JSX.Element | null => {
    return !isUndefined(modalProps.description) ||
      !isUndefined(modalProps.customContent)
      ? (
        <span className={style.container__body__status_text}>
          {/* {translate(modalProps.description)} */}
          {modalProps.customContent}
        </span>
        )
      : null
  }
  const renderDeclineBtn = (): JSX.Element | null => {
    return modalProps.decline !== undefined
      ? (
        <Button
          className={style.container__buttons_cancel}
          onClick={handleDecline}
        >
          {translate(modalProps.decline)}
        </Button>
        )
      : null
  }
  const renderAcceptBtn = (): JSX.Element | null => {
    return modalProps.accept !== undefined
      ? (
        <Button
          className={style.container__buttons_off}
          color='black'
          onClick={handleAccept}
        >
          {translate(modalProps.accept)}
        </Button>
        )
      : null
  }

  return (
    <div
      className={classNames(style.container, {
        [className]: Boolean(className)
      })}
    >
      <div className={style.container__header__icons}>
        <Icon
          src={modalProps.icon}
          size='xl'
          className={style.container__header_icon}
        />
        <Icon
          src='close'
          className={style.container__header_icon_close}
          onClick={closeModal}
        />
      </div>
      <div className={style.container__body__status}>
        {renderTitle()}
        {renderDescription()}
      </div>
      <div className={style.container__buttons}>
        {renderDeclineBtn()}
        {renderAcceptBtn()}
      </div>
    </div>
  )
}

export default Custom
