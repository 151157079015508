import React from 'react'
import { useIntl } from 'react-intl'
import cn from 'classnames'

import { Typography } from '@/views/typography'
import CellStatus from '../CellStatus'

import { useStores } from '@/hooks'

import { EMPTY_CONSTS, STORAGE_CELL_STATUS } from '@/const'
import { RIGHT_SIDEBAR_TYPES } from '@/apps/RightSidebar/const'

import { TStorageCellStatus } from '@/api/interfaces'

import style from './style/index.module.scss'

interface IProps {
  id: number
  isBlocked: boolean
  cellStatus: TStorageCellStatus
  equipmentType: string
  title: string
  cellNumber: number
  equipmentId: string
}
/**
 * @view
 * * Вьюха карточки ячейки
 * @param id             id текущей ячейки
 * @param cellStatus     статус ячейки
 * @param cellNumber     номер ячейки
 * @param equipmentType  тип оборудования
 * @param title          название оборудования в этой ячейке
 */
const Card: React.FC<IProps> = ({
  id,
  cellStatus,
  cellNumber,
  equipmentType,
  equipmentId,
  isBlocked,
  title,
}) => {
  const intl = useIntl()
  const { rightSideBarStore } = useStores()
  const showEmptyValue =
    title === EMPTY_CONSTS.STR &&
    cellStatus === STORAGE_CELL_STATUS.FREE
  const isEquipmetInCell = equipmentId !== EMPTY_CONSTS.STR
  /**
   * * функция для открытия деталки оборудования
   * @func handleOpenEquipmentSideBar
   * @param
   */
  const handleOpenEquipmentSideBar = (): void => {
    if (isEquipmetInCell) {
      rightSideBarStore.open(
        RIGHT_SIDEBAR_TYPES.EQUIPMENT_SIDEBAR,
        { equipmentId: Number(equipmentId) },
      )
    }
  }

  return (
    <li
      key={id}
      className={cn(style.cells__card, {
        [style.cells__card_active]: isEquipmetInCell,
      })}
      onClick={handleOpenEquipmentSideBar}
    >
      <div>
        {Boolean(cellNumber) && (
          <CellStatus
            isBlocked={isBlocked}
            cellStatus={cellStatus}
          >
            {cellNumber}
          </CellStatus>
        )}
      </div>
      <div className={style.cells__card_text}>
        {isBlocked ? (
          <Typography
            className={style.cells__card_title}
            size={16}
            weight={400}
          >
            {intl.formatMessage({
              id: 'cell.off',
              defaultMessage: 'Выключена',
            })}
          </Typography>
        ) : (
          <>
            {Boolean(equipmentType) && (
              <Typography
                className={style.cells__card_title}
                size={16}
                weight={700}
              >
                {equipmentType}
              </Typography>
            )}
            {showEmptyValue && (
              <Typography
                className={style.cells__card_title__block}
                size={14}
                weight={400}
              >
                {intl.formatMessage({
                  id: 'empty',
                  defaultMessage: 'Пустая',
                })}
              </Typography>
            )}
            {Boolean(title) && (
              <Typography
                className={style.cells__card_desc}
                size={14}
                weight={400}
              >
                {title}
              </Typography>
            )}
          </>
        )}
      </div>
    </li>
  )
}

export default Card
