import React, { ReactNode } from 'react'

import styles from './style/index.module.scss'

interface IProps {
  children: ReactNode
}

const StorageSidebarWrap: React.FC<IProps> = ({ children }) => {
  return (
    <div className={styles.wrap}>{children}</div>
  )
}

export default StorageSidebarWrap
