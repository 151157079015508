import React, { useMemo } from 'react'

import { useIntl } from 'react-intl'

import { IHandlersFooter } from '@/apps/MainSidebar/interfaces'
import { STORAGE_STATUSES } from '@/apps/StorageList/const'
import { TStorageStatus } from '@/interfaces/consts'

import { Button } from '@/views/button'
import Icon from '@/components/Icon'

import style from './style/index.module.scss'

interface IProps {
  handlersFooter: IHandlersFooter
  status?: TStorageStatus
}

const FooterButtons: React.FC<IProps> = ({
  handlersFooter,
  status,
}) => {
  const intl = useIntl()

  const {
    handleOpenStorageEditModal,
    handleDownloadHistory,
    handleSyncStorage,
  } = handlersFooter

  /**
   * * состояние выкл кнопки если текущий
   * * статус проекта - синхронизация
   */
  const isDisabledAll = useMemo(
    () =>
      STORAGE_STATUSES.SYNCHRONIZATION_BACKEND.includes(
        status ?? STORAGE_STATUSES.ERROR,
      ),
    [status],
  )

  return (
    <div className={style.footer}>
      <Button
        className={style.footer__btn}
        color="default"
        disabled={isDisabledAll}
        onClick={handleOpenStorageEditModal}
      >
        <Icon src="edit" />
        {intl.formatMessage({
          id: 'common.edit',
          defaultMessage: 'Редактировать',
        })}
      </Button>
      <Button
        onClick={handleSyncStorage}
        className={style.footer__btn}
        disabled={isDisabledAll}
        color="default"
      >
        <Icon src="synchronization" />
        {intl.formatMessage({
          id: 'common.sync',
          defaultMessage: 'Синхронизировать',
        })}
      </Button>
      <Button
        onClick={handleDownloadHistory}
        className={style.footer__btn}
        disabled={isDisabledAll}
        color="black"
      >
        <Icon src="clock" />
        {intl.formatMessage({
          id: 'common.downloadHistory',
          defaultMessage: 'Скачать историю',
        })}
      </Button>
    </div>
  )
}

export default FooterButtons
