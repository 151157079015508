import { LeftSidebarStore } from './LefsSidebar'
/**
 * Import all your stores
 */
import { Api } from '@/api'
import { ModalStore } from './Modal'
import { GlobalStore } from './Global'
import { SpinnerStore } from './Spinner'
import { Tooltip } from './Tooltip'
import { NotifyStore } from './Notify'
import { CurrentStorageStore } from './CurrentStorage'
import { RightSidebarStore } from './RightSidebar'
import { NotificationStore } from './Notification'
import { StorageListStore } from './StorageList'
import { IntegrationEventsSocket } from './Integration'
import { StorageEventsObserver } from './StorageEventsObserver'
import { EquipmentEventsObserver } from './EquipmentEventsObserver'
import { ExelEventsObserver } from './ExelEventsObserver'
import { UserEventsObserver } from './UserEventsObserver'
import { TLanguage } from '@/interfaces/Lang'
import { runtimeConfig } from '@/config'
import {
  collectApiUrl,
  collectDomain,
  isUndefined
} from '@/utils'

/**
 * Root Store Class with
 */
export class RootStore {
  leftSidebarStore: LeftSidebarStore
  modalStore: ModalStore
  notifyStore: NotifyStore
  api: Api
  global: GlobalStore
  spinerStore: SpinnerStore
  currentStorageStore: CurrentStorageStore
  rightSideBarStore: RightSidebarStore
  notificationStore: NotificationStore
  storageListStore: StorageListStore
  tooltip: Tooltip
  integrationEventsSocket: IntegrationEventsSocket
  StorageEventsObserver: StorageEventsObserver
  ExelEventsObserver: ExelEventsObserver
  EquipmentEventsObserver: EquipmentEventsObserver
  UserEventsObserver: UserEventsObserver
  URL: string

  constructor (
    lng: TLanguage,
    hostname?: string | undefined,
    hardURL?: string | undefined
  ) {
    this.URL = collectApiUrl({
      protocol: runtimeConfig.REACT_APP_API_PROTOCOL,
      domain: collectDomain(
        runtimeConfig.REACT_APP_IS_LOCAL_ONLY,
        runtimeConfig.REACT_APP_API_DOMAIN,
        hostname
      ),
      path: runtimeConfig.REACT_APP_API_PATH
    })
    if (!isUndefined(hardURL)) {
      this.URL = hardURL
    }
    console.log('this.URL', this.URL)
    this.api = new Api(this.URL, lng)
    this.modalStore = new ModalStore(this)
    this.spinerStore = new SpinnerStore(this)
    this.tooltip = new Tooltip(this)
    this.global = new GlobalStore(this)
    this.leftSidebarStore = new LeftSidebarStore(this)
    this.currentStorageStore = new CurrentStorageStore(this)
    this.rightSideBarStore = new RightSidebarStore(this)
    this.notifyStore = new NotifyStore(this)
    this.notificationStore = new NotificationStore(this)
    this.storageListStore = new StorageListStore(this)
    this.integrationEventsSocket = new IntegrationEventsSocket(this)

    this.StorageEventsObserver = new StorageEventsObserver(this)
    this.ExelEventsObserver = new ExelEventsObserver(this)
    this.EquipmentEventsObserver = new EquipmentEventsObserver(this)
    this.UserEventsObserver = new UserEventsObserver(this)
    // [INIT]

    this.global.initColorSheme()
    void this.api.user.createUserSession()
  }
}
