import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

/**
 * @component
 * * Космпонент для вставки вашего контента в корень приложения
 */

interface PortalProps {
  children: ReactNode
}

const Portal = ({
  children
}: PortalProps): React.ReactPortal => {
  return createPortal(children, document.body)
}

export default Portal
