import React, { useState } from 'react'

import { useStores } from '@/hooks'

import EquipmentUntie from '../../views/EquipmentUntieView'

import { isUndefined } from '@/utils'

import { IUnlinkEquipmentProps } from '@/apps/EquipmentList/interfaces'
import { IModal } from '../interfaces'

import { MODAL_CUSTOM_CONTENT, MODAL_TYPES } from '@/const'
import { MODAL_VARIANT_CONTENT } from '../../const'

interface IProps extends IModal {
  modalProps: IUnlinkEquipmentProps
}
/**
 * @component
 * * компонент с логикой модалки отвязки оборудования
 * @param closeModal метод закрытия модалки
 * @param modalProps данные для текущей модалки
 */
const EquipmentUnbind: React.FC<IProps> = ({
  closeModal,
  modalProps,
}) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const { equipmentData, refetch, handleUnbindRequest } =
    modalProps

  const { modalStore } = useStores()

  /**
   * * метод для отвязывания оборудования от шкафа
   * @func handleUnlinkEquipment
   */
  const handleUnlinkEquipment = (): void => {
    setIsDisabled(true)
    void handleUnbindRequest()
      .then(() => {
        refetch()
        closeModal()
      })
      .catch((err) => {
        const error = err.response.data?.message
        if (!isUndefined(error)) {
          modalStore.open(MODAL_TYPES.WARNING_ERROR, {
            error: [error],
          })
        } else {
          modalStore.open(
            MODAL_TYPES.CUSTOM,
            MODAL_CUSTOM_CONTENT.ERROR,
          )
        }
      })
      .finally(() => {
        setIsDisabled(false)
      })
  }

  return (
    <EquipmentUntie
      equipmentData={equipmentData}
      closeModal={closeModal}
      modalType={MODAL_VARIANT_CONTENT.UNBIND}
      handleSubmit={handleUnlinkEquipment}
      isDisabled={isDisabled}
    />
  )
}

export default EquipmentUnbind
