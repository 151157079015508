import React, { ReducerWithoutAction, useEffect, useReducer } from 'react'
import { AbstractSocket, useInit, useSocketCollectCallbacks } from '@/services'
import { runtimeConfig } from '@/config'
import { observer } from 'mobx-react'
import { useStores } from '@/hooks'
import { ISocketReportMessage, TSystemData } from '@/interfaces/Socket'
import {
  MODAL_TYPES,
  NOTIFICATION_TYPES,
  PRIVACY_SOCKET_EVENTS,
  SOCKET_SIGNALS
} from '@/const'
import { collectApiUrl, collectDomain, isUndefined } from '@/utils'

const UserSocket: React.FC = () => {
  // const navigate = useNavigate()
  const { modalStore, api, notifyStore, notificationStore } = useStores()

  const API_WS_URL: string = collectApiUrl({
    protocol: runtimeConfig.REACT_APP_WS_PROTOCOL,
    domain: collectDomain(
      runtimeConfig.REACT_APP_IS_LOCAL_ONLY,
      runtimeConfig.REACT_APP_WS_DOMAIN
    ),
    path: runtimeConfig.REACT_APP_API_WS_PRIVACY_URL
  })
  /**
   * для реинициализации подключения после дисконекта
   */
  const [count, setCount] = useReducer<ReducerWithoutAction<number>>(
    (prev) => ++prev,
    0
  )
  let absSocket: AbstractSocket | undefined // ссылка на инстанс сокета
  let timer: NodeJS.Timeout
  const token = api.authStorage.accessToken

  // [SOCKET EVENTS]
  const open = (): void => {}
  const connect = (): void => {}
  const disconnect = (): void => {
    setCount()
  }
  const error = (): void => {
    console.log('error')
  }

  /**
   * Обработчик события об ошибки загрузки ексель. Открывает модалку об ошибки
   */
  const excelErrorHandler = (request: TSystemData): void => {
    modalStore.open(MODAL_TYPES.FILE_UPLOAD_ERROR, {
      errorMessage: request.message.message
    })
  }

  /**
   * Добавляет уведомление скачивание отчета
   * @param [message.message] - тайтл уведомления
   * @param [message.link] - ссылка на отчет уведомления
   */
  const downloadReportHandler = ({ id, message }: ISocketReportMessage): void => {
    notifyStore.createNotification({
      id,
      type: NOTIFICATION_TYPES.DOWNLOAD_REPORT,
      title: message.message,
      link: message.link,
      subtitle: message.subject
    })
  }

  // [END SOCKET EVENTS]

  const EVENTS = {
    [PRIVACY_SOCKET_EVENTS.UPLOAD_EXCEL_ERROR]: excelErrorHandler,
    [PRIVACY_SOCKET_EVENTS.DOWNLOAD_REPORT]: downloadReportHandler,
    DEFAULT: error
  }

  const systemHandler = (data: TSystemData): void => {
    data.event in EVENTS ? EVENTS[data.event](data) : EVENTS.DEFAULT()
    notificationStore.addCount()
  }

  const collectDefaultSocketEvent = {
    // [DEFAULT]
    open,
    connect,
    disconnect,
    error,
    [SOCKET_SIGNALS.SYSTEM]: systemHandler
  }

  useEffect(() => {
    if (
      API_WS_URL !== undefined &&
      API_WS_URL !== '' &&
      absSocket === undefined &&
      token !== null &&
      token !== '' &&
      !isUndefined(token)
    ) {
      const QS = `?token=${token}` // для подключения к чат румам
      absSocket = useInit(API_WS_URL, QS) // инициализация
      useSocketCollectCallbacks(absSocket, collectDefaultSocketEvent) // Устарновка дефолтных параметров и кастомных
    }
    return () => {
      //! обязательно закрывать соединениие  после размонтирования  компоненты
      absSocket?.socket?.close()
      clearTimeout(timer)
    }
  }, [token, count])

  return null
}

export default observer(UserSocket)
