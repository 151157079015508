import React from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import ProblemList from '@/apps/ProblemList'

const ProblemPage: React.FC = () => {
  return (
    <MainLayout>
      <ProblemList />
    </MainLayout>
  )
}

export default ProblemPage
