import React from 'react'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'
import { useStores } from '@/hooks'

import { isUndefined } from '@/utils'
import { EMPTY_CONSTS } from '@/const'

import { Typography } from '@/views/typography'

import style from '../style/index.module.scss'
/**
 * @view
 * * вью для реализации интерфейса блока описания
 */
const Description: React.FC = observer(() => {
  const intl = useIntl()
  const {
    currentStorageStore: { storage }
  } = useStores()

  if (
    isUndefined(storage?.description) ||
    storage?.description === EMPTY_CONSTS.STR
  ) { 
    return <></>
  }

  return (
    <div className={style['storage-detail__top_desc']}>
      <Typography
        className={style['storage-detail__label']}
        size={14}
        weight={400}
      >
        {intl.formatMessage({
          id: 'sidebar.description',
          description: 'Описание'
        })}
        :
      </Typography>
      <Typography
        className={style['storage-detail__description']}
        size={14}
        weight={400}
      >
        {storage?.description}
      </Typography>
    </div>
  )
})

export default Description
