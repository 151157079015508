import React from 'react'

import { useField } from 'formik'
import { DEFAULT_INTEGRATION_FIELDS, SCUD_STATUSES } from '../../const'
import Loader from '@/views/Loader'

import styles from './style/index.module.scss'
/**
 * @view
 * * статус подключения с интеграцией скуда
 */
const IntegrationStatus = (): React.ReactElement => {
  const [{ value }] = useField(
    DEFAULT_INTEGRATION_FIELDS.status
  )

  return value === SCUD_STATUSES.LOADING
    ? (
      <Loader className={styles.loader} />
      )
    : (
      <div data-status={value} className={styles.status}>
        <div className={styles.status__circle} />
      </div>
      )
}

export default IntegrationStatus
