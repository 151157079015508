import React from 'react'
import { SidebarContainer } from '@/apps/RightSidebar/views/SidebarContainer'
import DateInputs, {
  IDateInputsProps
} from '@/apps/RightSidebar/views/EquipmentSideBarView/DateInputs'
import HistoryListView, {
  IProps
} from '@/apps/RightSidebar/views/EquipmentSideBarView/HistoryListView'

import styles from './styles/index.module.scss'

interface IHistoryPanelView {
  listProps: IProps
  datePickerProps: IDateInputsProps
}

const HistoryPanelView: React.FC<IHistoryPanelView> = ({
  datePickerProps,
  listProps
}) => {
  return (
    <div className={styles.history__root}>
      <SidebarContainer>
        <DateInputs {...datePickerProps} />
        <HistoryListView {...listProps} />
      </SidebarContainer>
    </div>
  )
}

export default HistoryPanelView
