import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import { Button } from '@/views/button'

import { IActionButtons } from '../interfaces'

import style from '../style/index.module.scss'

/**
 * * вью текущих кнопок в модалке прикрепления пользователя
 * @view ActionButtons
 *
 * @func handleSubmit  функция отправки
 * @func handleCancel  функция отмены
 * @returns
 */
const ActionButtons: FC<IActionButtons> = ({
  handleSubmit,
  closeModal,
}) => {
  const intl = useIntl()
  return (
    <div className={style.footer}>
      <Button
        className={style.footer__btn}
        color="gray"
        onClick={closeModal}
      >
        {intl.formatMessage({
          id: 'common.cancel',
          defaultMessage: 'Отмена',
        })}
      </Button>
      <Button
        className={style.footer__btn}
        color="black"
        onClick={handleSubmit}
      >
        {intl.formatMessage({
          id: 'common.bind',
          defaultMessage: 'Привязать',
        })}
      </Button>
    </div>
  )
}

export default React.memo(ActionButtons)
