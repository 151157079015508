import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'

import { IUserAddInfo } from '../interfaces'
import style from '../styles/index.module.scss'

/**
 * * компонент правого сайдбара пользователя
 * c доп информацией (почта и hid номер карты)
 * @view UserAddInfo
 *
 * @param cardHidNumber   hid номер карты
 * @param email           почта
 * @returns
 */
const UserAddInfo: FC<IUserAddInfo> = ({
  cardHidNumber,
  email
}) => {
  const intl = useIntl()
  return (
    <div className={style['user-sidebar__add-info']}>
      <div className={style['user-sidebar__add-info__content']}>
        <div className={style['user-sidebar__add-info__content_icon']}>
          <Icon size='small-xl' src='card' />
        </div>
        <div className={style['user-sidebar__add-info__content_text']}>
          <Typography className={style['user-sidebar__add-info__hid']} size={14} weight={400}>
            {intl.formatMessage({ id: 'user.field.card_hid_number', defaultMessage: 'card hid number' })}
          </Typography>
          <Typography size={16} weight={700}>
            {cardHidNumber}
          </Typography>
        </div>
      </div>
      <div className={style['user-sidebar__add-info__content']}>
        <div className={style['user-sidebar__add-info__content_icon']}>
          <Icon size='small-xl' src='mail' />
        </div>
        <div className={style['user-sidebar__add-info__content_text']}>
          <Typography size={14} weight={400}>
            {intl.formatMessage({ id: 'user.field.email', defaultMessage: 'Email' })}
          </Typography>
          <Typography className={style['user-sidebar__add-info__email']} size={16} weight={700}>
            {email}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default UserAddInfo
