import React from 'react'
import { useIntl } from 'react-intl'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { Typography } from '@/views/typography'
import LinkList from './LinksList'

import { ILinksLinkProps } from '../interfaces'

import style from './style/index.module.scss'

interface IProps extends ILinksLinkProps {
  memoNPS: string
  email: string
  handleOpenEditProfile: () => void
}
/**
 * @view
 * * Контент для мобилки в профиле юзера
 * * Левая панель
 * @param param0
 * @returns
 */
const MobileContentAsideProfile: React.FC<IProps> = ({
  memoNPS,
  email,
  handleOpenLogoutModal,
  handleOpenEditProfile,
  handleOpenNotificationModal
}) => {
  const intl = useIntl()
  return (
    <div>
      <div className={style.top}>
        <Typography
          className={style.top__title}
          size={18}
          weight={700}
          color='black'
        >
          {memoNPS}
        </Typography>
        <Typography
          className={style.top__email}
          size={16}
          weight={400}
          color='gray'
        >
          {email}
        </Typography>
        <Button
          onClick={handleOpenEditProfile}
          color='black'
        >
          <Icon src='edit' slot='icon-left' />
          <span>
            {intl.formatMessage({
              id: 'editProfile',
              defaultMessage: 'Редактироать профиль'
            })}
          </span>
        </Button>
      </div>
      <LinkList
        handleOpenNotificationModal={handleOpenNotificationModal}
        handleOpenLogoutModal={handleOpenLogoutModal}
      />
    </div>
  )
}

export default MobileContentAsideProfile
