import React, { FC } from 'react'

import { useIntl } from 'react-intl'
import cn from 'classnames'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'

import { isUndefined } from '@/utils'

import { PROBLEM_EDIT_TYPE } from '../../const'

import { IEditStatus } from './interfaces'

import style from './style/index.module.scss'

/**
 * * вью для реализаци хедера модалки создания/редактирования проблемы
 * @view
 *
 * @param id                id текущей проблемы
 */
const EditStatus: FC<IEditStatus> = ({ id, closeModal }) => {
  const intl = useIntl()
  const isNewProblem = isUndefined(id)
  const status = isNewProblem
    ? PROBLEM_EDIT_TYPE.CREATE
    : PROBLEM_EDIT_TYPE.EDIT
  const titleText = isNewProblem
    ? { id: 'problem.title.new', defaultMessage: 'Новая проблема' }
    : { id: 'problem.title.edit', defaultMessage: 'Редактировать проблему' }
  return (
    <div className={style.problem__header}>
      <div
        className={cn(
          style.problem__header__identifier,
          style[`problem__header__identifier_${status}`]
        )}
      >
        <Icon
          className={style.problem__header__icon}
          src='problem'
          size='xl'
        />
      </div>
      <Typography
        size={32}
        weight={700}
        className={style.problem__header__title}
      >
        {intl.formatMessage(titleText)}
      </Typography>
      <Icon
        src='close'
        className={style.problem__header__icon_close}
        onClick={closeModal}
      />
    </div>
  )
}

export default EditStatus
