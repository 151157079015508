import React, { SyntheticEvent } from 'react'

import { observer } from 'mobx-react'
import { ObservableMap } from 'mobx'
import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'

import { IQueryParams } from '@/api/interfaces'

import Header from './Header'
import UserPanel from './UserPanel'
import Footer, { IFooterUnbindModal } from './Footer'
import ErrorsBlock from '@/components/ErrorsBlock'
import SearchInput from '@/views/SearchInput'
import ScrollWrap, { IScrollWrapProps } from '../ScrollWrap'
import UserItem from './UserItem'

import style from './style/index.module.scss'

export type TUserValue = {
  id: number
  full_name: string
  last_name: string
  first_name: string
}

interface IProps {
  footerProps: IFooterUnbindModal
  handleParams: (param: IQueryParams) => void
  handleClearUserList: () => void
  handleSubmit: (e: SyntheticEvent<HTMLFormElement>) => void
  userIdList: ObservableMap<number, number>
  users: TUserValue[]
  fetchErrors?: string[]
  handleChangeUserList: (
    e: GxCheckboxCustomEvent<HTMLGxCheckboxElement>,
  ) => void
  scrollWrapProps: IScrollWrapProps
  isScrollEnd: boolean
}

/**
 * @view
 * * Рутовая вьюха модалки привязки пользователей
 *
 * @param handleParams         функция изменения query параметров
 * @param handleClearUserList  отчистка Map
 * @param handleSubmit         метод для привязки пользователей к шкафу
 * @param users                непривязанные пользователи
 * @param handleChangeUserList метод для изменения состояния выбранного пользователя
 * @param userIdList           Map выбранных пользователей
 * @param footerProps          пропсы для кнопок в футор
 * @param scrollWrapProps      пропсы для обертки лейзи лоада
 * @param isScrollEnd          флаг доскроллено ли до самого низа контейнера
 */
const EquipmentBinding: React.FC<IProps> = ({
  handleParams,
  handleClearUserList,
  users,
  userIdList,
  handleChangeUserList,
  handleSubmit,
  footerProps,
  fetchErrors,
  scrollWrapProps,
  isScrollEnd,
}) => {
  return (
    <form
      className={style['user-binding']}
      onSubmit={handleSubmit}
    >
      <Header
        handleClose={footerProps.closeModal}
        title={{
          id: 'user.userBinding',
          defaultMessage: 'Привязка пользователя',
        }}
      />
      <main>
        <section className={style['user-binding__section']}>
          <SearchInput
            className={style['user-binding__search']}
            handleParams={handleParams}
          />
          <UserPanel
            userIdList={userIdList}
            handleClearUserList={handleClearUserList}
          />
          <div
            className={
              style['user-binding__section__users-wrap']
            }
            data-scrollend={isScrollEnd}
          >
            <ScrollWrap {...scrollWrapProps}>
              {users.map(
                ({
                  full_name: fullName,
                  id,
                  first_name: firstName,
                  last_name: lastName,
                }) => {
                  return (
                    <UserItem
                      key={id}
                      id={id}
                      firstName={firstName}
                      lastName={lastName}
                      userIdList={userIdList}
                      handleChangeUserList={
                        handleChangeUserList
                      }
                      fullName={fullName}
                    />
                  )
                },
              )}
            </ScrollWrap>
          </div>
        </section>
        <Footer {...footerProps} />
        <ErrorsBlock errorsArray={fetchErrors} />
      </main>
    </form>
  )
}

export default observer(EquipmentBinding)
