import React from 'react'

import EquipmentBind from '../Core'
import { useStores } from '@/hooks'
import { IEquipmentQueryParams } from '@/api/interfaces'
import { isNull, isUndefined } from '@/utils'
import { IModal } from '../../interfaces'

interface IProps extends IModal {
  modalProps: {
    refetch: () => void
  }
}

const EquipmentBindToStorage: React.FC<IProps> = ({
  closeModal,
  modalProps,
}) => {
  const { refetch } = modalProps
  const {
    api,
    currentStorageStore: { storage },
  } = useStores()

  const handleFetchEquipments = async (
    param: IEquipmentQueryParams,
  ) => await api.equipment.getEquipmentsHoCanBeTied(param)

  const handleBindEquipments = async (
    equipIdArr: number[],
  ) => {
    if (!isNull(storage) && !isUndefined(storage?.id)) {
      return await api.equipment.tieEquipments(storage.id, {
        equipment_ids: equipIdArr,
      })
    }
  }

  return (
    <EquipmentBind
      handleFetchEquipments={handleFetchEquipments}
      handleBindEquipments={handleBindEquipments}
      refetch={refetch}
      closeModal={closeModal}
    />
  )
}

export default EquipmentBindToStorage
