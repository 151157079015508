import React, { useRef, JSX } from 'react'

import UserStorageTableView from '@/apps/RightSidebar/views/UserStorageTableView'
import * as UserStorageViews from '@/apps/RightSidebar/views/UserStorageTableView/UserStorageViews'

import { useLazyFetch, useStores } from '@/hooks'

import {
  DEFAULT_ERROR_ARRAY,
  EMPTY_CONSTS,
  MODAL_TYPES,
  TABLE_WRAPPER_ICON_SRC,
  TABLE_WRAPPER_TYPE,
} from '@/const'
import { STORAGE_TABLE_KEYS } from '@/apps/RightSidebar/const'
import { TGroupStorage } from '@/api/interfaces/GroupUsers'

import styles from '@/apps/RightSidebar/views/UserStorageTableView/styles/index.module.scss'

interface IProps {
  groupId: number
}
/**
 * @component
 * * Компонент таблицы Шкафов в деталке группы пользователей
 */
const Storages: React.FC<IProps> = ({ groupId }) => {
  const { api, modalStore } = useStores()
  const containerRef = useRef<HTMLDivElement>(null)
  const {
    handleFetch,
    params,
    isLoading,
    error = DEFAULT_ERROR_ARRAY,
    lazyLoadedData,
    tableScroll,
    refetch,
    isScrollEnd,
  } = useLazyFetch(
    containerRef,
    async () =>
      await api.groupUsers.getTiedStorages(groupId, params),
  )
  const { search } = params

  const handleOpenBindStorageModal = () => {
    modalStore.open(MODAL_TYPES.USER_GROUP_BIND_STORAGES, {
      id: groupId,
      refetch,
    })
  }

  const renderIdCell = (
    storageId: number,
    storageData: TGroupStorage,
  ): JSX.Element => {
    const handleUnbindUserInStorage = () => {
      return api.storage.unbindStorageByUserGroup(
        storageId,
        { group_id: groupId },
      )
    }
    return UserStorageViews.UnlinkStorage({
      handleUnbindMethod: handleUnbindUserInStorage,
      refetch,
      storageData,
    })
  }

  const columns = [
    {
      key: STORAGE_TABLE_KEYS.COUNT_CELLS,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: UserStorageViews.CountCells,
        className: styles.table__body_count,
      },
    },
    {
      key: STORAGE_TABLE_KEYS.TITLE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: UserStorageViews.Title,
        className: styles.table__body_title,
      },
    },
    {
      key: STORAGE_TABLE_KEYS.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: renderIdCell,
        className: styles.table__body_unlink,
      },
    },
  ]

  const searchPanelProps = {
    handleParams: handleFetch,
    handleOpen: handleOpenBindStorageModal,
    dataLength: lazyLoadedData?.length,
    search,
  }

  const tableWrapProps = {
    search,
    error,
    length: lazyLoadedData?.length,
    handleClick: handleOpenBindStorageModal,
    iconSrc: TABLE_WRAPPER_ICON_SRC.STORAGE,
    type: TABLE_WRAPPER_TYPE.USER_GROUP_STORAGE_TAB,
  }

  return (
    <UserStorageTableView
      isScrollEnd={isScrollEnd}
      searchPanelProps={searchPanelProps}
      tableWrapProps={tableWrapProps}
      containerRef={containerRef}
      tableScroll={tableScroll}
      lazyLoadedData={lazyLoadedData}
      columns={columns}
      isLoading={isLoading}
    />
  )
}

export default Storages
