import Media from 'react-media'
import React from 'react'
import { isUndefined } from '@/utils'
import { EMPTY_CONSTS, PAGE_SIZES } from '@/const'
import { QUERIES } from '@/apps/ProblemList/const'

type TWithMediaPageSize = (param: {
  queries: Record<string, string>
  sizes?: Record<string, number>
  children: (
    pageSize: number | undefined
  ) => React.ReactNode
}) => React.ReactElement

/**
 * Компонент для получения размера страаницы в зависимости от размеров и запросов
 * @param queries - запросы
 * @param sizes - размеры
 * @param children
 * @constructor
 */
const WithMediaPageSize: TWithMediaPageSize = ({
  queries,
  sizes = PAGE_SIZES,
  children
}) => {
  return (
    <Media queries={queries}>
      {(matches) => {
        const match = Object.keys(matches).find(
          (match) => matches[match]
        ) as keyof typeof QUERIES | undefined
        const pageSize = !isUndefined(match)
          ? sizes[match]
          : EMPTY_CONSTS.UNDEFINED
        return children(pageSize)
      }}
    </Media>
  )
}

export default WithMediaPageSize
