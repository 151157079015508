import React from 'react'

import style from '../style/index.module.scss'

interface IProps {
  email: string
}

const EmailCell: React.FC<IProps> = ({ email }) => {
  return (
    <p className={style.table__body_email}>{email}</p>
  )
}

export default EmailCell
