import React from 'react'

import { GxForm } from '@garpix/garpix-web-components-react'
import { TForm } from '@/interfaces/Common'

/**
 * * Вью формы Garpix Component
 * @view
 *
 * @param className - стили для таблицы
 * @func  onSubmit  - метод отробатывающий на сабмит формы
 */
const Form: TForm = React.forwardRef(
  (
    { children, className, onSubmit },
    ref: React.RefObject<HTMLGxFormElement>
  ) => {
    return (
      <GxForm
        className={className}
        onGx-submit={onSubmit}
        ref={ref}
      >
        {children}
      </GxForm>
    )
  }
)

export default Form
