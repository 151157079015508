import React from 'react'

import { useIntl } from 'react-intl'
import cn from 'classnames'

import { Typography } from '@/views/typography'
import Icon from '@/components/Icon'

import styles from './styles/index.module.scss'

export interface IProblemsEmpty {
  isSearch: boolean
}

/**
 * Вюха при пустом списке проблем
 * @view
 * @param isSearch - флаг о наличии данных через поиск
 */
const ProblemsEmpty: React.FC<IProblemsEmpty> = ({ isSearch }) => {
  const intl = useIntl()
  const text = isSearch
    ? {
        id: 'search.empty',
        defaultMessage: 'По данному запросу ничего не найдено'
      }
    : {
        id: 'emptyEquipmentProblem',
        defaultMessage: 'Проблем с оборудованием не обнаружено'
      }

  return (
    <div className={cn(styles.empty, { [styles.empty_search]: isSearch })}>
      {isSearch && <Icon src='search' size='small-xxl' />}
      <Typography size={18} weight={700} color='empty'>
        {intl.formatMessage(text)}
      </Typography>
    </div>
  )
}

export default ProblemsEmpty
