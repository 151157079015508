import React from 'react'
import { FormattedMessage } from 'react-intl'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { Typography } from '@/views/typography'
import { SuccessStatusIcon } from '@/assets/icon'
import styles from '../style/index.module.scss'

/**
 * @component SuccessPassword
 * * компонент формы успешного изменения пароля (редирект на страницу списка шкафов через 3 секунды)
 */
const SuccessPassword = (): React.ReactElement => {
  return (
    <div className={styles.container_success}>
      <GxIcon
        src={SuccessStatusIcon}
        className={styles.container__icon}
      />
      <Typography size={30} className={styles.container__successtext}>
        <FormattedMessage
          id='recovery.change_pass.success'
          defaultMessage='Ваш пароль успешно изменен!'
        />
      </Typography>
    </div>
  )
}

export default SuccessPassword
