import React from 'react'

import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import { DEFAULT_ERROR_ARRAY, EMPTY_CONSTS } from '@/const'
import { checkHasError } from '@/utils'

import style from './styles/index.module.scss'
import Icon from '../Icon'

interface IProps {
  networkError?: boolean
  errorsArray?: string[] | null | undefined
  className?: string
}

/**
 * @component ErrorsBlock
 * * компонент отображения ошибок
 *
 * @param {string[]}   errorsArray  массив ошибок
 * @param {boolean}    networkError ошибка интернет соединения\запроса
 * @param {string}     className  доп стилизация компоненты
 */
const ErrorsBlock = ({
  errorsArray,
  networkError,
  className = EMPTY_CONSTS.STR,
}: IProps): React.ReactElement => {
  const errors = errorsArray ?? DEFAULT_ERROR_ARRAY
  const isErrors = checkHasError(errors)
  const isNetworkError = Boolean(networkError)
  return (
    <div
      className={classNames(style.errors_hide, {
        [style.errors_show]: Boolean(
          isErrors || isNetworkError,
        ),
        [className]: Boolean(className),
      })}
    >
      <Icon size="small" src="error" />
      {isErrors
        ? errors.map((el) => {
            return (
              <span className={style.errors__text} key={el}>
                {el}
              </span>
            )
          })
        : EMPTY_CONSTS.NULL}
      {isNetworkError ? (
        <FormattedMessage
          id="common.network_error"
          defaultMessage="Ошибка сети. Проверьте своё подключение к интернету."
        />
      ) : (
        EMPTY_CONSTS.NULL
      )}
    </div>
  )
}

export default ErrorsBlock
