import { isUndefined } from "@/utils/";

/** Настроить куки
 * @param name название куки
 * @param value значение куки
 * @param expires !необязательный параметр! срок годности куки
 * @returns
 */
export const setCookie = (
  name: string,
  value: string,
  expires?: string
): void => {
  try {
    if (typeof window !== "undefined") {
      document.cookie = `${name}=${value};path=/;
      ${typeof expires === "string" ? `expires=${expires};` : ""}`;
    }
  } catch (error) {
    console.log("error", error);
  }
};

/** Получение куки
 * @param name название куки
 * @returns возвращает значение куки если найдет такую
 */
export const getCookie = (name: string): string | undefined => {
  try {
    if (typeof window === "undefined") {
      return undefined;
    }
    try {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  } catch (error) {
    console.log("error", error);
  }
};

/** Удаление куки
 * @param name название куки
 * @returns
 */
export const removeCookie = (name: string): void => {
  try {
    if (!isUndefined(document)) {
      document.cookie = `${name}=; path=/`;
    }
  } catch (error) {
    console.log("error", error);
  }
};

/** Удаление всех кук
 * @returns
 */
export const deleteAllCookies = (): void => {
  try {
    if (typeof window !== "undefined") {
      const cookies = document.cookie.split("; ");

      for (let i = 0; i < cookies.length; i += 1) {
        const domains = global.location.hostname.split(".");

        while (domains.length > 0) {
          const cookieName = encodeURIComponent(
            cookies[i].split(";")[0].split("=")[0]
          );

          const cookieBase = `${cookieName}=; expires=Thu, 01-Jan-1970 00:00:01 GMT;path=`;
          const paths = window.location.pathname.split("/");
          document.cookie = `${cookieBase}/`;
          while (paths.length > 0) {
            document.cookie = `${cookieBase}/${paths.join("/")}`;
            paths.pop();
          }
          domains.shift();
        }
      }
    }
  } catch (error) {
    console.log("error", error);
  }
};
