import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'

import { FormikHelpers, useFormik } from 'formik'

import { useStores } from '@/hooks'
import useFetch from '@/hooks/useFetch'

import StorageEditView from '../../views/StorageEditView'

import { EMPTY_CONSTS, EMPTY_STORAGE } from '@/const'
import {
  checkChangedStorageFields,
  getObjectErrorsFormik,
  getChangedFields,
  isUndefined
} from '@/utils'
import {
  StorageEditSheme,
  StorageCreateShema
} from '@/utils/ValidateSchemes/StorageEditSheme'

import {
  TStorageCreateValue,
  TStorageGetValue
} from '@/api/interfaces'
import { IModal } from '../interfaces'

interface IProps extends IModal {
  modalProps: {
    id?: number
    refetch?: () => void
  }
}

/**
 * * компонент создания - редактирования шкафа
 * @component
 *
 * @param modalProps - пропсмы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */

const StorageEdit: React.FC<IProps> = ({
  modalProps,
  closeModal
}): React.ReactElement => {
  const [
    hasChangeImportantFields,
    setHasChangeImportantFields
  ] = useState<boolean>(false)

  const { api, currentStorageStore } = useStores()
  const { refetch } = modalProps
  const projectId = modalProps?.id
  const currentValidateSheme =
    projectId !== EMPTY_CONSTS.UNDEFINED
      ? StorageEditSheme
      : StorageCreateShema

  /**
   * @func getInitialValue
   * * функция получения данных о изменяемой модалки если она есть
   * @param id - id текущего шкафа
   */
  const getInitialValue = (
    id?: number
  ): Promise<TStorageGetValue> | undefined => {
    if (id !== EMPTY_CONSTS.UNDEFINED) {
      return api.storage.getStorage(id)
    }
  }

  const {
    data: defaultValues,
    error,
    handleFetchError
  } = useFetch(async () => await getInitialValue(projectId))

  /**
   * @func onSubmit
   * * функция реализующая логику создание/редактирования шкафа
   * @param value - вводимые данные шкафа
   */
  const onSubmit = async (
    value: TStorageCreateValue,
    action: FormikHelpers<TStorageCreateValue>
  ): Promise<void> => {
    try {
      if (projectId !== EMPTY_CONSTS.UNDEFINED) {
        const changedField = getChangedFields(
          formik.initialValues,
          value
        )
        await api.storage.changeStorage(
          projectId,
          changedField as TStorageCreateValue
        )
        currentStorageStore.setStorageData(changedField)
      } else {
        await api.storage.createStorage(value)
      }

      if (!isUndefined(refetch)) {
        refetch()
      }

      closeModal()
    } catch (error) {
      if (error?.response?.data !== EMPTY_CONSTS.UNDEFINED) {
        const formattedError = getObjectErrorsFormik(error)
        action.setErrors(formattedError)
      }
      handleFetchError(error)
    } finally {
      action.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues:
      defaultValues !== EMPTY_CONSTS.UNDEFINED
        ? defaultValues
        : EMPTY_STORAGE,
    validationSchema: currentValidateSheme,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (value, action) => {
      void onSubmit(value, action)
    }
  })

  /**
   * @func setCheckChangedStorageFields
   * * функция для изменения флага говорящем о изменении важных
   * * поелй
   * @param name         имя текущего поля
   * @param fieldValue   значение текщего поля
   */

  const setCheckChangedStorageFields = (
    name: string,
    fieldValue: string
  ): void => {
    if (
      defaultValues !== EMPTY_CONSTS.UNDEFINED &&
      projectId !== EMPTY_CONSTS.UNDEFINED
    ) {
      const thisFieldChanged =
        defaultValues[name] !== fieldValue
      // ! проверка на наличие измененого поля и не выкл предупреждения об изменении
      if (thisFieldChanged && !hasChangeImportantFields) {
        setHasChangeImportantFields(true)
      }
      // ! проверка если измененое поле привели к изначальному состоянию
      // ! и вкл предупреждение об изменении
      if (
        !thisFieldChanged &&
        checkChangedStorageFields(
          defaultValues,
          formik.values
        )
      ) {
        setHasChangeImportantFields(false)
      }
    }
  }

  /**
   * @func handleChangedImportantFields
   * * функция проверяющая изменения только важных полей
   * * для переподключения шкафа
   * @param e - event
   */
  const handleChangedImportantFields = (
    e: GxInputCustomEvent<HTMLGxInputElement>
  ): void => {
    setCheckChangedStorageFields(
      e.target.name,
      e.target.value
    )

    void formik.setFieldTouched(e.target.name, true)
  }

  return (
    <StorageEditView
      formikTools={formik}
      closeModal={closeModal}
      projectId={projectId}
      hasChangeImportantFields={hasChangeImportantFields}
      handleChangedImportantFields={
        handleChangedImportantFields
      }
      error={error}
    />
  )
}

export default observer(StorageEdit)
