import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import Icon from '@/components/Icon'
import { EquipmentStatusIcon } from '@/components/EquipmentStatusIcon'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import { IEquipmentModal } from '../interfaces'
import style from '../styles/index.module.scss'

/**
 * * компонент модального окна с ошибкой отвязки оборудования
 * @component EquipmentUnlinkError
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const EquipmentUnlinkError: FC<IEquipmentModal> = ({
  modalProps,
  closeModal
}) => {
  const { equipment: { status } } = modalProps
  const intl = useIntl()

  return (
    <div className={style.unlink}>
      <div className={style.unlink__icons}>
        <Icon src='linkOff' size='xl' />
        <Icon
          src='close'
          className={style.unlink__icons_close}
          onClick={closeModal}
        />
      </div>
      <div className={style.unlink__status}>
        <Typography
          className={style.unlink__status_text}
          weight={700}
          size={32}
        >
          {intl.formatMessage({
            id: 'modal.title.equipment_unlink_error',
            defaultMessage: 'Вы не можете отвязать оборудование cо статусом'
          })}
        </Typography>
        <EquipmentStatusIcon
          className={style.unlink__status_icon}
          type={status}
        />
      </div>
      <Button
        className={style.unlink__button}
        color='black'
        onClick={closeModal}
      >
        {intl.formatMessage({
          id: 'common.its_ok',
          defaultMessage: 'Понятно'
        })}
      </Button>
    </div>
  )
}

export default EquipmentUnlinkError
