import React from 'react'
import {
  ICopyTag,
  INotificationProps,
} from '../../interfaces'
import { useIntl } from 'react-intl'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { CopyIcon } from '@/assets/icon'

import styles from './styles/index.module.scss'

interface ICopyTagProps
  extends INotificationProps,
    Omit<ICopyTag, 'type'> {}

const CopyTag: React.FC<ICopyTagProps> = () => {
  const intl = useIntl()
  return (
    <div className={styles['copy-tag']}>
      <GxIcon src={CopyIcon} />
      <span className={styles['copy-tag__title']}>
        {intl.formatMessage({
          id: 'tagCopied',
          defaultMessage: 'Номер метки скопирован',
        })}
      </span>
    </div>
  )
}

export default CopyTag
