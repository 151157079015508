import React from 'react'
import { Typography } from '@/views/typography'
import styles from '../styles/index.module.scss'
import { UserDataPropsType } from '../types'

interface IComponentsProps {
  userData?: UserDataPropsType
}
/**
 * * Вьюха с фамилией и именем юзера
 */
export const FullNameWithBadgeView: React.FC<
  IComponentsProps
> = ({ userData }) => (
  <Typography
    className={styles['sync-error__banner_full-name']}
  >
    {userData?.first_name} {userData?.last_name}
  </Typography>
)
/**
 * * Вьюха с hid номером
 */
export const HidView: React.FC<IComponentsProps> = ({
  userData,
}) => (
  <Typography>HID: {userData?.card_hid_number}</Typography>
)
/**
 * * Вьюха с hid номером и ФИ
 */
export const FullNameWithHidView: React.FC<
  IComponentsProps
> = ({ userData }) => (
  <div className={styles['banner-wrap']}>
    <div className={styles['banner-wrap__left']}>
      <FullNameWithBadgeView userData={userData} />
    </div>
    <div className={styles['banner-wrap__right']}>
      <HidView userData={userData} />
    </div>
  </div>
)
