import React from 'react'
import classNames from 'classnames'
import style from './styles/index.module.scss'

/**
 * @component EquipmentStatusNumber
 * * компонент иконок номера оборудования с цветом статуса
 * @param {IEquipmentStatusIcon}                 общий интерфейс входных параметров EquipmentStatusIcon
 *
 * @param type         тип иконки
 * @param className    дополнительные стили
 */
const EquipmentStatusNumber: React.FC<{ value?: string, type?: string, className?: string }> = ({
  type = 'new',
  value = '',
  className = ''
}) => {
  const isBig = value.length >= 3
  const iconClasses = classNames({
    [style.icon_number]: true,
    [style[`icon--${type ?? 'new'}`]]: Boolean(type),
    [style['icon_number--big']]: isBig,
    [className]: Boolean(className)
  })

  if (value === '') {
    return null
  }

  return (
    <div className={iconClasses}>
      {value}
    </div>
  )
}

export default EquipmentStatusNumber
