import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import Profile from '@/components/Profile'
import styles from '../styles/index.module.scss'
import { IReturnHookEditUsetForm } from '@/hooks/useEditUserForm'
import { TShowingPanelProps } from '../interfaces'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { USER_SHOWING_PANELS } from '@/const'
import Avatar from '@/components/Avatar'

type IProps =
  IReturnHookEditUsetForm &
  TShowingPanelProps

/**
 * @info Правая секция с формой
 * @returns
 */
const SectionRight: FC<IProps> = ({
  currentShowingPanel,
  setCurrentShowingPanel,
  actualInitials,
  ...props
}) => {
  const intl = useIntl()

  const handleOpenProfileAside = (): void => {
    setCurrentShowingPanel(USER_SHOWING_PANELS.ASIDE_LEFT)
  }
  return (
    <section
      data-show={currentShowingPanel}
      className={styles.profile__right}
    >
      <div className={styles.profile__right_top}>
        <Button
          onClick={handleOpenProfileAside}
          variant='text'
        >
          <Icon src='arrowBack' />
        </Button>
        <Avatar
          size='big'
          changable={false}
          name={actualInitials.first_name}
          surname={actualInitials.last_name}
          className={styles.profile__right_avatar}
        />
        <span className={styles.profile__right__title}>
          {intl.formatMessage({
            id: 'profile.title',
            defaultMessage: 'Данные профиля'
          })}
        </span>
      </div>
      <Profile.Form {...props} />
    </section>
  )
}

export default SectionRight
