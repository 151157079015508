import React from 'react'

import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import DateInput from '@/components/DateInput'
import { Button } from '@/views/button'

import style from './style/index.module.scss'
import { DATA_INPUT_THEME } from '@/const'
interface IProps {
  closeModal: () => void
  formik: FormikProps<{
    date_start?: string
    date_end?: string
  }>
}
/**
 * @view
 * * вью модалки для скачивания истории
 */
const DownloadHistoryView: React.FC<IProps> = ({
  closeModal,
  formik
}) => {
  const intl = useIntl()
  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    handleBlur
  } = formik

  const handleChangeDateStart = (date: string): void => {
    void setFieldValue('date_start', date)
  }

  const handleChangeDateEnd = (date: string): void => {
    void setFieldValue('date_end', date)
  }

  return (
    <form onSubmit={handleSubmit} className={style.history}>
      <div className={style.history__header}>
        <Icon className={style.history__icon} src='clock' />
        <h3 className={style.history__title}>
          {intl.formatMessage({
            id: 'common.downloadHistory',
            defaultMessage: 'Скачать историю'
          })}
        </h3>
        <Button
          className={style.history__close}
          onClick={closeModal}
          variant='text'
          color='gray'
        >
          <Icon src='close' />
        </Button>
      </div>
      <div>
        <p className={style.history__desc}>
          {intl.formatMessage({
            id: 'choosePeriod',
            defaultMessage: 'Выберите период'
          })}
        </p>
        <div className={style.history__data_fields}>
          <DateInput
            name='date_start'
            date={values.date_start}
            onBlur={handleBlur}
            error={
              Boolean(errors.date_start) &&
              touched.date_start
            }
            errorMessage={errors.date_start}
            handleDateChange={handleChangeDateStart}
            className={style.history__input}
            theme={DATA_INPUT_THEME.FULL}
          />
          <span className={style.history__slash} />
          <DateInput
            name='date_end'
            date={values.date_end}
            error={
              Boolean(errors.date_end) && touched.date_end
            }
            errorMessage={errors.date_end}
            onBlur={handleBlur}
            handleDateChange={handleChangeDateEnd}
            className={style.history__input}
            theme={DATA_INPUT_THEME.FULL}
          />
        </div>
      </div>
      <div className={style.history__footer}>
        <Button onClick={closeModal} color='gray'>
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Отмена'
          })}
        </Button>
        <Button
          disabled={isSubmitting}
          type='submit'
          className={style.history__btn_generation}
          color='black'
        >
          <Icon src='download' />
          {intl.formatMessage({
            id: 'generate',
            defaultMessage: 'Сформировать'
          })}
        </Button>
      </div>
    </form>
  )
}

export default DownloadHistoryView
