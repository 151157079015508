import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import { Button } from '@/views/button'

import { IActionFilterButtons } from '../interfaces'
import style from '../style/index.module.scss'

/**
 * * вью текущих кнопок в модалке фильтра
 * @view
 * @param id
 * @returns
 */
const ActionButtons: FC<IActionFilterButtons> = ({
  handleSave,
  handleReset
}) => {
  const intl = useIntl()

  return (
    <div className={style.filter__footer}>
      <Button
        className={style.filter__btn}
        onClick={handleReset}
      >
        {intl.formatMessage({ id: 'common.reset', defaultMessage: 'Сбросить' })}
      </Button>
      <Button
        className={style.filter__btn}
        color='black'
        onClick={handleSave}
      >
        {intl.formatMessage({ id: 'common.apply', defaultMessage: 'Применить' })}
      </Button>
    </div>
  )
}

export default React.memo(ActionButtons)
