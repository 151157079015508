import React, { useEffect } from 'react'

import { observer } from 'mobx-react'

import { useStores } from '@/hooks'
import useFetch from '@/hooks/useFetch'
import {
  DEFAULT_PAGE_SIZE,
  EMPTY_CONSTS,
  PAGE_SIZES
} from '@/const'
import { IStoragesQueryParams } from '@/api/interfaces'
import { QUERIES } from '@/apps/StorageList/const'
import { isUndefined } from '@/utils'

import WithMediaPageSize from '@/components/WithMediaPageSize'
import StorageTable from './views/StorageTable'
import StoragePanel from './views/StoragePanel'

import styles from './styles/index.module.scss'

/**
 * @component StorageList
 * * компонент страницы списка шкафов
 */
const StorageList: React.FC<{
  pageSize: number | undefined
}> = observer(({ pageSize }) => {
  const { api, storageListStore, StorageEventsObserver } =
    useStores()
  const {
    data,
    refetch,
    isLoading,
    handleParams,
    params = EMPTY_CONSTS.OBJ as IStoragesQueryParams,
    handlePage,
    error
  } = useFetch(api.storage.getStorages, {
    page_size: pageSize ?? DEFAULT_PAGE_SIZE
  })
  const { page } = params

  useEffect(() => {
    if (
      !isUndefined(pageSize) &&
      pageSize !== params.page_size
    ) {
      handleParams({ page_size: pageSize })
    }
  }, [pageSize])

  useEffect(() => {
    if (!isUndefined(data)) {
      storageListStore.setStorageList(data.results)
    }
  }, [data])

  useEffect(() => {
    StorageEventsObserver.subscribe(refetch)
    return () => {
      StorageEventsObserver.unsubscribe(refetch)
    }
  }, [])

  return (
    <div className={styles.container}>
      <StoragePanel
        handleParams={handleParams}
        params={params}
        refetch={refetch}
      />
      <StorageTable
        params={params}
        storages={storageListStore.storages}
        handleParams={handleParams}
        isLoading={isLoading}
        handlePage={handlePage}
        currentPage={page}
        total={data?.count}
        refetch={refetch}
        error={error}
      />
    </div>
  )
})

const EnhancedStorageList: React.FC = () => {
  return (
    <WithMediaPageSize queries={QUERIES} sizes={PAGE_SIZES}>
      {(pageSize) => <StorageList pageSize={pageSize} />}
    </WithMediaPageSize>
  )
}

export default observer(EnhancedStorageList)
