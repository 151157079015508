import React from 'react'
import { observer } from 'mobx-react'

import { useIntl } from 'react-intl'
import { IEquipment } from '@/api/interfaces'
import { MODAL_VARIANT_CONTENT } from '../../const'
import { IModalVariant } from '../../ModalContent/interfaces'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { EquipmentStatusIcon } from '@/components/EquipmentStatusIcon'

import BaseContentViews from '../BaseContentViews'

import style from './style/index.module.scss'

interface IProps {
  equipmentData: IEquipment
  modalType?: IModalVariant
  closeModal: () => void
  handleSubmit: () => void
  isDisabled?: boolean
}
/**
 * @view
 * * вьюха для отрисовки модалки удаления/отвязкти оборудования в
 * * зависимости от ее типа
 * @param modalType - тип отоброжаемого контента
 * @param equipmentData текущее оборудование
 * @param closeModal метод закртия модалкти
 * @param handleSubmit метод для какого либо запроса
 * @param isDisabled флаг для выкл кнопки сабмита
 */
const EquipmentUntieView: React.FC<IProps> = ({
  modalType = MODAL_VARIANT_CONTENT.DELETE,
  equipmentData,
  closeModal,
  handleSubmit,
  isDisabled,
}) => {
  const intl = useIntl()
  const {
    equipment_type: type,
    title,
    status,
  } = equipmentData

  const isDeleteModal =
    modalType === MODAL_VARIANT_CONTENT.DELETE
  const btnText = isDeleteModal
    ? { id: 'common.delete', defaultMessage: 'Удалить' }
    : { id: 'common.unbind', defaultMessage: 'Отвязать' }
  const imgType = isDeleteModal ? 'delete' : 'linkOff'
  const titleText = isDeleteModal
    ? {
        id: 'modal.title.equipment_delete',
        defaultMessage:
          'Вы уверены, что хотите удалить оборудование?',
      }
    : {
        id: 'modal.title.equipment_unbind',
        defaultMessage:
          'Вы точно хотите отвязать оборудование?',
      }

  return (
    <BaseContentViews.Wrapper>
      <BaseContentViews.Header
        colorIconWrapper="red"
        iconSlot={
          <Icon
            src={imgType}
            size="medium-28"
            data-type={modalType}
          />
        }
      >
        <p className={style.container__header_lable}>
          {intl.formatMessage(titleText)}
        </p>
      </BaseContentViews.Header>
      <BaseContentViews.BannerContent
        className={style.container__equipment_margin}
      >
        <div className={style.container__equipment}>
          <div className={style.container__equipment__body}>
            <span
              className={
                style.container__equipment__body_sub
              }
            >
              {type}
            </span>
            <h2
              className={style.container__equipment_title}
            >
              {title}
            </h2>
          </div>
          <EquipmentStatusIcon type={status} />
        </div>
      </BaseContentViews.BannerContent>
      <BaseContentViews.ButtonWrapper>
        <Button
          className={style.container__buttons_cancel}
          onClick={closeModal}
          height={44}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Отмена',
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color="black"
          onClick={handleSubmit}
          disabled={isDisabled}
          height={44}
        >
          {intl.formatMessage(btnText)}
        </Button>
      </BaseContentViews.ButtonWrapper>
    </BaseContentViews.Wrapper>
  )
}

export default observer(EquipmentUntieView)
