import React from 'react'

import { useIntl } from 'react-intl'

import { IPaginatedParams } from '@/api/interfaces'

import { Typography } from '@/views/typography'
import SearchInput from '@/views/SearchInput'
import { Button } from '@/views/button'
import Icon from '@/components/Icon'

import styles from './styles/index.module.scss'

/**
 * Вьюха верхней панели(шапки) списка проблем с поиском и кнопкой добавления
 * @view
 */

export interface IProblemPanelProps {
  handleParams: (param: IPaginatedParams) => void
  handleAddProblem: () => void
}

const ProblemPanelView: React.FC<IProblemPanelProps> = ({
  handleParams,
  handleAddProblem
}) => {
  const intl = useIntl()
  return (
    <div className={styles.panel}>
      <Typography
        size={32}
        weight={700}
        className={styles.panel__title}
      >
        {intl.formatMessage({
          id: 'problems',
          defaultMessage: 'Проблемы'
        })}
      </Typography>
      <div className={styles.panel__search}>
        <SearchInput
          className={styles.panel__search_input}
          handleParams={handleParams}
        />
        <Button
          color='black'
          className={styles.panel__search_add}
          onClick={handleAddProblem}
        >
          <Icon src='plus' />
          {intl.formatMessage({
            id: 'addProblem',
            defaultMessage: 'Добавить проблему'
          })}
        </Button>
      </div>
    </div>
  )
}

export default ProblemPanelView
