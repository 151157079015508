import React, { useEffect } from 'react'

import ErrorsBlock from '@/components/ErrorsBlock'
import UserSidebarView from '../../views/UserSideBarView'

import { useStores } from '@/hooks'
import useFetch from '@/hooks/useFetch'

import { IUserSideBar } from '../../interfaces'

import {
  DEFAULT_USER_INIT_VALUE,
  MODAL_TYPES,
} from '@/const'
import { checkHasError } from '@/utils'
import { IUserGetValue } from '@/api/interfaces'
import { ISocketUpdateUserData } from '@/interfaces/Socket'

/**
 * * компонент правого сайдбара пользователя
 * с детальной информацией о нем
 * @component UserSideBar
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const UserSideBar: React.FC<IUserSideBar> = ({
  contentProps,
}): React.ReactElement => {
  const { api, modalStore, UserEventsObserver } =
    useStores()
  const { id, refetch: handleRefetchUsersList } =
    contentProps
  const {
    data = DEFAULT_USER_INIT_VALUE,
    error,
    refetch: handleRefetchUserValues,
  } = useFetch(async () => await api.user.getUser(id))
  /**
   * @function handleRefetch
   * * метод для рефетча каких либо данных
   * * после изменения данных пользователя
   */
  const handleRefetch = (): void => {
    void handleRefetchUserValues()
    void handleRefetchUsersList()
  }

  /**
   * * функция открытия модалки редактирования пользователя
   * @function handleOpenModalEditUser
   * @returns
   */
  const handleOpenModalEditUser = (): void => {
    modalStore.open(MODAL_TYPES.USER_EDIT, {
      id,
      refetch: handleRefetch,
    })
  }
  /**
   * * метод для обновления данных о пользователе
   * @func updateUserSocket
   * @param userId
   */
  const updateUserSocket = ({
    user_id: userId,
  }: ISocketUpdateUserData) => {
    if (userId === id) {
      handleRefetchUserValues()
    }
  }

  useEffect(() => {
    UserEventsObserver.subscribe(updateUserSocket)
    return () => {
      UserEventsObserver.unsubscribe(updateUserSocket)
    }
  }, [])

  if (checkHasError(error))
    return <ErrorsBlock networkError />

  return (
    <UserSidebarView
      id={id}
      handleEdit={handleOpenModalEditUser}
      user={data as Required<IUserGetValue>}
    />
  )
}

export default UserSideBar
