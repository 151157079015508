import React from 'react'
import { useIntl } from 'react-intl'
import { GxIcon } from '@garpix/garpix-web-components-react'

import BaseContentViews from '../../BaseContentViews'

import { GroupUsers } from '@/assets/icon'
import { getHeaderStatusColor } from '@/apps/Modals/utils'
import { isUndefined } from '@/utils'

import styles from '../styles/index.module.scss'

interface IProps {
  groupId?: number
  isValid: boolean
}

const Header: React.FC<IProps> = ({ groupId, isValid }) => {
  const intl = useIntl()
  const status = getHeaderStatusColor({
    isValid,
    haveEntity: Boolean(groupId),
  })

  const titleText = !isUndefined(groupId)
    ? {
        id: 'group.editGroup',
        defaultMessage: 'Редактировать группу',
      }
    : {
        id: 'group.newGroup',
        defaultMessage: 'Новая группа',
      }

  return (
    <BaseContentViews.Header
      colorIconWrapper={status}
      closeBtnPosition="center"
      iconSlot={
        <GxIcon
          className={styles['group-edit__header_icon']}
          src={GroupUsers}
        />
      }
      className={styles['group-edit__header']}
    >
      <p className={styles['group-edit__header__title']}>
        {intl.formatMessage(titleText)}
      </p>
    </BaseContentViews.Header>
  )
}

export default Header
