import React from 'react'

import { Button } from '@/views/button'
import Icon from '@/components/Icon'

import { useTranslate } from '@/hooks'

import { IModal } from '@/apps/Modals/ModalContent/interfaces'

import style from '../styles/index.module.scss'
interface IProblemEditFormProps extends IModal {
  modalProps: {
    text: string
  }
}
/**
 * Модалка информирующая о статусе синхронизации СКУД
 * @props text - информация о синхронизации (успех или тип ошибки)
 */
const SynchronizationSkud: React.FC<
  IProblemEditFormProps
> = ({ closeModal, modalProps: { text } }) => {
  const { translate, intl } = useTranslate()
  return (
    <div className={style.sync}>
      <div className={style.sync__header}>
        <h2 className={style.sync__title}>
          {intl.formatMessage({
            id: 'modal.title.sync_skud',
            defaultMessage: 'Синхронизация',
          })}
        </h2>
        <Icon
          src="close"
          className={style.container__header_icon_close}
          onClick={closeModal}
        />
      </div>
      <div>{translate(text)}</div>
      <Button
        color="black"
        className={style.sync__button}
        onClick={closeModal}
      >
        {intl.formatMessage({
          id: 'common.its_ok',
          defaultMessage: 'Понятно',
        })}
      </Button>
    </div>
  )
}

export default SynchronizationSkud
