import React from 'react'
import classNames from 'classnames'
import {
  EquipmentStatusIcon,
  EquipmentStatusNumber,
} from '@/components/EquipmentStatusIcon'
import { Typography } from '@/views/typography'
import { SidebarContainer } from '@/apps/RightSidebar/views/SidebarContainer'

import { TEquipStatus } from '@/api/interfaces'
import { isNull, isUndefined } from '@/utils'

import styles from './styles/index.module.scss'

/**
 *
 * Вьюха шапки для правого сайдбара оборудования
 * @view
 * @param [props.number] - номер оборудования
 * @param [props.title] - тайтл оборудования
 * @param [props.storageTitle] - тайтл шкафа
 * @param [props.status] - статус оборудования
 */
interface IEquipmentHeader {
  number: number | null
  title: string
  storageTitle?: string
  status: TEquipStatus
}

const EquipmentHeader: React.FC<IEquipmentHeader> = ({
  number,
  title,
  storageTitle,
  status,
}) => {
  const haveCell = !isNull(number)
  return (
    <div className={styles['equipment-header__root']}>
      <SidebarContainer>
        <div className={styles['equipment-header__grid']}>
          <EquipmentStatusIcon type={status} />
          <Typography
            size={32}
            weight={700}
            className={
              styles['equipment-header__grid_title']
            }
          >
            {title}
          </Typography>

          {!isUndefined(storageTitle) && (
            <div
              className={classNames(
                styles[
                  'equipment-header__subtitle_wrapper'
                ],
                {
                  [styles[
                    'equipment-header__subtitle_wrapper--cell'
                  ]]: haveCell,
                },
              )}
            >
              {haveCell && (
                <EquipmentStatusNumber
                  value={String(number)}
                  type={status}
                  className={
                    styles[
                      'equipment-header__subtitle_number'
                    ]
                  }
                />
              )}
              <Typography size={15} weight={500}>
                {storageTitle}
              </Typography>
            </div>
          )}
        </div>
      </SidebarContainer>
    </div>
  )
}
export default EquipmentHeader
