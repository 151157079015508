import { useEffect, useRef } from 'react'
import { useStores } from './useStores'

import { ANIMATION_TIME } from '@/const'
import { SIDEBAR_TYPES } from '@/apps/MainSidebar/const'
/**
 * * хук для управления левым сайдбаром
 * @hook useLeftSidebar

 */

interface TLeftSidebar {
  handleOpenLeftSidebar: () => void
  handleCloseLeftSidebar: (callback: () => void) => void
  isOpen: boolean
}
const useLeftSidebar = (): TLeftSidebar => {
  const animationRef = useRef<NodeJS.Timeout>()
  const { leftSidebarStore, currentStorageStore } = useStores()
  const { isOpen } = leftSidebarStore

  /**
  * @function handleOpenLeftSidebar
  * * метод для открытия модалки
  * * в данный момент используется лишь для анимации
  */
  const handleOpenLeftSidebar = (): void => {
    leftSidebarStore.open(SIDEBAR_TYPES.STORAGE_DETAILS)
  }

  /**
  * @function handleOpenLeftSidebar
  * * метод для плавного закрытия модалки
  */
  const handleCloseLeftSidebar = (callback: () => void): void => {
    leftSidebarStore.setStartAnimationClosing()
    animationRef.current = setTimeout(() => {
      leftSidebarStore.close()
      currentStorageStore.clearStorage()
      callback()
    }, ANIMATION_TIME.MEDIUM)
  }

  useEffect(() => {
    return () => {
      if (animationRef.current !== undefined) {
        clearTimeout(animationRef.current)
      }
    }
  }, [])

  return {
    handleOpenLeftSidebar,
    handleCloseLeftSidebar,
    isOpen
  }
}

export default useLeftSidebar
