import {
  makeObservable,
  observable,
  _autoAction
} from 'mobx'

import {
  PubSub,
  PubSubProps
} from '../PubSub'

import { RootStore } from './Root'
import { ISocketUpdateUserMessage } from '@/interfaces/Socket'

/**
 * @class
 * * Класс, реализующий возможность подписываться на
 * * событя сокетов Шкафа
 */
export class UserEventsObserver extends PubSub {
  _ctx: RootStore

  constructor (ctx: RootStore) {
    super()

    this._ctx = ctx

    makeObservable(this, {
      ...PubSubProps,
      _ctx: observable,
      onUpdateUser: _autoAction 
    })
  }

  public onUpdateUser = ({ data }: ISocketUpdateUserMessage): void => {
    this.broadcast(data)
  }
}