import React, { useRef, useState } from 'react'

import { useIntl } from 'react-intl'
import { runInAction } from 'mobx'
import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'

import { useLazyFetch } from '@/hooks'
import { useObservableMap } from '../../../hook'

import { getErrorsArrayFromObj } from '@/utils'
import { EMPTY_CONSTS } from '@/const'

import StoragesBindView from '../../../views/StoragesBindView'
import {
  IPaginatedParams,
  IPaginationList,
} from '@/api/interfaces'
import { INotTiedStorages } from '@/apps/Modals/views/StoragesBindView/interfaces'

const StoragesBind: React.FC<{
  handleBindStorages: (idList: number[]) => Promise<void>
  closeModal: () => void
  refetch: () => void
  handleFetchStorages: (
    params: IPaginatedParams,
  ) => Promise<
    IPaginationList & {
      results: INotTiedStorages[]
    }
  >
}> = ({
  handleBindStorages,
  closeModal,
  refetch,
  handleFetchStorages,
}) => {
  const intl = useIntl()
  const refContainer = useRef<HTMLDivElement>(
    EMPTY_CONSTS.NULL,
  )
  const {
    tableScroll,
    lazyLoadedData: storages,
    params,
    handleFetch,
    isScrollEnd,
  } = useLazyFetch(refContainer, handleFetchStorages)
  const [errors, setErrors] = useState<
    string[] | null | undefined
  >(EMPTY_CONSTS.ARR)

  //------------------------------------------------

  const {
    observableMap: storageIdList,
    setObservableMap,
    clearObservableMap,
    deleteValueObservableMap,
  } = useObservableMap<number, number>()

  /**
   * * метод для добавления/удаления id пользователей из Map
   * @func handleSubmit
   * @param e
   */
  const handleChangeStorageList = (
    e: GxCheckboxCustomEvent<HTMLGxCheckboxElement>,
  ): void => {
    runInAction(() => {
      const userId = Number(e.target.dataset.id)
      if (e.target.checked) {
        setObservableMap(userId, userId)
      } else {
        deleteValueObservableMap(userId)
      }
    })
  }

  //------------------------------------------------

  /**
   * * функция отправки запроса на привязывание
   * шкафов к пользователю
   * @func   handleSubmit
   * @param
   */
  const handleSubmit = (): void => {
    const storageIds = Array.from(
      storageIdList,
      ([key]) => key,
    )
    handleBindStorages(storageIds)
      .then(() => {
        closeModal()
        refetch()
      })
      .catch((err) => {
        const error = getErrorsArrayFromObj(intl, err)
        setErrors(error)
      })
  }

  const storageContainerProps = {
    handleChangeStorageList,
    handleClearSelectedStorages: clearObservableMap,
    handleParams: handleFetch,
    params,
    storageIdList,
    storages,
    refContainer,
    tableScroll,
    isScrollEnd,
  }

  return (
    <StoragesBindView
      storageContainerProps={storageContainerProps}
      handleSubmit={handleSubmit}
      closeModal={closeModal}
      errors={errors}
    />
  )
}

export default StoragesBind
