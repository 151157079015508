import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '@/hooks'
import { THEME_MODAL } from '@/const'
import { NoSSR } from '@/components/NoSSR'
import BaseModal from '@/apps/Modals/Base'
import NotificationsPanel from '@/apps/Notifications/components/Panel'

/**
 * Модалка с уведомлениями
 */

const Notifications: React.FC = observer(() => {
  const { notificationStore } = useStores()
  const afterHideSidebar = (): void =>
    notificationStore.close()

  const closeSidebar = (): void => notificationStore.close()

  return (
    <NoSSR>
      <BaseModal
        handleClose={closeSidebar}
        open={notificationStore.isOpen}
        theme={THEME_MODAL.NOTIFICATIONS}
        onGx-after-hide={afterHideSidebar}
        id='notifications'
      >
        {notificationStore.isOpen
          ? (
            <NotificationsPanel closeModal={closeSidebar} />
            )
          : null}
      </BaseModal>
    </NoSSR>
  )
})

export default Notifications
