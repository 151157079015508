import React from 'react'
import { useFetch, useStores } from '@/hooks'
import { IRightSidebarProps } from '../../interfaces'

import { Core } from '@/apps/RightSidebar/views/UserGroupSidebarViews'
import { MODAL_TYPES } from '@/const'
import { isUndefined } from '@/utils'
import { Spinner } from '@/views/spinner'

interface IProps extends IRightSidebarProps {
  contentProps: {
    groupId: number
    refetch: () => void
  }
}
/**
 * @component
 * * Компонент деталки группы пользователей
 */
const UserGroupSidebar: React.FC<IProps> = ({
  contentProps,
}) => {
  const { groupId, refetch } = contentProps
  const { api, modalStore } = useStores()

  const {
    data: userGroup,
    refetch: refetchCurrentGroup,
    isLoading,
  } = useFetch(
    async () =>
      await api.groupUsers.getCurrentGroupUsers(groupId),
  )

  const handleRefetchValues = () => {
    void refetchCurrentGroup()
    void refetch()
  }

  const handleOpenEditGroupModal = () => {
    modalStore.open(MODAL_TYPES.USER_GROUP_EDIT, {
      groupId,
      refetch: handleRefetchValues,
    })
  }

  const panelProps = {
    title: userGroup?.title,
    description: userGroup?.description,
    color: userGroup?.color,
  }

  const footerProps = {
    handleEdit: handleOpenEditGroupModal,
  }

  if (isLoading && isUndefined(userGroup)) {
    return <Spinner isFullPage />
  }

  return (
    <Core
      groupId={groupId}
      footerProps={footerProps}
      panelProps={panelProps}
    />
  )
}

export default UserGroupSidebar
