import AbsractApi from '@/api/abstract'
import {
  IGroupStorageList,
  IGroupUserList,
  IGroupUserQueryParams,
  IGroupUserUntiedStorages,
  IGroupUsersCreate,
  IGroupUsersGetValue,
  INotTiedEquipmentToUserGroupParams,
} from '@/api/interfaces/GroupUsers'
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_LAZY_SIZE,
  DEFAULT_PAGE_SIZE,
} from '@/const'
import { groupUserSerializer } from './serializers'
import {
  INotTiedEquipmentList,
  IPaginatedParams,
  ITiedEquipmentList,
  ITieEquipmentsbody,
  ITiedEquipmentUsersList,
  IUserQueryParams,
} from '@/api/interfaces'

class GroupUserApi {
  _ctx: AbsractApi

  constructor(ctx: AbsractApi) {
    this._ctx = ctx
  }

  public getGroupUsers = async (
    params: IGroupUserQueryParams,
  ): Promise<IGroupUserList> => {
    const res = await this._ctx.get<IGroupUserList>(
      '/group_user/',
      {
        ...params,
        page: params?.page ?? DEFAULT_CURRENT_PAGE,
        page_size: params?.page_size ?? DEFAULT_PAGE_SIZE,
      },
    )

    return res.data
  }

  public createGroupUsers = async (
    values: IGroupUsersCreate,
  ) => {
    const res = await this._ctx.post('/group_user/', values)
    return res.data
  }

  public getCurrentGroupUsers = async (
    id: number,
  ): Promise<IGroupUsersCreate> => {
    const res = await this._ctx.get<IGroupUsersGetValue>(
      `/group_user/${id}/`,
    )
    return groupUserSerializer(res.data)
  }

  public editCurrentGroupUsers = async (
    id: number,
    values: IGroupUsersCreate,
  ): Promise<IGroupUsersCreate> => {
    const res = await this._ctx.patch<IGroupUsersGetValue>(
      `/group_user/${id}/`,
      values,
    )
    return groupUserSerializer(res.data)
  }

  public getTiedUsers = async (
    id: number,
    params: IPaginatedParams,
  ): Promise<ITiedEquipmentUsersList> => {
    const res =
      await this._ctx.get<ITiedEquipmentUsersList>(
        `/group_user/${id}/tied_to_users/`,
        params,
      )
    return res.data
  }

  public getTiedStorages = async (
    id: number,
    params: IPaginatedParams,
  ): Promise<IGroupStorageList> => {
    const res = await this._ctx.get<IGroupStorageList>(
      `/group_user/${id}/tied_to_storages/`,
      params,
    )
    return res.data
  }

  public getUntiedStorages = async (
    id: number,
    param?: IUserQueryParams,
  ): Promise<IGroupUserUntiedStorages> => {
    const res =
      await this._ctx.get<IGroupUserUntiedStorages>(
        `/group_user/${id}/not_tied_to_storage/`,
        {
          ...param,
          page: param?.page ?? DEFAULT_CURRENT_PAGE,
          page_size:
            param?.page_size ?? DEFAULT_PAGE_LAZY_SIZE,
        },
      )
    return res.data
  }

  public tiedStorages = async (
    id: number,
    value: {
      storage_ids: number[]
    },
  ): Promise<void> => {
    await this._ctx.post(
      `/group_user/${id}/tied_storages/`,
      value,
    )
  }

  public getTiedEquipments = async (
    id: number,
    params: IPaginatedParams,
  ): Promise<ITiedEquipmentList> => {
    const res = await this._ctx.get<ITiedEquipmentList>(
      `/group_user/${id}/tied_to_equipments/`,
      params,
    )
    return res.data
  }

  public getNotTiedEquipment = async ({
    groupId,
    ...param
  }: INotTiedEquipmentToUserGroupParams): Promise<INotTiedEquipmentList> => {
    const res = await this._ctx.get<INotTiedEquipmentList>(
      `/group_user/${groupId}/not_tied_to_equipment/`,
      {
        ...param,
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size:
          param?.page_size ?? DEFAULT_PAGE_LAZY_SIZE,
      },
    )
    return res.data
  }

  public tieEquipmentToGroup = async (
    groupId: number,
    values: ITieEquipmentsbody,
  ): Promise<void> => {
    await this._ctx.post(
      `/group_user/${groupId}/tied_equipments/`,
      values,
    )
  }

  public getUntiedUsers = async (
    id: number,
    params: IPaginatedParams,
  ): Promise<ITiedEquipmentUsersList> => {
    const res =
      await this._ctx.get<ITiedEquipmentUsersList>(
        `/group_user/${id}/not_tied_to_user/`,
        {
          ...params,
          page: params?.page ?? DEFAULT_CURRENT_PAGE,
          page_size: params?.page_size ?? DEFAULT_PAGE_SIZE,
        },
      )
    return res.data
  }

  public tiedUsers = async (
    id: number,
    users: number[],
  ): Promise<void> => {
    await this._ctx.post(`/group_user/${id}/tied_users/`, {
      users,
    })
  }

  public unbindUser = async (
    groupId: number,
    userId: number,
  ): Promise<void> => {
    await this._ctx.post(
      `/group_user/${groupId}/unbind_user/`,
      { user_id: userId },
    )
  }

  public deleteGroup = async (
    groupId: number,
  ): Promise<void> => {
    await this._ctx.delete(`/group_user/${groupId}/`)
  }
}

export default GroupUserApi
