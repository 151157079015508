import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { useFetch, useStores } from '@/hooks'

import { IUserQueryParams } from '@/api/interfaces'
import { ITabCompProps } from '../../interfaces'
import { QUERIES, PAGE_SIZES } from '../../const'
import { DEFAULT_PAGE_SIZE } from '@/const'

import ContentContainer from '../../views/ContentContainer'
import UsersView from '../../views/UsersView'
import StorageUserPanel from './StorageUserPanel'
import { isUndefined, serializeFilterParams } from '@/utils'
import WithMediaPageSize from '@/components/WithMediaPageSize'
import { ISocketUpdateUserData } from '@/interfaces/Socket'

/**
 * @component
 * * Компонент реализющий логику взаимодействия
 * * с таблицей пользователей в деталке шкафa
 * @param storageId  id текущего шкафа
 * @returns65
 */
const StorageUsers: React.FC<ITabCompProps & { pageSize?: number }> = observer(
  ({ storageId, pageSize }) => {
    const { api, StorageEventsObserver, ExelEventsObserver, UserEventsObserver } =
      useStores()

    const {
      data,
      params = {},
      refetch,
      handleParams,
      ...fetchParams
    } = useFetch(
      async (params) =>
        await api.user.getUsers({
          ...params,
          storage_id: storageId
        }),
      {
        page_size: pageSize ?? DEFAULT_PAGE_SIZE
      }
    )
    const [filterParams, setFilterParams] = useState<IUserQueryParams>(() => {
      const filteredParams = { ...params }
      delete filteredParams.storage_id
      return filteredParams as IUserQueryParams
    })

    const handleFilterParams = (newParams: IUserQueryParams): void => {
      const serialized = serializeFilterParams(newParams)
      handleParams(serialized)
      setFilterParams(newParams)
    }

    const updateUserListSocket = ({storage_id}:ISocketUpdateUserData) => {
      if (storage_id === storageId) {
        refetch()
      }
    }

    useEffect(() => {
      if (!isUndefined(pageSize) && pageSize !== params.page_size) {
        handleParams({ page_size: pageSize })
      }
    }, [pageSize])

    useEffect(() => {
      StorageEventsObserver.subscribe(refetch)
      ExelEventsObserver.subscribe(refetch)
      UserEventsObserver.subscribe(updateUserListSocket)
      return () => {
        StorageEventsObserver.unsubscribe(refetch)
        ExelEventsObserver.unsubscribe(refetch)
        UserEventsObserver.unsubscribe(updateUserListSocket)
      }
    }, [])

    return (
      <ContentContainer>
        <StorageUserPanel
          storageId={storageId}
          params={filterParams}
          refetch={refetch}
          handleParams={handleFilterParams}
        />
        <UsersView
          users={data?.results}
          total={data?.count}
          params={params}
          currentPage={params.page}
          refetch={refetch}
          handleParams={handleParams}
          {...fetchParams}
        />
      </ContentContainer>
    )
  }
)

const EnhancedStorageUsers: React.FC<ITabCompProps> = (props) => {
  return (
    <WithMediaPageSize queries={QUERIES} sizes={PAGE_SIZES}>
      {(pageSize) => <StorageUsers {...props} pageSize={pageSize} />}
    </WithMediaPageSize>
  )
}

export default EnhancedStorageUsers
