import React from 'react'
import { MODAL_CUSTOM_CONTENT } from '@/const'
import { Custom } from '@/apps/Modals/ModalContent'
import { IModal } from '@/apps/Modals/ModalContent/interfaces'
import styles from './styles/index.module.scss'

/**
 * Модалка при успешной загрузки ексель
 * @param closeModal
 * @constructor
 */
const SuccessUploadExcel: React.FC<IModal> = ({ closeModal }) => {
  const content = MODAL_CUSTOM_CONTENT.SUCCESS_UPLOAD_EXCEL
  return (
    <Custom
      modalProps={content}
      className={styles['upload-excel__root']}
      closeModal={closeModal}
    />
  )
}

export default SuccessUploadExcel
