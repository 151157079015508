import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { GxForm } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'

import ErrorsBlock from '@/components/ErrorsBlock'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import { Input } from '@/views/input'

import { EMPTY_CONSTS } from '@/const'

import { INewPassword } from '../interfaces'

import styles from '../style/index.module.scss'

/**
 * @component NewPassword
 * * компонент формы установки и отправки нового пароля пользователя
 * @param {INewPassword}                   общий интерфейс входных параметров NewPassword
 *
 * @param {function}  handlePasswordValue  функция для изменения пароля вводимого в инпут
 * @param {function}  onSubmit             функция для отправки нового пароля на сервер
 * @param {string}    username             логин (почта) пользователя
 * @param {IError}    error                обьект ошибок
 */
const NewPassword: React.FC<INewPassword> = ({
  handlePasswordValue,
  onSubmit,
  username,
  error
}): React.ReactElement => {
  const intl = useIntl()
  return (
    <>
      <GxForm onGx-submit={onSubmit}>
        <div className={styles.container__form}>
          <h1
            className={styles.container__title}
          >
            <FormattedMessage
              id='titles.password_recovery'
              defaultMessage='Восстановление пароля'
            />
          </h1>
          <div className={styles.container__subform}>
            <Typography
              size={24}
              className={styles.container__subtitle}
            >
              {intl.formatMessage(
                {
                  id: 'recovery.change_pass_comment',
                  defaultMessage:
                    'Придумайте новый пароль для аккаунта <b>{username}</b>.'
                },
                {
                  b: (chunks) => <b>{chunks}</b>,
                  username
                }
              )}
            </Typography>
            <Input
              type='password'
              name='password'
              data-testid='password'
              labelStyles={styles.form__field_label}
              outerStyles={classNames(styles.form__field, styles.form__field_input)}
              label={intl.formatMessage({
                id: 'recovery.change_pass.new',
                defaultMessage: 'Пароль'
              })}
              onBlur={(e) =>
                handlePasswordValue(e, 'password')}
              onChange={(e) =>
                handlePasswordValue(e, 'password')}
              error={Boolean(error?.new_password)}
            />
            <Input
              type='password'
              name='password'
              data-testid='new_password'
              labelStyles={styles.form__field_label}
              outerStyles={styles.form__field}
              label={intl.formatMessage({
                id: 'recovery.change_pass.repeat',
                defaultMessage: 'Повторите пароль'
              })}
              onBlur={(e) =>
                handlePasswordValue(e, 'new_password')}
              onChange={(e) =>
                handlePasswordValue(e, 'new_password')}
              error={Boolean(error?.new_password)}
            />
          </div>
          <Button
            type='submit'
            color='black'
            className={styles.form__button}
          >
            <FormattedMessage
              id='common.save'
              defaultMessage='Сохранить'
            />
          </Button>
          <ErrorsBlock
            networkError={error?.network_error}
            errorsArray={[
              ...(error?.non_field_error ?? EMPTY_CONSTS.STR),
              ...(error?.non_field_errors ?? EMPTY_CONSTS.STR),
              ...(error?.new_password ?? EMPTY_CONSTS.STR)
            ]}
          />
        </div>
      </GxForm>
    </>
  )
}

export default NewPassword
