import React, { FC } from 'react'

import { observer } from 'mobx-react'

import ErrorsBlock from '@/components/ErrorsBlock'
import { ActionButtons, Title } from '../BindView'
import StorageContainer from './StorageContainer'

import { IUserStoragesBindView } from './interfaces'

import style from './style/index.module.scss'

/**
 * * компонент привязки шкафов к пользователю
 * @view UserStoragesBindView
 *
 * @param id            id пользователя
 * @func  closeModal    функция закрытия модалки
 * @returns
 */
const StoragesBindView: FC<IUserStoragesBindView> = ({
  storageContainerProps,
  handleSubmit,
  closeModal,
  errors,
}) => {
  return (
    <div className={style.container}>
      <Title
        title={{
          id: 'user.storage.bind',
          defaultMessage: 'Binding to storages',
        }}
        closeModal={closeModal}
      />
      <StorageContainer {...storageContainerProps} />
      <ErrorsBlock errorsArray={errors} />
      <ActionButtons
        closeModal={closeModal}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

export default observer(StoragesBindView)
