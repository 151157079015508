import React, { useRef } from 'react'
import UserLayout from '@/apps/RightSidebar/views/UserSideBarView/UserLayout'
import { SidebarContainer } from '@/apps/RightSidebar/views/SidebarContainer'
import DateInputs from '@/apps/RightSidebar/views/EquipmentSideBarView/DateInputs'
import { useDatePicker } from '@/apps/RightSidebar/hooks'
import { useLazyFetch, useStores } from '@/hooks'
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_LAZY_SIZE,
  EMPTY_CONSTS
} from '@/const'
import { isUndefined } from '@/utils'
import { Spinner } from '@/views/spinner'
import EmptyHistory from '@/apps/RightSidebar/views/EmptyHistory'
import UserHistoryTableView from '@/apps/RightSidebar/views/UserSideBarView/UserHistoryTable'

export interface IUserTableProps {
  id: number
}

/**
 * Список пользовательнской истории
 * @component
 * @param id - id истории
 * @constructor
 */
const UserHistoryTable: React.FC<IUserTableProps> = ({
  id
}) => {
  const scrollableListRef = useRef<HTMLDivElement>(
    EMPTY_CONSTS.NULL
  )

  const { api } = useStores()

  const {
    params,
    lazyLoadedData: data,
    isLoading,
    tableScroll: handleScroll,
    handleParams
  } = useLazyFetch(scrollableListRef, api.user.getHistory, {
    id,
    page_size: DEFAULT_PAGE_LAZY_SIZE
  })

  const { handleDownload: onDownload, ...datePicker } =
    useDatePicker(api.user.downloadHistory, id)

  /**
   * Сеттер даты start
   */
  const handleStartChange = (date: string): void => {
    datePicker.handleStartChange(date)
    handleParams({
      ...params,
      date_start: date,
      page: DEFAULT_CURRENT_PAGE
    })
  }

  /**
   * Сеттер даты end
   */

  const handleEndChange = (date: string): void => {
    datePicker.handleEndChange(date)
    handleParams({
      ...params,
      date_end: date,
      page: DEFAULT_CURRENT_PAGE
    })
  }

  if (isLoading && isUndefined(data)) {
    return <Spinner isFullPage />
  }

  if (!isLoading && data?.length === EMPTY_CONSTS.ZERO) {
    return <EmptyHistory />
  }

  return (
    <UserLayout>
      <SidebarContainer>
        <DateInputs
          startDate={datePicker.startDate}
          endDate={datePicker.endDate}
          handleEndChange={handleEndChange}
          handleStartChange={handleStartChange}
          handleDownload={onDownload}
          isReportLoading={datePicker.isLoading}
        />
        <UserHistoryTableView
          data={data}
          handleScroll={handleScroll}
          listRef={scrollableListRef}
        />
      </SidebarContainer>
    </UserLayout>
  )
}

export default UserHistoryTable
