import React, { FC, useState, useMemo, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { FormikErrors, useFormik } from 'formik'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'
import { Button } from '@/views/button'
import { Input } from '@/views/input'
import Icon from '@/components/Icon'
import { EMPTY_CONSTS, MODAL_TYPES } from '@/const'
import { useStores } from '@/hooks'
import { EditPassword } from '@/utils/ValidateSchemes'
import { IChangePasswordModal } from '../interfaces'
import style from '../styles/index.module.scss'
import ErrorsBlock from '@/components/ErrorsBlock'
import classNames from 'classnames'
import { IChangePassword } from '@/api/interfaces'

export const fields = {
  password: 'password',
  new_password: 'new_password',
  repeat_password: 'repeat_password',
  response_errors: 'response_errors'
}

export interface IEditPasswordValues {
  password: string
  new_password: string
  repeat_password: string
}

export type ValidateErrors = FormikErrors<IChangePassword>

export type IEditPasswordErrors = {
  response_errors?: {
    password?: string[]
    new_password?: string[]
    network_error?: string[]
    non_field_errors?: string[]
  }
} & ValidateErrors

export const initialValues = {
  [fields.password]: EMPTY_CONSTS.STR,
  [fields.new_password]: EMPTY_CONSTS.STR,
  [fields.repeat_password]: EMPTY_CONSTS.STR
} as unknown as IEditPasswordValues

/**
 * * компонент модального окна изменения пароля юзера
 * @component EquipmentDelete
*
* @param modalProps - пропсы для текущей модалки
* @func  closeModal - функция закрытия модалки
* @returns
 */
const ChangePassword: FC<IChangePasswordModal> = ({
  modalProps,
  closeModal
}) => {
  const { handleRecoveryPassword } = modalProps
  const intl = useIntl()
  const { api, modalStore } = useStores()
  const [isLoading, setIsLoading] = useState(EMPTY_CONSTS.FALSE)
  const [customErrors, setCustomErrors] = useState<IEditPasswordErrors>(EMPTY_CONSTS.OBJ)

  /**
   * Функция выполняющаяся перед запросом
   */
  const beforeSubmit = useCallback(() => (): void => {
    setIsLoading(EMPTY_CONSTS.TRUE)
  }, [])

  /**
   * Функция выполняющаяся после успешного запроса
   */
  const afterSuccess = () => (): void => {
    modalStore.open(MODAL_TYPES.SUCCES_EDIT_PASSWORD, {})
  }

  /**
* * функция достающая ошибки из ответа сервера и установка этих ошибок (в модалку и локально)
* @param e  event, в котором содержится информация об ответе сервера
*/
  const afterError = (e: any): void => {
    const err = e.response?.data
    //  проверка на null && undefined
    if (err !== EMPTY_CONSTS.NULL && err !== EMPTY_CONSTS.UNDEFINED) {
      const errorsObj = {
        response_errors: err
      } as unknown as ValidateErrors
      setErrors(errorsObj)
    }
  }

  /**
   * Функция выполняющаяся после запроса
   */
  const afterFinally = useCallback(() => (): void => {
    setIsLoading(EMPTY_CONSTS.FALSE)
  }, [])

  /**
   * Запрос на отправку формы
   */
  const formikSubmit = async (
    values: IEditPasswordValues
  ): Promise<void> => {
    const summitValues = {
      [fields.password]: values.password,
      [fields.new_password]: values.new_password
    } as unknown as IChangePassword
    beforeSubmit()
    await api.user
      .changePassword(summitValues)
      .then(afterSuccess())
      .catch((e) => {
        afterError(e)
      })
      .finally(() => {
        afterFinally()
      })
  }

  /**
   * Обработчик onInput изменения поля
   */
  const handleInput = (e: GxInputCustomEvent<any>): void => {
    const newValue = e.target.value
    const name = e.target.name
    void setFieldValue(name, newValue)
  }

  const {
    handleSubmit,
    setFieldValue,
    setErrors,
    values,
    errors
  } = useFormik<IEditPasswordValues>({
    initialValues,
    onSubmit: formikSubmit,
    validationSchema: EditPassword,
    validateOnMount: EMPTY_CONSTS.FALSE,
    validateOnChange: EMPTY_CONSTS.FALSE,
    validateOnBlur: EMPTY_CONSTS.TRUE
  })

  const labels = useMemo(() => ({
    [fields.password]: intl.formatMessage({ id: 'profile.modal.oldPassword', defaultMessage: 'Старый пароль' }),
    [fields.new_password]: intl.formatMessage({ id: 'profile.modal.newPassword', defaultMessage: 'Новый пароль' }),
    [fields.repeat_password]: intl.formatMessage({ id: 'profile.modal.repeatPassword', defaultMessage: 'Повторите пароль' })
  }), [])

  const texts = useMemo(() => ({
    title: intl.formatMessage({ id: 'profile.modal.changePassword', defaultMessage: 'Изменить пароль' }),
    forgot: intl.formatMessage({ id: 'login.forgot_password', defaultMessage: 'Забыли пароль?' }),
    cancel: intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Отмена' }),
    edit: intl.formatMessage({ id: 'button.change', defaultMessage: 'Изменить' })
  }), [])

  useEffect(() => {
    const translatedErrors = Object.entries(errors).reduce((sum, [key, val]) => {
      if (key === fields.response_errors) return { ...sum, [key]: val }
      return {
        ...sum,
        [key]: intl.formatMessage({
          id: val,
          defaultMessage: 'error'
        })
      }
    }, {})
    setCustomErrors(translatedErrors)
  }, [errors])

  return (
    <div className={style['container__change-password']}>
      <span className={style['container__change-password_title']}>
        {texts.title}
      </span>
      <Button
        className={style.container__close}
        onClick={closeModal}
        variant='text'
        color='gray'
      >
        <Icon src='close' />
      </Button>
      <form
        className={style['container__change-password_form']}
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <Input
          id={fields.password}
          name={fields.password}
          data-testid={fields.password}
          label={labels[fields.password]}
          onChange={handleInput}
          value={values[fields.password]}
          error={Boolean(customErrors?.[fields.password] !== undefined ||
            customErrors?.response_errors?.[fields.password] !== undefined)}
          errorMessage={customErrors?.[fields.password]}
          type='password'
          autocomplete='off'
          required
        />
        <Button
          onClick={handleRecoveryPassword}
          variant='text'
          color='orange'
          className={style['container__forgot-password']}
        >
          {texts.forgot}
        </Button>
        <Input
          id={fields.new_password}
          name={fields.new_password}
          data-testid={fields.new_password}
          label={labels[fields.new_password]}
          onChange={handleInput}
          value={values[fields.new_password]}
          error={Boolean(customErrors?.[fields.new_password] !== undefined ||
            customErrors?.response_errors?.[fields.new_password] !== undefined)}
          errorMessage={customErrors?.[fields.new_password]}
          type='password'
          autocomplete='off'
          required
        />
        <Input
          id={fields.repeat_password}
          name={fields.repeat_password}
          data-testid={fields.repeat_password}
          label={labels[fields.repeat_password]}
          onChange={handleInput}
          value={values[fields.repeat_password]}
          error={Boolean(customErrors?.[fields.repeat_password] !== undefined ||
            customErrors?.response_errors?.[fields.repeat_password] !== undefined)}
          errorMessage={customErrors?.[fields.repeat_password]}
          type='password'
          autocomplete='off'
          required
        />
        <div className={classNames(style.container__buttons, style['container__confirm-code_btn'])}>
          <Button
            className={style.container__buttons_cancel}
            onClick={closeModal}
          >
            {texts.cancel}
          </Button>
          <Button
            className={style.container__buttons_off}
            disabled={isLoading}
            color='black'
            type='submit'
          >
            {texts.edit}
          </Button>
        </div>
        <ErrorsBlock
          networkError={Boolean(customErrors?.response_errors?.network_error)}
          errorsArray={
            customErrors?.response_errors?.non_field_errors ??
            customErrors?.response_errors?.[fields.password] ??
            customErrors?.response_errors?.[fields.new_password] ??
            customErrors?.response_errors?.[fields.repeat_password] ?? null
          }
        />
      </form>
    </div>
  )
}

export default ChangePassword
