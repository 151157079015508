import React from 'react'
import { usePage as usePageCms } from '@garpix/cms'

import { Seo } from '@/apps/seo'

import { IObjectPage } from '@/interfaces/ObjectPage'
import { EMPTY_CONSTS } from '@/const'

interface IProps {
  children: React.ReactElement
}
/**
 * @component 
 * * контейнер с компонентой мета тегов для конкретной стр 
 */
const SeoWrapper: React.FC<IProps> = ({ children }) => {
  const value = usePageCms()
  const { object } = value?.page?.init_state as {
    object: IObjectPage
  }
  const {
    seo_title: seoTitle = EMPTY_CONSTS.STR,
    title,
    seo_description: seoDescription,
    description,
    seo_keywords: seoKeywords,
    seo_image: seoImage,
    seo_author: seoAuthor = EMPTY_CONSTS.STR,
    seo_og_type: seoOgType,
  } = object

  return (
    <>
      <Seo
        seoTitle={seoTitle}
        title={title}
        seoDescription={seoDescription}
        description={description}
        seoKeywords={seoKeywords}
        seoImage={seoImage}
        seoAuthor={seoAuthor}
        seoOgType={seoOgType}
      />
      {children}
    </>
  )
}

export default SeoWrapper
