import React, { useEffect } from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { useIntl } from 'react-intl'

import { Button } from '@/views/button'

import { useStores, useMutation, usePage } from '@/hooks'

import { CtxIntegrationConfig } from '@/interfaces/ObjectPage'

import { UnlockIcon } from '@/assets/icon'
import { MODAL_CUSTOM_CONTENT, MODAL_TYPES } from '@/const'

import styles from './styles/index.module.scss'

interface IProps {
  isShow: boolean
  setShowUnlockBtn: React.Dispatch<
    React.SetStateAction<boolean>
  >
}
/**
 * @component
 * * Компонент с логикой разблокировки пользователец (СКУД)
 * @param param0
 * @returns
 */
const UnlockUsers: React.FC<IProps> = ({
  isShow,
  setShowUnlockBtn,
}) => {
  const { page } = usePage()

  const integration = page.init_state.global
    .integration as CtxIntegrationConfig
  /**
   * фдаг для отображения панели
   */
  const showUnlockBtn =
    integration.is_active &&
    integration.count_block_users >= 0

  const { modalStore, api } = useStores()
  const intl = useIntl()
  /**
   * метод для разблокировки всех пользователей
   * и послед событиями после этого
   */
  const { mutation: unlockAllUsers } = useMutation({
    mutation: api.user.unlockAllUsers,
    onSuccess: (): void => {
      setShowUnlockBtn(false)
      modalStore.open(MODAL_TYPES.CUSTOM, {
        ...MODAL_CUSTOM_CONTENT.SUCCES_SETTING_SYNC,
        title: {
          id: 'success',
          defaultMessage: 'Успешно!',
        },
      })
    },
    onError: (): void => {
      modalStore.open(
        MODAL_TYPES.CUSTOM,
        MODAL_CUSTOM_CONTENT.ERROR,
      )
    },
  })
  /**
   * @func handleOpenUnlockUsersModal
   * * метод открытия модалки с подтверждением разблокировки
   */
  const handleOpenUnlockUsersModal = (): void => {
    modalStore.open(MODAL_TYPES.CUSTOM, {
      ...MODAL_CUSTOM_CONTENT.CONFIRM_USER_UNLOCKING,
      handleAccept: unlockAllUsers,
    })
  }
  /**
   * @effect
   * сетаем значения появления/закрытия для управления анимацией
   */
  useEffect(() => {
    setShowUnlockBtn(showUnlockBtn)
  }, [])

  return (
    <Button
      data-show={isShow}
      onClick={handleOpenUnlockUsersModal}
      className={styles.unlock}
      color="orange"
    >
      <GxIcon
        className={styles.unlock__icon}
        src={UnlockIcon}
      />
      {intl.formatMessage({
        id: 'unlockAllUsers',
        defaultMessage: 'Разблокировать всех пользователей',
      })}
    </Button>
  )
}

export default UnlockUsers
