import { TAccesControlIntegrationValues } from '@/api/interfaces/Integration'
import { EMPTY_CONSTS } from '@/const'

export const SCUD_STATUSES = {
  LOADING: 'loading',
  DISABLED: 'disabled',
  CONNECT: 'connected',
  ERROR: 'error',
} as const

export const DEFAULT_INTEGRATION_VALUES: TAccesControlIntegrationValues =
  {
    is_active: EMPTY_CONSTS.TRUE,
    acs_type: EMPTY_CONSTS.STR,
    api_link: EMPTY_CONSTS.STR,
    is_need_auth: EMPTY_CONSTS.TRUE,
    login: EMPTY_CONSTS.STR,
    password: EMPTY_CONSTS.STR,
    status: SCUD_STATUSES.DISABLED,
    email: EMPTY_CONSTS.STR,
    is_email_alerts: EMPTY_CONSTS.FALSE,
  }

export const INTEGRATION_OPTIONS = [
  {
    label: 'Орион Про',
    value: 'orion',
  },
  {
    label: 'Sigur',
    value: 'sigur',
  },
]

export const DEFAULT_INTEGRATION_FIELDS = {
  is_active: 'is_active',
  acs_type: 'acs_type',
  api_link: 'api_link',
  api_type: 'api_type',
  is_need_auth: 'is_need_auth',
  login: 'login',
  password: 'password',
  status: 'status',
  integrationError: 'integrationError',
  is_active_synch: 'is_active_synch',
  is_email_alerts: 'is_email_alerts',
  email: 'email',
} as const

export const BOTTOM_FIELDS = [
  {
    id: 0,
    name: DEFAULT_INTEGRATION_FIELDS.login,
    label: {
      id: 'login.login',
      defaultMessage: 'Логин',
    },
    type: 'text',
    clearable: true,
  },
  {
    id: 1,
    name: DEFAULT_INTEGRATION_FIELDS.password,
    label: {
      id: 'login.password',
      defaultMessage: 'Пароль',
    },
    type: 'password',
    clearable: undefined,
  },
]

export const SCUD_SETTINGS_CHECKBOXS = [
  {
    id: 0,
    name: DEFAULT_INTEGRATION_FIELDS.is_active_synch,
    label: {
      id: 'activeSync',
      defaultMessage: 'Синхронизация пользователей',
    },
  },
  {
    id: 1,
    name: DEFAULT_INTEGRATION_FIELDS.is_email_alerts,
    label: {
      id: 'enableNotify',
      defaultMessage: 'Уведомления на почту',
    },
  },
]
