import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'

import { useFetch, useStores } from '@/hooks'

import { EMPTY_CONSTS, MODAL_TYPES } from '@/const'
import {
  getFullName,
  getInitials,
  isNull,
  isUndefined,
} from '@/utils'

import {
  EquipmentHeader,
  EquipmentInfo,
  EquipmentLayout,
  EquipmentTabs,
} from '@/apps/RightSidebar/views/EquipmentSideBarView'
import SidebarFooter from '@/apps/RightSidebar/views/SidebarFooter'
import Loader from '@/views/Loader'
import { ISocketUpdateEquipmentData } from '@/interfaces/Socket'

interface IEquipmentSidebar {
  handleClose: () => void
  contentProps: {
    equipmentId: number
    refetch: () => void
  }
}

const EquipmentSidebar: React.FC<IEquipmentSidebar> = ({
  contentProps: {
    equipmentId,
    refetch: refetchEquipmentsList,
  },
}) => {
  const intl = useIntl()
  const { api, modalStore, EquipmentEventsObserver } =
    useStores()
  const refetchRef = useRef(EMPTY_CONSTS.FUNC)

  const {
    data,
    isLoading,
    refetch: refetchEquipmentData,
  } = useFetch(api.equipment.getEquipment, {
    id: equipmentId,
  })

  const initials = getInitials(
    data?.first_name,
    data?.last_name,
  )
  const fullName = getFullName(
    data?.first_name,
    data?.last_name,
  )

  /**
   * @function handleRefetchValues
   * * метод для рефетча каких либо данных
   * * после успешного изменения данных пользователя
   */
  const handleRefetchValues = (): void => {
    void refetchEquipmentData()
    void refetchEquipmentsList()
    void refetchRef.current()
  }

  const handleEdit = (): void => {
    modalStore.open(MODAL_TYPES.EQUIPMENT_EDIT, {
      theme: 'large',
      equipment: data,
      refetch: handleRefetchValues,
    })
  }
  /**
   * * метод для обновления данных об оборудовании
   * * (сокеты)
   * @function handleRefetchEquipmentSocket
   */
  const handleRefetchEquipmentSocket = ({
    storage_id: storageId,
    equipment_id: equipmentIdSocket,
  }: ISocketUpdateEquipmentData): void => {
    if (
      storageId === data?.storage?.id &&
      equipmentIdSocket === data?.id
    ) {
      refetchEquipmentData()
    }
  }

  useEffect(() => {
    EquipmentEventsObserver.subscribe(
      handleRefetchEquipmentSocket,
    )
    return () => {
      EquipmentEventsObserver.unsubscribe(
        handleRefetchEquipmentSocket,
      )
    }
  }, [])

  if (isLoading || isUndefined(data)) {
    return (
      <EquipmentLayout>
        <Loader
          size="large"
          theme="center"
          color="orange"
        />
      </EquipmentLayout>
    )
  }

  const storageTitle = !isNull(data.storage)
    ? data.storage?.title
    : undefined

  return (
    <>
      <EquipmentHeader
        number={data.cell_number}
        title={data.title}
        storageTitle={storageTitle}
        status={data.status}
      />
      <EquipmentInfo
        type={data.equipment_type}
        serialNumber={data.serial_number}
        inventoryNumber={data.inventory_number}
        labelNumber={data.label_number}
        description={data.description}
      />
      <EquipmentTabs
        equipmentID={equipmentId}
        handleEdit={handleEdit}
        equipmentData={data}
        refetchRef={refetchRef}
      />
      <SidebarFooter
        handleEdit={handleEdit}
        badgeTitle={initials}
        userName={fullName}
        updatedAt={intl.formatDate(data.updated_at)}
      />
    </>
  )
}

export default EquipmentSidebar
