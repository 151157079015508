import React from 'react'

import { observer } from 'mobx-react'
import { ObservableMap } from 'mobx'
import { useIntl } from 'react-intl'

import ResetCounter from '@/views/ResetCouner'

import { Typography } from '@/views/typography'

import style from '../style/index.module.scss'

interface IProps {
  handleClearUserList: () => void
  userIdList: ObservableMap<number, number>
}
/**
 * @view
 * * вью панели юзера с каунтером выбранных пользователей
 *
 * @param userIdList            карта выбранных пользователей
 * @param handleClearUserList   метод отчистки карты
 * @returns
 */
const UserPanel: React.FC<IProps> = ({
  userIdList,
  handleClearUserList
}) => {
  const intl = useIntl()
  return (
    <div className={style['user-binding__bar']}>
      <Typography size={14} weight={400}>
        {intl.formatMessage({
          id: 'user.unattachedApplications',
          defaultMessage: 'Непривязанные пользователи'
        })}
      </Typography>
      <ResetCounter
        handleClick={handleClearUserList}
        count={userIdList.size}
      />
    </div>
  )
}

export default observer(UserPanel)
