import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'

import ErrorsBlock from '@/components/ErrorsBlock'
import LinkButton from '@/components/LinkButton'
import { Button } from '@/views/button'
import AuthWrap from '@/views/AuthWrap'
import { Input } from '@/views/input'
import Form from '@/views/Form'

import useCustomAuth from './hooks'

import { LoginSсheme } from '@/utils/ValidateSchemes'

import {
  DEFAULT_LINKS,
  EMPTY_CONSTS,
  INITIAL_AUTH_VALUES
} from '@/const'

import { ILoginProps } from './interfaces'

import styles from './style/index.module.scss'

/**
 * @component Login
 * * компонент страницы Login
 *
 * @param successLink  путь для редиректа в случае успешной авторизации
 */
const Login: React.FC<ILoginProps> = ({
  successLink
}) => {
  const intl = useIntl()
  const { errors, onSubmit, isSubmitting, setErrors } =
    useCustomAuth({ successLink })

  const formik = useFormik({
    initialValues: INITIAL_AUTH_VALUES,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: LoginSсheme,
    onSubmit: async (data) => {
      onSubmit(data)
    }
  })
  const { errors: err } = formik
  const { password, username } = err

  const handleInputChange = (
    event: GxInputCustomEvent<any>
  ): void => {
    setErrors(EMPTY_CONSTS.NULL)
    formik.handleChange(event)
  }

  const handleInputBlur = (
    event: GxInputCustomEvent<any>
  ): void => {
    setErrors(EMPTY_CONSTS.NULL)
    formik.handleBlur(event)
  }

  return (
    <AuthWrap>
      <h1 className={styles.container__title}>
        <FormattedMessage
          id='titles.auth'
          defaultMessage='Вход в личный кабинет'
        />
      </h1>
      <Form onSubmit={formik.handleSubmit}>
        <div className={styles.form}>
          <div className={styles.form__subform}>
            <Input
              type='text'
              label={intl.formatMessage({
                id: 'login.email',
                defaultMessage: 'Почта'
              })}
              data-testid='username'
              name='username'
              outerStyles={styles.form__field}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              error={errors?.username ?? username}
              errorMessage={username}
              autocomplete='on'
              
            />
            <Input
              type='password'
              data-testid='password'
              name='password'
              outerStyles={styles.form__field}
              label={intl.formatMessage({
                id: 'login.password',
                defaultMessage: 'Пароль'
              })}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              error={errors?.password ?? password}
              errorMessage={password}
            />
            <LinkButton
              size='medium'
              href={DEFAULT_LINKS.userPasswordRecovery}
              className={styles.form__button_link}
            >
              <FormattedMessage
                id='login.forgot_password'
                defaultMessage='Забыли пароль?'
              />
            </LinkButton>
          </div>
          <Button
            type='submit'
            color='black'
            className={styles.form__button}
            disabled={isSubmitting}
          >
            <FormattedMessage
              id='login.enter'
              defaultMessage='Войти'
            />
          </Button>
        </div>
        <ErrorsBlock
          networkError={errors?.network_error}
          errorsArray={errors?.non_field_errors ?? [errors?.message]}
        />
      </Form>
    </AuthWrap>
  )
}

export default Login
