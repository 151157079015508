import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import { useStores } from '@/hooks'

import Icon from '@/components/Icon'

import BaseContentViews from '../../views/BaseContentViews'
import { Button } from '@/views/button'

import {
  DEFAULT_USER_ROLE,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES,
} from '@/const'

import { IUserModal } from '../interfaces'

import style from './styles/index.module.scss'
import { isUndefined } from '@/utils'

/**
 * * компонент модального окна удаления пользователя
 * @component UserDelete
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const UserDelete: FC<IUserModal> = ({
  modalProps,
  closeModal,
}) => {
  const {
    id,
    full_name: fullName,
    user_role: userRole = DEFAULT_USER_ROLE,
    refetch,
  } = modalProps
  const intl = useIntl()
  const { api, modalStore } = useStores()

  /**
   * @func onSubmit
   * * функция реализующая логику удаления пользователя
   */
  const onSubmit = (): void => {
    void api.user
      .deleteUser(id)
      .then(() => {
        closeModal()
        refetch()
      })
      .catch((e) => {
        const error = e?.response?.data?.error
        if (!isUndefined(error)) {
          modalStore.open(MODAL_TYPES.WARNING_ERROR, {
            error: [error],
          })
        } else {
          modalStore.open(
            MODAL_TYPES.CUSTOM,
            MODAL_CUSTOM_CONTENT.ERROR,
          )
        }
      })
  }

  return (
    <BaseContentViews.Wrapper>
      <BaseContentViews.Header
        colorIconWrapper="red"
        iconSlot={<Icon src="delete" size="small-xl" />}
      >
        <h2 className={style['user-delete__title']}>
          {intl.formatMessage({
            id: 'modal.title.user_delete',
            defaultMessage:
              'Вы уверены, что хотите удалить пользователя?',
          })}
        </h2>
        <p className={style['user-delete__desc']}>
          {intl.formatMessage({
            id: 'asd',
            defaultMessage: `После удаления пользователя, привязанное оборудование к этому пользователю будет отвязано.`,
          })}
        </p>
      </BaseContentViews.Header>
      <BaseContentViews.BannerContent
        className={style['user-delete__wrap']}
      >
        <span className={style['user-delete__full_name']}>
          {fullName ?? '-'}
        </span>
        {userRole !== undefined && (
          <span className={style['user-delete__role']}>
            {intl.formatMessage({
              id: `user.user_role.${userRole}`,
              defaultMessage: 'role',
            })}
          </span>
        )}
      </BaseContentViews.BannerContent>
      <BaseContentViews.ButtonWrapper>
        <Button
          className={style.container__buttons_cancel}
          height={44}
          onClick={closeModal}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color="black"
          height={44}
          onClick={onSubmit}
        >
          {intl.formatMessage({
            id: 'common.delete',
            defaultMessage: 'Delete',
          })}
        </Button>
      </BaseContentViews.ButtonWrapper>
    </BaseContentViews.Wrapper>
  )
}

export default UserDelete
