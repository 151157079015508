const runtimeConfig = (() => {
  if (typeof window === 'undefined') {
    // Для сервера
    return {
      ...process.env,
    }
  } else {
    // Для клиента
    return {
      ...globalThis.env,
    }
  }
})()

export { runtimeConfig }
