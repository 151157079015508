import styles from '../style/index.module.scss'

export const tableStyles = {
  classNameTable: styles.table,
  classNameHeaderRow: styles.header__row,
  classNameBodyRow: styles.body__row
}

export const QUERIES = {
  small: '(max-height: 1024px)',
  medium: '(min-height: 1025px) and (max-height: 1199px)',
  large: '(min-height: 1200px) and (max-height: 1399px)',
  extra: '(min-height: 1400px)'
}
