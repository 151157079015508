import React from 'react'

import { useStores } from '@/hooks'

import EquipmentBind from '../Core'
import { IModal } from '@/apps/Modals/ModalContent/interfaces'
import { IEquipmentQueryParams } from '@/api/interfaces'

interface IProps extends IModal {
  modalProps: {
    groupId: number
    refetch: () => void
  }
}

const EquipmentBindToGroupUser: React.FC<IProps> = ({
  closeModal,
  modalProps,
}) => {
  const { refetch, groupId } = modalProps
  const { api } = useStores()

  const handleFetchEquipments = async (
    param: IEquipmentQueryParams,
  ) =>
    await api.groupUsers.getNotTiedEquipment({
      groupId,
      ...param,
    })

  const handleBindEquipments = async (
    equipIdArr: number[],
  ) =>
    await api.groupUsers.tieEquipmentToGroup(groupId, {
      equipment_ids: equipIdArr,
    })

  return (
    <EquipmentBind
      handleFetchEquipments={handleFetchEquipments}
      handleBindEquipments={handleBindEquipments}
      closeModal={closeModal}
      refetch={refetch}
    />
  )
}

export default EquipmentBindToGroupUser
