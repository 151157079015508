import React from 'react'

import TabPanelWrap, {
  ITabPanelWrapProps,
} from '../TabPanelWrap'
import Table from '@/apps/Table/component/Table'

import { IColumn } from '@/apps/Table/interfaces'
import { ITiedEquipmentList } from '@/api/interfaces'

import { tableStyles } from './styles/tableStyles'

interface IProps extends ITabPanelWrapProps {
  columns: IColumn[]
  equipments?: ITiedEquipmentList[]
}
/**
 * @view
 * *Вью таблицы привязанного оборудования в деталках
 */
const EquipmentsTableView: React.FC<IProps> = ({
  searchPanelProps,
  tableWrapProps,
  containerRef,
  isScrollEnd,
  tableScroll,
  equipments,
  columns,
  isLoading,
}) => {
  return (
    <TabPanelWrap
      searchPanelProps={searchPanelProps}
      tableWrapProps={tableWrapProps}
      containerRef={containerRef}
      isScrollEnd={isScrollEnd}
      tableScroll={tableScroll}
      isLoading={isLoading}
      loadedData={equipments}
    >
      <Table
        columns={columns}
        rowsData={equipments}
        styles={tableStyles}
      />
    </TabPanelWrap>
  )
}

export default EquipmentsTableView
