import React, { useMemo, useState } from 'react'

import { useIntl } from 'react-intl'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'

import { STORAGE_TAB, STORAGE_TAB_KEYS } from '../../const'

import {
  Tab,
  TabGroup,
  TabPanel,
} from '@/components/TabGroup'

import StorageCells from '../../components/StorageCells'
import EquipmentTableList from '../../components/EquipmentTableList'
import StorageUsers from '../../components/StorageUsers'
import TagHistory from '../../components/TagsHistory'

import style from './style/index.module.scss'
import { Typography } from '@/views/typography'

const STORAGE_TAB_PANEL = [
  {
    id: 0,
    name: STORAGE_TAB_KEYS.CELLS,
    Component: StorageCells,
  },
  {
    id: 1,
    name: STORAGE_TAB_KEYS.EQUIPMENTS,
    Component: EquipmentTableList,
  },
  {
    id: 2,
    name: STORAGE_TAB_KEYS.USERS,
    Component: StorageUsers,
  },
  {
    id: 3,
    name: STORAGE_TAB_KEYS.TAG_HISTORY,
    Component: TagHistory,
  },
]
/**
 * @view
 * * вью реализовывающий переключения между контентом стр деталки шкафа
 */
const StorageTabs = (): React.ReactElement => {
  const [activeTab, setActiveTab] = useState<
    (typeof STORAGE_TAB_KEYS)[keyof typeof STORAGE_TAB_KEYS]
  >(STORAGE_TAB_KEYS.CELLS)
  const intl = useIntl()
  const location = useLocation()
  const { id } = useMemo(
    () => qs.parse(location.search),
    [location.search],
  )

  const storageId = Number(id)

  return (
    <TabGroup className={style['tab-group']}>
      {STORAGE_TAB.map(({ id, panel, title }) => {
        const isActiveTab = panel === activeTab
        const handleChangeTab = (): void => {
          setActiveTab(panel)
        }
        return (
          <Tab
            key={id}
            slot="nav"
            panel={panel}
            className={style['tab-group__tab']}
            active={isActiveTab}
            onClick={handleChangeTab}
          >
            <Typography size={16} weight={500}>
              {intl.formatMessage(title)}
            </Typography>
          </Tab>
        )
      })}
      {STORAGE_TAB_PANEL.map(({ id, name, Component }) => {
        const isActivePanel = activeTab === name
        return (
          <TabPanel
            className={style['tab-group__panel']}
            key={id}
            name={name}
            active={isActivePanel}
          >
            {isActivePanel && (
              <Component storageId={storageId} />
            )}
          </TabPanel>
        )
      })}
    </TabGroup>
  )
}

export default StorageTabs
