import {
  IHidFormatsList,
  IHidFormatsSerializedList,
} from '@/api/interfaces'
/**
 * * сериалайзер для преобразования данных к AsyncSelect
 * @function hidFormatsSerializer
 * @param response 
 * @returns 
 */
export const hidFormatsSerializer = (
  response: IHidFormatsList
): IHidFormatsSerializedList => {
  const results = response.formats.map((el) => ({
    id: el.key,
    title: el.name,
  }))

  const defaultValue = results.find(
    (el) => el.id === response.default
  )
  
  const defaultValueSerialized = {
    value: defaultValue?.id ?? 'default',
    label: defaultValue?.title ?? ''
  }

  return {
    defaultValue: defaultValueSerialized,
    results,
  }
}
