
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import ErrorsBlock from '@/components/ErrorsBlock'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { Input } from '@/views/input'
import { EMPTY_CONSTS } from '@/const'
import { prevDef } from '@/utils'
import { IRecoveryPasswordModal } from '../interfaces'
import styles from '../styles/index.module.scss'

const fields = {
  code: 'code',
  email_confirmation_code: 'email_confirmation_code'
}

/**
 * @component CheckEmailCode
 * * компонент формы проверки ввода кода из почты
 * @param {ICheckCode}                 общий интерфейс входных параметров CheckCode
 *
 * @param {function}  handleCodeValue  функция для изменения кода вводимого в инпут
 * @param {function}  onSubmit         функция для отправки проверочного кода на сервер
 * @param {function}  resendCode       функция повторной отправки проверочного кода на почту
 * @param {string}    username         логин (почта) пользователя
 * @param {IError}    errors            обьект ошибок
 */
const CheckEmailCode: React.FC<IRecoveryPasswordModal> = observer(({
  modalProps,
  closeModal
}): React.ReactElement => {
  const {
    handleCodeValue,
    onSubmit,
    resendCode,
    username,
    errors
  } = modalProps
  const intl = useIntl()

  const labels = useMemo(() => ({
    [fields.code]: intl.formatMessage({ id: 'recovery.code', defaultMessage: 'Код' })
  }), [])

  const texts = useMemo(() => ({
    username: intl.formatMessage({
      id: 'profile.modal.confirmEmail.description',
      defaultMessage: 'Мы отправили на электронную  почту <b>{username}</b> письмо с кодом. Если письмо не пришло во Входящие, проверьте папку Спам.'
    }, {
      b: chunks => <b>{chunks}</b>,
      username
    })
  }), [username])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    prevDef(e)
    void onSubmit()
  }

  return (
    <div className={classNames(styles.container, styles['container__recovery-password'])}>
      <Button
        className={styles.container__close}
        onClick={closeModal}
        variant='text'
        color='gray'
      >
        <Icon src='close' />
      </Button>
      <span className={styles['container__recovery-password_title']}>
        <FormattedMessage
          id='profile.modal.confirmEmail'
          defaultMessage='Подтверждение почты'
        />
      </span>
      <span
        className={styles['container__recovery-password_description']}
      >
        {texts.username}
      </span>
      <form
        className={styles['container__recovery-password_form']}
        onSubmit={handleSubmit}
      >
        <Input
          type='text'
          label={labels?.[fields.email_confirmation_code]}
          data-testid={fields.email_confirmation_code}
          name={fields.email_confirmation_code}
          labelStyles={styles.form__field_label}
          outerStyles={classNames(styles.form__field,
            styles.form__field_input,
            styles['container__confirm-code_field']
          )}
          onBlur={handleCodeValue}
          onChange={handleCodeValue}
          error={Boolean(errors?.response_errors?.email_confirmation_code !== undefined ||
              errors?.validate_errors?.email_confirmation_code !== undefined)}
          errorMessage={errors?.validate_errors?.email_confirmation_code}
          placeholder='ХХХХХХ'
        />
        <Button
          onClick={resendCode}
          variant='text'
          color='orange'
        >
          <FormattedMessage
            id='profile.modal.resentCode'
            defaultMessage='Отправить письмо повторно'
          />
        </Button>
        <div className={styles.container__buttons}>
          <Button
            type='submit'
            color='black'
            className={classNames(styles.form__button,
              styles['container__confirm-code_btn']
            )}
          >
            <FormattedMessage
              id='button.send'
              defaultMessage='Отправить'
            />
          </Button>
        </div>
      </form>
      <ErrorsBlock
        networkError={errors?.response_errors?.network_error}
        errorsArray={errors?.response_errors?.non_field_errors ??
            errors?.response_errors?.email_confirmation_code ?? EMPTY_CONSTS.NULL}
      />
    </div>
  )
})

export default CheckEmailCode
