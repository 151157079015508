import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import useTranslate, { TMessage } from '@/hooks/useTranslate'

import styles from './styles/index.module.scss'
type TNavItem = {
  href: string,
  title: TMessage
}
interface IProps {
  navItems: TNavItem[]
}

const NavigationBar:React.FC<IProps> = ({navItems}) => {
  const { translate } = useTranslate()
  return (
    <nav className={styles['navigation-bar']}>
      <ul className={styles['navigation-bar__list']}>
        {navItems.map(({ href, title }, i) => (
          <li key={i}>
            <NavLink
              className={({isActive}) =>
                classNames(styles['navigation-bar__link'], {
                  [styles['navigation-bar__link__active']]: isActive
                })
              }
              to={href}
            >
              {translate(title)}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default NavigationBar
