import * as Yup from 'yup'
import { TYupStringSchema } from './interfaces'

export const DIGITALS = {
  min_symbols: 8,
  max_symbols: 50
}

const CODE_LENGTH = 6

/**
 * * Regular Expression - обьект с набором регулярных выражений
 * @object REGEX
 */
export const REGEX = {
  numbers: /^[0-9]+$/,
  password: /^[a-zA-Z0-9_-]+$/,
  two_numbers: /^.*[0-9]+.*[0-9]+.*$/,
  one_capital_letter: /^(?=.*?[A-Z]).{1,}$/,
  two_letters: /^.*[a-zA-Z]+.*[a-zA-Z]+.*$/,
  email: /^([a-zA-Z0-9._-|+]+@[a-zA-Z0-9._-|+]+\.([a-zA-Z0-9_-|+]){2,})$/,
  url: /^(http|https):\/\/[a-zA-Z0-9._-|+]+\.[a-zA-Z0-9._-|+]{2,6}.*\/$/
}

const MESSAGE = {
  email: { username: 'validation.uncorrect_email' },
  code_length: (field) => ({ [field]: 'validation.code_length' }),
  only_numbers: (field) => ({ [field]: 'validation.only_numbers' }),
  min_symbols: (field) => ({ [field]: 'validation.min_number' }),
  max_symbols: (field) => ({ [field]: 'validation.max_number' }),
  required_field: (field) => ({ [field]: 'validation.required' }),
  password: (field) => ({ [field]: 'validation.password' }),
  two_numbers: (field) => ({ [field]: 'validation.two_numbers' }),
  one_capital_letter: (field) => ({ [field]: 'validation.one_capital_letter' }),
  two_letters: (field) => ({ [field]: 'validation.two_letters' }),
  equal_passwords: { new_password: 'validation.password_match' }
}

const requiredString = (field: string): TYupStringSchema => Yup.string().required(MESSAGE.required_field(field))
const requiredNumber = (field: string): TYupStringSchema => Yup.string()
  .required(MESSAGE.required_field(field))
  .matches(REGEX.numbers, { message: MESSAGE.only_numbers(field) })
  .min(CODE_LENGTH, MESSAGE.code_length(field))
  .max(CODE_LENGTH, MESSAGE.code_length(field))

const username = requiredString('username').matches(REGEX.email, { message: MESSAGE.email })

const code = requiredNumber('restore_password_confirm_code')

const passwordValidate = (key: string): TYupStringSchema => requiredString(key)
  .min(DIGITALS.min_symbols, MESSAGE.min_symbols(key))
  .max(DIGITALS.max_symbols, MESSAGE.max_symbols(key))
  .matches(REGEX.two_numbers, { message: MESSAGE.two_numbers(key) })
  .matches(REGEX.two_letters, { message: MESSAGE.two_letters(key) })
  .matches(REGEX.one_capital_letter, { message: MESSAGE.one_capital_letter(key) })
  .matches(REGEX.password, { message: MESSAGE.password(key) })

const USERNAME_SCHEMA = Yup.object().shape({
  username
})

const CODE_SCHEMA = Yup.object().shape({
  code
})

const CODE_PASSWORD = Yup.object().shape({
  code: requiredNumber('restore_password_confirm_code')
})

const CODE_EMAIL = Yup.object().shape({
  code: requiredNumber('email_confirmation_code')
})

const NEW_PASSWORD_SCHEMA = Yup.object().shape({
  new_password: passwordValidate('new_password'),
  password: passwordValidate('password').test(
    'password-match',
    MESSAGE.equal_passwords,
    (value, context) => context.parent.new_password === value
  )
})

export {
  USERNAME_SCHEMA,
  NEW_PASSWORD_SCHEMA,
  CODE_SCHEMA,
  CODE_PASSWORD,
  CODE_EMAIL
}
