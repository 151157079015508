import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import isEqual from 'react-fast-compare'

import { useStores } from '@/hooks'

import { IEquipmentQueryParams } from '@/api/interfaces'

import { EMPTY_CONSTS, FILTER_KEY } from '@/const'
import { IValue } from '@/components/AsyncSelect/interfaces'

import AsyncSelect from '@/components/AsyncSelect/AsyncSelect'
import SearchInput from '@/views/SearchInput'
import ResetCouner from '@/views/ResetCouner'

import style from './style/index.module.scss'

export interface IFilterEquipmentPanel {
  clearObservableMap: () => void
  handleParams: (param: IEquipmentQueryParams) => void
  equipmentSelectCount: number
}
/**
 * @view
 * вью с поиском и фильтрами в модалке привязки оборудования
 * @param clearObservableMap      метод для отчиски выбранного оборудования
 * @param handleParams            метод для изменения параметров сортировки
 * @param equipmentSelectCount    количество выбранного оборудования
 * @returns
 */
const FilterPanel: React.FC<IFilterEquipmentPanel> = ({
  clearObservableMap,
  handleParams,
  equipmentSelectCount
}) => {
  const { api } = useStores()
  const previousValueFilters = useRef<IValue>()
  const intl = useIntl()
  /**
   * * метод для изменения параметров сортировки не привязанного
   * * оборудования
   * @func handleChangeFilterParams
   * @param selectedData - выбранный параметр сортировки
   */
  const handleChangeFilterParams = (selectedData: IValue): void => {
    if (!isEqual(previousValueFilters.current, selectedData)) {
      handleParams({ [FILTER_KEY.EQUIPMENT_TYPE]: selectedData.value })
      previousValueFilters.current = selectedData
    }
  }

  const equipmentValue = intl.formatMessage({ id: 'input.all', defaultMessage: 'Все' })
  const defaulSelectValue = {
    value: EMPTY_CONSTS.STR,
    label: equipmentValue
  }

  return (
    <div className={style['filter-panel']}>
      <div className={style['filter-panel__top']}>
        <SearchInput
          className={style['filter-panel__search']}
          handleParams={handleParams}
        />
        <AsyncSelect
          handleChange={handleChangeFilterParams}
          handleFetchError={EMPTY_CONSTS.FUNC}
          labelClassName={style['filter-panel__select_label']}
          defaultValue={defaulSelectValue}
          loadFunction={api.equipment.getEquipmentTypes}
        />
      </div>
      <div className={style['filter-panel__bottom']}>
        <p className={style['filter-panel__label']}>
          {
            intl.formatMessage({
              id: 'unattachedEquipment',
              defaultMessage: 'Непривязанное оборудование'
            })
          }
        </p>
        <ResetCouner
          count={equipmentSelectCount}
          handleClick={clearObservableMap}
        />
      </div>
    </div>
  )
}

export default observer(FilterPanel)
