import React from 'react'
import classNames from 'classnames'
import { Typography } from '../typography'
import Icon from '@/components/Icon'
import useTranslate, {
  TMessage
} from '@/hooks/useTranslate'

import styles from './style/index.module.scss'

interface IProps {
  text: TMessage
  className?: string
}
/**
 * @view
 * * вьюха строки ошибки
 * @param text - текст
 * @param className
 */
const ErrorMessage: React.FC<IProps> = ({
  text,
  className
}) => {
  const { translate } = useTranslate()
  return (
    <div
      className={classNames(
        styles['error-message'],
        className
      )}
    >
      <Icon src='statusNotConnected' size='medium' />
      <Typography
        className={styles['error-message__text']}
        color='black'
        weight={400}
        size={14}
      >
        {translate(text)}
      </Typography>
    </div>
  )
}

export default ErrorMessage
