import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useIntl } from 'react-intl'

import ErrorsBlock from '@/components/ErrorsBlock'
import { ActionButtons, Title } from '../BindView'
import EquipmentContainer from './EquipmentContainer'

import { useLazyFetch, useStores } from '@/hooks'

import {
  checkHasError,
  getErrorsArrayFromObj,
} from '@/utils'

import { EMPTY_CONSTS } from '@/const'

import {
  INotTiedEquipments,
  IUserEquipmentsBindView,
} from './interfaces'

import style from './style/index.module.scss'

/**
 * * компонент привязки оборудования к пользователю
 * @view UserEquipmentsBindView
 *
 * @param id            id пользователя
 * @func  closeModal    функция закрытия модалки
 * @returns
 */
const UserEquipmentsBindView: FC<
  IUserEquipmentsBindView
> = ({ modalProps, closeModal }) => {
  const { id, refetch } = modalProps
  const intl = useIntl()
  const { api } = useStores()
  const refContainer = useRef<HTMLDivElement>(
    EMPTY_CONSTS.NULL,
  )
  const {
    tableScroll,
    error,
    lazyLoadedData,
    params,
    handleFetch,
    isScrollEnd,
  } = useLazyFetch(
    refContainer,
    async () =>
      await api.user.getEquipmentsNotTiedToUser(id, params),
  )
  const [checkedEquipments, setcheckedEquipments] =
    useState<number[]>(EMPTY_CONSTS.ARR)
  const [equipments, setEquipments] = useState<
    INotTiedEquipments[]
  >(EMPTY_CONSTS.ARR)
  const [errors, setErrors] = useState<
    string[] | null | undefined
  >(EMPTY_CONSTS.ARR)

  /**
   * * функция отправки запроса на привязывание
   * оборудования к пользователю
   * @func   handleSubmit
   * @param
   */
  const handleSubmit = (): void => {
    const equipmentIds = equipments.reduce(
      (ids, { id }) =>
        checkedEquipments.includes(id) ? [...ids, id] : ids,
      EMPTY_CONSTS.ARR,
    )

    api.user
      .tieEquipmentToUser(id, {
        equipment_ids: equipmentIds,
      })
      .then(() => {
        closeModal()
        refetch()
      })
      .catch((err) => {
        const error = getErrorsArrayFromObj(intl, err)
        setErrors(error)
      })
  }

  /**
   * * функция изменения данных оборудования
   * (+очистка ошибок на изменение)
   * @func   handleEquipment
   * @param
   */
  const handleCheckedEquipment = (
    equipments: number[],
  ): void => {
    setcheckedEquipments(equipments)
    setErrors(EMPTY_CONSTS.ARR)
  }

  /**
   * * функция изменения данных оборудования
   * (+очистка ошибок на изменение)
   * @func   handleEquipment
   * @param
   */
  const handleEquipment = (
    equipments: INotTiedEquipments[],
  ): void => {
    setEquipments(equipments)
    setErrors(EMPTY_CONSTS.ARR)
  }

  /**
   * *  @info   useEffect
   * запись нового оборудования полученного из запроса,
   * обновление ошибок
   *
   * @param
   */
  useEffect(() => {
    if (lazyLoadedData !== undefined) {
      handleEquipment(
        lazyLoadedData as INotTiedEquipments[],
      )
      if (checkedEquipments.length === 0) {
        handleCheckedEquipment(
          lazyLoadedData.reduce((all, el) => {
            if (el?.isSelected === true) {
              return [...all, el.id]
            }
            return all
          }, []),
        )
      }
    }
    if (checkHasError(error)) {
      setErrors(error)
    }
  }, [lazyLoadedData])

  return (
    <div className={style.container}>
      <Title
        title={{
          id: 'user.equipment.bind',
          defaultMessage: 'Binding to equipment',
        }}
        closeModal={closeModal}
      />
      <EquipmentContainer
        handleCheckedEquipment={handleCheckedEquipment}
        checkedEquipments={checkedEquipments}
        refContainer={refContainer}
        tableScroll={tableScroll}
        handleParams={handleFetch}
        params={params}
        equipments={equipments}
        isScrollEnd={isScrollEnd}
      />
      <ErrorsBlock errorsArray={errors} />
      <ActionButtons
        closeModal={closeModal}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

export default UserEquipmentsBindView
