import React, { FC } from 'react'
import { EMPTY_CONSTS } from '@/const'
import { useIntl } from 'react-intl'
import Icon from '@/components/Icon'
import Badge from '@/components/Badge'
import { Button } from '@/views/button'
import { Typography } from '@/views/typography'
import { ISidebarFooter } from '../../interfaces'
import style from './styles/index.module.scss'

/**
 * Футер правого сайдбара
 * @view SidebarFooter
 *
 * @param handleEdit      метод на кнопку редактирования
 * @param badgeTitle      тайтл для баджа (инициалы ФИ пользователя)
 * @param updatedAt       дата обновления
 * @param userName        фамилия имя пользователя
 * @returns
 */
const SidebarFooter: FC<ISidebarFooter> = ({
  handleEdit,
  badgeTitle = EMPTY_CONSTS.STR,
  updatedAt,
  userName,
}) => {
  const intl = useIntl()

  return (
    <div slot="footer" className={style['sidebar-footer']}>
      <Button
        className={style['sidebar-footer__btn']}
        onClick={handleEdit}
        color="gray"
        height={40}
      >
        <Icon size="xs" src="edit" />
        {intl.formatMessage({
          id: 'common.edit',
          defaultMessage: 'Edit',
        })}
      </Button>
      <div className={style['sidebar-footer__info']}>
        <div className={style['sidebar-footer__billet']}>
          {badgeTitle !== EMPTY_CONSTS.STR && (
            <Badge size="xs" title={badgeTitle} />
          )}
          {Boolean(userName) && (
            <Typography
              className={style['sidebar-footer__badge']}
              size={14}
              weight={400}
            >
              {userName}
            </Typography>
          )}
        </div>
        {Boolean(updatedAt) && (
          <div className={style['sidebar-footer__billet']}>
            <Typography size={14} weight={400}>
              {updatedAt}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default SidebarFooter
