import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from '@/views/button'
import SearchInput from '@/views/SearchInput'
import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'

import { ISearchBindPanel } from '../../interfaces'
import { isUndefined } from '@/utils'

import styles from './styles/index.module.scss'

/**
 * @component SearchBindPanel
 * * компонент таблицы Шкафов
 *
 * @param handleParams          установить дополнительные параметры к текущим query параметрам
 * @param search                значение query-параметра поиска по названию
 * @param dataLength            длина массива полученных элементов с сервера (data.results)
 */
const SearchBindPanel: FC<ISearchBindPanel> = ({
  handleParams,
  handleOpen,
  search,
  dataLength,
}) => {
  /**
   * *  @info
   * если в поисковой строке нет значения
   * (значение undefined при первом монтировании компоненты)
   * и на запрос не были получены данные, то необходимо скрыть всю
   * компоненту
   */
  console.log('search', search)

  if (isUndefined(search) && dataLength < 1) {
    return null
  }

  return (
    <div className={styles.panel}>
      <SearchInput
        className={styles.panel_search}
        handleParams={handleParams}
        size={44}
      />
      <Button
        color="black"
        className={styles.panel_btn}
        onClick={handleOpen}
        height={44}
      >
        <Icon
          size="small"
          src="linkOn"
          className={styles.panel_icon}
        />
        <Typography
          size={15}
          weight={500}
          className={styles.panel_text}
        >
          <FormattedMessage
            id="common.bind"
            defaultMessage="Привязать"
          />
        </Typography>
      </Button>
    </div>
  )
}

export default SearchBindPanel
