import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { EQUIPMENT_EDIT_FIELDS } from '@/apps/Modals/const'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { Input } from '@/views/input'

import styles from './styles/index.module.scss'

interface IHandlersProps {
  handleRemoveElement: (label: string) => void
  handleAddBtn: () => void
  handleChange: (
    event:
    | React.ChangeEvent<HTMLGxInputElement>
    | GxInputCustomEvent<HTMLGxInputElement>
  ) => void
  handleKeyPress: (
    event: React.KeyboardEvent<HTMLGxInputElement>
  ) => void
}

interface IMarksView {
  value: string
  marks: string[]
  handlers: IHandlersProps
  error: string
}

/**
 * Вюха на компонет для добавления меток оборудования
 * @view
 * @param [props.value] - значение в инпути
 * @param [props.marks] - список меток
 * @param [props.error] - тект ошибки
 * @param [props.handlers.handleRemoveElement] - удаление метки по id
 * @param [props.handlers.handleAddBtn] - метод на кнопку добавления
 * @param [props.handlers.handleChange] - метод на инпут
 * @param [props.handlers.handleKeyPress] - метод на клавиши инпута
 */
const MarksView: React.FC<IMarksView> = ({
  value,
  marks,
  handlers,
  error
}) => {
  const intl = useIntl()
  return (
    <div className={styles.marks}>
      <div
        className={classNames(styles.marks__field)}
      >
        <Input
          name={EQUIPMENT_EDIT_FIELDS.LABEL}
          label={intl.formatMessage({
            id: 'hardwareTags',
            defaultMessage: 'Метки оборудования'
          })}
          type='text'
          value={value}
          placeholder={intl.formatMessage({
            id: 'enterTagNumber',
            defaultMessage: 'Введите номер метки'
          })}
          onChange={handlers.handleChange}
          onKeyUp={handlers.handleKeyPress}
          error={Boolean(error)}
          errorMessage={error}
          clearable
        />
        <Button
          color='black'
          onClick={handlers.handleAddBtn}
          className={styles.marks__btn}
        >
          <Icon src='plus' size='xs' />
        </Button>
      </div>
      <div className={styles.marks__content}>
        <ul className={styles.marks__list}>
          {marks.map((label) => (
            <li className={styles.marks__item} key={label}>
              {label}
              <Button
                onClick={() =>
                  handlers.handleRemoveElement(label)}
                color='transparent'
                className={styles.marks__item_btn}
              >
                <Icon src='close' size='xs' />
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default MarksView
