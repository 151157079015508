import * as yup from 'yup'
import { DIGITALS, REGEX } from '../validation'

const MESSAGE = {
  min_symbols: 'validation.min_number',
  max_symbols: 'validation.max_number',
  required_field: 'validation.required',
  password: 'validation.password',
  two_numbers: 'validation.two_numbers',
  one_capital_letter: 'validation.one_capital_letter',
  two_letters: 'validation.two_letters',
  equal_passwords: 'validation.password_match'
}

const password = yup.string().required(MESSAGE.required_field)
  .min(DIGITALS.min_symbols, MESSAGE.min_symbols)
  .max(DIGITALS.max_symbols, MESSAGE.max_symbols)
  .matches(REGEX.two_numbers, { message: MESSAGE.two_numbers })
  .matches(REGEX.two_letters, { message: MESSAGE.two_letters })
  .matches(REGEX.one_capital_letter, { message: MESSAGE.one_capital_letter })
  .matches(REGEX.password, { message: MESSAGE.password })

const EditPassword = yup.object().shape({
  password,
  new_password: password,
  repeat_password: password.test(
    'password-match',
    MESSAGE.equal_passwords,
    (value, context) => context.parent.new_password === value
  )
})

export default EditPassword
