import { StylesConfig } from 'react-select'
const getBorder = ({
  isError,
  isFocused,
}: {
  isError: boolean
  isFocused: boolean
}): string => {
  if (isFocused) {
    return '1px solid var(--color-orange)'
  } else if (isError) {
    return '1px solid var(--color-red)'
  } else {
    return '1px solid var(--color-lightgray)'
  }
}
const getCustomSelectStyles = (
  isError: boolean,
): StylesConfig<any, any, any> => {
  return {
    control: (styles, { isFocused, isDisabled }) => ({
      ...styles,
      minHeight: '44px',
      borderRadius: '8px',
      backgroundColor: isDisabled
        ? 'var(--color-bg-disabled)'
        : 'var(--gx-input-background-color)',
      border: getBorder({
        isError,
        isFocused,
      }),
      ':hover': {
        ...styles[':hover'],
        border: '1px solid var(--color-gray)',
      },
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 'normal',
      boxShadow: 'none',
      transition: 'var(--transition-fast)',
      overflow: 'hidden',
      width: '100%',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: 'var(--color-gray)',
      fontSize: '14px',
    }),
    valueContainer: (styles) => ({
      ...styles,
      flexWrap: 'nowrap',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    container: (styles) => ({
      ...styles,
      transition: 'var(--transition-fast)',
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: 'var(--color-white)',
      transition: 'var(--transition-fast)',
      borderRadius: '8px',
      overflow: 'hidden',
    }),
    menuPortal: (styles) => ({
      ...styles,
      transition: 'var(--transition-fast)',
      zIndex: '100',
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: '235px',
      overflow: 'auto',
      border: '0px',
      padding: '10px 0',
      boxShadow:
        '3px 3px 4px 0px var(--color-box-shadow-dark)',
      transition: 'var(--transition-fast)',
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--color-orange)',
      '::-webkit-scrollbar': {
        width: '4px',
        backgroundColor: 'none',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--color-orange)',
        borderRadius: '4px',
      },
    }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 400,
        padding: '10px',
        transition: 'var(--transition-fast)',
        color: isSelected
          ? 'var(--color-orange)'
          : 'var(--color-black)',
        backgroundColor: 'var(--color-white)',
        cursor: 'pointer',
        ':hover': {
          ...styles[':hover'],
          color: isSelected
            ? 'var(--color-orange)'
            : 'var(--color-black)',
          backgroundColor: 'var(--color-bg-hover)',
        },
      }
    },
    singleValue: (styles) => ({
      ...styles,
      color: 'var(--color-black)',
      fontSize: '14px',
    }),
    multiValue: (styles) => ({
      ...styles,
      position: 'relative',
      border: '1px solid var(--color-gray)',
      backgroundColor: 'var(--color-white)',
      padding: '5px',
      borderRadius: '5px',
      fontSize: '16',
      fontWeight: '400',
      lineHeight: 'normal',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '5px',
      opacity: 0,
      ':hover': {
        ...styles[':hover'],
      },
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      fontSize: 18,
    }),
  }
}

export default getCustomSelectStyles
