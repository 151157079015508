import React from 'react'
import classNames from 'classnames'

import { getCurrentStatus } from '../../utils'
import { STORAGE_STATUSES } from '../../const'
import { TStorageStatus } from '@/interfaces/consts'

import Loader from '@/views/Loader'
import Icon from '@/components/Icon'
import Succes from './Succes'

import style from './style/index.module.scss'

interface IProps {
  countCells: number | null
  storageStatus: TStorageStatus
  className?: string
}

const content = {
  [STORAGE_STATUSES.SUCCES]: ({ countCells }) => (
    <Succes countCells={countCells} />
  ),
  [STORAGE_STATUSES.ERROR]: () => (
    <Icon
      className={style.error}
      src="statusNotConnected"
    />
  ),
  [STORAGE_STATUSES.SYNCHRONIZATION_FRONTEND]: () => (
    <Loader size="medium" />
  ),
}

/**
 * @component StorageStatus
 * * компонент таблицы Шкафов. Реализует интерфес показывающий текущий статус шкафа
 * @param countCells             колл ячеек
 * @param storageStatus          статус шкафа с бэкенда
 */

const StorageStatus: React.FC<IProps> = ({
  countCells,
  storageStatus,
  className,
}) => {
  const currentStatus = getCurrentStatus(storageStatus)

  const CurrentContent = content[currentStatus]
  return (
    <div
      data-status={currentStatus}
      className={classNames(
        style['storage-status'],
        className,
      )}
    >
      <CurrentContent countCells={countCells} />
    </div>
  )
}

export default StorageStatus
