import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import Icon from '@/components/Icon'

import { EMPTY_CONSTS, EMPTY_SEARCH_TYPE } from '@/const'

import styles from './styles/index.module.scss'

type TEmptySerchTheme = 'default'

interface IEmptySearch {
  className?: string
  theme?: TEmptySerchTheme
}

const EmptySearch: React.FC<IEmptySearch> = ({
  className = EMPTY_CONSTS.STR,
  theme = EMPTY_SEARCH_TYPE.default
}) => {
  const intl = useIntl()
  return (
    <div
      className={classNames(
        styles[`empty-search__${theme}`],
        { [className]: Boolean(className) }
      )}
    >
      <Icon src='search' />
      <div>
        {intl.formatMessage({
          id: 'search.empty',
          defaultMessage: 'По данному запросу ничего не найдено'
        })}
      </div>
    </div>
  )
}

export default EmptySearch
