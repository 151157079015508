import { RootStore } from './Root'
import { makeAutoObservable } from 'mobx'
import {
  ISocketIntegrationData,
  ISocketSyncErrorIntegrationMessage,
} from '@/interfaces/Socket'
import { MODAL_TYPES } from '@/const'
import { CUSTOM_DATA_ATTR_STYLES } from '@/apps/Modals/const'
/**
 * @class IntegrationEventSocket
 * * класс для обработки ивентов интеграции (скуд) по сокетам
 */
export class IntegrationEventsSocket {
  _rootStore: RootStore
  integration: ISocketIntegrationData | null

  constructor(RootStore: RootStore) {
    this._rootStore = RootStore
    this.integration = null
    makeAutoObservable(this)
  }

  public integrationEventsSubscription = ({
    data,
  }: {
    data: ISocketIntegrationData
  }): void => {
    this.integration = data
  }

  public onIntegrationSyncError = (
    response: ISocketSyncErrorIntegrationMessage,
  ) => {
    this._rootStore.modalStore.setCustomDataStyles(
      CUSTOM_DATA_ATTR_STYLES.NOTIFY_PADDING,
    )
    this._rootStore.modalStore.open(
      MODAL_TYPES.INTEGRATION_SYNC_ERROR,
      {
        ...response.data,
      },
    )
  }

  public clear = (): void => {
    this.integration = null
  }
}
