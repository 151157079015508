import React from 'react'
import classNames from 'classnames'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { Button } from '@/views/button'
import { CloseIcon } from '@/assets/icon'
import styles from '../styles/index.module.scss'
import { useStores } from '@/hooks'

interface IHeaderProps {
  colorIconWrapper?: 'red' | 'green' | 'orange'
  showCloseBtn?: boolean
  iconSlot?: React.ReactNode
  children: React.ReactNode
  className?: string
  closeBtnPosition?: 'top' | 'center'
}
/**
 * @view
 * Универсальная шапка для модалок
 */
const Header: React.FC<IHeaderProps> = ({
  children,
  iconSlot,
  showCloseBtn = true,
  colorIconWrapper = 'green',
  className,
  closeBtnPosition = 'top',
}) => {
  const {
    modalStore: { close },
  } = useStores()
  return (
    <header
      className={classNames(
        styles['base-modal__header'],
        className,
      )}
    >
      {iconSlot && (
        <div
          className={classNames(
            styles['base-modal__header__icon_wrap'],
            {
              [styles[
                `base-modal__header__icon_wrap--${colorIconWrapper}`
              ]]: Boolean(colorIconWrapper),
            },
          )}
        >
          {iconSlot}
        </div>
      )}
      <div className={styles['base-modal__header_wrap']}>
        {children}
      </div>
      {showCloseBtn && (
        <Button
          className={classNames(
            styles['base-modal__header__btn'],
            {
              [styles[
                `base-modal__header__btn--${closeBtnPosition}`
              ]]: Boolean(closeBtnPosition),
            },
          )}
          color="transparent"
          onClick={close}
        >
          <GxIcon src={CloseIcon} />
        </Button>
      )}
    </header>
  )
}

export default Header
