import { useStores } from '@/hooks'
import { isUndefined } from '@/utils'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import tippy, { Instance } from 'tippy.js'
import './styles/index.scss'
/**
 * Просто компонент рисующий тултип манипулируется через стор
 */
const Tooltip: React.FC = () => {
  const [instanceTippy, setInstanceTippy] =
    useState<Instance<any>>()
  const { tooltip } = useStores()

  useEffect(() => {
    if (!isUndefined(instanceTippy)) {
      instanceTippy.destroy()
    }
    if (tooltip.domId !== null) {
      const dummyDomElement = document.querySelector(
        tooltip.domId
      ) as Element

      const instanceTippy = tippy(dummyDomElement, {
        trigger: 'manual',
        allowHTML: true,
        theme: 'light',
        placement: 'auto',
        content: tooltip.content as any
      })
      setInstanceTippy(instanceTippy)
      instanceTippy.show()
    }
  }, [tooltip.isShow])

  return null
}

export default observer(Tooltip)
