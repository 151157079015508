import React, { useEffect } from 'react'

import { observer } from 'mobx-react'
import Media from 'react-media'
import { Link } from 'react-router-dom'

import Icon from '@/components/Icon'
import Badge from '@/views/Badge'
import UserEditDropdown from '../../view/UserEditDropdown'
import Avatar from '@/components/Avatar'
import { Button } from '@/views/button'

import { useCurrentUser, usePage, useStores } from '@/hooks'
import { getInitials } from '@/utils'
import { BADGE_THEME, MEDIA_QUERY } from '@/const'

import style from './styles/index.module.scss'

/**
 * ** Component  -  компонент банера юзера
 * ? подойдет только для этой апп
 */
const User: React.FC = observer(() => {
  const { notificationStore, api } = useStores()
  const { baseSlug } = usePage()
  const haveNotify = Boolean(notificationStore?.count)

  const currentUser = useCurrentUser()
  const initials: string = getInitials(
    currentUser.first_name,
    currentUser.last_name,
  )
  const handleOpenNotification = notificationStore.open

  useEffect(() => {
    void api.notification
      .getNotification({ is_read: false })
      .then((data) => {
        notificationStore.setCount(data.count)
      })
  }, [])

  return (
    <div className={style.container}>
      <Button
        variant="icon"
        onClick={handleOpenNotification}
        className={style.notification}
      >
        <Icon src="notification" size="large" />
        {haveNotify && (
          <Badge
            count={notificationStore.count}
            theme={BADGE_THEME.ICON}
          />
        )}
      </Button>
      <Media
        queries={{
          large: MEDIA_QUERY.MEDIA_700,
        }}
      >
        {(matches) => {
          return (
            <>
              {!matches.large ? (
                <UserEditDropdown
                  initials={initials}
                  firstName={currentUser.first_name}
                />
              ) : (
                <Link
                  data-notify={haveNotify}
                  className={style.avatar__link}
                  to={baseSlug.userProfilePage}
                >
                  <Avatar
                    className={style.avatar__mobile}
                    name={currentUser.first_name}
                    surname={currentUser.last_name}
                    changable={false}
                    size="little"
                  />
                </Link>
              )}
            </>
          )
        }}
      </Media>
    </div>
  )
})

export default User
