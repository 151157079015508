import React from 'react'

import styles from '../styles/index.module.scss'

interface IButtonWrapperProps {
  children: React.ReactNode
}
/**
 * @view
 * Универсальная обертка для кнопок модалки
 */
const ButtonWrapper: React.FC<IButtonWrapperProps> = ({
  children,
}) => {
  return (
    <div className={styles['base-modal__wrap_btn']}>
      {children}
    </div>
  )
}

export default ButtonWrapper
