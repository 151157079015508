import React from 'react'

import { ICellFilterParams, IStorageCell } from '@/api/interfaces'
import { IStorageTable } from '@/apps/StorageList/interfaces'

import { CONTENT_CONTAINER_THEME } from '../../const'
import {
  DEFAULT_CELL_PAGE_SIZE,
  EMPTY_CONSTS, TABLE_PAGE,
  TABLE_WRAPPER_ICON_SRC
} from '@/const'

import CellPanel from './CellPanel'
import CellCards from './CellCards'
import Pagination from '@/components/Pagination'
import TableWrapper from '@/views/TableWrapper'
import ContentContainer from '../ContentContainer'

import style from './styles/index.module.scss'

interface IProps extends IStorageTable {
  cells?: IStorageCell[]
  params: ICellFilterParams
  handleParams: (params: ICellFilterParams) => void
}
/**
 * @view
 * * вью реализовывающий интрфейс стр ячеек
 * @param cells                    массив ячеек
 * @param params                   текущие query параметры
 * @param handleParams             установить дополнительные параметры к текущим query параметрам
 * @param isLoading                статус загрузки данных с сервера
 * @param currentPage               номер текущей страницы
 * @function handlePage            функция меняющая номер страницы
 * @param total                    общее количество оборудований
 * @param error                    ошибки, полученные при запросе оборудования
 * @returns
 */
const CellsView: React.FC<IProps> = ({
  cells = EMPTY_CONSTS.ARR,
  handleParams,
  refetch,
  handlePage,
  params,
  total,
  isLoading,
  currentPage,
  error
}) => {
  return (
    <ContentContainer theme={CONTENT_CONTAINER_THEME.CELLS}>
      <CellPanel
        params={params}
        refetch={refetch}
        handleParams={handleParams}
      />
      <TableWrapper
        iconSrc={TABLE_WRAPPER_ICON_SRC.STORAGE}
        search={params?.search}
        isLoading={isLoading}
        type={TABLE_PAGE.CELL}
        error={error}
        length={cells?.length}
      >
        <div className={style.wrapper__body}>
          <CellCards cells={cells} />
        </div>
        <div className={style.wrapper__footer}>
          <Pagination
            total={total}
            currentPage={currentPage}
            pageSize={DEFAULT_CELL_PAGE_SIZE}
            handleChange={handlePage}
          />
        </div>
      </TableWrapper>
    </ContentContainer>
  )
}

export default CellsView
