import React from 'react'

import TabPanelWrap, {
  ITabPanelWrapProps,
} from '../TabPanelWrap'
import Table from '@/apps/Table/component/Table'
import UserBadge from '@/views/UserBadge'
import UserRole from '@/views/UserRole'
import { UnlinkStorage as UnlinkUser } from '@/apps/RightSidebar/views/UserStorageTableView/UserStorageViews'

import { getInitials } from '@/utils'

import { USER_GROUP_USERS_TABLE_KEYS } from '../../const'
import { EMPTY_CONSTS } from '@/const'

import {
  ITiedEquipmentUser,
  TUser,
  TUserRole,
} from '@/api/interfaces'

import { tableStyles } from './styles/tableStyles'
import styles from './styles/index.module.scss'
import { USER_STORAGE_UNBIND_VARIANT } from '@/apps/Modals/const'

interface IProps extends ITabPanelWrapProps {
  refetch: () => void
  lazyLoadedData?: ITiedEquipmentUser[]
  handleUnbindUser: (idUser: number) => Promise<void>
}
/**
 * @view
 * * Вьюха таблицы юзеров в деталке
 */
const UserTableTab: React.FC<IProps> = ({
  searchPanelProps,
  tableWrapProps,
  containerRef,
  isScrollEnd,
  tableScroll,
  lazyLoadedData,
  refetch,
  isLoading,
  handleUnbindUser,
}) => {
  /**
   * * Рендер отвязки пользователя в сайдбаре Группы пользователя
   * @function renderIdCell
   * @param id
   * @param userData
   */
  const renderIdCell = (
    userId: number,
    userData: TUser,
  ) => {
    const undbindMethod = async () => {
      await handleUnbindUser(userId)
    }
    return (
      <UnlinkUser
        handleUnbindMethod={undbindMethod}
        refetch={refetch}
        userData={userData}
        type={USER_STORAGE_UNBIND_VARIANT.USER_UNBIND}
      />
    )
  }

  const columns = [
    {
      key: USER_GROUP_USERS_TABLE_KEYS.FULL_NAME,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (fullName: string, props: TUser) => {
          const initials = getInitials(
            props.first_name,
            props.last_name,
          )
          return (
            <UserBadge
              size={18}
              title={initials}
              weight={700}
              className={styles['users-panel__badge']}
            >
              {fullName}
            </UserBadge>
          )
        },
      },
    },
    {
      key: USER_GROUP_USERS_TABLE_KEYS.ACCOUNT_TYPE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (accType: string) => <span>{accType}</span>,
        className: styles['table__body_acc-type'],
      },
    },
    {
      key: USER_GROUP_USERS_TABLE_KEYS.USER_ROLE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (role: TUserRole) => (
          <UserRole role={role} />
        ),
        className: styles.table__body_title,
      },
    },
    {
      key: USER_GROUP_USERS_TABLE_KEYS.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: renderIdCell,
        className: styles.table__body_unlink,
      },
    },
  ]

  return (
    <TabPanelWrap
      searchPanelProps={searchPanelProps}
      tableWrapProps={tableWrapProps}
      containerRef={containerRef}
      isScrollEnd={isScrollEnd}
      tableScroll={tableScroll}
      loadedData={lazyLoadedData}
      isLoading={isLoading}
    >
      <Table
        columns={columns}
        rowsData={lazyLoadedData}
        styles={tableStyles}
      />
    </TabPanelWrap>
  )
}

export default UserTableTab
