import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  GxForm,
  GxIcon
} from '@garpix/garpix-web-components-react'

import ErrorsBlock from '@/components/ErrorsBlock'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import { Input } from '@/views/input'
import { ArrowBackIcon } from '@/assets/icon'

import { EMPTY_CONSTS } from '@/const'

import { ISendCode } from '../interfaces'

import styles from '../style/index.module.scss'

/**
 * @component SendCode
 * * компонент формы установки и отправки нового пароля пользователя
 *
 * @param {function}  handleUsernameValue  функция для изменения логина (почты) пользователя вводимого в инпут
 * @param {function}  onSubmit             функция для отправки проверочного кода на почту
 * @param {function}  handleBack           функция возврата на страницу Login
 * @param {string}    username             логин (почта) пользователя
 * @param {IError}    error                обьект ошибок
 */
const SendCode: React.FC<ISendCode> = ({
  handleUsernameValue,
  onSubmit,
  handleBack,
  username,
  error
}) => {
  const intl = useIntl()
  return (
    <>
      <GxForm onGx-submit={onSubmit}>
        <div className={styles.container__form}>
          <h1 className={styles.container__title}>
            <Button
              onClick={handleBack}
              variant='text'
            >
              <GxIcon
                src={ArrowBackIcon}
                className={styles.container__backicon}
              />
            </Button>
            <FormattedMessage
              id='titles.password_recovery'
              defaultMessage='Восстановление пароля'
            />
          </h1>
          <div className={styles.container__subform}>
            <Typography
              size={24}
              className={styles.container__subtitle}
            >
              <FormattedMessage
                id='recovery.send_code'
                defaultMessage='Укажите электронную почту, которую вы использовали для входа в Личный кабинет.'
              />
            </Typography>
            <Input
              type='text'
              label={intl.formatMessage({
                id: 'login.email',
                defaultMessage: 'Почта'
              })}
              name='username'
              data-testid='username'
              data-cy='email'
              labelStyles={styles.form__field_label}
              outerStyles={styles.form__field}
              value={username}
              onBlur={handleUsernameValue}
              onChange={handleUsernameValue}
              error={Boolean(error?.username)}
            />
          </div>
          <Button
            type='submit'
            color='black'
            className={styles.form__button}
          >
            <FormattedMessage
              id='button.recovery'
              defaultMessage='Восстановить'
            />
          </Button>
          <ErrorsBlock
            networkError={error?.network_error}
            errorsArray={[
              ...(error?.non_field_error ?? EMPTY_CONSTS.STR),
              ...(error?.non_field_errors ?? EMPTY_CONSTS.STR),
              ...(error?.username ?? EMPTY_CONSTS.STR)
            ]}
          />
        </div>
      </GxForm>
    </>
  )
}

export default SendCode
