import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './style/index.module.scss'
import { EMPTY_CONSTS } from '@/const'

interface TSpinner {
  isFullPage?: boolean
  isModal?: boolean
  size?: 'large' | 'medium' | 'small'
  global?: boolean
  theme?: string
  className?: string
}

export const Spinner: FC<TSpinner> = ({
  isFullPage,
  isModal,
  size,
  className,
  theme = EMPTY_CONSTS.STR,
  global = false
}) => {
  return (
    <div
      className={classNames({
        [styles.spinner__container]: true,
        [styles.spinner__container_full]: isFullPage,
        [styles.spinner__container_modals]: isModal,
        [styles[`spinner__container_${theme}`]]: Boolean(theme)
      }, className)}
      data-global={global}
    >
      <svg
        className={classNames(styles.spinner, {
          [styles.spinner_large]: size === 'large',
          [styles.spinner_medium]: size === 'medium',
          [styles.spinner_small]: size === 'small'
        })}
        width='120'
        height='120'
        viewBox='0 0 120 120'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60ZM12 60C12 86.5097 33.4903 108 60 108C86.5097 108 108 86.5097 108 60C108 33.4903 86.5097 12 60 12C33.4903 12 12 33.4903 12 60Z'
          fill='#EFF1F5'
        />
        <path
          d='M69.377 113.18C69.9524 116.443 67.7722 119.587 64.4677 119.833C53.9048 120.622 43.2821 118.602 33.6977 113.928C24.1134 109.253 15.9816 102.126 10.1 93.3166C8.25996 90.5607 9.395 86.9072 12.3208 85.3515V85.3515C15.2467 83.7958 18.8504 84.9339 20.7588 87.6429C25.4025 94.235 31.6529 99.5791 38.9582 103.142C46.2635 106.705 54.3228 108.34 62.3764 107.941C65.6861 107.777 68.8016 109.916 69.377 113.18V113.18Z'
          fill='#FF8800'
        />
      </svg>
    </div>
  )
}
