import * as yup from 'yup'
import { INTL_DEFAULT_MESSAGE } from '@/const'
import { REGEX } from '../validation'

const EditEmailScheme = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
    .matches(REGEX.email, {
      message: INTL_DEFAULT_MESSAGE.UNCORRECT_EMAIL
    })
})

export default EditEmailScheme
