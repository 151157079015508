import React from 'react'

import UserGroupDeleteView from '../../views/UserGroupDeleteView'

import { useMutation, useStores } from '@/hooks'

import { IModal } from '../interfaces'

import { isUndefined } from '@/utils'

import { MODAL_CUSTOM_CONTENT, MODAL_TYPES } from '@/const'
import { IGroupList } from '@/api/interfaces/GroupUsers'

type IUserGroupProps = Omit<
  IGroupList,
  'create_at' | 'users'
>

interface IProps extends IModal {
  modalProps: {
    userGroup: IUserGroupProps
    refetch: () => void
  }
}
/**
 * @component
 * * Компонент модалки удаления группы пользователя
 */
const UserGroupDelete: React.FC<IProps> = ({
  closeModal,
  modalProps,
}) => {
  const { userGroup, refetch } = modalProps
  const { api, modalStore } = useStores()

  const { mutation, isLoading } = useMutation({
    mutation: async () =>
      await api.groupUsers.deleteGroup(userGroup.id),
    onSuccess: () => {
      refetch()
      closeModal()
    },
    onError: (err) => {
      const error = err.response?.data?.message
      if (!isUndefined(error)) {
        modalStore.open(MODAL_TYPES.WARNING_ERROR, {
          error: [error],
        })
      } else {
        modalStore.open(
          MODAL_TYPES.CUSTOM,
          MODAL_CUSTOM_CONTENT.ERROR,
        )
      }
    },
  })

  return (
    <UserGroupDeleteView
      handleDelete={mutation}
      isDisabled={isLoading}
      closeModal={closeModal}
      title={userGroup.title}
      color={userGroup.color}
    />
  )
}

export default UserGroupDelete
