import React from "react";
import { useLocation } from "react-router-dom";
import { NoSSR, PageProvider, usePageContext } from "@garpix/cms";
import { PageModelType } from "@/api/interfaces/Page";
import RightSidebar from "@/apps/RightSidebar";
import { useStores } from "@/hooks";
import Modals from "@/apps/Modals";

import { ERROR_PAGE_TYPE } from "@/apps/Errors/const";

import CustomVh from "@/components/CustomVh";
import Tooltip from "@/components/Tooltip";

// [PAGES]
import Error from "@/pages/ErrorsPage";

import UserAuthorizationPage from "./UserAuthorizationPage";
import UserPasswordRecoveryPage from "./UserPasswordRecoveryPage";
import EquipmentListPage from "./EquipmentListPage";
import UserListPage from "./UserListPage";
import UserProfilePage from "./UserProfilePage";
import StorageListPage from "./StorageListPage";
import ProblemPage from "./ProblemPage";
import StoragePage from "./StoragePage";
import AccessControlPage from "./AccessControlPage";
import GroupUserPage from "./GroupUserPage";

import Socket from "@/apps/socket";
import UserSocket from "@/apps/userSocket";
import Notify from "@/components/notify/Notify";
import ErrorBoundary from "@/components/ErrorBoundary";
import { runtimeConfig } from "@/config";
import Toast from "@/apps/Toast";
import Notifications from "@/apps/Notifications";
import { isUndefined } from "@/utils";

const PAGE_TYPES: Partial<{
  [key in PageModelType]: React.FC;
}> = {
  // ---------------------------------------------------//
  // [DEFAULT]
  Page401: () => <Error type={ERROR_PAGE_TYPE.FORBIDDEN_ERROR} clearCookie />,
  Page403: () => <Error type={ERROR_PAGE_TYPE.FORBIDDEN} />,
  Page404: () => <Error type={ERROR_PAGE_TYPE.NOT_FOUND} />,
  PageNotFound: () => <Error type={ERROR_PAGE_TYPE.NOT_FOUND} />,
  PageErrorServer: () => <Error type={ERROR_PAGE_TYPE.SERVER_ERROR} />,
  Page: () => <Error type={ERROR_PAGE_TYPE.CUSTOM_ERROR} />,
  // [END DEFAULT]
  // ---------------------------------------------------//
  // [LOGIN] - доменная часть страниц авторизации в дальнейшем декомпозировать
  UserAuthorizationPage,
  UserPasswordRecoveryPage,
  // [END LOGIN]
  // ---------------------------------------------------//
  // [LISTS] - доменная часть списочных страниц
  UserProfilePage,
  EquipmentListPage,
  UserListPage,
  StorageListPage,
  AccessControlPage,
  ProblemPage,
  StoragePage,
  GroupUserPage
  // [END LISTS]
  // ---------------------------------------------------//
};

const PageStarter = (): React.ReactElement | null => {
  const { page: data } = usePageContext();

  const pageType = data?.pageType;
  const page = data?.page;
  if (pageType === undefined) {
    return null;
  }
  if (runtimeConfig.IS_DEV === "true") {
    // console.log('pageType', pageType)
    // console.log('page', page)
  }

  // todo: убрать проверки  поправить интерфейсы [LOCKER-366]
  const Page = PAGE_TYPES[pageType];
  if (Page !== undefined) return <Page {...page} />;
  return null;
};
// todo: ОПИСАТЬ ТИП ДЛЯ  staticContext
const Combine = ({ staticContext }: any): React.ReactElement => {
  const location = useLocation();
  const store = useStores();

  return (
    <PageProvider
      scrollRestoration="auto"
      isScrollTo={false}
      fetchData={store.api.page.getPage}
      pathname={location.pathname}
      queryParams={location.search}
      initData={staticContext?.initData}
    >
      <ErrorBoundary>
        <NoSSR>
          {!isUndefined(staticContext?.SOCKET_OFF) ? null : (
            <>
              <Socket />
              <UserSocket />
            </>
          )}
          <CustomVh />
          <Tooltip />
        </NoSSR>
        <Notify />
        <PageStarter />
        <RightSidebar />
        <Modals />
        <Toast />
        <Notifications />
      </ErrorBoundary>
    </PageProvider>
  );
};

export default Combine;
