import React, { FC, ReactNode, memo } from 'react'

import cn from 'classnames'

import style from '../styles/index.module.scss'
import { ICell, IRowData } from '../../interfaces'

interface IProps {
  content: ReactNode
  currentRowData?: IRowData
  theme?: 'head' | 'body' | 'sticky'
  view?: 'td' | 'th'
}

/**
 * * Вью для отрисовки ячейки таблицы
 * @view
 */
const Cell: FC<IProps & ICell> = ({
  className,
  render,
  content,
  view: Tag = 'td',
  theme = 'body',
  currentRowData,
  ...props
}) => {
  return (
    <Tag
      className={cn(
        style.table__cell,
        style[`table__cell-${theme}`],
        className
      )}
      role='cell'
      {...props}
    >
      {render !== undefined
        ? render(content, currentRowData)
        : content}
    </Tag>
  )
}

export default memo(Cell)
