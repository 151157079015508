import AbsractApi from '@/api/abstract'
import {
  IEquipmentApi,
  IEquipmentList,
  IEquipmentQueryParams,
  IEquipmentTypesList,
  IEquipmentTypesQueryParams,
  IEquipmentsExcelSample,
  IUnbindUserEquipmentValue,
  TCreateExcel,
  TCreateEquipment,
  IEquipmentCreateResponse,
  IEquipment,
  ITiedEquipmentUsersList,
  ITiedEquipmentUsersListParams,
  TGetEquipmentsHoCanBeTied,
  IEquipmentsHoCanBeTiedList,
  TtieEquipments,
  IEquipmentProblemsList,
  IEquipmentProblemsParams,
  IDownloadReportParams,
  TUpdateEquipment,
  IHistoryEquipmentQueryParams,
  IHistoryEquipmentList,
  IAxiosConfig,
} from '@/api/interfaces'
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE,
  CREATE_EXCEL,
  DEFAULT_PAGE_LAZY_SIZE,
} from '@/const'
import { isUndefined } from '@/utils'

class EquipmentApi implements IEquipmentApi {
  ctx: AbsractApi

  constructor(ctx: AbsractApi) {
    this.ctx = ctx
  }

  public getEquipments = async (
    param?: IEquipmentQueryParams,
    config?: IAxiosConfig,
  ): Promise<IEquipmentList> => {
    const res = await this.ctx.get<IEquipmentList>(
      '/equipments/',
      {
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size: DEFAULT_PAGE_SIZE,
        ...param,
      },
      config,
    )
    return res.data
  }

  public getUsers = async ({
    id,
    ...param
  }: ITiedEquipmentUsersListParams): Promise<ITiedEquipmentUsersList> => {
    const res = await this.ctx.get<ITiedEquipmentUsersList>(
      `/equipments/${id}/tied_to_users/`,
      {
        ...param,
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size: DEFAULT_PAGE_SIZE,
      },
    )
    return res.data
  }

  public getEquipmentTypes = async (
    param: IEquipmentTypesQueryParams,
  ): Promise<IEquipmentTypesList> => {
    const res = await this.ctx.get<IEquipmentTypesList>(
      '/equipments/types/',
      {
        ...param,
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size: DEFAULT_PAGE_SIZE,
      },
    )
    return res.data
  }

  public getExcelSample = async (): Promise<string> => {
    const res = await this.ctx.get<IEquipmentsExcelSample>(
      '/equipments/excel_sample/',
    )
    return res.data.equipment_file
  }

  public createExcel: TCreateExcel = async ({
    file,
    storage,
  }) => {
    const fd = new FormData()
    fd.append(CREATE_EXCEL.FILE, file)
    if (!isUndefined(storage)) {
      fd.append(CREATE_EXCEL.STORAGE_ID, storage)
    }
    await this.ctx.post<FormData>(
      '/equipments/create_from_excel/',
      fd,
    )
  }

  public downloadProblemHistory = async ({
    id,
    ...param
  }: IDownloadReportParams): Promise<void> => {
    await this.ctx.post(
      `/equipments/${id}/problem_history/`,
      param,
    )
  }

  public downloadHistory = async ({
    id,
    ...param
  }: IDownloadReportParams): Promise<void> => {
    await this.ctx.post(`/equipments/${id}/history/`, param)
  }

  public unbindUserEquipment = async (
    id: number,
    value: IUnbindUserEquipmentValue,
  ): Promise<void> => {
    await this.ctx.post(
      `/equipments/${id}/unbind_user/`,
      value,
    )
  }

  public getEquipment = async (param: {
    id: number
  }): Promise<IEquipment> => {
    const res = await this.ctx.get<IEquipment>(
      `/equipments/${param.id}/`,
    )
    return res.data
  }

  public createEquipment: TCreateEquipment = async (
    params,
  ) => {
    const res =
      await this.ctx.post<IEquipmentCreateResponse>(
        '/equipments/',
        params,
      )
    return res.data
  }

  public updateEquipment: TUpdateEquipment = async ({
    id,
    ...params
  }) => {
    const res =
      await this.ctx.patch<IEquipmentCreateResponse>(
        `/equipments/${id}/`,
        params,
      )
    return res.data
  }

  public getEquipmentsHoCanBeTied: TGetEquipmentsHoCanBeTied =
    async (params) => {
      const res =
        await this.ctx.get<IEquipmentsHoCanBeTiedList>(
          '/equipments/equipments_ho_can_be_tied/',
          params,
        )
      return res.data
    }

  public tieEquipments: TtieEquipments = async (
    storageId,
    value,
  ) => {
    await this.ctx.post(
      `/storage/${storageId}/tied_equipments/`,
      value,
    )
  }

  public getProblems = async (
    param: IEquipmentProblemsParams,
  ): Promise<IEquipmentProblemsList> => {
    const res = await this.ctx.get<IEquipmentProblemsList>(
      `/equipments/${param.id}/problems/`,
      {
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size: DEFAULT_PAGE_SIZE,
        ...param,
      },
    )
    return res.data
  }

  public deleteEquipment = async (
    id: number,
  ): Promise<void> => {
    await this.ctx.delete(`/equipments/${id}/`)
  }

  public getHistoryEquipment = async ({
    id,
    ...params
  }: IHistoryEquipmentQueryParams): Promise<IHistoryEquipmentList> => {
    const res = await this.ctx.get<IHistoryEquipmentList>(
      `/equipments/${id}/history`,
      {
        page: params?.page ?? DEFAULT_CURRENT_PAGE,
        page_size: params?.page ?? DEFAULT_PAGE_LAZY_SIZE,
        ...params,
      },
    )
    return res.data
  }

  public unbindGroupUser = async (
    equipmentId: number,
    groupId: number,
  ): Promise<void> => {
    await this.ctx.post(
      `/equipments/${equipmentId}/unbind_group_user/`,
      {
        group_id: groupId,
      },
    )
  }
}

export default EquipmentApi
