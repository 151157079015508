import React from 'react'
import classNames from 'classnames'
import styles from './styles/index.module.scss'

export interface IBadge {
  title?: string
  size?: 'xxs' | 'xs' | 'small' | 'medium' | 'large'
  photo?: string
  className?: string
}

/**
 * @component Badge
 * * компонент значка пользователя
 * @param {IBadge}      общий интерфейс входных параметров Badge
 *
 * @param title             инициалы (имя фамилия)
 * @param size              размер
 * @param photo             путь к фото
 * @param className         дополнительные стили
 */
const Badge: React.FC<IBadge> = ({
  title = '',
  photo,
  size = 'xs',
  className = ''
}) => {
  return (
    <div className={classNames({
      [styles.badge]: true,
      [styles[`badge--${size}`]]: size,
      [className]: Boolean(className)
    })}
    >
      {photo !== undefined ? <img src={photo} /> : null}
      {title}
    </div>
  )
}

export default Badge
