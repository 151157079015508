import React, { ReactNode } from 'react'

import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'

import style from './style/index.module.scss'

interface IProps {
  children: ReactNode
}

/**
 * @view
 * * Вью для стр с ошибками в которой мы реализуем
 * * общий контейнер для контента с ошибками
 * @see figma
 */

const ErrorsContainer: React.FC<IProps> = ({
  children
}) => {
  return (
    <CSSTransitionGroup className={style.animation}>
      <div className={style['error-container']}>
        {children}
      </div>
    </CSSTransitionGroup>
  )
}

export default ErrorsContainer
