import React, { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import useCustomRestorePassword from '@/apps/Modals/hook/ModalsPipelineEditPassword'
import Icon from '@/components/Icon'
import Profile from '@/views/Profile'
import { Button } from '@/views/button'
import { IReturnHookEditUsetForm } from '@/hooks/useEditUserForm'
import { useStores } from '@/hooks'
import { MODAL_TYPES } from '@/const'
import styles from './styles/index.module.scss'
import useCustomRestoreEmail from '@/apps/Modals/hook/ModalsPipelineEditEmail'

type IProps = Omit<IReturnHookEditUsetForm, 'actualInitials'>

/**
 * @info Форма профиля к Кнопкой
 */
const ProfileForm: FC<IProps> = (props) => {
  const intl = useIntl()
  // const { messages } = intl
  const { modalStore } = useStores()

  const { handleRecoveryPassword } = useCustomRestorePassword()
  const { handleEditMail, currentEmail } = useCustomRestoreEmail()

  const {
    isLoading,
    handleSubmit,
    ...rest
  } = props

  const handelChangePssword = (): void => {
    modalStore.open(MODAL_TYPES.CHANGE_PASSWORD, { handleRecoveryPassword })
  }

  const texts = useMemo(() => ({
    save: intl.formatMessage({ id: 'common.save', defaultMessage: 'Сохранить' })
  }), [])

  const actiualEmail = currentEmail.length === 0 ? rest.values.email : currentEmail

  return (
    <form
      className={styles.profile__form}
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Profile.Form
        {...rest}
        handelChangePssword={handelChangePssword}
        handleEditMail={handleEditMail}
        actiualEmail={actiualEmail}
      />
      <Button
        color='black'
        type='submit'
        className={styles['profile__form__btn--save']}
        load={isLoading}
      >
        <Icon src='ok' />
        {texts.save}
        {/* {messages['common.save'] as string} */}
      </Button>
    </form>
  )
}

export default ProfileForm
