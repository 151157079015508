import React from 'react'
import { Field, useFormikContext } from 'formik'

import { Input } from '@/views/input'
import { Switch } from '@/views/switch'

import { useTranslate } from '@/hooks'

import { TAccesControlIntegrationValues } from '@/api/interfaces/Integration'

import { DEFAULT_INTEGRATION_FIELDS } from '@/apps/Integration/const'

import styles from '../style/index.module.scss'
/**
 * @view
 * плашка с вкл-выкл уведомлений на почту по скуду
 */
const EmailNotify = () => {
  const { translate } = useTranslate()
  const { values } =
    useFormikContext<TAccesControlIntegrationValues>()

  return (
    <>
      <Field
        name={DEFAULT_INTEGRATION_FIELDS.is_email_alerts}
      >
        {({ field }) => (
          <Switch
            handleChange={field.onChange}
            checked={field.value}
            name={field.name}
            className={styles.switch}
          >
            {translate({
              id: 'enableNotify',
              defaultMessage: 'Уведомления на почту',
            })}
          </Switch>
        )}
      </Field>
      <div
        className={styles.animation}
        data-show={values.is_email_alerts}
      >
        <div className={styles.auth}>
          <Field name={DEFAULT_INTEGRATION_FIELDS.email}>
            {({ field, meta, form }) => (
              <Input
                {...field}
                outerStyles={styles.field}
                label={translate({
                  id: 'emailNotify',
                  defaultMessage: 'Почта для уведомлений',
                })}
                error={
                  Boolean(form.values.is_email_alerts) &&
                  Boolean(meta.error) &&
                  Boolean(meta.touched)
                }
                errorMessage={meta.error}
              />
            )}
          </Field>
        </div>
      </div>
    </>
  )
}

export default EmailNotify
