import React, { forwardRef } from 'react'

import { Components } from '@garpix/garpix-web-components'
import { GxTab } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'

import style from './styles/index.module.scss'

/**
 * * компонент кнопки таба
 * @vcomponent Tab
 *
 * @param panel           название панели на которую переключает таб
 * @returns
 */
const Tab = forwardRef<Components.GxTabGroup, any>(
  (props, ref): React.ReactElement => {
    const {
      children = '',
      className = '',
      slot = 'nav',
      panel = '',
    } = props
    return (
      <GxTab
        ref={ref}
        {...props}
        slot={slot}
        panel={panel}
        className={classNames({
          [style.tab]: true,
          [className]: Boolean(className),
        })}
        resize="auto"
      >
        {children}
      </GxTab>
    )
  },
)
export default Tab
