import React, {
  SyntheticEvent,
  useRef,
  useState,
} from 'react'

import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import { useLazyFetch } from '@/hooks'
import { useObservableMap } from '@/apps/Modals/hook'

import { getErrorsArrayFromObj } from '@/utils'
import {
  DEFAULT_PAGE_LAZY_SIZE,
  EMPTY_CONSTS,
} from '@/const'

import EquipmentBindingViews from '@/apps/Modals/views/EquipmentBindingViews'
import ErrorsBlock from '@/components/ErrorsBlock'
import {
  INotTiedEquipmentList,
  IPaginatedParams,
} from '@/api/interfaces'

interface IProps {
  handleFetchEquipments: (
    param: IPaginatedParams,
  ) => Promise<INotTiedEquipmentList>
  handleBindEquipments: (
    equipIdArr: number[],
  ) => Promise<void>
  closeModal: () => void
  refetch: () => void
}
/**
 * @component
 * * коспонент модалки привязки оборудования
 * * с ее логикой
 * @param closeModal
 * @param refetch
 * @returns
 */
const EquipmentBind: React.FC<IProps> = ({
  closeModal,
  refetch,
  handleFetchEquipments,
  handleBindEquipments,
}) => {
  const [isDisabled, setIsDisabled] =
    useState<boolean>(false)
  const [fetchErrors, setFetchErrors] = useState<string[]>()
  const listRef = useRef<HTMLUListElement>(
    EMPTY_CONSTS.NULL,
  )
  const intl = useIntl()
  const {
    lazyLoadedData: equipments = EMPTY_CONSTS.ARR,
    isLoading,
    tableScroll: scrollEvent,
    handleParams,
    error: lazyLoadError,
  } = useLazyFetch(listRef, handleFetchEquipments, {
    page_size: DEFAULT_PAGE_LAZY_SIZE,
  })

  const {
    observableMap: selectedEquipmentId,
    setObservableMap,
    clearObservableMap,
    deleteValueObservableMap,
  } = useObservableMap<number, number>()
  /**
   * * метод для изменения Map с выбранными пользоватиелями
   * @func handleChangeEquipList
   * @param e
   */
  const handleChangeEquipList = (
    e: GxCheckboxCustomEvent<HTMLGxCheckboxElement>,
  ): void => {
    const equipmentId = Number(e.target.dataset.id)
    if (e.target.checked) {
      setObservableMap(equipmentId, equipmentId)
    } else {
      deleteValueObservableMap(equipmentId)
    }
  }
  /**
   * * метод для привязки оборудования
   * @func
   * @param e
   */
  const handleSubmit = (
    e: SyntheticEvent<HTMLFormElement>,
  ): void => {
    e.preventDefault()
    const equipIdArr = Array.from(
      selectedEquipmentId,
      ([key]) => key,
    )
    setIsDisabled(true)

    handleBindEquipments(equipIdArr)
      .then(() => {
        refetch()
        closeModal()
      })
      .catch((err) => {
        setFetchErrors(getErrorsArrayFromObj(intl, err))
      })
      .finally(() => {
        setIsDisabled(false)
      })
  }

  const scrollWrapProps = {
    dataCount: equipments.length,
    listRef,
    isLoading,
    scrollEvent,
  }

  const filterPanelProps = {
    equipmentSelectCount: selectedEquipmentId.size,
    clearObservableMap,
    handleParams,
  }

  const footerProps = {
    isDisabled,
    closeModal,
  }

  const equipmentItemProps = {
    selectedEquipmentId,
    handleChangeEquipList,
  }

  const checkLazyErr =
    lazyLoadError !== EMPTY_CONSTS.NULL &&
    lazyLoadError !== EMPTY_CONSTS.UNDEFINED &&
    lazyLoadError.length !== 0

  if (checkLazyErr) {
    return <ErrorsBlock networkError />
  }

  return (
    <EquipmentBindingViews
      equipmentItemProps={equipmentItemProps}
      equipments={equipments}
      scrollWrapProps={scrollWrapProps}
      handleSubmit={handleSubmit}
      filterPanelProps={filterPanelProps}
      footerProps={footerProps}
      fetchErrors={fetchErrors}
    />
  )
}

export default observer(EquipmentBind)
