import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { IReturnHookEditUsetForm } from '@/hooks/useEditUserForm'
import styles from '../styles/index.module.scss'

interface IProps extends Pick<IReturnHookEditUsetForm, 'values'> {
  handelChangePssword: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleEditMail: () => void
  actiualEmail: string
}

/**
 * @info информационные элемены внизу формы
 *       с возможностью вызова редактирования
 * @returns
 */
const InfoFields: FC<IProps> = ({
  values,
  actiualEmail,
  handelChangePssword,
  handleEditMail
}) => {
  const intl = useIntl()

  const texts = useMemo(() => ({
    hid: intl.formatMessage({ id: 'profile.form.hid', defaultMessage: 'HID номер карты' }),
    email: intl.formatMessage({ id: 'profile.form.email', defaultMessage: 'Email' }),
    password: intl.formatMessage({ id: 'profile.form.password', defaultMessage: 'Пароль' }),
    edit_password: intl.formatMessage({ id: 'profile.modal.changePassword', defaultMessage: 'Изменить пароль' })
  }), [])

  return (
    <>
      {/* Информация о HID карточке */}
      <div className={styles.form__info__wrap}>
        <Icon
          src='card'
          size='small-xl'
        />
        <div className={styles.form__info__right}>
          {texts.hid}
          <span className={styles.form__info_bold}>
            {values?.card_hid_number}
          </span>
        </div>
      </div>

      {/* Информация о email c кнопкой редактирования */}
      <div className={styles.form__info__wrap}>
        <Icon
          src='mail'
          size='small-xl'
        />
        <div className={styles.form__info__right}>
          {texts.email}
          <div className={styles.form__info__password}>
            <span className={styles.form__info_bold}>
              {actiualEmail}
            </span>
            <Button
              variant='text'
              color='orange'
              onClick={handleEditMail}
            >
              <Icon src='edit' />
            </Button>
          </div>
        </div>
      </div>

      {/* Кнопка изменение пароля */}
      <div className={styles.form__info__wrap}>
        <Icon
          src='locker'
          size='small-xl'
        />
        <div className={styles.form__info__right}>
          {texts.password}
          <Button
            variant='text'
            color='orange'
            onClick={handelChangePssword}
          >
            {texts.edit_password}
          </Button>
        </div>
      </div>
    </>
  )
}

export default memo(InfoFields)
