export const INFO_ERROR = {
  500: {
    title: {
      id: 'error.page_server_error',
      defaultMessage: 'Ошибка обращения к серверу'
    },
    description: {
      id: 'error.text_500',
      defaultMessage: `Мы уже устраняем неисправность, попрорбуйте обновить страницу через некоторое время. {br} 
      Приносим извинения за временные неудобства!`
    },
    status: 500
  },
  404: {
    title: {
      id: 'error.page_not_found',
      defaultMessage: 'Страница не найдена'
    },
    description: {
      id: 'error.text_404',
      defaultMessage: `Страница, которую вы ищите, {br}
        не существует,  возможно она устарела или была удалена.`
    },
    status: 404
  },
  403: {
    title: {
      id: 'error.page_not_available',
      defaultMessage: 'Страница недоступна'
    },
    description: {
      id: 'error.text_403',
      defaultMessage:
        'У вас нет доступа к этой странице. Свяжитесь с администратором.'
    },
    status: 403
  },
  401: {
    title: {
      id: 'error.page_not_available',
      defaultMessage: 'Страница недоступна'
    },
    description: {
      id: 'error.text_401',
      defaultMessage:
        'У вас нет доступа к этой странице. Свяжитесь с администратором.'
    },
    status: 401
  },
  error: {
    title: {
      id: 'error.error_custom',
      defaultMessage: 'Упс! Что-то пошло не так'
    },
    description: {
      id: 'error.text_500',
      defaultMessage:
      `Мы уже устраняем неисправность, попрорбуйте обновить страницу через некоторое время. {br} 
      Приносим извинения за временные неудобства!`
    },
    status: 'ОШИБКА'
  }
}

export const ERROR_PAGE_TYPE = {
  FORBIDDEN_ERROR: "401",
  FORBIDDEN: '403',
  NOT_FOUND: '404',
  SERVER_ERROR: '500',
  CUSTOM_ERROR: 'error'
}
