import React from 'react'
import { useIntl } from 'react-intl'
import { FormikProps } from 'formik'

import ErrorsBlock from '@/components/ErrorsBlock'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { Input } from '@/views/input'
import Textarea from '@/views/Textarea/Textarea'
import BaseContentViews from '../BaseContentViews'
import Header from './Header'
import ColorPicker from '@/views/ColorPicker/Button'
import { Typography } from '@/views/typography'

import { IGroupUsersCreate } from '@/api/interfaces/GroupUsers'

import styles from './styles/index.module.scss'

interface IProps {
  formikTools: FormikProps<IGroupUsersCreate>
  closeModal: () => void
  groupId?: number
  error: string[] | null | undefined
}

const UserGroupEditView: React.FC<IProps> = ({
  formikTools,
  closeModal,
  error,
  groupId,
}) => {
  const intl = useIntl()
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = formikTools

  const handleChangeColor = (color: string) => {
    setFieldValue('color', color)
  }

  return (
    <BaseContentViews.Wrapper>
      <Header
        isValid={formikTools.isValid}
        groupId={groupId}
      />
      <form onSubmit={handleSubmit}>
        <div className={styles['group-edit__core']}>
          <div>
            <Typography
              className={
                styles['group-edit__core_color_title']
              }
              size={14}
              weight={400}
            >
              {intl.formatMessage({
                id: 'group.colorGroup',
                defaultMessage: 'Цвет группы',
              })}
            </Typography>
            <ColorPicker
              setColor={handleChangeColor}
              color={values.color}
            />
          </div>
          <Input
            label={intl.formatMessage({
              id: 'equipment.title',
              defaultMessage: 'Название',
            })}
            name="title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            error={touched?.title && Boolean(errors?.title)}
            errorMessage={errors?.title}
            required
            clearable
          />
          <Textarea
            label={intl.formatMessage({
              id: 'description',
              defaultMessage: 'Описание',
            })}
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            error={
              touched?.description &&
              Boolean(errors?.description)
                ? errors?.description
                : undefined
            }
          />
        </div>
        <BaseContentViews.ButtonWrapper>
          <Button
            color="gray"
            onClick={closeModal}
            height={48}
          >
            {intl.formatMessage({
              id: 'common.cancel',
              defaultMessage: 'Отмена',
            })}
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting}
            height={48}
            color="black"
          >
            <Icon src="plus" />
            {intl.formatMessage({
              id: 'common.add',
              defaultMessage: 'Добавить',
            })}
          </Button>
        </BaseContentViews.ButtonWrapper>
        <ErrorsBlock errorsArray={error} />
      </form>
    </BaseContentViews.Wrapper>
  )
}

export default UserGroupEditView
