import React from 'react'

import cn from 'classnames'

import style from './style/index.module.scss'

interface IProps {
  children: React.ReactNode
  className?: string
}
/**
 * @view
 * * вьюха квадратика ячейки для истории
 */
const CellHistoryView: React.FC<IProps> = ({
  children,
  className
}) => {
  return (
    <div className={cn(style.cell, className)}>
      {children}
    </div>
  )
}

export default CellHistoryView
