import React, { JSX } from 'react'

import { useStores, useTranslate } from '@/hooks'

import Icon from '@/components/Icon'
import { EquipmentStatusIcon } from '@/components/EquipmentStatusIcon'
import EditDropdown from '@/views/EditDropdown'

import { IEquipment } from '@/api/interfaces'
import { isUndefined } from '@/utils'
import { CUSTOM_DATA_ATTR_STYLES } from '@/apps/Modals/const'
import { MODAL_TYPES, THEME_MODAL } from '@/const'

import styles from '../style/index.module.scss'

/**
 * @component Title
 * * компонент названия оборудования
 * @param
 */
export const Title = (
  el: string,
  equipment: IEquipment,
): JSX.Element => {
  return (
    <div className={styles.body__title}>
      <span className={styles.body__title_sub}>
        {equipment.equipment_type}
      </span>
      {el}
    </div>
  )
}

/**
 * @component StorageTitle
 * * компонент названия шкафа
 * @param
 */
export const StorageTitle = (el?: string): JSX.Element => {
  const { translate } = useTranslate()
  const haveStorage = !isUndefined(el)
  const storageTitle = haveStorage
    ? el
    : {
        id: 'common.noStorage',
        defaultMessage: 'Нет шкафа',
      }
  return (
    <div
      data-storage={haveStorage}
      className={styles.body__storage}
    >
      {translate(storageTitle)}
    </div>
  )
}

/**
 * @component UpdatedAt
 * * компонент даты обновления оборудования
 * @param
 */
export const UpdatedAt = (el: string): JSX.Element => {
  return (
    <div className={styles.body__content}>
      <Icon src="edit" />
      <span className={styles.body__content_updated}>
        {el}
      </span>
    </div>
  )
}

/**
 * @component Status
 * * компонент статуса оборудования
 * @param
 */
export const Status = (el: string): JSX.Element => {
  return (
    <div className={styles.body__content}>
      <EquipmentStatusIcon type={el} />
    </div>
  )
}

/**
 * @component EquipId
 * * компонент дропдауна оборудования
 * @param
 */
export const EquipId = (
  equipment: IEquipment,
  refetch: () => void,
): JSX.Element => {
  const { modalStore } = useStores()
  /**
   * @function handleOpenUserEditModal
   * * метод открытия модалки редактирования пользователя
   */
  const handleOpenEquipmentEditModal = (): void => {
    modalStore.open(MODAL_TYPES.EQUIPMENT_EDIT, {
      id: equipment.id,
      theme: THEME_MODAL.LARGE,
      refetch,
    })
  }
  /**
   * @function handleOpenDeleteUserModal
   * * метод открытия модалки удаления пользователя
   */
  const handleOpenDeleteEquipmentModal = (): void => {
    modalStore.setCustomDataStyles(
      CUSTOM_DATA_ATTR_STYLES.NOTIFY_PADDING,
    )

    modalStore.open(MODAL_TYPES.EQUIPMENT_DELETE, {
      equipment,
      refetch,
    })
  }

  return (
    <EditDropdown
      hadnleEdit={handleOpenEquipmentEditModal}
      handleDelete={handleOpenDeleteEquipmentModal}
    />
  )
}
