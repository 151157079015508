import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'

import { ArrowBackIcon, ErrorExclamation } from '@/assets/icon'

import style from './styles/index.module.scss'

interface IProps {
  open: boolean
  error: string | string[]
  disabled?: boolean
}
/**
 * @component
 * AsyncSelect IndicatorsContainer custom
 */
const IndicatorsContainer: React.FC<IProps> = ({
  open,
  error,
  disabled
}) => {
  return (
    <>
      <GxIcon
        src={ErrorExclamation}
        className={classNames(
          style['async__icon--danger'],
          {
            [style['async__icon--danger_show']]:
              Boolean(error)
          }
        )}
      />
      <GxIcon
        src={ArrowBackIcon}
        className={classNames(style.async__icon, {
          [style.async__icon_open]: open,
          [style.async__icon_disabled]: disabled
        })}
      />
    </>
  )
}

export default IndicatorsContainer
