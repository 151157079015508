import React from "react";

import styles from './styles/index.module.scss'
import { COLORS } from "@/const";

interface IProps {
  title?: string
  color?: string
}
/**
 * * Въюха с названием и цветом группы
 * @view
 */
const GroupTitle: React.FC<IProps> = ({
  title, color
}) => {
  const groupColor = {
    backgroundColor: color ?? COLORS.WHITE
  }

  return (
    <div className={styles['group-title']}>
      <span style={groupColor} className={styles['group-title__color']} />
      <span className={styles['group-title__name']}>{title}</span>
    </div>
  )
}

export default GroupTitle