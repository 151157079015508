import React, { FC, useRef } from 'react'
import { useIntl } from 'react-intl'
import DatePicker from './DatePicker'
import useOnClickOutside from '@/hooks/useOnOutsideClick'
import { DatePickerProvider } from './context'
import { formatDateForFrontend, isNull, isUndefined } from '@/utils'
import { ICustomCalendar, TCustomDate } from './interfaces'
import styles from './style/index.module.scss'
import { DATE_MASKS } from '@/const'

/**
 * @component CustomCalendar
 * * Главный компонент календаря
 * @param {ICustomCalendar}                 общий интерфейс входных параметров CustomCalendar
 *
 * @param onClose               функция на закрытие календаря
 * @param setData               функция меняющая выбранную дату в календаре
 * @param data                  выбранная дата
 * @param classNameCalendar     дополнительные стили
 */
const CustomCalendar: FC<ICustomCalendar> = ({
  onClose,
  setData,
  data,
  classNameCalendar = ''
}) => {
  const intl = useIntl()
  const containerRef = useRef(null)
  useOnClickOutside(containerRef, onClose)

  /**
     * * функция для изменения выбранной даты в календаре
     * @func  changeDate
     * @param  val          новая выбранная дата
     */
  const changeDate = (val: TCustomDate): void => {
    if (!isNull(val) && !isUndefined(val)) {
      setData(formatDateForFrontend(val, DATE_MASKS.CALENDAR_VALUE))
      onClose()
    }
  }

  /**
     * * функция для сброса даты и закрытия календаря
     * @func   resetDate
     * @param
     */
  const resetDate = (): void => {
    setData(undefined)
    onClose()
  }

  return (
    <DatePickerProvider onChange={changeDate} data={data}>
      <div className={styles.calendar} ref={containerRef}>
        <div className={styles.calendar__container}>
          <DatePicker classNameCalendar={classNameCalendar} />
        </div>
        <div className={styles.calendar__footer}>
          <button
            onClick={resetDate}
            className={styles.calendar__footer_reset}
            type='button'
          >
            {intl.formatMessage({ id: 'calendar.reset', defaultMessage: 'Reset filter' })}
          </button>
        </div>
      </div>
    </DatePickerProvider>
  )
}

export default CustomCalendar
