import React from 'react'

import { SuccessStatusIcon } from '@/assets/icon'

import { IntlShape } from 'react-intl'

import { Typography } from '@/views/typography'

import style from './style/index.module.scss'

interface IProp {
  intl: IntlShape
}
/**
 * @view
 * * вью отражающий текущий статус шкафа на стр его деталки
 */
export const Succes = ({
  intl,
}: IProp): React.ReactElement => {
  return (
    <>
      <img
        src={SuccessStatusIcon}
        height="200"
        width="200"
        loading="lazy"
        alt="Succes"
        className={style.status__icon}
      />
      <Typography size={24} weight={700}>
        {intl.formatMessage({
          id: 'connected.success',
          defaultMessage: 'Успешное подключение',
        })}
      </Typography>
    </>
  )
}
