import React from 'react'

import { useTranslate } from '@/hooks'
import { TMessage } from '@/hooks/useTranslate'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'

import style from '../style/index.module.scss'
/**
 * @view
 * * вюха шапки модалки привязки пользователей
 */

interface IProps {
  title: TMessage
  handleClose: () => void
}
const Header: React.FC<IProps> = ({
  title,
  handleClose
}) => {
  const { translate } = useTranslate()
  return (
    <header className={style['user-binding__head']}>
      <Icon className={style['user-binding__icon']} src='linkOn' size='xl' />
      <Typography className={style['user-binding__title']} weight={700} size={32}>
        {translate(title)}
      </Typography>
      <Button className={style['user-binding__btn_close']} onClick={handleClose} color='transparent'>
        <Icon className={style['user-binding__icon_close']} src='close' />
      </Button>
    </header>
  )
}

export default Header
