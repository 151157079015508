import React, { FC, useRef } from 'react'

import * as UserEquipComp from '@/apps/RightSidebar/views/EquipmentTableView/EquipTableComp'
import EquipmentsTableView from '../../views/EquipmentTableView'

import { useLazyFetch, useStores } from '@/hooks'

import {
  DEFAULT_ERROR_ARRAY,
  EMPTY_CONSTS,
  EQUIPMENT_KEY,
  MODAL_TYPES,
  TABLE_WRAPPER_ICON_SRC,
  TABLE_WRAPPER_TYPE,
} from '@/const'

import {
  IEquipment,
  ITiedEquipment,
} from '@/api/interfaces'
import { IDetailsUserTable } from '../../interfaces'

import styles from '@/apps/RightSidebar/views/EquipmentTableView/styles/index.module.scss'

/**
 * @component UserEquipmentTable
 * * компонент таблицы оборудования в деталке пользователя
 *
 * @param id               id пользователя
 */
const UserEquipmentTable: FC<IDetailsUserTable> = ({
  id,
}) => {
  const { api, modalStore } = useStores()
  const containerRef = useRef<HTMLDivElement>(
    EMPTY_CONSTS.NULL,
  )
  const {
    isLoading,
    handleFetch,
    params,
    error = DEFAULT_ERROR_ARRAY,
    lazyLoadedData,
    tableScroll,
    refetch,
    isScrollEnd,
  } = useLazyFetch(
    containerRef,
    async () =>
      await api.user.getEquipmentsTiedToUser(id, params),
  )
  const { search } = params
  /**
   * * рендер дроп дауна с отвязкой оборудования
   * @func renderIdCell
   * @param equipId
   * @param equipmentData
   * @returns
   */
  const renderIdCell = (
    equipId: number,
    equipmentData: IEquipment,
  ) => {
    /**
     * * метод для отвязки выюранного оборудования
     * @func handleUnbindEquipment
     */
    const handleUnbindEquipment = (): Promise<void> =>
      api.equipment.unbindUserEquipment(equipId, {
        user_id: id,
      })

    return (
      <UserEquipComp.UnlinkEquipment
        handleUnbindRequest={handleUnbindEquipment}
        refetch={refetch}
        equipmentData={equipmentData}
      />
    )
  }

  const columns = [
    {
      key: EQUIPMENT_KEY.TITLE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (_: string, equipment: ITiedEquipment) => (
          <UserEquipComp.Title
            title={equipment.title}
            equipment_type={equipment.equipment_type}
            availability_status={
              equipment.availability_status
            }
            showIcon
          />
        ),
      },
    },
    {
      key: EQUIPMENT_KEY.STATUS,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: { render: UserEquipComp.Status },
    },
    {
      key: EQUIPMENT_KEY.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: renderIdCell,
        className: styles.table__body_unlink,
      },
    },
  ]

  /**
   * * функция открытия модалки привязывания
   * оборудования к пользователю
   * @function handleOpenBindUserEquipments
   * @returns
   */
  const handleOpenBindUserEquipments = (): void => {
    modalStore.open(MODAL_TYPES.USER_EQUIPMENTS_BIND, {
      id,
      refetch,
    })
  }

  const searchPanelProps = {
    handleParams: handleFetch,
    handleOpen: handleOpenBindUserEquipments,
    dataLength: lazyLoadedData?.length as number,
    search,
  }

  const tableWrapProps = {
    search,
    error,
    length: lazyLoadedData?.length,
    handleClick: handleOpenBindUserEquipments,
    iconSrc: TABLE_WRAPPER_ICON_SRC.EQUIPMENT,
    type: TABLE_WRAPPER_TYPE.EQUIPMENT_USER_TAB,
  }

  return (
    <EquipmentsTableView
      searchPanelProps={searchPanelProps}
      tableWrapProps={tableWrapProps}
      isScrollEnd={isScrollEnd}
      containerRef={containerRef}
      tableScroll={tableScroll}
      equipments={lazyLoadedData}
      columns={columns}
      isLoading={isLoading}
    />
  )
}

export default UserEquipmentTable
