import * as yup from 'yup'
import { EMPTY_CONSTS, INTL_DEFAULT_MESSAGE } from '@/const'

const DownloadHistoryStorageSchema = yup.object().shape({
  date_start: yup
    .date()
    .nullable()
    .transform((curr, orig) => orig === EMPTY_CONSTS.STR ? null : curr)
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
    .max(new Date(), INTL_DEFAULT_MESSAGE.MAX_DATE),
  date_end: yup
    .date()
    .nullable()
    .transform((curr, orig) => orig === EMPTY_CONSTS.STR ? null : curr)
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
    .max(new Date(), INTL_DEFAULT_MESSAGE.MAX_DATE)
    .min(yup.ref('date_start'), INTL_DEFAULT_MESSAGE.MAX_START_DATE)
})

export default DownloadHistoryStorageSchema
