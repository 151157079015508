import React from 'react'

import cn from 'classnames'
import { GxSpinner } from '@garpix/garpix-web-components-react'

import style from './style/index.module.scss'

interface ISmallSpinner {
  size?: 'large' | 'medium' | 'small'
  className?: string
  theme?: 'center'
  color?: 'orange'
}

const Loader: React.FC<ISmallSpinner> = ({
  size,
  className = '',
  theme = '',
  color = ''
}) => {
  return (
    <GxSpinner
      size={size}
      className={cn(style.loader, {
        [style[`loader--${theme}`]]: Boolean(theme),
        [style[`loader--${color}`]]: Boolean(color),
        [className]: Boolean(className)
      })}
    />
  )
}

export default Loader
