import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Formik, FormikConfig } from 'formik';
import { useFetch, useStores } from '@/hooks';
import { usePaginateSelect } from '@/apps/Modals/hook';
import { isNull, isUndefined } from '@/utils';
import {
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES,
} from '@/const';
import { SHOWING_PANELS } from '../../const';
import { IStorageSettings } from '@/api/interfaces';
import { IStorageShowingPanelProps } from '../../interfaces';
import { Spinner } from '@/views/spinner';
import StorageSettingsView from '@/apps/MainSidebar/views/StorageSettingsView';
import { StorageSettingsScheme } from '@/utils/ValidateSchemes';

type IProps = IStorageShowingPanelProps;
/**
 * Компонент настроек шкафа
 * @component
 * @param handleClose - закрыть настойки
 */
const StorageSettings: React.FC<IProps> = ({
  showingPanel,
  showUnlockBtn,
  setShowingPanel,
}) => {
  const { api, modalStore } = useStores();
  const location = useLocation();
  const { id: storageId } = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );
  /**
   * @function handleClose
   * * закрытие настроек
   */
  const handleClose = (): void => {
    setShowingPanel(SHOWING_PANELS.EDIT);
  };

  const { data, refetch } = useFetch(async () => {
    if (!isNull(storageId)) {
      return await api.storage.getSettings(
        Number(storageId),
      );
    }
  });
  const handleSubmit: FormikConfig<IStorageSettings>['onSubmit'] =
    (values, formikHelpers) => {
      if (!isNull(Number(storageId))) {
        api.storage
          .setSettings({ id: Number(storageId), ...values })
          .catch((err) => {
            const errMessages = err.response.data;
            if (
              !isUndefined(errMessages) &&
              Array.isArray(errMessages)
            ) {
              modalStore.open(MODAL_TYPES.CUSTOM, {
                ...MODAL_CUSTOM_CONTENT.ERROR,
                title: errMessages[0],
              });
            }
          })
          .finally(() => {
            formikHelpers.setSubmitting(EMPTY_CONSTS.FALSE);
          });
      }
    };

  const { loadOptions: loadEquipmentOptions } =
    usePaginateSelect(api.storage.getEquipmentSelection);

  const { loadOptions: loadUserOptions } =
    usePaginateSelect(api.storage.getUserSelection);

  useEffect(() => {
    if (showingPanel === SHOWING_PANELS.SETTINGS) {
      refetch();
    }
  }, [showingPanel]);

  if (isUndefined(data)) {
    return <Spinner />;
  }

  return (
    <Formik
      validationSchema={StorageSettingsScheme}
      initialValues={data}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        return (
          <StorageSettingsView
            formik={formik}
            showingPanel={showingPanel}
            showUnlockBtn={showUnlockBtn}
            handleClose={handleClose}
            loadEquipmentOptions={loadEquipmentOptions}
            loadUserOptions={loadUserOptions}
          />
        );
      }}
    </Formik>
  );
};

export default StorageSettings;
