import { makeAutoObservable, runInAction } from 'mobx'

import {
  IAllCells,
  ICurrentStorage,
} from '@/api/interfaces'
import {
  ISocketTypes,
  ISocketStorageData,
} from '@/interfaces/Socket'

import {
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES,
  SOCKET_SIGNALS,
} from '@/const'
import { RootStore } from '@/services'

import { isNull, sumObjKeys } from '@/utils'

const MODAL_CONTENT_SOCKER = {
  [SOCKET_SIGNALS.STORAGES.SYNC_ERROR]:
    MODAL_CUSTOM_CONTENT.ERROR_SYNC,
  [SOCKET_SIGNALS.STORAGES.SETTINGS_SYNC_SUCCESS]:
    MODAL_CUSTOM_CONTENT.SUCCES_SETTING_SYNC,
}

export type TReqCurrentStorage = Required<ICurrentStorage>
export class CurrentStorageStore {
  allCells: number | null
  storage: TReqCurrentStorage | null
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.storage = EMPTY_CONSTS.NULL
    this.allCells = EMPTY_CONSTS.NULL

    makeAutoObservable(this)
  }

  public updateStorage = async (
    id: number,
  ): Promise<void> => {
    return await this.rootStore.api.storage
      .getStorage(id)
      .then((res: TReqCurrentStorage) => {
        runInAction(() => {
          this.storage = res
          this.setAllCells(res.count_cells)
        })
      })
  }

  public setAllCells = (obj: IAllCells): void => {
    this.allCells = sumObjKeys(obj)
  }

  public clearStorage = (): void => {
    this.storage = EMPTY_CONSTS.NULL
  }

  public setStorageData = (data: object): void => {
    if (!isNull(this.storage)) {
      this.storage = {
        ...this.storage,
        ...data,
      }
    }
  }

  /**
   * * метод который реагирует на ивенты сокетов (Ошибка и успешное сох настроек)
   * * с открытием модалок в зависимости от того на стр шкафа или нет
   * @function checkStorageEvents
   * @param message
   */
  public checkStorageEvents = (message: {
    type:
      | ISocketTypes['ERROR_SYNC']
      | ISocketTypes['SUCCESS_SETTING_SYNC']
    data: ISocketStorageData
  }): void => {
    if (this.checkIsStoragePage(message.data.storage_id)) {
      this.rootStore.modalStore.open(MODAL_TYPES.CUSTOM, {
        ...MODAL_CONTENT_SOCKER[message.type],
        title: message.data.message,
      })
    }
  }

  public checkIsStoragePage = (
    storageId: number,
  ): boolean => {
    return (
      !isNull(this.storage) && storageId === this.storage.id
    )
  }
}
