import React from 'react'

import IntegrationSyncErrorView from '../../views/IntegrationSyncErrorView'

import { useFetch, useStores } from '@/hooks'

import { IModal } from '../interfaces'
import { ISocketSyncErrorIntegrationMessage } from '@/interfaces/Socket'

interface IProps extends IModal {
  modalProps: ISocketSyncErrorIntegrationMessage['data']
}
/**
 * @comp
 * * компонент модалки с ошибкой интеграции по скуду
 */
const IntegrationSyncError: React.FC<IProps> = ({
  closeModal,
  modalProps: { user, message },
}) => {
  const { api } = useStores()
  /**
   * @description
   * * юзер может быть не из лк
   */
  const isUserInStorage = typeof user === 'number'

  const { data } = useFetch(async () => {
    if (isUserInStorage) {
      return await api.user.getUser(user)
    }
  })

  const userData = isUserInStorage ? data : user

  return (
    <IntegrationSyncErrorView
      closeModal={closeModal}
      message={message}
      userData={userData}
    />
  )
}

export default IntegrationSyncError
