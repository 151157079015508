import React from 'react'

import Table from '@/apps/Table/component/Table'

import TabPanelWrap, {
  ITabPanelWrapProps,
} from '../TabPanelWrap'

import { tableStyles } from './styles/tableStyles'
import { TGroupStorage } from '@/api/interfaces/GroupUsers'
import { IColumn } from '@/apps/Table/interfaces'

/**
 * @component UserStorageTable
 * * компонент таблицы шкафов в деталке пользователя
 *
 * @param id              id пользователя
 */

interface IProps extends ITabPanelWrapProps {
  lazyLoadedData?: TGroupStorage[]
  columns: IColumn[]
}
/**
 * @component UserStorageTableView
 * * Вьха таблицы шкафов в деталке
 */
const UserStorageTableView: React.FC<IProps> = ({
  searchPanelProps,
  tableWrapProps,
  containerRef,
  isScrollEnd,
  tableScroll,
  lazyLoadedData,
  columns,
  isLoading,
}) => {
  return (
    <TabPanelWrap
      searchPanelProps={searchPanelProps}
      tableWrapProps={tableWrapProps}
      containerRef={containerRef}
      isScrollEnd={isScrollEnd}
      tableScroll={tableScroll}
      isLoading={isLoading}
      loadedData={lazyLoadedData}
    >
      <Table
        columns={columns}
        rowsData={lazyLoadedData}
        styles={tableStyles}
      />
    </TabPanelWrap>
  )
}

export default UserStorageTableView
