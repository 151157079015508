import React, { FC } from 'react'

import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'

import { ITitle } from '../interfaces'

import style from '../style/index.module.scss'

/**
 * * вью тайтла модалки
 * @view Title
 *
 * @func  closeModal    функция закрытия модалки
 */
const Title: FC<ITitle> = ({ closeModal, title }) => {
  const intl = useIntl()
  return (
    <div className={style.header}>
      <Icon
        className={style.header__icon}
        src='linkOn'
        size='xl'
      />
      <Typography
        size={32}
        weight={700}
        className={style.header_title}
      >
        {intl.formatMessage(title)}
      </Typography>
      <Icon
        src='close'
        className={style.header__icon_close}
        onClick={closeModal}
      />
    </div>
  )
}

export default React.memo(Title)
