import React, { FC, memo, useMemo } from 'react'
import classNames from 'classnames'
import styles from './styles/index.module.scss'

export enum EIconCameraSizes {
  'big',
  'large',
  'medium',
  'little'
}

export type IIconCameraSizes = keyof typeof EIconCameraSizes

interface IIconCameraProps {
  size: IIconCameraSizes
  show?: boolean
}

const IconCamera: FC<IIconCameraProps> = ({ size, show = false }) => {
  const cameraIconClasses = classNames(
    styles.icon,
    styles.icon__camera,
    styles[`icon__size--${size}`]
  )
  return (
    <div className={cameraIconClasses} data-show={show}>
      <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M26.4716 13.3343C27.6259 11.4687 29.6635 10.3333 31.8573 10.3333H48.1427C50.3365 10.3333 52.3741 11.4687 53.5284 13.3343L56.5739 18.2562C56.6346 18.3544 56.7419 18.4142 56.8573 18.4142H66.6667C70.1645 18.4142 73 21.2497 73 24.7475V63.3333C73 66.8311 70.1645 69.6667 66.6667 69.6667H13.3333C9.83553 69.6667 7 66.8311 7 63.3333V24.7475C7 21.2497 9.83553 18.4142 13.3333 18.4142H23.1427C23.2581 18.4142 23.3654 18.3544 23.4261 18.2562L26.4716 13.3343ZM31.8573 16.3333C31.7419 16.3333 31.6346 16.3931 31.5739 16.4913L28.5284 21.4132C27.3741 23.2788 25.3365 24.4142 23.1427 24.4142H13.3333C13.1492 24.4142 13 24.5634 13 24.7475V63.3333C13 63.5174 13.1492 63.6667 13.3333 63.6667H66.6667C66.8508 63.6667 67 63.5174 67 63.3333V24.7475C67 24.5634 66.8508 24.4142 66.6667 24.4142H56.8573C54.6635 24.4142 52.6259 23.2788 51.4716 21.4132L48.4261 16.4913C48.3654 16.3931 48.2581 16.3333 48.1427 16.3333H31.8573Z' fill='#FF8800' />
        <path fillRule='evenodd' clipRule='evenodd' d='M40 50.6667C44.0501 50.6667 47.3334 47.3834 47.3334 43.3333C47.3334 39.2832 44.0501 36 40 36C35.9499 36 32.6667 39.2832 32.6667 43.3333C32.6667 47.3834 35.9499 50.6667 40 50.6667ZM40 56.6667C47.3638 56.6667 53.3334 50.6971 53.3334 43.3333C53.3334 35.9695 47.3638 30 40 30C32.6362 30 26.6667 35.9695 26.6667 43.3333C26.6667 50.6971 32.6362 56.6667 40 56.6667Z' fill='#FF8800' />
      </svg>
    </div>
  )
}

export enum EAvatarSizes {
  'small',
  'little',
  'medium',
  'big',
  'large'
}

export type IAvatarSizes = keyof typeof EAvatarSizes

interface IProps {
  size: IAvatarSizes
  initials: string
  isHover: boolean
  src?: string | null
  className?: string
  changable?: boolean
  onPointerOver: (e: React.PointerEvent<HTMLDivElement>) => void
  onPointerLeave: (event: React.PointerEvent<HTMLDivElement>) => void
}

/**
 * @info обертка центрирует контент
 *       в блок определенной максимальной ширины
 * @param param0
 * @returns
 */
const Avatar: FC<IProps> = ({
  src,
  size = 'small',
  initials,
  isHover = false,
  onPointerLeave,
  onPointerOver,
  className,
  changable = true
}) => {
  const isImage = useMemo(() => Boolean(src), [src])
  const sizeIconCamera = useMemo(() => size in EIconCameraSizes ? size : 'large', [size])

  const content = useMemo(() => {
    return src == null
      ? (
        <span className={styles.avatar__initials}>
          {initials}
        </span>
        )
      : <img
          width={100}
          height={100}
          className={styles.avatar__image}
          src={src}
        />
  }, [src, src, initials])

  const avatarClasses = classNames(
    styles.avatar,
    className,
    styles[`avatar__size--${size}`],
    { [styles.avatar__default]: !isImage }
  )

  return (
    <div
      className={avatarClasses}
      data-hover={isHover}
      onPointerOver={onPointerOver}
      onPointerLeave={onPointerLeave}
    >
      <div className={styles.avatar__default__border} data-show={!isImage} />
      {
        changable && (
          <IconCamera
            size={sizeIconCamera as IIconCameraSizes}
            show={isHover}
          />
        )
      }
      {content}
    </div>
  )
}

export default memo(Avatar)
