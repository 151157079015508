import React from 'react'

import UserEquipmentsBindView from '../../views/UserEquipmentsBindView'

import { IUserEquipmentsBind } from '../interfaces'

/**
 * * компонент привязки оборудования к пользователю
 * @component UserEquipmentsBind
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const UserEquipmentsBind: React.FC<IUserEquipmentsBind> = ({
  modalProps,
  closeModal
}): React.ReactElement => {
  return (
    <UserEquipmentsBindView
      closeModal={closeModal}
      modalProps={modalProps}
    />
  )
}

export default UserEquipmentsBind
