import Logo from './Logo'
import Menu from './Menu'
import User from './User'
import UserMenu from './UserMenu'

export default {
  Logo,
  Menu,
  User,
  UserMenu
}
