import React, { FC } from 'react'
import Profile from '@/views/Profile'
import { IReturnHookEditUsetForm } from '@/hooks/useEditUserForm'
import styles from '../styles/index.module.scss'

interface IProps extends Omit<IReturnHookEditUsetForm, 'isLoading' | 'handleSubmit' | 'actualInitials'> {
  handelChangePssword: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleEditMail: () => void
  actiualEmail: string
}

/**
 * @info Форма Профиля
 * @param param0
 * @returns
 */
const ProfileDataForm: FC<IProps> = (props) => {
  return (
    <div className={styles.form}>
      <Profile.Fields {...props} />
      <div className={styles.form__border} />
      <Profile.InfoFields {...props} />
    </div>
  )
}

export default ProfileDataForm
