import React from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import classNames from 'classnames'

import { Button } from '@/views/button'
import ErrorMessage from '@/views/ErrorMessage'

import { isUndefined } from '@/utils'

import styles from './styles/index.module.scss'
/**
 * @view
 * * вью футера интеграции с ошибкой подкл - (сокеты)
 * * и сохранением
 * @returns
 */
const Footer = (): React.ReactElement => {
  const intl = useIntl()
  const { errors, isSubmitting, dirty } = useFormikContext<{
    integrationError: string
  }>()
  return (
    <footer
      className={classNames(
        styles.footer,
        styles.animation,
      )}
      data-show={dirty}
    >
      <Button
        type="submit"
        disabled={isSubmitting}
        color="black"
        className={styles.footer__save}
      >
        {intl.formatMessage({
          id: 'common.save',
          defaultMessage: 'Сохранить',
        })}
      </Button>
      {!isUndefined(errors.integrationError) && (
        <ErrorMessage
          className={styles.footer__error}
          text={errors.integrationError}
        />
      )}
    </footer>
  )
}

export default Footer
