import React, { useMemo } from 'react'

import { useIntl } from 'react-intl'
import { useCurrentUser, usePage, useSiteConfig, useStores } from '@/hooks'
import { MODAL_TYPES, DEFAULT_LINKS, PAGE_MODELS } from '@/const'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'

import style from './style/index.module.scss'
import { useNavigate } from 'react-router-dom'

/**
 * Всплывающее меню пользователя левого сайдбара
 * @component
 *
 * @param handleCloseMenu - метод закрытия меню
 *
 */
const UserMenu: React.FC<{
  handleCloseMenu: () => void
}> = ({ handleCloseMenu }) => {
  const intl = useIntl()
  const config = useSiteConfig()
  const navigate = useNavigate()

  const { modalStore } = useStores()
  const currentUser = useCurrentUser()
  const page = usePage()

  const title = String(
    `${currentUser.last_name} ${currentUser.first_name} ${currentUser.patronymic}`
  ).trim()

  const handleOpenLogoutModal = (): void => {
    handleCloseMenu()
    modalStore.open(MODAL_TYPES.LOG_OUT)
  }

  const prifileLink = useMemo(() => config?.links?.profile ?? DEFAULT_LINKS.profile, [config])

  const handleClickEditProfile = (): void => {
    if (page?.page?.page_model === PAGE_MODELS.UserProfilePage) {
      handleCloseMenu()
    } else {
      navigate(prifileLink)
    }
  }

  return (
    <div className={style.menu}>
      <header className={style.menu__header}>
        <div className={style.menu__header_flex}>
          <div className={style.menu__header_title}>
            {title}
          </div>
          <div className={style.menu__header_subtitle}>
            {currentUser.email}
          </div>
        </div>
        <Button
          color='transparent'
          className={style.menu__header_btn}
          onClick={handleOpenLogoutModal}
        >
          <Icon
            src='exit'
            className={style.menu__header_icon}
          />
        </Button>
      </header>
      <main className={style.menu__main}>
        <Button
          color='black'
          className={style.menu__main_btn}
          onClick={handleClickEditProfile}
        >
          <Icon src='edit' slot='icon-left' />
          <span className={style.menu__main_btn__edit__text}>
            {intl.formatMessage({
              id: 'editProfile',
              defaultMessage: 'Редактироать профиль'
            })}
          </span>
        </Button>
      </main>
      {/* Закоментировано пока не появится страница помощи */}
      {/* <footer className={style.menu__footer}> */}
      {/*   <a href='#' className={style.menu__footer_link}> */}
      {/*     <Icon src='help' /> */}
      {/*     {intl.formatMessage({ */}
      {/*       id: 'help', */}
      {/*       defaultMessage: 'Помощь' */}
      {/*     })} */}
      {/*   </a> */}
      {/* </footer> */}
    </div>
  )
}

export default UserMenu
