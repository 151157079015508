import { makeAutoObservable } from 'mobx'

import { RootStore } from './Root'

export type TLSidebarShowBlock = 'storage' | 'sidebar'
export class LeftSidebarStore {
  currentShowingBlock: TLSidebarShowBlock
  isStartClosingAnimation: boolean
  isOpen: boolean
  sidebarProps: unknown

  rootStore: RootStore

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.isOpen = true
    this.isStartClosingAnimation = false
    this.sidebarProps = {}
    this.currentShowingBlock = 'storage'

    makeAutoObservable(this)
  }

  setStartAnimationClosing = (): void => {
    this.isStartClosingAnimation = true
  }

  open = (sidebarProps?: unknown): void => {
    this.sidebarProps = sidebarProps
    this.isOpen = true
  }

  close = (): void => {
    this.sidebarProps = {}
    this.isOpen = false
    this.isStartClosingAnimation = false
  }

  changeShowBlock = (block: TLSidebarShowBlock): void => {
    this.currentShowingBlock = block
  }
}
