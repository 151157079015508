import React, { ReactNode } from 'react'

import { useStores, useTranslate } from '@/hooks'
import { TMessage } from '@/hooks/useTranslate'
import { randomId } from '@/utils'

interface IProps {
  children: ReactNode
  contetnt: TMessage
}
/**
 * * обертка для вашей ноды, которой нужен tooltip
 * @view TooltipView
 * @param contetnt - текст тултипа
 */
const TooltipView: React.FC<IProps> = ({
  children,
  contetnt,
}) => {
  const { tooltip } = useStores()
  const { translate } = useTranslate()

  const uniqId = `id-${randomId()}`

  /**
   * метод для обработки наведения на элемент
   * и вывода тултипа с подсказкой
   */
  const handleMouseOver = (
    e: React.SyntheticEvent<HTMLDivElement>
  ): void => {
    const target = e.currentTarget
    tooltip.setDomId(`#${target.id}`)
    tooltip.setContent(translate(contetnt))
    tooltip.show()
  }

  /**
   * метод для отслеживания когда пользователь покидает мышкой кнопку
   * используется для размонтирования тултипа
   */
  const handleMouseOut = (): void => {
    tooltip.end()
  }

  return (
    <div
      id={uniqId}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {children}
    </div>
  )
}

export default TooltipView
