import AbsractApi from '@/api/abstract'
import { IPageApi } from '@/api/interfaces'
import {
  IColorSheme,
  IResultGetPage,
  Page,
  PageModelType,
} from '@/api/interfaces/Page'
import { QueryParams } from '@garpix/base-api'
import { AxiosRequestConfig } from 'axios'
import {
  defaultSerializer,
  errorSerializer,
  notFoundSerializer,
  permissionDeniedSerializer,
  unauthorizedSerializer,
} from './serializers'

/**
 * Класс ответвенный за получение данных по странице - конекстам
 */
class PageApi implements IPageApi {
  ctx: AbsractApi
  PAGES: {
    [key in PageModelType]: (data: any) => any
  }

  constructor(ctx: AbsractApi) {
    this.ctx = ctx
    this.PAGES = {
      Page401: defaultSerializer,
      Page403: permissionDeniedSerializer,
      Page404: notFoundSerializer,
      PageNotFound: notFoundSerializer,
      PageErrorServer: errorSerializer,
      Page: defaultSerializer,
      UserAuthorizationPage: defaultSerializer,
      UserPasswordRecoveryPage: defaultSerializer,
      UserProfilePage: defaultSerializer,
      EquipmentListPage: defaultSerializer,
      UserListPage: defaultSerializer,
      StorageListPage: defaultSerializer,
      StoragePage: defaultSerializer,
      ProblemPage: defaultSerializer,
      AccessControlPage: defaultSerializer,
      GroupUserPage: defaultSerializer,
    }
  }

  getColorSheme = async (): Promise<IColorSheme> => {
    const res = await this.ctx.get<IColorSheme>(
      '/colors/',
      {},
    )
    return res.data
  }

  getPage = async (
    pathname: string,
    queryParams: QueryParams<unknown> | undefined,
    axiosParams: AxiosRequestConfig,
  ): Promise<IResultGetPage> => {
    try {
      const path =
        pathname.charAt(0) === '/'
          ? pathname
          : `/${pathname}`
      const res: { data: Page } = await this.ctx.get(
        `/page${path}`,
        queryParams,
        axiosParams,
      )
      const page = res.data
      console.log('page', page.page_model)
      const serializer = this.PAGES[page.page_model]
      return {
        pageType: page.page_model,
        page: serializer(page),
        statusCode: 200,
      }
    } catch (error: any) {
      if (
        error?.response?.status === 403 ||
        error?.response?.status === 401
      ) {
        return {
          pageType: 'Page401',
          statusCode: 401,
          page: unauthorizedSerializer(),
        }
      }
      if (error?.response?.status === 404) {
        const serializer = this.PAGES.Page404
        return {
          pageType: 'Page404',
          statusCode: 404,
          page: serializer(error.response.data),
        }
      }
      return {
        pageType: 'PageErrorServer',
        page: errorSerializer(),
        statusCode: 500,
      }
    }
  }
}

export default PageApi
