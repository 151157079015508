import * as yup from 'yup'
import { INTL_DEFAULT_MESSAGE } from '@/const'
import { REGEX } from '../validation'

const ProfileEditSheme = yup.object().shape({
  user_role: yup
    .object()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  last_name: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  first_name: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  patronymic: yup
    .string()
    .trim()
    .optional(),
  email: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
    .matches(REGEX.email, {
      message: INTL_DEFAULT_MESSAGE.UNCORRECT_EMAIL
    }),
  card_hid_number: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
})

export default ProfileEditSheme
