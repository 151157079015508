import {
  TLoadOptions
} from '@/apps/Modals/ModalContent/interfaces'
import { EMPTY_CONSTS } from '@/const'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { optionsSerializer } from '@/apps/Modals/helpers'

type TUsePaginateSelect = (
  api: (
    params: any
  ) => Promise<{
    results: Array<{ id: number | string, title: string }>
    next?: string | null
  }>
) => {
  error: string
  loadOptions: TLoadOptions
}
const usePaginateSelect: TUsePaginateSelect = (ctxApi) => {
  const intl = useIntl()
  const [error, setError] = useState<string>(
    EMPTY_CONSTS.STR
  )

  /**
   * Метод для получения и формирования списка опций
   * @func loadOptions
   *
   * @param q - search
   * @param prevOptions - предыдущие опции
   * @param additional - дополнительные свойста. в нашем случае для получения страницы
   */
  const loadOptions: TLoadOptions = async (
    q,
    prevOptions,
    additional
  ) => {
    return await ctxApi({
      page: additional?.page,
      search: q
    })
      .then(async ({ results, next }) => {
        setError(EMPTY_CONSTS.STR)
        return await optionsSerializer({
          next,
          additional,
          options: results
        })
      })
      .catch((err) => {
        setError(
          intl.formatMessage({ id: 'error.error_custom' })
        )
        return err
      })
  }

  return { loadOptions, error }
}

export default usePaginateSelect
