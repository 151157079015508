import React from 'react'
import { useIntl } from 'react-intl'

import style from './style/index.module.scss'
/**
 * @view
 * * вью с для отсутствия истории
 */
const EmptyHistory = (): React.ReactElement => {
  const intl = useIntl()
  return (
    <div className={style['empty-history']}>
      <h3 className={style['empty-history__title']}>{intl.formatMessage({
        id: 'noHistory',
        defaultMessage: 'Нет истории'
      })}
      </h3>
    </div>
  )
}

export default EmptyHistory
