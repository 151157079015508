import React, { FC } from 'react'

import Cell from '../../view/Cell'
import Row from '../../view/Row'
import WrapRow from '../../view/WrapRow'
import TableView from '../../view/TableCoreView'

import { ITable } from '../../interfaces'
import { EMPTY_CONSTS } from '@/const'

/**
 * * Компонент для реализации логики отрисовки всей таблицы
 * @component
 */
const Table: FC<ITable> = ({
  columns,
  rowsData,
  onRow,
  styles
}) => {
  return (
    <TableView className={styles?.classNameTable}>
      <WrapRow theme='head' view='thead'>
        <Row
          theme='head'
          className={styles?.classNameHeaderRow}
        >
          {columns.map(
            ({ key, contentHeader, headerCellProps }) => (
              <Cell
                view='th'
                key={key}
                theme='head'
                content={contentHeader}
                {...headerCellProps}
              />
            )
          )}
          <Cell 
            view='th' 
            theme='sticky'
            content={EMPTY_CONSTS.NULL} 
          />
        </Row>
      </WrapRow>
      <WrapRow view='tbody' theme='body'>
        {rowsData?.map((row) => {
          return (
            <Row
              theme='body'
              className={styles?.classNameBodyRow}
              key={row.id}
              rowData={row}
              onRow={onRow}
            >
              {columns.map((coll) => {
                return (
                  <Cell
                    view='td'
                    theme='body'
                    content={row[coll.key]}
                    currentRowData={row}
                    key={coll.key}
                    {...coll.bodyCellProps}
                  />
                )
              })}
            </Row>
          )
        })}
      </WrapRow>
    </TableView>
  )
}

export default Table
