import React from 'react'

import { IModal } from '../interfaces'

import FilterView from '../../views/FilterView'
/**
 * @component
 * * компонент модалки фильтра юзеров привязанных к шкафу
 */
const StorageUserFilter: React.FC<IModal> = (props) => {
  return (
    <FilterView filterType='storageUser' {...props} />
  )
}

export default StorageUserFilter
