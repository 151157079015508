import React, {
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import {
  useFetch,
  useLeftSidebar,
  useStores,
} from '@/hooks';

import StorageDetailsView from '../../views/StorageDetailsView';
import { Spinner } from '@/views/spinner';

import { isNull } from '@/utils';
import { IStorageShowingPanelProps } from '../../interfaces';
import { MODAL_TYPES, SPINNER_THEME } from '@/const';
import { CUSTOM_DATA_ATTR_STYLES } from '@/apps/Modals/const';
import { SHOWING_PANELS } from '../../const';

type IProps = IStorageShowingPanelProps;
/**
 * @component
 * * компонент, реализующий логику деталки шкафа,
 */
const StorageDetails: React.FC<IProps> = ({
  setShowingPanel,
  showingPanel,
  showUnlockBtn,
}) => {
  const location = useLocation();
  const { handleOpenLeftSidebar } = useLeftSidebar();
  const {
    modalStore,
    StorageEventsObserver,
    currentStorageStore: { updateStorage },
  } = useStores();

  const { id } = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  const { isLoading, refetch } = useFetch(async () => {
    if (id !== null) {
      return await updateStorage(Number(id));
    }
  });
  /**
   * @function handleShowSettings
   * * открытие настроек
   */
  const handleShowSettings = (): void => {
    setShowingPanel(SHOWING_PANELS.SETTINGS);
  };
  /**
   * @func handleSyncStorage
   * * метод для принудительной синхронизации шкафа
   */
  const handleSyncStorage = (): void => {
    modalStore.open(MODAL_TYPES.STORAGE_SYNC, {
      refetch,
      id,
    });
  };
  /**
   * @func handleDownloadHistory
   * * метод для скачивания истории о шкафе
   */
  const handleDownloadHistory = (): void => {
    modalStore.open(MODAL_TYPES.DOWNLODA_HISTORY);
  };
  /**
   * @func handleOpenStorageEditModal
   * * метод для открытия модалки редактирования шкафа
   */
  const handleOpenStorageEditModal = (): void => {
    modalStore.setCustomDataStyles(CUSTOM_DATA_ATTR_STYLES.SCROLL)
    modalStore.open(MODAL_TYPES.STORAGE_EDIT, { id });
  };

  /**
   * @func handleShutDownStorage
   * * метод для принудительного выключения шкафа
   */
  const handleShutDownStorage = (): void => {
    modalStore.open(MODAL_TYPES.STORAGE_TURN_OFF);
  };
  /**
   * @func updateStorageInSocketEvents
   * * метод для обновления данных о шкафе при
   * * ивентах по сокетам
   */
  const updateStorageInSocketEvents = useCallback(() => {
    if (!isNull(id)) {
      void updateStorage(Number(id));
    }
  }, []);

  const handlersFooter = {
    handleDownloadHistory,
    handleSyncStorage,
    handleOpenStorageEditModal,
  };

  useEffect(() => {
    handleOpenLeftSidebar();
  }, []);

  useEffect(() => {
    StorageEventsObserver.subscribe(
      updateStorageInSocketEvents,
    );
    return () => {
      StorageEventsObserver.unsubscribe(
        updateStorageInSocketEvents,
      );
    };
  }, []);

  if (isLoading) {
    return <Spinner theme={SPINNER_THEME.MAIN_SIDEBAR} />;
  }

  return (
    <StorageDetailsView
      showUnlockBtn={showUnlockBtn}
      showingPanel={showingPanel}
      handlersFooter={handlersFooter}
      handleShutDownStorage={handleShutDownStorage}
      handleShowSettings={handleShowSettings}
    />
  );
};

export default observer(StorageDetails);
