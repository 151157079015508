import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

import {
  StepsRestorePassword,
  useRestorePassword
} from '@garpix/cms'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'

import useCustomAuth from '@/apps/Login/hooks'
import { useSiteConfig, useStores } from '@/hooks'

import {
  IError,
  INewPasswords,
  IUseCustomRestorePassword
} from '../interfaces'

import {
  NEW_PASSWORD_SCHEMA,
  USERNAME_SCHEMA,
  CODE_SCHEMA
} from '@/utils/validation'
import { isString, isUndefined } from '@/utils'

import { DEFAULT_LINKS, EMPTY_CONSTS } from '@/const'

/**
 * @hook   useCustomRestorePassword
 * * Хук для восстановления пароля пользователя (оборачивает хук useRestorePassword из @garpix/cms)
 *
 * @returns {
 *  onSubmit                     функция для отправки запроса на сервер (вид запроса определяется из номер шага step)
 *  handleUsernameValue          функция для изменения логина (почты) пользователя вводимого в инпут
 *  handlePasswordValue          функция для изменения пароля вводимого в инпут
 *  handleCodeValue              функция для изменения кода вводимого в инпут
 *  resendCode                   функция повторной отправки проверочного кода на почту
 *  backToLogin                  функция возврата на страницу Login
 *  handleBack                   функция возврата на предыдущее окно (SendCode)
 *  username                     логин (почта) пользователя
 *  error                        обьект ошибок
 *  step                         текущий шаг (этап воостановления пароля)
 * }
 */
// !TODO[LOCKER-778] - релиз 2.0. Рефактор
const useCustomRestorePassword =
  (): IUseCustomRestorePassword => {
    const intl = useIntl()
    const navigate = useNavigate()
    const store = useStores()
    const config = useSiteConfig()
    const linkToRoot =
      config?.links?.root ?? DEFAULT_LINKS.root
    const [error, setError] = useState<IError | null>(EMPTY_CONSTS.NULL)
    const [passwords, setPasswords] =
      useState<INewPasswords>({
        password: EMPTY_CONSTS.STR,
        new_password: EMPTY_CONSTS.STR
      })
    let redirectTimeout: NodeJS.Timeout

    const {
      errors,
      step,
      username,
      code,
      onSubmit: handleSubmit,
      setCodeValue,
      handleBack,
      setPasswordValue,
      setUsernameValue
    } = useRestorePassword()

    const { handleAuthSubmit } = useCustomAuth({
      successLink: linkToRoot
    })

    /**
     * * функция для возврата на страницу Login
     * @func   backToLogin
     * @param
     */
    const backToLogin = (): void => {
      navigate(DEFAULT_LINKS.userAuth)
    }

    /**
     * * функция для изменения логина (почты) пользователя вводимого в инпут (внутри вызывается setError для очистки тела ошибок)
     * @func   handleUsernameValue
     *
     * @param e    event, в котором содержится значение инпута
     */
    const handleUsernameValue = (
      e: GxInputCustomEvent<any>
    ): void => {
      setError(null)
      setUsernameValue(e.target.value)
    }

    /**
     * *  функция для изменения кода вводимого в инпут (внутри вызывается setError для очистки тела ошибок)
     * @func   handleCodeValue
     *
     * @param e    event, в котором содержится значение инпута
     */
    const handleCodeValue = (
      e: GxInputCustomEvent<any>
    ): void => {
      setError(null)
      setCodeValue(e.target.value)
    }

    /**
     * *  функция для изменения пароля вводимого в инпут
     * (внутри вызывается setError для очистки тела ошибок, и setPasswords для записи 2 паролей с инпутов)
     * @func   handlePasswordValue
     *
     * @param e    event, в котором содержится значение инпута
     */
    const handlePasswordValue = (
      e: GxInputCustomEvent<any>,
      type: string
    ): void => {
      setError(null)
      setPasswords({ ...passwords, [type]: e.target.value })
      setPasswordValue(e.target.value)
    }

    /**
     * * функция отправки данных (включает валидацию полей)
     * @func   onSubmit
     * @param event  событие отправки формы
     */
    const onSubmit = async (
      event: React.SyntheticEvent<Element, Event>
    ): Promise<void> => {
      try {
        if (step === StepsRestorePassword.sendCode) {
          await USERNAME_SCHEMA.validate({ username })
        }
        if (step === StepsRestorePassword.checkCode) {
          await CODE_SCHEMA.validate({ code })
        }
        if (step === StepsRestorePassword.setPassword) {
          await NEW_PASSWORD_SCHEMA.validate(passwords)
        }
        void handleSubmit(event)
      } catch (e) {
        const err = e?.message
        const errMessage =
          err?.username ??
          err?.restore_password_confirm_code ??
          err?.new_password ??
          err?.password ??
          EMPTY_CONSTS.STR

        if (isString(errMessage)) {
          setError({
            non_field_error: [
              intl.formatMessage({
                id: errMessage,
                defaultMessage: 'Ошибка'
              })
            ],
            username: [EMPTY_CONSTS.STR],
            new_password: [EMPTY_CONSTS.STR],
            restore_password_confirm_code: [EMPTY_CONSTS.STR]
          })
        }
      }
    }

    /**
     * *  функция повторной отправки проверочного кода на почту.
     * Ошибки, полученные при запросе, записываются в обьект ошибок error.
     * @func   resendCode
     *
     * @param
     */
    const resendCode = async (): Promise<void> => {
      setError(EMPTY_CONSTS.NULL)
      void store.api.user
        .sendCodeRestorePassword({ username })
        .catch((e) => {
          const data = e?.response?.data
          if (!isUndefined(data)) {
            setError({ ...data })
          }
        })
    }

    /**
     * *  @info   useEffect
     * ошибки получаемые при запросах хуком useRestorePassword (errors),
     * записываются в обьект ошибок error. На последнем шаге (форма успешной смены пароля)
     * через 3 секунды происходит редирект на страницу Шкафы.
     * При демонтированни компонента очищается Timeout.
     *
     * @param
     */
    useEffect(() => {
      setError(errors)
      if (step === StepsRestorePassword.finish) {
        const { password } = passwords
        redirectTimeout = setTimeout(() => {
          handleAuthSubmit({ password, username })
        }, 3000)
      }

      return () => {
        clearTimeout(redirectTimeout)
      }
    }, [errors, step])

    return {
      onSubmit,
      handleUsernameValue,
      handlePasswordValue,
      handleCodeValue,
      resendCode,
      backToLogin,
      handleBack,
      username,
      error,
      step
    }
  }

export default useCustomRestorePassword
