import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import { useFetch, useStores, useTranslate } from '@/hooks'

import { TAccesControlIntegrationValues } from '@/api/interfaces/Integration'

import {
  getObjectErrorsFormik,
  isNull,
  isUndefined,
} from '@/utils'
import { IntegrationScheme } from '@/utils/ValidateSchemes'

import {
  DEFAULT_INTEGRATION_FIELDS,
  DEFAULT_INTEGRATION_VALUES,
  SCUD_STATUSES,
} from '../const'

import ErrorsBlock from '@/components/ErrorsBlock'
import IntegrationView from '../views/IntegrationView'
import { Spinner } from '@/views/spinner'

const getInitialValues = (
  response?: TAccesControlIntegrationValues,
): TAccesControlIntegrationValues => {
  if (isUndefined(response)) {
    return DEFAULT_INTEGRATION_VALUES
  }
  return response
}
/**
 * @component
 * * Компонент с логикой Скуда (изменение, получение)
 */
const Integration = (): React.ReactElement => {
  const { api } = useStores()
  const { data, isLoading, error } = useFetch(
    api.integration.getAccessControllValues,
  )
  const { translate } = useTranslate()

  const handleSubmit = async (
    value: TAccesControlIntegrationValues,
    action: FormikHelpers<TAccesControlIntegrationValues>,
  ): Promise<void> => {
    void api.integration
      .changeAccessControllValues(value)
      .then(async () => {
        if (!value.is_active) {
          /**
           * ? показываем предупреждение что интеграция выкл
           *  и выкл статус подкл
           */
          await action.setFieldValue(
            DEFAULT_INTEGRATION_FIELDS.status,
            SCUD_STATUSES.DISABLED,
          )
          await action.setFieldTouched(
            DEFAULT_INTEGRATION_FIELDS.integrationError,
            true,
          )
          action.setFieldError(
            DEFAULT_INTEGRATION_FIELDS.integrationError,
            translate({
              id: 'integrationDisabled',
              defaultMessage: 'Интеграция выключена',
            }),
          )
        } else {
          void action.setFieldValue(
            DEFAULT_INTEGRATION_FIELDS.status,
            SCUD_STATUSES.LOADING,
          )
        }
      })
      .catch((err) => {
        if (!isUndefined(err?.response?.data)) {
          const formattedError = getObjectErrorsFormik(err)
          action.setErrors(formattedError)
        }
      })
  }

  if (
    !isUndefined(error) &&
    !isNull(error) &&
    error.length !== 0
  ) {
    return <ErrorsBlock networkError />
  }

  if (isLoading || isUndefined(data)) {
    return <Spinner isFullPage />
  }

  return (
    <Formik
      initialValues={getInitialValues(data)}
      validationSchema={IntegrationScheme}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <IntegrationView />
    </Formik>
  )
}

export default Integration
