import React from 'react'

import { FormikProps } from 'formik'

import { TStorageGetValue } from '@/api/interfaces'
import { GxInputCustomEvent } from '@garpix/garpix-web-components'

import ErrorsBlock from '@/components/ErrorsBlock'
import EditStatus from './EditStatus'
import ActionButtons from './ActionButtons'
import CoreFields from './CoreFields'
import Form from '@/views/Form'

import style from './style/index.module.scss'

interface IProps {
  formikTools: FormikProps<TStorageGetValue>
  projectId?: number
  hasChangeImportantFields: boolean
  error: string[] | null | undefined

  handleChangedImportantFields: (
    e: GxInputCustomEvent<HTMLGxInputElement>
  ) => void
  closeModal: () => void
}

const StorageEditView: React.FC<IProps> = ({
  formikTools,
  handleChangedImportantFields,
  projectId,
  hasChangeImportantFields,
  closeModal,
  error
}) => {
  const disabled =
    formikTools.isSubmitting

  return (
    <Form
      className={style['storage-edit']}
      onSubmit={formikTools.handleSubmit}
    >
      <div className={style['storage-edit__mb']}>
        <EditStatus
          error={!formikTools.isValid}
          projectId={projectId}
          closeModal={closeModal}
          hasChangeImportantFields={
            hasChangeImportantFields
          }
        />
      </div>
      <CoreFields
        hasChangeImportantFields={hasChangeImportantFields}
        handleChangedImportantFields={
          handleChangedImportantFields
        }
        projectId={projectId}
        formikTools={formikTools}
      />
      <ErrorsBlock errorsArray={error} />
      <ActionButtons
        projectId={projectId}
        handleCancel={closeModal}
        hasChangeImportantFields={hasChangeImportantFields}
        disabled={disabled}
      />
    </Form>
  )
}

export default StorageEditView
