import React, {
  ChangeEvent,
  useRef,
  useState,
  MouseEvent
} from 'react'

import CustomCalendar from '@/apps/CustomCalendar'

import { formatDateForFrontend, isUndefined } from '@/utils'

import {
  DATA_INPUT_THEME,
  DATE_MASKS,
  EMPTY_CONSTS,
  INPUT_ICON_WIDTH
} from '@/const'

import { IDateInput } from './interfaces'

import styles from './styles/index.module.scss'
import classNames from 'classnames'
import { useTranslate } from '@/hooks'

/**
 * @component DateInput
 * компонент для выбора даты в инпуте
 * @param date              - выбранная дата
 * @param handleDateChange  - обработчик на изменение даты
 * @param className         - стили
 * @constructor
 */
const DateInput: React.FC<IDateInput> = ({
  date,
  handleDateChange,
  onBlur,
  className = EMPTY_CONSTS.STR,
  name,
  theme = DATA_INPUT_THEME.DEFAULT,
  error = false,
  errorMessage = EMPTY_CONSTS.STR
}) => {
  const [isCalendar, setIsCalendar] = useState(
    EMPTY_CONSTS.FALSE
  )
  const inputRef = useRef<HTMLInputElement>(
    EMPTY_CONSTS.NULL
  )
  const dateValue = !isUndefined(date)
    ? formatDateForFrontend(date, DATE_MASKS.CALENDAR_VALUE)
    : EMPTY_CONSTS.STR

  const { translate } = useTranslate()
  /**
   * @function handleChange
   * записывает даты введенную в инпут
   * @param e - event инпута
   * @returns
   */
  const handleChange = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.value !== EMPTY_CONSTS.STR) {
      handleDateChange(
        formatDateForFrontend(
          e.target.value,
          DATE_MASKS.CALENDAR_VALUE
        )
      )
    }
  }

  /**
   * @function handleInputClick
   * отслеживает область клика для вызова календаря
   * @param e - event инпута
   * @returns
   */
  const handleInputClick = (
    e: MouseEvent<HTMLInputElement>
  ): void => {
    e.preventDefault()
    const offsetX = e.nativeEvent.offsetX
    const inputProperties =
      inputRef?.current?.getBoundingClientRect()
    const width =
      inputProperties?.width ?? EMPTY_CONSTS.ZERO
    if (width - offsetX < INPUT_ICON_WIDTH) {
      setIsCalendar(EMPTY_CONSTS.TRUE)
    }
  }

  /**
   * @function handleCloseCalendar
   * функция закрытия календаря
   */
  const handleCloseCalendar = (): void => {
    setIsCalendar(EMPTY_CONSTS.FALSE)
  }

  return (
    <div
      className={classNames(
        styles.datepicker,
        styles[theme]
      )}
    >
      <div
        data-error={error}
        className={styles.datepicker__wrap}
      >
        <input
          name={name}
          ref={inputRef}
          onBlur={onBlur}
          type='date'
          value={dateValue}
          onChange={handleChange}
          onClick={handleInputClick}
          className={className}
        />
        {error && (
          <p className={styles.datepicker__err_text}>
            {translate(errorMessage)}
          </p>
        )}
      </div>
      {isCalendar && (
        <CustomCalendar
          onClose={handleCloseCalendar}
          setData={handleDateChange}
          data={dateValue}
        />
      )}
    </div>
  )
}

export default DateInput
