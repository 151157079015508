import React, { FC } from 'react'

import { useIntl } from 'react-intl'
import { useStores } from '@/hooks'

import { tableStyles } from '../../../EquipmentList/const'
import {
  DEFAULT_ERROR_ARRAY,
  EMPTY_CONSTS,
  EQUIPMENT_KEY,
  TABLE_PAGE,
  TABLE_WRAPPER_ICON_SRC,
} from '@/const'
import { RIGHT_SIDEBAR_TYPES } from '@/apps/RightSidebar/const'
import {
  IEquipment,
  IEquipmentQueryParams,
} from '@/api/interfaces'
import { IEquipmentTable } from '../../../EquipmentList/interfaces'
import { IRowData, TOnRow } from '@/apps/Table/interfaces'

import Table from '@/apps/Table/component/Table'
import { RenderSortButton } from '@/components/SortButton/RenderSortButton'
import Pagination from '@/components/Pagination'
import * as EquipComp from '../../../EquipmentList/components/EquipTableComponent'
import TableWrapper from '@/views/TableWrapper'
import EditWithTime from '@/apps/StorageList/views/EditWithTime'

import styles from '../../../EquipmentList/style/index.module.scss'
import { UnlinkEquipment } from '@/apps/RightSidebar/views/EquipmentTableView/EquipTableComp'

/**
 * @component EquipmentTable
 * * компонент таблицы оборудования шкафа
 *
 * @param equipments               массив оборудований
 * @param params                   текущие query параметры
 * @param handleParams             установить дополнительные параметры к текущим query параметрам
 * @param isLoading                статус загрузки данных с сервера
 * @param currentPage               номер текущей страницы
 * @function handlePage            функция меняющая номер страницы
 * @param total                    общее количество оборудований
 * @param error                    ошибки, полученные при запросе оборудования
 * @function refetch               функция для обновления страницы
 */
const EquipmentTable: FC<IEquipmentTable> = ({
  params = EMPTY_CONSTS.OBJ as IEquipmentQueryParams,
  handleParams,
  equipments,
  isLoading,
  currentPage,
  handlePage,
  total,
  error = DEFAULT_ERROR_ARRAY,
  refetch,
  storageId = EMPTY_CONSTS.ZERO,
}) => {
  const intl = useIntl()
  const { rightSideBarStore, api } = useStores()
  const { ordering, search } = params

  /**
   * * функиця для открытия деталки оборудования
   * @func handleOpenUserSideBar
   * @param equipment - данные о кликнутом оборудовании
   */
  const handleOpenUserSideBar = (
    equipment: IRowData,
  ): void => {
    rightSideBarStore.open(
      RIGHT_SIDEBAR_TYPES.EQUIPMENT_SIDEBAR,
      { equipmentId: equipment.id },
    )
  }

  const onRow: TOnRow = (rowData) => ({
    onClick: () => handleOpenUserSideBar(rowData),
  })

  const renderIdCell = (
    _: number,
    equipment: IEquipment,
  ) => {
    const handleUnbindEquipmentOnStorage = async () =>
      await api.storage.unbindStorageEquipment(storageId, {
        equipment_id: equipment.id,
      })

    return (
      <UnlinkEquipment
        handleUnbindRequest={handleUnbindEquipmentOnStorage}
        equipmentData={equipment}
        refetch={refetch}
      />
    )
  }

  const columns = [
    {
      key: EQUIPMENT_KEY.TITLE,
      contentHeader: intl.formatMessage({
        id: 'equipment.title',
        defaultMessage: 'Название',
      }),
      headerCellProps: {
        className: styles.header__name,
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            EQUIPMENT_KEY.TITLE,
          ),
      },
      bodyCellProps: {
        className: styles.body__cell_name,
        render: EquipComp.Title,
      },
    },
    {
      key: EQUIPMENT_KEY.SERIAL_NUMBER,
      contentHeader: intl.formatMessage({
        id: 'equipment.serial_number',
        defaultMessage: 'Серийный номер',
      }),
      headerCellProps: {
        className: styles.header__serial,
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            EQUIPMENT_KEY.SERIAL_NUMBER,
          ),
      },
      bodyCellProps: {
        className: styles.body__cell_serial,
      },
    },
    {
      key: EQUIPMENT_KEY.INVENTORY_NUMBER,
      contentHeader: intl.formatMessage({
        id: 'equipment.inventory_number',
        defaultMessage: 'Инвентарный номер',
      }),
      headerCellProps: {
        className: styles.header__inventory,
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            EQUIPMENT_KEY.INVENTORY_NUMBER,
          ),
      },
      bodyCellProps: {
        className: styles.body__cell_inventory,
      },
    },
    {
      key: EQUIPMENT_KEY.UPDATED_AT,
      contentHeader: intl.formatMessage({
        id: 'equipment.updated_at',
        defaultMessage: 'Изменено',
      }),
      headerCellProps: {
        className: styles.header__updated,
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            EQUIPMENT_KEY.UPDATED_AT,
          ),
      },
      bodyCellProps: {
        render: (date: string) => (
          <EditWithTime showIcon={false} date={date} />
        ),
        className: styles.body__cell_updated,
      },
    },
    {
      key: EQUIPMENT_KEY.STATUS,
      contentHeader: intl.formatMessage({
        id: 'equipment.status',
        defaultMessage: 'Статус',
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            EQUIPMENT_KEY.STATUS,
          ),
      },
      bodyCellProps: { render: EquipComp.Status },
    },
    {
      key: EQUIPMENT_KEY.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: renderIdCell,
      },
    },
  ]

  return (
    <TableWrapper
      iconSrc={TABLE_WRAPPER_ICON_SRC.EQUIPMENT}
      search={search}
      isLoading={isLoading}
      type={TABLE_PAGE.EQUIPMENT}
      error={error}
      length={equipments?.length}
    >
      <div className={styles['storage-equipment']}>
        <div
          className={styles['storage-equipment__content']}
        >
          <Table
            onRow={onRow}
            columns={columns}
            rowsData={equipments}
            styles={tableStyles}
          />
        </div>
        <Pagination
          pageSize={params.page_size}
          currentPage={currentPage}
          handleChange={handlePage}
          total={total}
          className={styles['storage-equipment__footer']}
        />
      </div>
    </TableWrapper>
  )
}

export default EquipmentTable
