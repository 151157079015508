import React from 'react'

import SidebarFooter from '../../SidebarFooter'
import PanelTop, { TPanelTopProps } from '../PanelTop'
import TabsPanel from '../TabsPanel'

import { ISidebarFooter } from '@/apps/RightSidebar/interfaces'

interface IProps {
  footerProps: ISidebarFooter
  groupId: number
  panelProps: TPanelTopProps
}
/**
 * @view
 * * Рутовая вьюха деталки группы пользователей
 */
const Core: React.FC<IProps> = ({
  footerProps,
  panelProps,
  groupId,
}) => {
  return (
    <>
      <PanelTop {...panelProps} />
      <TabsPanel groupId={groupId} />
      <SidebarFooter {...footerProps} />
    </>
  )
}

export default Core
