import React from 'react'

import { IProblem } from '@/api/interfaces'
import { EMPTY_CONSTS } from '@/const'
import Table from '@/apps/Table/component/Table'

import EditDropdown from '@/views/EditDropdown'

import { Typography } from '@/views/typography'
import TypeCell from '@/apps/ProblemList/views/TypeCell'
import EquipmentTypesCell from '@/apps/ProblemList/views/EquipmentTypesCell'

import { PROBLEM_COL } from '@/apps/ProblemList/const'

import styles from './styles/index.module.scss'

const tableStyles = {
  classNameTable: styles.table,
  classNameBodyRow: styles.table__body_row
}

export interface IProblemProps {
  data: IProblem[] | undefined
  children: React.ReactNode
  handleDelete: (id: number) => void
  handleEditProblem: (problem: IProblem) => void
}

/**
 * Вьха таблицы страницы списка проблем
 * @view
 * @param data - список проблем
 * @param children
 */

const ProblemTableView: React.FC<IProblemProps> = ({
  data,
  children,
  handleDelete,
  handleEditProblem
}) => {
  const columns = [
    {
      key: PROBLEM_COL.TITLE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (title: string) => {
          return (
            <Typography
              size={18}
              weight={700}
              className={styles.problem__title}
            >
              {title}
            </Typography>
          )
        }
      }
    },
    {
      key: PROBLEM_COL.EQUIPMENT_TYPES,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: EquipmentTypesCell
      }
    },
    {
      key: PROBLEM_COL.TYPE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: TypeCell
      }
    },
    {
      key: PROBLEM_COL.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (id: number, problem: IProblem) => {
          return (
            <EditDropdown
              hadnleEdit={() => handleEditProblem(problem)}
              handleDelete={() => handleDelete(id)}
            />
          )
        }
      }
    }
  ]

  return (
    <div className={styles.problem__table}>
      <div className={styles.problem__table_height}>
        <Table
          columns={columns}
          rowsData={data}
          styles={tableStyles}
        />
      </div>
      {children}
    </div>
  )
}

export default ProblemTableView
