import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from '@/hooks'

import { STORAGE_STATUSES } from '../StorageList/const'

import StorageTabs from './views/StorageTabs'
import ConnectionStatus from './views/ConnectionStatus'
import StorageAnimationWrap from '@/views/StorageAnimationWrap'

const StorageDetails = (): React.ReactElement => {
  const {
    currentStorageStore: { storage }
  } = useStores()
  const isSuccesStorageStatus =
    storage?.storage_status !== undefined &&
    storage?.storage_status === STORAGE_STATUSES.SUCCES

  return (
    <StorageAnimationWrap type='storage'>
      {isSuccesStorageStatus
        ? (
          <StorageTabs />
          )
        : (
          <ConnectionStatus
            status={storage?.storage_status}
          />
          )}
    </StorageAnimationWrap>
  )
}

export default observer(StorageDetails)
