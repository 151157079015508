import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useStores } from '@/hooks'

import {
  IColumn,
  IRowData,
  TOnRow,
} from '@/apps/Table/interfaces'
import { IStorageTable } from '../../interfaces'
import {
  DEFAULT_ERROR_ARRAY,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES,
  TABLE_PAGE,
  TABLE_WRAPPER_ICON_SRC,
} from '@/const'
import { tableStyles } from '../../const'

import Table from '@/apps/Table/component/Table'
import { RenderSortButton } from '@/components/SortButton/RenderSortButton'
import Pagination from '@/components/Pagination'
import * as StorageComp from '../StorageTableComponents'
import TableWrapper from '@/views/TableWrapper'

import styles from '../../styles/index.module.scss'
import TableWrap from '@/apps/Table/view/TableWrap'

/**
 * @component StorageTable
 * * компонент таблицы Шкафов
 *
 * @param storages                 массив шкафов
 * @param params                   текущие query параметры
 * @param handleParams             установить дополнительные параметры к текущим query параметрам
 * @param isLoading                статус загрузки данных с сервера
 * @param currentPage               номер текущей страницы
 * @function handlePage            функция меняющая номер страницы
 * @param total                    общее количество оборудований
 * @param error                    ошибки, полученные при запросе оборудования
 */
const StorageTable: FC<IStorageTable> = ({
  params,
  handleParams,
  storages,
  isLoading,
  currentPage,
  handlePage,
  total,
  error = DEFAULT_ERROR_ARRAY,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { modalStore } = useStores()
  const { ordering, search } = params

  /**
   * * метод для открытя деталки страницы шкафа
   * @func handleOpenModalEditStorage
   * @param rowData - данные о текущей строке таблицы
   */
  const handleOpenStorageSidebar = (
    rowData: IRowData,
  ): void => {
    if (rowData?.slug !== undefined) {
      navigate(`${String(rowData?.slug)}/?id=${rowData.id}`)
    } else {
      modalStore.open(
        MODAL_TYPES.CUSTOM,
        MODAL_CUSTOM_CONTENT.ERROR,
      )
    }
  }

  const onRow: TOnRow = (rowData) => ({
    onClick: () => handleOpenStorageSidebar(rowData),
  })

  const columns: IColumn[] = [
    {
      key: 'count_cells',
      contentHeader: intl.formatMessage({
        id: 'app.cells',
        defaultMessage: 'Ячейки',
      }),
      headerCellProps: {
        render: (title) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            'count_cells',
          ),
        className: styles.table__head_cell_count,
      },
      bodyCellProps: {
        render: StorageComp.CountCells,
        className: styles.table__body_cell_count,
      },
    },
    {
      key: 'title',
      contentHeader: intl.formatMessage({
        id: 'project.form.name',
        defaultMessage: 'Название',
      }),
      headerCellProps: {
        render: (title) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            'title',
          ),
        className: styles.table__head_cell_title,
      },
      bodyCellProps: {
        render: StorageComp.Title,
        className: styles.table__body_cell_title,
      },
    },
    {
      key: 'description',
      contentHeader: intl.formatMessage({
        id: 'project.form.description',
        defaultMessage: 'Описание',
      }),
      headerCellProps: {
        render: (title) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            'description',
          ),
      },
      bodyCellProps: {
        render: StorageComp.Description,
        className: styles.table__body_cell_description,
      },
    },
    {
      key: 'updated_at',
      contentHeader: intl.formatMessage({
        id: 'storage.updated_at',
        defaultMessage: 'Изменено',
      }),
      headerCellProps: {
        render: (title) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            'updated_at',
          ),
      },
      bodyCellProps: {
        render: StorageComp.UpdatedAt,
        className: styles.table__body_cell_date,
      },
    },
  ]

  return (
    <TableWrapper
      iconSrc={TABLE_WRAPPER_ICON_SRC.STORAGE}
      search={search}
      isLoading={isLoading}
      type={TABLE_PAGE.STORAGE}
      error={error}
      length={storages?.length}
    >
      <TableWrap>
        <div className={styles.container__table__content}>
          <Table
            onRow={onRow}
            columns={columns}
            rowsData={storages}
            styles={tableStyles}
          />
        </div>
        <Pagination
          className={styles.container__pagination}
          currentPage={currentPage}
          handleChange={handlePage}
          total={total}
          pageSize={params.page_size}
        />
      </TableWrap>
    </TableWrapper>
  )
}

export default StorageTable
