import React, { FC, useState } from 'react'

import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'

import { useStores } from '@/hooks'

import { isUndefined } from '@/utils'

import {
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES
} from '@/const'

import { IStorageSync } from '../interfaces'

import style from '../styles/index.module.scss'
import classNames from 'classnames'

/**
 * * компонент модального окна для принудительной синхронизации шкафа
 * @component StorageSync
 *
 * @func  closeModal - функция закрытия модалки
 * @param modalProps - пропсы для модалки
 * @returns
 */
const StorageSync: FC<IStorageSync> = ({
  closeModal,
  modalProps
}) => {
  const intl = useIntl()
  const { id, refetch } = modalProps
  const { api, modalStore } = useStores()
  const [isLoading, setIsLoading] = useState<boolean>(
    EMPTY_CONSTS.FALSE
  )

  /**
   * @func onSubmit
   * * функция реализующая логику принудительной синхронизации шкафа
   */
  const onSubmit = (): void => {
    setIsLoading(EMPTY_CONSTS.TRUE)
    void api.storage
      .forcedSyncStorage(id)
      .then(() => {
        refetch()
        closeModal()
      })
      .catch((err) => {
        const error = err?.response?.data?.detail
        if (!isUndefined(error)) {
          modalStore.open(MODAL_TYPES.WARNING_ERROR, {
            error: [error]
          })
        } else {
          modalStore.open(
            MODAL_TYPES.CUSTOM,
            MODAL_CUSTOM_CONTENT.ERROR
          )
        }
      })
      .finally(() => {
        setIsLoading(EMPTY_CONSTS.FALSE)
      })
  }

  return (
    <div className={style.container}>
      <div className={style.container__header}>
        <Icon
          src='synchronization'
          size='xl'
          className={style.container__header_icon_exit}
        />
        <h2 className={classNames(style.title, style.title__storage_sync)}>
          {intl.formatMessage({
            id: 'modal.title.storage_sync',
            defaultMessage:
              'Вы уверены, что хотите начать принудительную синхронизацию?'
          })}
        </h2>
      </div>
      <div className={style.container__buttons}>
        <Button
          className={style.container__buttons_cancel}
          onClick={closeModal}
          disabled={isLoading}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Отмена'
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color='black'
          onClick={onSubmit}
          disabled={isLoading}
        >
          <Icon src='synchronization' />
          {intl.formatMessage({
            id: 'common.sync',
            defaultMessage: 'Синхронизировать'
          })}
        </Button>
      </div>
    </div>
  )
}

export default StorageSync
