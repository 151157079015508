import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'

export class RightSidebarStore {
  content: string | null
  rootStore: RootStore
  contentProps: any
  isOpen: boolean

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.isOpen = false
    this.content = null // это вариант контента
    this.contentProps = null
    makeAutoObservable(this)
  }

  open = (name: string, contentProps?: any): void => {
    this.content = name
    this.contentProps = contentProps
    this.isOpen = true
  }

  close = (): void => {
    this.content = null
    this.isOpen = false
    this.contentProps = null
  }
}
