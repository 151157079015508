import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'

import { isNull } from '@/utils'
import { useStores } from '@/hooks'
import {
  DEFAULT_INTEGRATION_FIELDS,
  SCUD_STATUSES,
} from '../../const'

import Header from '../Header'
import Footer from '../Footer'
import CoreForm from '../CoreForm'

import styles from './style/index.module.scss'
/**
 * @view
 * * главная вьюха с интеграцией скуда (все поля)
 */
const IntegrationView = (): React.ReactElement => {
  const {
    integrationEventsSocket: { integration, clear },
  } = useStores()
  const {
    setFieldValue,
    setFieldError,
    setFieldTouched,
    handleSubmit,
  } = useFormikContext()
  /**
   * @useEffect
   * * устанавливаем слушатель на ивенты интеграции что бы изменять
   * * статус подкл и ошибку подкл (если есть)
   *
   * ! DONT TOUCH AWAIT touch (formik bug)
   * ! без них не установится ошибка с сокета
   */
  useEffect(
    () =>
      autorun(async () => {
        if (!isNull(integration)) {
          await setFieldValue(
            DEFAULT_INTEGRATION_FIELDS.status,
            integration.status,
          )

          if (integration.status === SCUD_STATUSES.ERROR) {
            await setFieldTouched(
              DEFAULT_INTEGRATION_FIELDS.integrationError,
              true,
            )
            setFieldError(
              DEFAULT_INTEGRATION_FIELDS.integrationError,
              integration.message,
            )
          }

          clear()
        }
      }),
    [integration],
  )

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.integration}
    >
      <Header />
      <CoreForm />
      <Footer />
    </form>
  )
}

export default observer(IntegrationView)
