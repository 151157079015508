import React from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import StorageList from '@/apps/StorageList'

const StorageListPage: React.FC = () => {
  return (
    <MainLayout>
      <StorageList />
    </MainLayout>
  )
}

export default StorageListPage
