import React, { FC } from 'react'
import { useTranslate } from '@/hooks'

import { getInitials } from '@/utils'
import { IUserSideBarView } from './interfaces'

import UserTitle from './UserTitle'
import UserAddInfo from './UserAddInfo'
import UserTabs from './UserTabs'
import SidebarFooter from '@/apps/RightSidebar/views/SidebarFooter'

/**
 * * компонент правого сайдбара пользователя
 * с детальной информацией о нем
 * @component UserSidebarView
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const UserSidebarView: FC<IUserSideBarView> = ({
  handleEdit,
  user,
  id
}) => {
  const { translate } = useTranslate()
  const {
    email,
    card_hid_number: cardHidNumber,
    updated_at: updatedAt,
    full_name: fullName,
    first_name: firstName,
    last_name: lastName
  } = user

  const badgeTitle = getInitials(firstName, lastName)

  const userRole = translate({
    id: `user.user_role.${user.user_role}`,
    defaultMessage: 'role'
  })
  const accountType = translate({
    id: `user.account_type.${user.account_type}`,
    defaultMessage: 'account type'
  })

  return (
    <>
      <UserTitle
        fullName={fullName}
        badgeTitle={badgeTitle}
        subTitle={`${accountType}/${userRole}`}
      />
      <UserAddInfo
        cardHidNumber={cardHidNumber}
        email={email}
      />
      <UserTabs id={id} />
      <SidebarFooter
        handleEdit={handleEdit}
        badgeTitle={badgeTitle}
        userName={`${user.last_name} ${user.first_name}`}
        updatedAt={updatedAt}
      />
    </>
  )
}

export default UserSidebarView
