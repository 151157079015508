import React, { FC } from 'react'

import { ITableWrapper } from './interfaces'

import { checkHasError, isUndefined } from '@/utils'
import { DEFAULT_ERROR_ARRAY } from '@/const'

import EmptyTablePanel from '@/components/EmptyTablePanel'
import ErrorsBlock from '@/components/ErrorsBlock'
import { Spinner } from '../spinner'

import styles from './style/index.module.scss'

/**
 * @component TableWrapper
 * * компонент обертки таблицы (показывает пустой фон, лоадер, ошибки)
 *
 * @param search              параметр поиска
 * @param isLoading           статус загрузки данных с сервера
 * @param type                тип таблицы
 * @param children            дочерние компоненты
 * @param error               ошибки
 * @param length              длина массива элементов (контент оборачиваемой таблицы)
 * @function handleClick      функция срабатывающая при клике на кнопку в пустой панеле (если такая есть)
 * @param iconSrc             тип иконки
 */
const TableWrapper: FC<ITableWrapper> = ({
  search,
  isLoading = false,
  type,
  children,
  error = DEFAULT_ERROR_ARRAY,
  length,
  handleClick,
  iconSrc
}) => {
  if (!isLoading && !isUndefined(length) && length < 1) {
    return (
      <EmptyTablePanel
        iconSrc={iconSrc}
        type={type}
        search={search}
        handleClick={handleClick}
      />
    )
  } else if (checkHasError(error)) {
    return (
      <div className={styles.wrapper}>
        <ErrorsBlock errorsArray={error} />
      </div>
    )
  } else if (isLoading || isUndefined(length)) {
    return (
      <div className={styles.wrapper}>
        <Spinner isFullPage />
      </div>
    )
  }

  return <> {children} </>
}

export default TableWrapper
