import React, {
  FC,
  useState,
  HTMLAttributes
} from 'react'

import cn from 'classnames'

import { IRowData, TEvents, TOnRow } from '../../interfaces'
import { DEFAULT_ROW_METHODS } from '../../const'

import style from '../styles/index.module.scss'

interface IProps
  extends HTMLAttributes<HTMLTableRowElement> {
  theme: 'head' | 'body' | 'sticky'
  rowData?: IRowData
  onRow?: TOnRow
}

/**
 * * Вью для отрисовки строки
 * @view
 */
const Row: FC<IProps> = ({
  children,
  className,
  theme,
  rowData,
  onRow,
  ...props
}) => {
  const setInitialMethod = (): TEvents => {
    if (onRow !== undefined && rowData !== undefined) {
      return onRow(rowData)
    }
    return DEFAULT_ROW_METHODS
  }
  const [methods] = useState(() => setInitialMethod())

  return (
    <tr
      {...props}
      {...methods}
      role='row'
      className={cn(
        style.table__row,
        style[`table__row_${theme}`],
        className
      )}
    >
      {children}
    </tr>
  )
}

export default Row
