import React, { HTMLAttributes } from 'react'
import classNames from 'classnames'

import styles from './styles/index.module.scss'
import { TStoragePanels } from '../../interfaces'

interface IProps extends HTMLAttributes<HTMLDivElement> {
  showingPanel?: TStoragePanels
  children: React.ReactNode
  theme?: TStoragePanels
  className?: string
}

const StorageLayoutView: React.FC<IProps> = ({
  children,
  className,
  showingPanel,
  theme,
  ...props
}) => {
  return (
    <div
      {...props}
      data-panel={showingPanel}
      className={classNames(
        styles.storage__root,
        className,
        {
          [styles[`storage__root_${theme ?? 'edit'}`]]:
            Boolean(theme)
        }
      )}
    >
      {children}
    </div>
  )
}

export default StorageLayoutView
