import React, { useState } from 'react'
import { observer } from 'mobx-react'

import StorageEdit from './components/StorageEdit'
import StorageSettings from './components/StorageSettings'
import UnlockUsers from '@/components/UnlockUsers'
import StorageAnimationWrap from '@/views/StorageAnimationWrap'
import HeaderStatus from './views/StorageDetailsView/HeaderStatus'
import StorageSidebarWrap from './views/StorageSidebarWrap'

import { useStores } from '@/hooks'

import { TStoragePanels } from './interfaces'

import { isNull, isUndefined } from '@/utils'
import { SHOWING_PANELS } from './const'

/**
 * @component
 * * основной компонент левого сайдбара
 */

const MainSidebar = (): React.ReactElement => {
  const {
    currentStorageStore: { storage }
  } = useStores()

  const [showUnlockBtn, setShowUnlockBtn] =
    useState(false)
  const [showingPanel, setShowingPanel] =
    useState<TStoragePanels>(SHOWING_PANELS.EDIT)

  const storagePanelsProps = {
    showUnlockBtn,
    showingPanel,
    setShowingPanel
  }

  return (
    <StorageAnimationWrap type='sidebar'>
      {!isNull(storage) &&
        !isUndefined(storage?.storage_status) && (
          <HeaderStatus status={storage.storage_status} />
      )}
      <StorageSidebarWrap>
        <UnlockUsers
          isShow={showUnlockBtn}
          setShowUnlockBtn={setShowUnlockBtn}
        />
        <StorageEdit
          {...storagePanelsProps}
        />
        <StorageSettings
          {...storagePanelsProps}
        />
      </StorageSidebarWrap>
    </StorageAnimationWrap>
  )
}

export default observer(MainSidebar)
