import React from 'react'

import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import SeoWrapper from '@/components/SeoWrapper'
import { StorageLogo } from '@/assets/icon'

import styles from '../style/index.module.scss'

interface LayoutProps {
  children: React.ReactNode
}

/**
 * @view AuthLayout
 * *вью layout страницы авторизации и восстановления пароля
 *
 * @param {React.ReactNode} children  дочерние компоненты, который оборачивает данный layout
 */
const AuthLayout = ({
  children
}: LayoutProps): React.ReactElement => {
  return (
    <SeoWrapper>
      <div className={styles.auth}>
        <CSSTransitionGroup animation='fadeInTop'>
          <img
            src={StorageLogo}
            className={styles.auth__logo}
            alt='логотип'
          />
        </CSSTransitionGroup>
        {children}
      </div>
    </SeoWrapper>
  )
}

export default AuthLayout
