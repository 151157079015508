import React, { RefObject } from 'react'

import Table from '@/apps/Table/component/Table'

import UserRole from '@/views/UserRole'
import UserBadge from '@/views/UserBadge'
import UnlinkDropdown from '@/views/UnlinkDropdown'
import TabPanelWrap from '../../TabPanelWrap'
import EquipmentStatus from '@/views/EquipmentStatus'

import { getInitials } from '@/utils'

import {
  EMPTY_CONSTS,
  TABLE_PAGE,
  TABLE_WRAPPER_TYPE,
} from '@/const'
import { EQUIPMENT_COLUMNS } from '@/apps/RightSidebar/const'

import {
  IEquipment,
  ITiedEquipmentUser,
  ITiedEquipmentUsersListParams,
  TEquipmentStatus,
  TUser,
  TUserRole,
} from '@/api/interfaces'

import styles from './styles/index.module.scss'

const tableStyles = {
  classNameTable: styles.table,
  classNameBodyRow: styles.table__body_row,
}

interface IUserPanelView {
  handleFetch: (
    params: ITiedEquipmentUsersListParams,
  ) => void
  listRef: RefObject<HTMLDivElement>
  handleScroll: () => void
  isLoading: boolean
  data?: ITiedEquipmentUser[]
  error?: string[] | null
  params: ITiedEquipmentUsersListParams
  handleUnbind: (user: TUser) => void
  handleEdit: () => void
  equipmentData: IEquipment
  isScrollEnd: boolean
}

/**
 * Вюха панели пользователей деталки оборудования
 * @param listRef - реф на  обертку списка пользователей
 * @param isLoading
 * @param data - список пользователей
 * @param error
 * @param params - текущие парамметры запроса
 * @param handleFetch - фетч для поиска
 * @param handleScroll - обработчик скрола на фетч списка
 * @param handleUnbind - отвязка пользователя от оборудования по id
 * @param equipmentData - текущее оборудование
 * @param isScrollEnd   - флаг доскроллено ли до самого низа контейнера
 * @constructor
 */
const UsersPanelView: React.FC<IUserPanelView> = ({
  listRef,
  isLoading,
  data,
  error,
  params,
  handleFetch,
  handleScroll,
  handleUnbind,
  handleEdit,
  isScrollEnd,
}) => {
  const searchPanelProps = {
    handleParams: handleFetch,
    handleOpen: handleEdit,
    dataLength: data?.length as number,
    search: params.name,
  }
  const tableWrapProps = {
    search: params?.name,
    error,
    iconSrc: TABLE_PAGE.USER,
    type: TABLE_WRAPPER_TYPE.EQUIPMENT_USER_TAB,
    length: data?.length,
    handleClick: handleEdit,
  }

  const columns = [
    {
      key: EQUIPMENT_COLUMNS.FULL_NAME,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (fullName: string, props: TUser) => {
          const initials = getInitials(
            props.first_name,
            props.last_name,
          )
          return (
            <UserBadge
              size={18}
              title={initials}
              weight={700}
              className={styles['users-panel__badge']}
            >
              {fullName}
            </UserBadge>
          )
        },
      },
    },
    {
      key: EQUIPMENT_COLUMNS.USER_ROLE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        className: styles['users-panel__user-role'],
        render: (role: TUserRole) => (
          <UserRole role={role} />
        ),
      },
    },
    {
      key: EQUIPMENT_COLUMNS.EQUIPMENT_STATUS,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (status: TEquipmentStatus) => (
          <EquipmentStatus status={status} />
        ),
      },
    },
    {
      key: EQUIPMENT_COLUMNS.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (_: number, props: TUser) => {
          const handleClick = (): void =>
            handleUnbind(props)
          return <UnlinkDropdown onClick={handleClick} />
        },
      },
    },
  ]
  return (
    <div
      className={styles['users-panel__root']}
      data-scrollend={isScrollEnd}
    >
      <TabPanelWrap
        searchPanelProps={searchPanelProps}
        tableWrapProps={tableWrapProps}
        containerRef={listRef}
        isScrollEnd={isScrollEnd}
        tableScroll={handleScroll}
        isLoading={isLoading}
        loadedData={data}
      >
        <Table
          rowsData={data}
          columns={columns}
          styles={tableStyles}
        />
      </TabPanelWrap>
    </div>
  )
}

export default UsersPanelView
