import React, { RefObject } from 'react'
import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { IPaginatedParams, TUser } from '@/api/interfaces'
import { BUTTON_COLORS, SORT_BUTTON_FIELDS } from '@/const'

import SortButton from '@/components/SortButton'
import Icon from '@/components/Icon'
import SearchInput from '@/views/SearchInput'
import Loader from '@/views/Loader'
import EmptySearch from '@/views/EmptySearch'
import { Button } from '@/views/button'
import UserList from '@/apps/Modals/views/EquipmentEditView/UserList'

import styles from './styles/index.module.scss'

interface IUserView {
  params?: IPaginatedParams
  handleFetch: (params: IPaginatedParams) => void
  listRef: RefObject<HTMLUListElement>
  usersPull: Map<number, number>
  handleScroll: () => void
  isLoading: boolean
  data: TUser[]
  handleCheck: (
    e:
    | React.ChangeEvent<HTMLGxCheckboxElement>
    | GxCheckboxCustomEvent<HTMLGxCheckboxElement>
  ) => void
  handleClear: () => void
  isScrollEnd: boolean
}

/**
 * Вюха Списка пользователей с поиском и фильтром
 * @param params - параметры запроса
 * @param handleFetch - метод для фетча списка по параметрам
 * @param listRef - ссылка на елемент списка
 * @param usersPull - список выбранных пользователей
 * @param handleScroll - обработчик на скролл
 * @param isLoading - состояние загрузки
 * @param data - список пользователей
 * @param handleCheck - метод на обработку чекбокса
 * @param handleClear - метод для сброса выбраных пользоватлей
 * @param isScrollEnd - флаг доскроллено ли до самого низа контейнера
 */

const UsersView: React.FC<IUserView> = ({
  params,
  handleFetch,
  listRef,
  usersPull,
  handleScroll,
  isLoading,
  data,
  handleCheck,
  handleClear,
  isScrollEnd
}) => {
  const intl = useIntl()
  const count = [...usersPull].length

  const renderContent = (): React.ReactElement => {
    return isLoading && data.length === 0
      ? (
        <Loader
          className={styles.users__container_loader}
          size='large'
        />
        )
      : data.length === 0
        ? (
          <EmptySearch
            className={styles.users__container_empty}
          />
          )
        : (
          <UserList
            listRef={listRef}
            usersPull={usersPull}
            handleScroll={handleScroll}
            data={data}
            handleCheck={handleCheck}
          />
          )
  }

  return (
    <div className={styles.users}>
      <div className={styles.users__header}>
        <div className={styles.users__header_container}>
          <SortButton
            ordering={params?.ordering}
            handleParams={handleFetch}
            field={SORT_BUTTON_FIELDS.FULL_NAME}
            title={intl.formatMessage({
              id: 'user.fullname',
              defaultMessage: 'ФИО'
            })}
          />
          <div className={styles.users__counter_wrap}>
            <div
              className={classNames(styles.users__counter, {
                [styles['users__counter--active']]:
                  Boolean(count)
              })}
            >
              <span>{count}</span>
              <Button
                color={BUTTON_COLORS.TRANSPARENT}
                className={styles.users__counter_btn}
                onClick={handleClear}
              >
                <Icon src='close' size='xs' />
              </Button>
            </div>
          </div>
        </div>
        <SearchInput
          handleParams={handleFetch}
          className={styles.users__search}
        />
      </div>
      <div
        className={styles.users__container}
        data-scrollend={isScrollEnd}
      >
        {renderContent()}
      </div>
    </div>
  )
}

export default React.memo(UsersView)
