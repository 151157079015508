import React from 'react'

import WithMediaPageSize from '@/components/WithMediaPageSize'
import TagsHistoryViews from '../../views/TagsHistoryViews'
import ContentContainer from '../../views/ContentContainer'

import { useFetch, useStores } from '@/hooks'

import { ITabCompProps } from '../../interfaces'

import { QUERIES, PAGE_SIZES } from '../../const'

type TProps = ITabCompProps & {
  pageSize?: number
}
/**
 * @comp * Компонент списка истории меток
 */
const TagHistory: React.FC<TProps> = ({
  storageId,
  pageSize,
}) => {
  const { api } = useStores()

  const {
    data,
    handlePage,
    params,
    isLoading,
    error,
    handleParams,
  } = useFetch(
    async (param) =>
      await api.storage.getHistoryTags(storageId, param),
    { page_size: pageSize },
  )

  return (
    <ContentContainer>
      <TagsHistoryViews
        tagsList={data?.results}
        params={params}
        isLoading={isLoading}
        error={error}
        handlePage={handlePage}
        handleParams={handleParams}
        total={data?.count}
      />
    </ContentContainer>
  )
}

const EnhancedTagHistory: React.FC<ITabCompProps> = (
  props,
) => {
  return (
    <WithMediaPageSize queries={QUERIES} sizes={PAGE_SIZES}>
      {(pageSize) => (
        <TagHistory {...props} pageSize={pageSize} />
      )}
    </WithMediaPageSize>
  )
}

export default EnhancedTagHistory
