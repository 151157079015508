import React, { FC } from 'react'

import { useFormikContext } from 'formik'

import EditStatus from './EditStatus'
import ActionButtons from './ActionButtons'
import CoreFields from './CoreFields'

import { IProblemEdit } from '@/api/interfaces'
import { IProblemEditView } from './interfaces'

import style from './style/index.module.scss'

const ProblemEditView: FC<IProblemEditView> = ({
  id,
  closeModal,
  handleSubmit
}) => {
  const { isSubmitting, isValid } = useFormikContext<IProblemEdit>()
  const disabled = isSubmitting || !isValid

  return (
    <form className={style.form} onSubmit={handleSubmit}>
      <div className={style.problem}>
        <EditStatus id={id} closeModal={closeModal} />
        <CoreFields />
        <ActionButtons
          id={id}
          handleCancel={closeModal}
          disabled={disabled}
        />
      </div>
    </form>
  )
}

export default ProblemEditView
