export const CELL_COUNTS = [
  {
    id: 0,
    field: 'empty_cells_count',
    type: 'empty',
    filterKey: 'FREE',
    titleTooltip: {
      id: 'empty_cell',
      defaultMessage: 'Пустая ячейка',
    },
  },
  {
    id: 2,
    field: 'reserved_cells_count',
    type: 'freeReversed',
    filterKey: 'FREE_REVERSED',
    titleTooltip: {
      id: 'reserved_cell',
      defaultMessage: 'Ячейка зарезервирована',
    },
  },
  {
    id: 3,
    field: 'available_cells_count',
    type: 'available',
    filterKey: 'OCCUPIED_SERVICEABLE',
    titleTooltip: {
      id: 'occupied_avaliable_cell',
      defaultMessage: 'Лежит исправное оборудование',
    },
  },
  {
    id: 4,
    field: 'temporarily_renter_cells_count',
    type: 'occupiedTempRenter',
    filterKey: 'OCCUPIED_TEMPORARILY_RENTER',
    titleTooltip: {
      id: 'occupied_temp_cell',
      defaultMessage: 'Лежит временно сданное оборудование',
    },
  },
  {
    id: 5,
    field: 'not_working_cells_count',
    type: 'notWorking',
    filterKey: 'OCCUPIED_DEFECTIVE',
    titleTooltip: {
      id: 'occupied_not_working_cell',
      defaultMessage: 'Ячейка зарезервирована',
    },
  },
] as const

export const STORAGE_TAB_KEYS = {
  CELLS: 'cells',
  EQUIPMENTS: 'equipments',
  USERS: 'users',
  TAG_HISTORY: 'tagHistory',
} as const

export const STORAGE_TAB = [
  {
    id: 0,
    panel: STORAGE_TAB_KEYS.CELLS,
    title: {
      id: 'app.cells',
      defaultMessage: 'Ячейки',
    },
  },
  {
    id: 1,
    panel: STORAGE_TAB_KEYS.EQUIPMENTS,
    title: {
      id: 'user.equipment',
      defaultMessage: 'Оборудование',
    },
  },
  {
    id: 2,
    panel: STORAGE_TAB_KEYS.USERS,
    title: {
      id: 'users',
      defaultMessage: 'Пользователи',
    },
  },
  {
    id: 3,
    panel: STORAGE_TAB_KEYS.TAG_HISTORY,
    title: {
      id: 'app.tagHistory',
      defaultMessage: 'История меток',
    },
  },
]

export const SHOWING_BLOCK_TYPE = {
  STORAGE: 'storage',
  SIDEBAR: 'sidebar',
}

export const DEFAULT_USER_STORAGE_TABLE_FIELDS = {
  FULL_NAME: 'full_name',
  EMAIL: 'email',
  USER_ROLE: 'user_role',
  EQUIPMENT_STATUS: 'equipment_status',
  ID: 'id',
}

export const CONTENT_CONTAINER_THEME = {
  DEFAULT: 'default',
  CELLS: 'cells',
} as const

export const QUERIES = {
  small: '(max-height: 1024px)',
  medium: '(min-height: 1025px) and (max-height: 1199px)',
  large: '(min-height: 1200px) and (max-height: 1399px)',
  extra: '(min-height: 1400px)',
}

export const PAGE_SIZES = {
  small: 8,
  medium: 9,
  large: 12,
  extra: 15,
}

export const TAGS_HISTORY_KEYS = {
  ID: 'id',
  CARD_NUMBER: 'card_number',
  CREATE_AT: 'created_at',
  SCAN_TIME: 'scan_time',
} as const
