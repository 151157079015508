import React, { MouseEvent } from 'react'
import { GxDropdown } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import { IDropdown } from '@/interfaces/Common'
import style from './styles/index.module.scss'

/**
 * @component Dropdown
 * * компонент выпадающего списка (меню)
 * @param {IDropdown}     общий интерфейс входных параметров Dropdown
 *
 * @param full             на всю доступную ширину
 * @param children         наполнение дропдауна
 * @param className        дополнительные стили
 * @param props            дополнительные параметры (пропсы)
 */
const Dropdown: React.FC<IDropdown> = ({
  full = false,
  children,
  className = '',
  onClick,
  ...props
}) => {
  const handleClick = (
    e: MouseEvent<HTMLGxDropdownElement>
  ): void => {
    e.stopPropagation()
    onClick?.(e)
  }
  return (
    <GxDropdown
      hoist
      className={classNames({
        [style.dropdown]: true,
        [style.full]: Boolean(full),
        [className]: Boolean(className)
      })}
      onClick={handleClick}
      {...props}
    >
      {children}
    </GxDropdown>
  )
}

export default Dropdown
