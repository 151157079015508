import React, { useRef } from 'react'

import EquipmentsTableView from '@/apps/RightSidebar/views/EquipmentTableView'
import * as UserEquipComp from '@/apps/RightSidebar/views/EquipmentTableView/EquipTableComp'

import {
  DEFAULT_ERROR_ARRAY,
  EMPTY_CONSTS,
  EQUIPMENT_KEY,
  MODAL_TYPES,
  TABLE_WRAPPER_ICON_SRC,
  TABLE_WRAPPER_TYPE,
} from '@/const'
import { useLazyFetch, useStores } from '@/hooks'
import {
  IEquipment,
  ITiedEquipment,
} from '@/api/interfaces'

import styles from '@/apps/RightSidebar/views/EquipmentTableView/styles/index.module.scss'

interface IProps {
  groupId: number
}
/**
 * @comp
 * * компонент таблицы оборудования
 * @param groupId
 */
const Equipments: React.FC<IProps> = ({ groupId }) => {
  const { api, modalStore } = useStores()
  const containerRef = useRef<HTMLDivElement>(
    EMPTY_CONSTS.NULL,
  )
  const {
    isLoading,
    handleFetch,
    params,
    error = DEFAULT_ERROR_ARRAY,
    lazyLoadedData,
    tableScroll,
    refetch,
    isScrollEnd,
  } = useLazyFetch(
    containerRef,
    async () =>
      await api.groupUsers.getTiedEquipments(
        groupId,
        params,
      ),
  )
  const { search } = params

  const renderIdCell = (
    equipId: number,
    equipmentData: IEquipment,
  ) => {
    const handleUnbindEquipment = (): Promise<void> =>
      api.equipment.unbindGroupUser(equipId, groupId)

    return (
      <UserEquipComp.UnlinkEquipment
        handleUnbindRequest={handleUnbindEquipment}
        refetch={refetch}
        equipmentData={equipmentData}
      />
    )
  }

  const columns = [
    {
      key: EQUIPMENT_KEY.TITLE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (_: string, equipment: ITiedEquipment) => (
          <UserEquipComp.Title
            title={equipment.title}
            equipment_type={equipment.equipment_type}
            availability_status={
              equipment.availability_status
            }
          />
        ),
      },
    },
    {
      key: EQUIPMENT_KEY.STATUS,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: { render: UserEquipComp.Status },
    },
    {
      key: EQUIPMENT_KEY.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: renderIdCell,
        className: styles.table__body_unlink,
      },
    },
  ]

  const handleOpenBindEquipmentsToGroupUser = (): void => {
    modalStore.open(MODAL_TYPES.USER_GROUP_BIND_EQUIPMENT, {
      refetch,
      groupId,
    })
  }

  const searchPanelProps = {
    handleParams: handleFetch,
    handleOpen: handleOpenBindEquipmentsToGroupUser,
    dataLength: lazyLoadedData?.length as number,
    search,
  }

  const tableWrapProps = {
    search,
    error,
    length: lazyLoadedData?.length,
    handleClick: handleOpenBindEquipmentsToGroupUser,
    iconSrc: TABLE_WRAPPER_ICON_SRC.EQUIPMENT,
    type: TABLE_WRAPPER_TYPE.USER_GROUP_EQUIPMENT_TAB,
  }

  return (
    <EquipmentsTableView
      searchPanelProps={searchPanelProps}
      tableWrapProps={tableWrapProps}
      isScrollEnd={isScrollEnd}
      containerRef={containerRef}
      tableScroll={tableScroll}
      equipments={lazyLoadedData}
      columns={columns}
      isLoading={isLoading}
    />
  )
}

export default Equipments
