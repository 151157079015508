import React, { FC, useEffect, useState } from 'react'

import EquipmentTable from './EquipmentTable'

import { useStores } from '@/hooks'
import useFetch from '@/hooks/useFetch'

import { isUndefined, serializeFilterParams } from '@/utils'
import { ITabCompProps } from '../../interfaces'
import { QUERIES, PAGE_SIZES } from '../../const'
import { DEFAULT_PAGE_SIZE } from '@/const'

import EquipmentPanel from './EquipmentPanel'
import ContentContainer from '../../views/ContentContainer'
import { IEquipmentFilterParams } from '@/apps/Modals/ModalContent/interfaces'
import WithMediaPageSize from '@/components/WithMediaPageSize'
import { observer } from 'mobx-react'

/**
 * @component EquipmentTableList
 * * компонент страницы списка оборудования в деталке шкафов
 * @param storageId - id текущего шкафа
 */
const EquipmentTableList: FC<
ITabCompProps & { pageSize?: number }
> = ({ storageId, pageSize }) => {
  const { api, StorageEventsObserver, ExelEventsObserver, EquipmentEventsObserver } = useStores()
  const {
    data,
    isLoading,
    handleParams,
    params = {},
    handlePage,
    error,
    refetch
  } = useFetch(api.equipment.getEquipments, {
    storage_id: storageId,
    page_size: pageSize ?? DEFAULT_PAGE_SIZE
  })

  const [filterParams, setFilterParams] =
    useState<IEquipmentFilterParams>(
      () => {
        const filteredParams = { ...params }
        delete filteredParams.storage_id
        return filteredParams as IEquipmentFilterParams
      }
    )
  const { page } = params
  /**
   * * метод для изменения фильтров
   * @param newParams - выбранные фильтра из селекта
   */
  const handleFilterParams = (
    newParams: IEquipmentFilterParams
  ): void => {
    const serialized = serializeFilterParams(newParams)
    handleParams(serialized)
    setFilterParams(newParams)
  }

  useEffect(() => {
    if (
      !isUndefined(pageSize) &&
      pageSize !== params.page_size
    ) {
      handleParams({ page_size: pageSize })
    }
  }, [pageSize])

  useEffect(() => {
    StorageEventsObserver.subscribe(refetch)
    ExelEventsObserver.subscribe(refetch)
    EquipmentEventsObserver.subscribe(refetch)
    return () => {
      StorageEventsObserver.unsubscribe(refetch)
      ExelEventsObserver.unsubscribe(refetch)
      EquipmentEventsObserver.subscribe(refetch)
    }
  }, [])

  return (
    <ContentContainer>
      <EquipmentPanel
        refetch={refetch}
        handleParams={handleFilterParams}
        params={filterParams}
      />
      <EquipmentTable
        params={params}
        equipments={data?.results}
        handleParams={handleParams}
        isLoading={isLoading}
        handlePage={handlePage}
        currentPage={page}
        total={data?.count}
        error={error}
        refetch={refetch}
        storageId={Number(storageId)}
      />
    </ContentContainer>
  )
}

const EnhancedEquipmentTableList: FC<ITabCompProps> = (
  props
) => {
  return (
    <WithMediaPageSize queries={QUERIES} sizes={PAGE_SIZES}>
      {(pageSize) => (
        <EquipmentTableList
          {...props}
          pageSize={pageSize}
        />
      )}
    </WithMediaPageSize>
  )
}

export default observer(EnhancedEquipmentTableList)
