import AbstractApi from '@/api/abstract'
import {
  IProblemApi,
  IProblem,
  IProblemCreateValue,
  IPaginatedParams,
  IProblemList,
  IAxiosConfig
} from '@/api/interfaces'
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE
} from '@/const'

class ProblemApi implements IProblemApi {
  ctx: AbstractApi

  constructor (ctx: AbstractApi) {
    this.ctx = ctx
  }

  public getProblems = async (
    param?: IPaginatedParams,
    config?: IAxiosConfig
  ): Promise<IProblemList> => {
    const res = await this.ctx.get<IProblemList>(
      '/problems/',
      {
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size: DEFAULT_PAGE_SIZE,
        ...param
      },
      config
    )
    return res.data
  }

  public deleteProblem = async (
    id: number
  ): Promise<{ id: number }> => {
    const res = await this.ctx.delete<{ id: number }>(
      `/problems/${id}/`
    )
    return res.data
  }

  public getProblem = async (
    id: number
  ): Promise<IProblem> => {
    const { data } = await this.ctx.get<IProblem>(
      `/problems/${id}/`
    )
    return data
  }

  public createProblem = async (
    value: IProblemCreateValue
  ): Promise<IProblem> => {
    const { data } = await this.ctx.post<IProblem>(
      '/problems/',
      value
    )
    return data
  }

  public changeProblem = async (
    id: number,
    value: IProblemCreateValue
  ): Promise<IProblem> => {
    const { data } = await this.ctx.patch<IProblem>(
      `/problems/${id}/`,
      value
    )
    return data
  }
}

export default ProblemApi
