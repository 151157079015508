import React from 'react'

import { Formik, FormikHelpers } from 'formik'

import { problemEditSerializer } from '@/apps/Modals/helpers'
import ProblemEditView from '../../views/ProblemEditView'

import { useStores } from '@/hooks'

import { isUndefined } from '@/utils'
import { ProblemEditScheme } from '@/utils/ValidateSchemes'

import { PROBLEM_EDIT_FIELDS } from '@/apps/Modals/const'
import {
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES
} from '@/const'

import { IProblem, IProblemEdit } from '@/api/interfaces'
import { IModal } from '@/apps/Modals/ModalContent/interfaces'
import ErrorsBlock from '@/components/ErrorsBlock'

const initialValues: Partial<IProblem> = {
  [PROBLEM_EDIT_FIELDS.EQUIPMENT_TYPES]: EMPTY_CONSTS.ARR,
  [PROBLEM_EDIT_FIELDS.TITLE]: EMPTY_CONSTS.STR,
  [PROBLEM_EDIT_FIELDS.TYPE]: EMPTY_CONSTS.STR
}

interface IProblemEditFormProps extends IModal {
  modalProps: {
    problem?: IProblem
    refetch: () => void
  }
}

/**
 * Модалка создания\редактирования проблемы
 * @component
 * @constructor
 */
const ProblemEdit: React.FC<IProblemEditFormProps> = ({
  closeModal,
  modalProps: { problem, refetch }
}) => {
  const { api, modalStore } = useStores()

  const handleSubmit: (
    values: IProblemEdit,
    formikHelpers: FormikHelpers<IProblemEdit>
  ) => void = (values, action) => {
    const serializedValues = problemEditSerializer(values)
    const promise = !isUndefined(values.id)
      ? api.problem.changeProblem(
        values.id,
        serializedValues
      )
      : api.problem.createProblem(serializedValues)
    promise
      .then(() => {
        refetch()
        closeModal()
      })
      .catch((err) => {
        const error = err?.response?.data?.detail
        if (!isUndefined(error)) {
          modalStore.open(MODAL_TYPES.WARNING_ERROR, {
            error: [error]
          })
        } else {
          modalStore.open(MODAL_TYPES.CUSTOM, {
            ...MODAL_CUSTOM_CONTENT.ERROR,
            customContent: (
              <div>
                <ErrorsBlock
                  networkError={false}
                  errorsArray={
                    err.response.data.non_field_errors
                  }
                />
              </div>
            )
          })
        }
      })
      .finally(() => {
        action.setSubmitting(false)
      })

    // ErrorsBlock
    //           networkError={Boolean(
    //             err?.response_errors?.network_error
    //           )}
    //           errorsArray={
    //             err?.response_errors?.non_field_errors
    //           }
    //         />
  }
  return (
    <Formik
      initialValues={problem ?? initialValues}
      onSubmit={handleSubmit}
      validationSchema={ProblemEditScheme}
    >
      {({ handleSubmit }) => {
        return (
          <ProblemEditView
            id={problem?.id}
            handleSubmit={handleSubmit}
            closeModal={closeModal}
          />
        )
      }}
    </Formik>
  )
}

export default ProblemEdit
