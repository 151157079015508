import React, { FC, useMemo, useState } from 'react'
import AvatarView, { IAvatarSizes } from '../../views/Avatar'
import { getInitials } from '@/utils'

interface IProps {
  size: IAvatarSizes
  src?: string | null
  name?: string | null
  surname?: string | null
  changable?: boolean
  className?: string
}

/**
 * @info обертка центрирует контент
 *       в блок определенной максимальной ширины
 * @param param0
 * @returns
 */
const Avatar: FC<IProps> = ({
  src,
  name,
  surname,
  size = 'small',
  changable = true,
  className
}) => {
  const [isHover, setIsHover] = useState(false)

  const memoInitials = useMemo(() => getInitials(name, surname), [name, surname])

  const onPointerOver = (): void => {
    if (!changable) return
    setIsHover(true)
  }

  const onPointerLeave = (): void => {
    if (!changable) return
    setIsHover(false)
  }

  return (
    <AvatarView
      src={src}
      size={size}
      isHover={isHover}
      onPointerOver={onPointerOver}
      onPointerLeave={onPointerLeave}
      initials={memoInitials}
      className={className}
      changable={changable}
    />
  )
}

export default Avatar
