import {
  INotificationApi, INotifyList,
  TGetNotification
} from '@/api/interfaces'
import AbsractApi from '@/api/abstract'

class NotificationApi implements INotificationApi {
  ctx: AbsractApi

  constructor (ctx: AbsractApi) {
    this.ctx = ctx
  }

  public getNotification: TGetNotification = async (
    param
  ) => {
    const res = await this.ctx.get<INotifyList>('/notification/', param)
    return res.data
  }

  public deleteAllNotification = async (): Promise<void> => {
    await this.ctx.delete('/notification/delete_all_notifies/')
  }

  public readAllNotification = async (): Promise<void> => {
    await this.ctx.post('/notification/read_all_notifies/', {})
  }
}

export default NotificationApi
