import React, { FC } from 'react'

import Checkbox from '@/components/Checkbox'
import ControlPanel from '../ControlPanel'

import { EMPTY_CONSTS } from '@/const'

import { IEquipmentContainer, IhandleClick } from '../interfaces'

import style from '../style/index.module.scss'

/**
 * * вью для реализаци основной части модалки прикрепления
 * оборудования к пользователю
 * @view EquipmentContainer
 *
 * @func handleEquipment             функция изменения оборудования
 * @func handleParams                функция изменения query параметров
 * @param params                     query параметры
 * @param Equipments                 оборудование
 * @param isScrollEnd                флаг доскроллено ли до самого низа контейнера
 */
const EquipmentContainer: FC<IEquipmentContainer> = ({
  handleCheckedEquipment,
  checkedEquipments,
  handleParams,
  params,
  equipments = EMPTY_CONSTS.ARR,
  refContainer,
  tableScroll,
  isScrollEnd
}) => {
  const { ordering } = params
  /**
   * * функция изменения состояния выбранного оборудования
   * (выбрать\отменить выбор оборудования)
   * @func   handleClick
   * @param
   */
  const handleClick = (e: React.UIEvent<HTMLElement> & IhandleClick): void => {
    e.preventDefault()
    const checkedElem = checkedEquipments.find(id => id === Number(e.target.name))
    if (checkedElem !== undefined) {
      handleCheckedEquipment(checkedEquipments.filter(id => id !== Number(e.target.name)))
    } else {
      const elem = equipments.find(el => el.id === Number(e.target.name))
      if (elem !== undefined) {
        handleCheckedEquipment([...checkedEquipments, elem.id])
      }
    }
  }

  return (
    <div className={style.container}>
      <div
        className={style.container__content}
        data-scrollend={isScrollEnd}
      >
        <ControlPanel
          checkedEquipments={checkedEquipments}
          handleParams={handleParams}
          handleCheckedEquipment={handleCheckedEquipment}
          ordering={ordering}
        />
        <div
          ref={refContainer}
          onScroll={tableScroll}
          className={style.container__content__equipments}
        >
          {equipments?.length >= 1
            ? equipments.map(({ id, title, equipment_type: equipmentType }) => (
              <div
                key={id}
                className={style.container__content__equipment}
              >
                <div className={style.container__content__title}>
                  <Checkbox
                    name={id}
                    checked={checkedEquipments.includes(id)}
                    onGx-change={handleClick}
                    className={style.container__content__checkbox}
                  />
                  {title}
                </div>
                <span className={style.container__content__type}>
                  {equipmentType}
                </span>
              </div>
            ))
            : null}
        </div>
      </div>
    </div>
  )
}

export default EquipmentContainer
