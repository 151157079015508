import React from 'react'
import classNames from 'classnames'

import styles from '../styles/index.module.scss'

interface IBannerContentProps {
  children: React.ReactNode
  className?: string
}
/**
 * @view
 * Универсальная "полоса" в которую можно положить контент о
 * юзере, шкафу и тд
 */
const BannerContent: React.FC<IBannerContentProps> = ({
  children,
  className,
}) => (
  <div
    className={classNames(
      styles['base-modal__banner'],
      className,
    )}
  >
    {children}
  </div>
)

export default BannerContent
