import React, { FC, useState } from 'react'

import EquipmentUntieView from '../../views/EquipmentUntieView'

import { useStores } from '@/hooks'

import { isUndefined } from '@/utils'

import {
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES
} from '@/const'

import { IEquipmentModal } from '../interfaces'

/**
 * * компонент модального окна удаления оборудования
 * @component EquipmentDelete
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const EquipmentDelete: FC<IEquipmentModal> = ({
  modalProps,
  closeModal
}) => {
  const { api, modalStore } = useStores()
  const { refetch, equipment } = modalProps
  const [isDisabled, setIsDisabled] = useState<boolean>(
    EMPTY_CONSTS.FALSE
  )

  /**
   * @func onSubmit
   * * функция реализующая логику удаления оборудования
   */
  const onSubmit = (): void => {
    setIsDisabled(EMPTY_CONSTS.TRUE)
    void api.equipment
      .deleteEquipment(equipment.id)
      .then(() => {
        refetch()
        closeModal()
      })
      .catch((err) => {
        const error = err?.response?.data?.error
        if (!isUndefined(error)) {
          modalStore.open(MODAL_TYPES.WARNING_ERROR, {
            error: [error]
          })
        } else {
          modalStore.open(
            MODAL_TYPES.CUSTOM,
            MODAL_CUSTOM_CONTENT.ERROR
          )
        }
      })
      .finally(() => {
        setIsDisabled(EMPTY_CONSTS.FALSE)
      })
  }

  return (
    <EquipmentUntieView
      equipmentData={equipment}
      closeModal={closeModal}
      handleSubmit={onSubmit}
      isDisabled={isDisabled}
    />
  )
}

export default EquipmentDelete
