import AbsractApi from './abstract'
import {
  PageApi,
  UserApi,
  StorageApi,
  EquipmentApi,
  NotificationApi,
  ProblemApi,
  IntegrationApi,
  GroupUserApi
} from './domains'
import ErrorApiOverride from './abstract/error'
import { TLanguage } from '@/interfaces/Lang'

class Api extends AbsractApi {
  page: PageApi
  user: UserApi
  storage: StorageApi
  equipment: EquipmentApi
  problem: ProblemApi
  notification: NotificationApi
  errorHandlers: ErrorApiOverride
  integration: IntegrationApi
  groupUsers: GroupUserApi

  constructor (MAIN_URL: string, language: TLanguage) {
    console.log('MAIN_URL', MAIN_URL)
    super(MAIN_URL, language)
    this.errorHandlers = new ErrorApiOverride(this)
    this.user = new UserApi(this)
    this.page = new PageApi(this)
    this.storage = new StorageApi(this)
    this.equipment = new EquipmentApi(this)
    this.problem = new ProblemApi(this)
    this.notification = new NotificationApi(this)
    this.integration = new IntegrationApi(this)
    this.groupUsers = new GroupUserApi(this)
  }
}

export { Api }
