import React, { useState } from 'react'

import { GetOptionValue, MultiValue } from 'react-select'
import { useFormikContext } from 'formik'

import PaginateAsyncSelect from '@/components/AsyncSelect/PaginateAsyncSelect'

import { useStores, useTranslate } from '@/hooks'
import { usePaginateSelect } from '@/apps/Modals/hook'

import { isUndefined } from '@/utils'

import { ADDITIONAL, EMPTY_CONSTS } from '@/const'
import { PROBLEM_EDIT_FIELDS } from '@/apps/Modals/const'

import { IOptions } from '@/apps/Modals/ModalContent/interfaces'
import { IProblem } from '@/api/interfaces'

/**
 * Компонет селекта для выбора нескольких типов оборудования
 * @component MultiTypeSelect
 */
const MultiTypeSelect: React.FC = () => {
  const { intl, translate } = useTranslate()
  const {
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors
  } = useFormikContext<IProblem>()
  const isEquipmentTypes = !isUndefined(values.equipment_types) && Array.isArray(values.equipment_types)
  const [value, setValue] = useState<IOptions[] | undefined>(
    () => {
      if (isEquipmentTypes) {
        return values.equipment_types.map((type) => ({
          id: type,
          label: type
        }))
      }
      return EMPTY_CONSTS.UNDEFINED
    }
  )
  const { api } = useStores()
  const { loadOptions } = usePaginateSelect(
    api.equipment.getEquipmentTypes
  )
  const handleChange = (
    option: MultiValue<IOptions>
  ): void => {
    const labels = option.map((el) => el.label)
    void setFieldValue(
      PROBLEM_EDIT_FIELDS.EQUIPMENT_TYPES,
      labels
    )
    setValue([...option])
  }
  const handleBlur = (): void => {
    void setFieldTouched(
      PROBLEM_EDIT_FIELDS.EQUIPMENT_TYPES,
      EMPTY_CONSTS.TRUE
    )
  }
  const getOptionValue: GetOptionValue<IOptions> = (option) => option.label
  const getOptionLabel: GetOptionValue<IOptions> = (option) => option.label
  const error =
    Boolean(touched.equipment_types) &&
    Boolean(errors.equipment_types) &&
    errors.equipment_types !== undefined
      ? translate(String(errors.equipment_types))
      : EMPTY_CONSTS.STR
  const messages = {
    label: intl.formatMessage({
      id: 'equipmentType',
      defaultMessage: 'Тип оборудования'
    }),
    placeholder: intl.formatMessage({
      id: 'select',
      defaultMessage: 'Выберите'
    })
  }
  return (
    <PaginateAsyncSelect
      name={PROBLEM_EDIT_FIELDS.EQUIPMENT_TYPES}
      error={error}
      value={value}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      loadOptions={loadOptions}
      additional={ADDITIONAL.PAGE}
      onChange={handleChange}
      onBlur={handleBlur}
      label={messages.label}
      placeholder={messages.placeholder}
      closeMenuOnSelect={EMPTY_CONSTS.FALSE}
      isSearchable
      isMulti
      hideSelectedOptions={false}
    />
  )
}

export default MultiTypeSelect
