import * as yup from 'yup'
import { INTL_DEFAULT_MESSAGE, MAX_LENGTH } from '@/const'

const EquipmentEditScheme = yup.object().shape({
  equipment_type: yup
    .string()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  inventory_number: yup
    .string()
    .trim(),
  serial_number: yup
    .string()
    .trim(),
  title: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED),
  description: yup.string().trim(),
  label: yup
    .string()
    .trim()
    .max(MAX_LENGTH.LABEL, INTL_DEFAULT_MESSAGE.MAX)
})

export default EquipmentEditScheme
