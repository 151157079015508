import React from 'react'
import { useFormik } from 'formik'

import DownloadHistoryView from '../../views/DownloadHistoryView'

import { useStores } from '@/hooks'
import { getObjectErrorsFormik, isUndefined } from '@/utils'
import { IModal } from '../interfaces'
import { DownloadHistoryStorageSchema } from '@/utils/ValidateSchemes'
/**
 * * комп модалки скачивания истории шкафа
 * @param closeModal закрыть модалку
 */
const DownloadHistory: React.FC<IModal> = ({
  closeModal
}) => {
  const { api, currentStorageStore } = useStores()
  const storageId = currentStorageStore.storage?.id

  const formik = useFormik({
    initialValues: {
      date_start: undefined,
      date_end: undefined
    },
    validateOnBlur: false,
    validationSchema: DownloadHistoryStorageSchema,
    onSubmit: (value, action) => {
      action.setSubmitting(true)
      if (!isUndefined(storageId)) {
        api.storage
          .generateHistory({
            id: storageId,
            date_start: value.date_start,
            date_end: value.date_end
          })
          .then(() => {
            closeModal()
          })
          .catch((error) => {
            if (!isUndefined(error?.response?.data)) {
              const formattedError =
                getObjectErrorsFormik(error)
              action.setErrors(formattedError)
            }
          })
          .finally(() => {
            action.setSubmitting(false)
          })
      }
    }
  })

  return (
    <DownloadHistoryView
      formik={formik}
      closeModal={closeModal}
    />
  )
}

export default DownloadHistory
