import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import Media from 'react-media'
import Avatar from '@/components/Avatar'
import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import MobileContentAsideProfile from '../MobileContent'
import { useStores } from '@/hooks'
import {
  MEDIA_QUERY,
  MODAL_TYPES,
  USER_SHOWING_PANELS,
} from '@/const'
import { IActualInitials } from '@/hooks/useEditUserForm'
import { TShowingPanelProps } from '../interfaces'

import styles from '../styles/index.module.scss'

type TProps = {
  initials: IActualInitials
} & TShowingPanelProps

/**
 * @info Девая Часть Страницы Профиля
 * @param param0
 * @returns
 */
const AsideLeft: FC<TProps> = ({
  initials,
  currentShowingPanel,
  setCurrentShowingPanel,
}) => {
  const intl = useIntl()
  const store = useStores()

  /*
   * * функция для логауда юзера
   */
  const handleOpenLogoutModal = (): void => {
    store.modalStore.open(MODAL_TYPES.LOG_OUT)
  }

  const handleOpenEditProfile = (): void => {
    setCurrentShowingPanel(
      USER_SHOWING_PANELS.SECTION_RIGHT,
    )
  }

  const handleOpenNotificationModal = (): void => {
    store.notificationStore.open()
  }

  const memoNSP = useMemo(
    () =>
      String(
        `${initials.last_name} ${initials.first_name} ${initials.patronymic}`,
      ).trim(),
    [initials],
  )

  return (
    <aside
      data-show={currentShowingPanel}
      className={styles.profile__aside}
    >
      <Avatar
        size="large"
        name={initials.first_name}
        surname={initials.last_name}
        className={styles.profile__aside_avatar}
        changable={false}
      />
      <Media queries={MEDIA_QUERY}>
        {(matches) => {
          return matches.MEDIA_700 ? (
            <MobileContentAsideProfile
              memoNPS={memoNSP}
              email={initials.email}
              handleOpenLogoutModal={handleOpenLogoutModal}
              handleOpenEditProfile={handleOpenEditProfile}
              handleOpenNotificationModal={
                handleOpenNotificationModal
              }
            />
          ) : (
            <>
              <p className={styles.profile__aside__name}>
                {memoNSP}
              </p>
              <Button
                variant="text"
                color="orange"
                onClick={handleOpenLogoutModal}
              >
                {intl.formatMessage({
                  id: 'button.exit',
                  defaultMessage: 'Выйти',
                })}
                <Icon src="exit" size="small-xl" />
              </Button>
            </>
          )
        }}
      </Media>
    </aside>
  )
}

export default memo(AsideLeft)
