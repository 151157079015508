import React from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import EquipmentList from '@/apps/EquipmentList'

const EquipmentListPage: React.FC = () => {
  return (
    <MainLayout>
      <EquipmentList />
    </MainLayout>
  )
}

export default EquipmentListPage
