import React from 'react'
import FilterView from '../../views/FilterView'
import { IFilterModal } from '../interfaces'

/**
 * * компонент фильтра пользователей
 * @component UserFilter
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const UserFilter: React.FC<IFilterModal> = ({
  modalProps,
  closeModal
}) => (
  <FilterView
    filterType='user'
    modalProps={modalProps}
    closeModal={closeModal}
  />
)

export default UserFilter
