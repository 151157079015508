import { TStorageStatus } from '@/interfaces/consts'
import { STORAGE_STATUSES } from '@/apps/StorageList/const'
import {
  INTEGRATION_ERR_CONTENT_KEYS,
  STORAGE_EDIT_STATUS_EDITING,
} from '../const'
import { isUndefined } from '@/utils'
import { EMPTY_CONSTS } from '@/const'
import { UserDataPropsType } from '../views/IntegrationSyncErrorView/types'

interface IGetStatusEditStorageProps {
  id: number | undefined
  error: boolean
  hasChangeImportantFields: boolean
  storageStatus: TStorageStatus
}

/**
 *
 * @func getStatusEditStorage
 * * метод для получения текущего состояния формы шкафа
 * @param id                         id текущего шкафа
 * @param hasChangeImportantFields   флаг говорящий о изменении важных файлов
 * @param error                      флаг говорящий об наличие ошибок в форме
 * @param storageStatus              текущий статус шкафа
 * @returns
 */
export const getStatusEditStorage = ({
  id,
  error,
  hasChangeImportantFields,
  storageStatus,
}: IGetStatusEditStorageProps): string | null => {
  if (error || storageStatus === STORAGE_STATUSES.ERROR) {
    return STORAGE_EDIT_STATUS_EDITING.ERROR
  } else if (isUndefined(id)) {
    return STORAGE_EDIT_STATUS_EDITING.CREATE
  } else if (!isUndefined(id)) {
    if (hasChangeImportantFields) {
      return STORAGE_EDIT_STATUS_EDITING.EDIT
    }
    return STORAGE_EDIT_STATUS_EDITING.SUCCESS
  } else {
    return EMPTY_CONSTS.NULL
  }
}
/**
 * * метод для получения ключа конкретного контента в модалке скуда
 * @function getIntegrationErrContentVariant
 * @param userData
 */
export const getIntegrationErrContentVariant = (
  userData?: UserDataPropsType,
) => {
  const haveUserVal =
    userData?.first_name && userData?.last_name

  if (haveUserVal && !userData?.card_hid_number) {
    return INTEGRATION_ERR_CONTENT_KEYS.FULL_NAME
  } else if (haveUserVal && userData?.card_hid_number) {
    return INTEGRATION_ERR_CONTENT_KEYS.HID_AND_FULL_NAME
  } else if (userData?.card_hid_number) {
    return INTEGRATION_ERR_CONTENT_KEYS.HID
  } else {
    return INTEGRATION_ERR_CONTENT_KEYS.FULL_NAME
  }
}

export const getHeaderStatusColor = ({
  isValid,
  haveEntity,
}) => {
  if (!isValid) {
    return 'red'
  } else if (haveEntity) {
    return 'orange'
  } else {
    return 'green'
  }
}
