import { GxMenu } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React from 'react'
import style from './styles/index.module.scss'
import { IDropdownMenu } from './interfaces'

/**
 * @component DropdownMenu
 * * компонент меню из выпадающего списка
 * @param {IDropdownMenu}     общий интерфейс входных параметров DropdownMenu
 *
 * @param children           наполнение дропдауна
 * @param className          дополнительные стили
 */
const DropdownMenu = ({
  children,
  className = ''
}): React.ReactElement<IDropdownMenu> => {
  const classes = classNames({
    [style.menu]: true,
    [className]: Boolean(className)
  })
  return <GxMenu className={classes}>{children}</GxMenu>
}

export default DropdownMenu
