import React, { useMemo } from 'react'

import { formatDateForFrontend } from '@/utils'

import Icon from '@/components/Icon'

import style from './style/index.module.scss'

interface IProps {
  date: string
  showIcon?: boolean
  mask?: string
}

/**
 *  * Вью для таблицы Шкафов,
 *  * отрисовывает дату изменения и кнопку для редактирования
 * @view EditWithTime
 *
 * @param date -        дата изменения
 * @param showIcon
 */

const EditWithTime: React.FC<IProps> = ({
  date,
  showIcon = true,
  mask,
}) => {
  const formatedDate = useMemo(
    () => formatDateForFrontend(date, mask),
    [date],
  )
  return (
    <div className={style.editor}>
      {showIcon && (
        <Icon src="edit" size="medium-adaptive" />
      )}
      <span className={style.editor__time}>
        {formatedDate}
      </span>
    </div>
  )
}

export default EditWithTime
