export const WEEKDAY = {
  ru: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
  en: ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
}

export const FIRST_MONTH = 1
export const LAST_MONTH = 12
export const TOTAL_DAYS_OF_WEEK = 7

export const DEFAULT_DAY_CELL = {
  isSelected: false,
  isFirstWeekDay: false,
  isLastWeekDay: false,
  isBetweenPickedDates: false,
  onClickDayCell: () => {}
}

export const DEFAULT_CALENDAR_DATA_CONTEXT = {
  pickedDate: null,
  saveDate: () => {},
  curMonth: { year: 0, month: 0 },
  setCurMonth: () => {}
}
