import React from 'react'

import { IStorageCell } from '@/api/interfaces'

import Card from './Card'

import style from './style/index.module.scss'
import { isUndefined } from '@/utils'
import { EMPTY_CONSTS } from '@/const'

interface IProps {
  cells?: IStorageCell[]
}

const CellCards: React.FC<IProps> = ({ cells }) => {
  return (
    <ul className={style.cells}>
      {!isUndefined(cells) &&
        cells.length !== EMPTY_CONSTS.ZERO &&
        cells.map(
          ({
            id,
            cell_number: cellNumber,
            title,
            equipment_type: equipmentType,
            cell_status: cellStatus,
            equipment_id: equipmentId,
            is_blocked: isBlocked
          }) => (
            <Card
              key={id}
              id={id}
              cellNumber={cellNumber}
              title={title}
              equipmentType={equipmentType}
              cellStatus={cellStatus}
              isBlocked={isBlocked}
              equipmentId={equipmentId}
            />
          )
        )}
    </ul>
  )
}

export default CellCards
