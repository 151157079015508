import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useAuth } from '@garpix/cms'
import {
  IAUthData,
  ILoginError,
  ILoginProps,
  IUseCustomAuth
} from '../interfaces'
import { DEFAULT_LINKS } from '@/const'

/**
 * @hook   useCustomAuth
 * * Хук для авторизации пользователя
 *
 * @param  {ILoginProps} IUseCustomAuth
 */
const useCustomAuth = ({
  successLink = DEFAULT_LINKS.root
}: ILoginProps): IUseCustomAuth => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [errors, setErrors] = useState<ILoginError | null>(
    null
  )
  /**
   * * редирект в случае успешной авторизации
   * @func   handleSuccess
   * @param
   */
  const handleSuccess = (): void => {
    navigate(successLink)
  }

  /**
   * * функция для доп обработки ошибки (не требуется так как хук useAuth уже обработал ошибки, но наличие данной функции является обязательным для передачи в useAuth)
   * @func   handleError
   * @param
   */
  const handleError = (): void => {}

  /** хук для авторизации
   * @hook useAuth
   *
   * @param {boolean}   handleSuccess  функция, которая вызывается в случае успешной авторизации
   * @param {Object}    handleError    функция, которая вызывается в случае неуспешной авторизации
   *
   * @return {
   *  handleChange   функция изменения данных инпута
   *  errors         ошибки при выполнения запроса
   *  handleBlur     функция изменения данных инпута при убирании фокуса с него
   *  authData       { username, password }
   *  handleSubmit   функция отправки данных
   *  isSubmitting   флаг переключается в true во время запроса на сервер
   * }
   */
  const {
    handleChange,
    errors: error,
    handleBlur,
    handleSubmit: handleAuthSubmit,
    isSubmitting
  } = useAuth({
    handleSuccess,
    handleError
  })

  /**
   * * функция отправки данных (включает валидацию полей)
   * @func   onSubmit
   * @param
   */
  const onSubmit = (data: IAUthData): void => {
    try {
      handleAuthSubmit(data)
    } catch (e) {
      const err = e?.message
      const errMessage = err?.username ?? err?.password
      if (typeof errMessage === 'string') {
        setErrors({
          ...err,
          non_field_errors: [
            intl.formatMessage({
              id: errMessage,
              defaultMessage: 'Ошибка'
            })
          ]
        })
      }
    }
  }

  /**
   * *  @info   useEffect
   * ошибки получаемые при запросах хуком useAuth (error),
   * записываются в обьект ошибок errors.
   *
   * @param
   */
  useEffect(() => {
    setErrors(error)
  }, [error])

  return {
    handleChange,
    errors,
    handleBlur,
    onSubmit,
    handleAuthSubmit,
    isSubmitting,
    setErrors
  }
}

export default useCustomAuth
