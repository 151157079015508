import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

import { isNull } from '@/utils'
import { EMPTY_CONSTS } from '@/const'

import { PROBLEM_TYPE } from '@/apps/ProblemList/const'
import { Typography } from '@/views/typography'

import styles from './styles/index.module.scss'

/**
 * Вьюха с типом проблемы страницы проблем
 * @view
 * @param type - тип проблемы
 */

const TypeCell = (type: string): React.ReactNode => {
  const intl = useIntl()
  const message = Object.values(PROBLEM_TYPE).includes(type)
    ? intl.formatMessage({
      id: `problem.type.${type}`
    })
    : EMPTY_CONSTS.NULL
  if (isNull(message)) {
    return EMPTY_CONSTS.NULL
  }
  return (
    <div
      className={classNames(styles.type, {
        [styles['type--required']]:
          type === PROBLEM_TYPE.REQUIRED_DESCRIPTION
      })}
    >
      <Typography size={14} weight={400}>
        {message}
      </Typography>
    </div>
  )
}

export default TypeCell
