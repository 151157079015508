import React from 'react'
import { useIntl } from 'react-intl'
import { TUserRole } from '@/api/interfaces'

export interface IUserRole {
  role: TUserRole
}

/**
 * Вюха с ролью пользователя
 * @view
 * @param role - тип роли
 */
const UserRole: React.FC<IUserRole> = ({ role }) => {
  const intl = useIntl()
  return (
    <div>
      {intl.formatMessage({ id: `user.user_role.${role}`, defaultMessage: 'роль' })}
    </div>
  )
}

export default UserRole
