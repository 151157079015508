import React, { FC } from 'react'

import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import cn from 'classnames'
import { useStores } from '@/hooks'

import { IStorageModal } from '../interfaces'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import StorageStatus from '@/apps/StorageList/views/StorageStatus'

import style from '../styles/index.module.scss'

/**
 * * компонент модального окна для отключения шкафа
 * @component StorageTurnOff
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const StorageTurnOff: FC<IStorageModal> = ({
  closeModal
}) => {
  const {
    currentStorageStore: { storage, allCells }
  } = useStores()
  const intl = useIntl()
  /**
   * @func onSubmit
   * * функция реализующая логику отключения шкафа
   */
  const onSubmit = (): void => {
    closeModal()
  }

  return (
    <div className={style.container}>
      <div className={style.container__header}>
        <Icon
          src='shutDown'
          size='xl'
          className={style.container__header_icon}
        />
        <Typography weight={700} size={30}>
          {intl.formatMessage({
            id: 'modal.title.storage_turn_off',
            defaultMessage:
              'Are you sure you want to turn off the storage?'
          })}
        </Typography>
      </div>
      <div
        className={cn(
          style.container__body,
          style.container__body_cells
        )}
      >
        {storage?.storage_status !== undefined && (
          <StorageStatus
            countCells={allCells}
            storageStatus={storage.storage_status}
          />
        )}
        <div className={style.container__body_storage}>
          {storage?.title !== undefined && (
            <Typography
              weight={700}
              size={18}
              className={style.container__body_storage_text}
            >
              {storage.title}
            </Typography>
          )}
        </div>
      </div>
      <div className={style.container__buttons}>
        <Button
          className={style.container__buttons_cancel}
          onClick={closeModal}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Cancel'
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color='black'
          onClick={onSubmit}
        >
          {intl.formatMessage({
            id: 'common.disable',
            defaultMessage: 'Disable'
          })}
        </Button>
      </div>
    </div>
  )
}

export default observer(StorageTurnOff)
