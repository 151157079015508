import { CSSObjectWithLabel, OptionProps, GroupBase } from 'react-select'
import classNames from 'classnames'
import { ISelectValue } from '.'

import style from './style/index.module.scss'

export const selectClassNames = {
  menu: () => style.container__menu,
  input: () => style.container__input,
  placeholder: () => style.container__input,
  valueContainer: () => style.container__inputContainer,
  singleValue: () => style.container__value,
  menuList: () => style.container__list,
  container: ({ selectProps }) => {
    return classNames(style.main__container, {
      [style.main__container_open]: selectProps.menuIsOpen
    })
  },
  multiValue: () => style['multi-value'],
  multiValueLabel: () => style['multi-value__label'],
  multiValueRemove: () => style['multi-value__error']
}

export const customStyles = {
  option: (base: CSSObjectWithLabel, { isSelected, isMulti }: OptionProps<ISelectValue, boolean, GroupBase<ISelectValue>>) => {
    return {
      ...base,
      display: 'flex',
      padding: '10px',
      transition: 'var(--transition-fast)',
      color: isSelected ? 'var(--color-orange)' : 'var(--color-black)',
      backgroundColor: 'var(--color-white)',
      cursor: 'pointer',
      '::before': {
        ...base['::before'],
        content: "''",
        display: isMulti ? 'block' : 'none',
        width: '20px',
        height: '20px',
        borderRadius: '6px',
        border: '1px solid var(--color-inputs-light)',
        color: isSelected ? 'var(--color-orange)' : 'transparent',
        marginRight: '10px',
        flexShrink: 0
      },
      ':hover': {
        ...base[':hover'],
        color: isSelected ? 'var(--color-orange)' : 'var(--color-black)',
        backgroundColor: 'var(--color-bg-hover)'
      }
    }
  }
}
