import React, { FC } from 'react'
import classNames from 'classnames'
import style from './style/index.module.scss'

interface SwitchType {
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  handleBlur?: React.FocusEventHandler<HTMLInputElement>
  checked: boolean
  children?: React.ReactNode
  name?: string
  disabled?: boolean
  className?: string
}

export const Switch: FC<SwitchType> = (props) => {
  const {
    handleChange,
    checked,
    name,
    handleBlur,
    disabled,
    className,
  } = props

  return (
    <div
      className={classNames(style.switch__flex, className)}
    >
      {props?.children}
      <div
        data-disabled={disabled}
        className={classNames(style.switch, {
          [style.switch_active]: checked,
        })}
      >
        <input
          checked={checked}
          onChange={handleChange}
          onBlur={handleBlur}
          type="checkbox"
          disabled={disabled}
          name={name}
          className={style.switch__input}
        />
      </div>
    </div>
  )
}
