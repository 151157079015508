import React from 'react'
import { useIntl } from 'react-intl'

import { Dropdown, DropdownMenu } from '@/components/Dropdown'
import MenuItem from '@/components/MenuItem'
import Icon from '@/components/Icon'

import { IUnlinkDropdown } from './interfaces'

import styles from './styles/index.module.scss'

/**
 * @component UnlinkDropdown
 * * компонент дропдауна с кнопкой отвязки
 *
 * @param onClick           функция которая вызывается при клике на отвязку
 */
const UnlinkDropdown: React.FC<IUnlinkDropdown> = ({ onClick }) => {
  const intl = useIntl()
  const handleClick = (): void => {
    if (onClick !== undefined) {
      onClick()
    }
  }

  return (
    <div className={styles.dropdown}>
      <Dropdown className={styles.dropdown__wrap}>
        <Icon src='more' slot='trigger' />
        <DropdownMenu className={styles.dropdown__menu}>
          <MenuItem value='linkOff' className={styles.dropdown__unlink} onClick={handleClick}>
            <div className={styles.dropdown__unlink}>
              <Icon src='linkOff' className={styles.dropdown__unlink_icon} />
              {intl.formatMessage({ id: 'common.unbind', defaultMessage: 'Unbind' })}
            </div>
          </MenuItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default UnlinkDropdown
