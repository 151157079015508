import * as yup from 'yup'
import { INTL_DEFAULT_MESSAGE } from '@/const'

const GroupUsersEditScheme = yup.object().shape({
  color: yup.string(),
  title: yup
    .string()
    .trim()
    .required(INTL_DEFAULT_MESSAGE.REQUIRED)
})

export default GroupUsersEditScheme