import React from 'react'

import { useStores } from '@/hooks'

import { MODAL_TYPES, TABLE_PAGE } from '@/const'
import { IUserPanel } from '@/apps/UserList/interfaces'

import SearchPanel from '@/apps/StorageDetails/views/SearchPanel'

interface IStorageUserPanel extends IUserPanel {
  storageId: number
}
/**
 * @component
 * * компонент панели с поиском и привязкой в табе пользователя
 * @param handleParams
 *  @param params
 *  @param refetch
 *  @param storageId
 * @returns
 */
const StorageUserPanel: React.FC<IStorageUserPanel> = ({
  handleParams,
  params,
  refetch,
  storageId,
}) => {
  const { modalStore } = useStores()
  /**
   * @function handleOpenUserBindModal
   * * метод для открытия модалки с привязкой пользователей к шкафу
   */
  const handleOpenUserBindModal = (): void => {
    modalStore.open(MODAL_TYPES.USER_BINDING, {
      refetch,
      storageId,
    })
  }
  /**
   * @function handleCreateUser
   * * метод для открытия модалки создания юзера
   */
  const handleCreateUser = (): void => {
    modalStore.open(MODAL_TYPES.USER_EDIT, {
      refetch,
    })
  }
  /**
   * @function handleUploadUserExel
   * * метод для открытия модалки загрузки пользователя
   */
  const handleUploadUserExel = (): void => {
    modalStore.open(MODAL_TYPES.UPLOAD_USER_EXCEL, {
      refetch,
    })
  }

  const filterBtnProps = {
    type: TABLE_PAGE.STORAGE_USER,
    params,
  }

  const addBtnProps = {
    tooltipContent: {
      id: 'tieUser',
      defaultMessage: 'Привязать пользователя',
    },
    onClick: handleOpenUserBindModal,
  }

  return (
    <SearchPanel
      addBtnProps={addBtnProps}
      filterBtnProps={filterBtnProps}
      handleParams={handleParams}
      handleCreateBtn={handleCreateUser}
      handleUpload={handleUploadUserExel}
      createBtnTooltipContent={{
        id: 'button.add_user',
        defaultMessage: 'Add user',
      }}
    />
  )
}

export default StorageUserPanel
