import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from '@/hooks'

import { Typography } from '@/views/typography'

import style from '../style/index.module.scss'
/**
 * @view
 * * вью для отрисовки интерфейса заголовка на стр шкафа
 * @returns
 */
const Title = (): React.ReactElement => {
  const {
    currentStorageStore: { storage },
  } = useStores()
  return (
    <div>
      {storage?.serial_number !== undefined && (
        <Typography
          className={style['storage-detail__label']}
          size={14}
          weight={400}
        >
          {storage.serial_number}
        </Typography>
      )}
      {storage?.title !== undefined && (
        <Typography size={24} weight={700}>
          {storage.title}
        </Typography>
      )}
    </div>
  )
}

export default observer(Title)
