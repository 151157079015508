import React, { FC, useState } from 'react'

import { GxIcon } from '@garpix/garpix-web-components-react'
import ReactSelect from 'react-select'
import classNames from 'classnames'

import {
  ArrowBackIcon,
  ErrorExclamation
} from '@/assets/icon'
import { Typography } from '@/views/typography'

import { EMPTY_CONSTS } from '@/const'

import { customStyles, selectClassNames } from './baseStyles'
import style from './style/index.module.scss'

export interface ISelectValue {
  label: string
  value: string | number
}

interface ISelect {
  onChange: (value: ISelectValue) => void
  options: ISelectValue[]
  value?: ISelectValue | null
  label?: string
  placeholder?: string
  name?: string
  id?: string
  isMulti?: boolean
  isSearchable?: boolean
  error?: string
  isDisabled?: boolean
  className?: string
  required?: boolean
}

export const Select: FC<ISelect> = ({
  onChange,
  label,
  value,
  options,
  placeholder = EMPTY_CONSTS.STR,
  isMulti = EMPTY_CONSTS.FALSE,
  error,
  isDisabled,
  isSearchable = EMPTY_CONSTS.FALSE,
  className = EMPTY_CONSTS.STR,
  required = EMPTY_CONSTS.FALSE,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const closeDropdown = (): void => {
    setOpen(false)
  }

  const openDropdown = (): void => {
    setOpen(true)
  }

  return (
    <div
      className={
        classNames(style.main, {
          [className]: Boolean(className)
        }
        )
}
    >
      <Typography
        size={14}
        weight={400}
        className={classNames({
          [style.main__label_required]: required,
          [style.main__label]: EMPTY_CONSTS.TRUE
        })}
      >
        {label}
      </Typography>
      <div className={style.main__select}>
        <ReactSelect
          {...props}
          onMenuClose={closeDropdown}
          isMulti={isMulti}
          value={value}
          isSearchable={isSearchable}
          onChange={onChange}
          menuIsOpen={open}
          onMenuOpen={openDropdown}
          placeholder={placeholder}
          options={options}
          isDisabled={isDisabled}
          styles={customStyles}
          components={{
            IndicatorsContainer: () => (
              <GxIcon
                src={ArrowBackIcon}
                className={classNames(style.main__icon, {
                  [style.main__icon_open]: open,
                  [style.main__icon_disabled]: isDisabled
                })}
              />
            )
          }}
          classNames={{
            ...selectClassNames,
            control: ({ menuIsOpen }) => {
              return classNames(style.container__control, {
                [style.container__control_open]: menuIsOpen,
                [style.container__control_disabled]: isDisabled,
                [style.container__control_error]:
                  Boolean(error)
              })
            }
          }}
        />
        {Boolean(error) && (
          <>
            <GxIcon
              src={ErrorExclamation}
              className={style.main__error_icon}
            />
            <Typography
              size={14}
              weight={400}
              className={style.main__error_label}
            >
              {error}
            </Typography>
          </>
        )}
      </div>
    </div>
  )
}
