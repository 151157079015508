import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'

import style from '../style/index.module.scss'

/**
 * * вью для реализаци тайтла модалки фильтра
 * @view
 *
 * @param closeModal                функция закрытия фильтра
 */
const FilterTitle: FC<any> = ({
  closeModal
}) => {
  const intl = useIntl()

  return (
    <div className={style.header}>
      <Typography
        size={32} weight={700}
        className={style.header_title}
      >
        {intl.formatMessage({ id: 'common.filter', defaultMessage: 'Фильтры' })}
      </Typography>
      <Icon
        src='close'
        className={style.header__close}
        onClick={closeModal}
      />
    </div>
  )
}

export default FilterTitle
