import React from 'react'
import Login from '@/apps/Login'
import AuthLayout from '@/apps/layout/components/AuthLayout'

const UserAuthorizationPage = (): React.ReactElement => {
  return (
    <AuthLayout>
      <Login />
    </AuthLayout>
  )
}

export default UserAuthorizationPage
