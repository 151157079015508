import React from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@/views/typography'

import styles from './styles/index.module.scss'
import NotificationHead from '@/apps/Notifications/views/Head'

/**
 * Блок при пустом списке с уведомлениями
 * @view
 * @constructor
 */

interface IEmpty {
  handleClose: () => void
}
const Empty: React.FC<IEmpty> = ({ handleClose }) => {
  const intl = useIntl()
  return (
    <div className={styles.empty}>
      <NotificationHead handleClose={handleClose} />
      <div className={styles.empty__content}>
        <Typography size={18} weight={700}>
          {intl.formatMessage({
            id: 'noUnreadMessages',
            defaultMessage:
              'У вас нет непрочитанных сообщений'
          })}
        </Typography>
      </div>
    </div>
  )
}

export default Empty
