import React from 'react'

import cn from 'classnames'
import { IntlShape } from 'react-intl'
import { GxIcon } from '@garpix/garpix-web-components-react'

import { Typography } from '@/views/typography'
import { Spinner } from '@/views/spinner'
import { Button } from '@/views/button'

import { useStores, useMutation } from '@/hooks'

import { isUndefined } from '@/utils'

import { StopSyncIcon } from '@/assets/icon'

import { MODAL_CUSTOM_CONTENT, MODAL_TYPES } from '@/const'

import style from './style/index.module.scss'
import { TReqCurrentStorage } from '@/services/store/CurrentStorage'

interface IProp {
  intl: IntlShape
}
/**
 * @view
 * * вью отражающий текущий статус шкафа на стр его деталки
 */
export const Synchronization = ({
  intl,
}: IProp): React.ReactElement => {
  const { api, currentStorageStore, modalStore } =
    useStores()
  const storage =
    currentStorageStore.storage ||
    ({} as TReqCurrentStorage)
  const isStorageExist = !isUndefined(storage?.id)

  const { mutation, isLoading } = useMutation({
    mutation: async () => {
      if (isStorageExist) {
        return await api.storage.stopSynchronization(
          storage.id,
        )
      }
    },
    onSuccess: () => {
      if (isStorageExist) {
        void currentStorageStore.updateStorage(storage.id)
      }
    },
    onError: () => {
      modalStore.open(
        MODAL_TYPES.CUSTOM,
        MODAL_CUSTOM_CONTENT.ERROR,
      )
    },
  })

  console.log(isLoading)

  return (
    <>
      <Spinner
        className={cn(
          style.status__icon,
          style.status__icon_sync,
        )}
        size="medium"
      />
      <Typography
        className={style.status__title}
        size={24}
        weight={700}
      >
        {intl.formatMessage({
          id: 'connected.sync',
          defaultMessage: 'Подключение...',
        })}
      </Typography>
      <Button
        disabled={isLoading}
        onClick={mutation}
        className={style.status__stop_sync}
        color="black"
      >
        <GxIcon
          className={style.status__stop_sync}
          src={StopSyncIcon}
        />
        {intl.formatMessage({
          id: 'connected.stopSync',
          defaultMessage: 'Остановить синхронизацию',
        })}
      </Button>
    </>
  )
}
