import React from 'react'

import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'

import { TStorageCellFilterStatus, ICellFilterParams } from '@/api/interfaces'
import { CELL_COUNTS } from '@/apps/StorageDetails/const'

import { Typography } from '@/views/typography'
import CellCount from './CellCount'

import style from './style/index.module.scss'

interface IProps {
  params: ICellFilterParams
  handleChangeCellStausFilter: (
    value: TStorageCellFilterStatus
  ) => void
}

/**
 * @view
 * * вью для реализации интерфейса счетчиков кол-ва ячеек
 * @param handleChangeCellStausFilter  метод для установки фильтра статуса ячеек
 * @param params                       текущие фильтра в запросе на ячейки
 */
const CellCounts: React.FC<IProps> = ({
  handleChangeCellStausFilter,
  params
}) => {
  const intl = useIntl()
  return (
    <div className={style['cells-count']}>
      <Typography
        className={style['cells-count__label']}
        size={14}
        weight={400}
      >
        {intl.formatMessage({
          id: 'storage.cells.cellStatus',
          defaultMessage: 'Статус ячейки'
        })}
      </Typography>
      <ul className={style['cells-count__list']}>
        {CELL_COUNTS.map((el) => (
          <CellCount
            key={el.id}
            {...el}
            tooltipData={el.titleTooltip}
            params={params}
            handleChangeCellStausFilter={
              handleChangeCellStausFilter
            }
          />
        ))}
      </ul>
    </div>
  )
}

export default observer(CellCounts)
