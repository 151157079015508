import React, { FC, ReactNode } from 'react'

import cn from 'classnames'

import style from '../styles/index.module.scss'

interface IProps {
  className?: string
  children: ReactNode
}
/**
 * * Вью для отрисовки тела (основы) таблицы
 * @view
 */
const TableCoreView: FC<IProps> = ({ children, className }) => {
  return (
    <table
      className={cn(style.table, className)}
    >
      {children}
    </table>
  )
}

export default TableCoreView
