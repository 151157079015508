import React, { FC } from 'react'

import Badge from '@/components/Badge'
import { Typography } from '@/views/typography'

import { IUserTitle } from '../interfaces'

import style from '../styles/index.module.scss'

/**
 * * вью тайтла пользователя
 * @view UserTitle
 *
 * @param fullName        фио пользователя
 * @param badgeTitle      инициалы пользователя
 * @param subTitle        текст под тайтлом
 * @returns
 */
const UserTitle: FC<IUserTitle> = ({
  fullName,
  badgeTitle,
  subTitle
}) => {
  return (
    <div className={style['user-sidebar__header']}>
      <Badge size='medium' title={badgeTitle} />
      <div className={style['user-sidebar__header__title']}>
        <Typography
          size={32}
          weight={700}
          className={style['user-sidebar__header__title_text']}
        >
          {fullName}
        </Typography>
        <Typography
          size={16}
          weight={400}
          className={style['user-sidebar__header__title_subtitle']}
        >
          {subTitle}
        </Typography>
      </div>
    </div>
  )
}

export default UserTitle
