import React, { useState } from 'react'
import { Field } from 'formik'

import Icon from '@/components/Icon'
import { Switch } from '@/views/switch'
import { Button } from '@/views/button'
import { Typography } from '@/views/typography'

import { useStores, useTranslate } from '@/hooks'

import { DEFAULT_INTEGRATION_FIELDS } from '@/apps/Integration/const'
import { TMessage } from '@/hooks/useTranslate'

import {
  convertErrorToString,
  getHttpRequestStatusType,
  isUndefined,
} from '@/utils'

import { HTTP_RESPONSE_TYPES, MODAL_TYPES } from '@/const'

import styles from '../style/index.module.scss'
/**
 * @view
 * плашка с синхрой юзеров по скуду
 */
const SyncUsers = () => {
  const { modalStore, api } = useStores()
  const { translate, intl } = useTranslate()
  const [isDisabled, setIsDisabled] = useState(false)

  const handleCloseErrorModal = (
    text: TMessage = {
      id: 'modal.content.server_error.sync_skud',
      defaultMessage:
        'Сихронизация не запущена, неизвестная ошибка',
    },
  ): void => {
    modalStore.open(MODAL_TYPES.SYNCHRONIZATION, {
      text,
    })
  }
  const handleForceSync = (): void => {
    setIsDisabled(true)
    api.integration
      .forcedSynchronization()
      .then(() =>
        handleCloseErrorModal({
          id: 'modal.content.success.sync_skud',
          defaultMessage:
            'Запущен процесс синхронизации пользователей со СКУД',
        }),
      )
      .catch((error) => {
        const statusType = getHttpRequestStatusType(
          error.response.status,
        )
        if (
          statusType === HTTP_RESPONSE_TYPES.SERVER_ERROR
        ) {
          handleCloseErrorModal()
        }
        if (
          statusType === HTTP_RESPONSE_TYPES.CLIENT_ERROR
        ) {
          if (!isUndefined(error?.response?.data)) {
            const formattedError =
              convertErrorToString(error)
            handleCloseErrorModal(formattedError)
          } else {
            handleCloseErrorModal()
          }
        }
      })
      .finally(() => {
        setIsDisabled(false)
      })
  }

  return (
    <>
      <Field
        name={DEFAULT_INTEGRATION_FIELDS.is_active_synch}
      >
        {({ field }) => (
          <Switch
            handleChange={field.onChange}
            checked={field.value}
            name={field.name}
            className={styles.switch}
          >
            {translate({
              id: 'activeSync',
              defaultMessage: 'Синхронизация пользователей',
            })}
          </Switch>
        )}
      </Field>
      <div className={styles.sync__wrap}>
        <Button
          disabled={isDisabled}
          onClick={handleForceSync}
          color="gray"
        >
          <Icon size="small" src="synchronization" />
          {translate({
            id: 'synchronize',
            defaultMessage: 'Синхронизовать',
          })}
        </Button>
        <Typography color="gray" size={14} weight={400}>
          {intl.formatMessage(
            {
              id: 'scud.syncText',
              defaultMessage:
                'Вы можете принудительно {br} синхронизировать всех пользователей',
            },
            {
              br: () => <br />,
            },
          )}
        </Typography>
      </div>
    </>
  )
}

export default SyncUsers
