import {
  IGetAccesControlIntegrationValues,
  TAccesControlIntegrationValues,
} from '@/api/interfaces/Integration'

export const accesControlValuesSerialize = (
  response: IGetAccesControlIntegrationValues,
): TAccesControlIntegrationValues => {
  const serializeValues = {
    ...response,
  }
  Reflect.deleteProperty(serializeValues, 'id')
  Reflect.deleteProperty(serializeValues, 'api_type')
  return serializeValues
}

export const changeControlValuesSerialize = (
  value: TAccesControlIntegrationValues,
): TAccesControlIntegrationValues => ({
  ...value,
  email: value.is_email_alerts ? value.email : '',
})
