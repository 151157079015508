import React from 'react'

import { useIntl } from 'react-intl'

import { RenderSortButton } from '@/components/SortButton/RenderSortButton'
import * as UserComp from '@/apps/UserList/components/UserTableComponent'
import Table from '@/apps/Table/component/Table'
import StatusEquipmet from './StatusEquipmet'
import * as UserStorageComp from '../../../RightSidebar/views/UserStorageTableView/UserStorageViews'
import TableWrapper from '@/views/TableWrapper'
import Pagination from '@/components/Pagination'
import EmailCell from './EmailCell'

import { useStores } from '@/hooks'

import { RIGHT_SIDEBAR_TYPES } from '@/apps/RightSidebar/const'
import { USER_STORAGE_UNBIND_VARIANT } from '@/apps/Modals/const'
import {
  EMPTY_CONSTS,
  TABLE_PAGE,
  TABLE_WRAPPER_ICON_SRC,
} from '@/const'
import { DEFAULT_USER_STORAGE_TABLE_FIELDS } from '../../const'
import { userTableStyles } from './style/tableStyle'

import { IRowData, TOnRow } from '@/apps/Table/interfaces'
import { IUserQueryParams, TUser } from '@/api/interfaces'
import { IUserTable } from '@/apps/UserList/interfaces'

import style from './style/index.module.scss'

/**
 * @view
 * * вью по отрисоке таблицы
 * @param users         массив данных о юзере привязанного к этому шкафу
 * @param params        текущие query параметры
 * @param handleParams  установить дополнительные параметры к текущим query параметрам
 * @returns
 */
const UsersView: React.FC<IUserTable> = ({
  users = EMPTY_CONSTS.ARR,
  total,
  params = EMPTY_CONSTS.OBJ as IUserQueryParams,
  isLoading,
  error,
  handleParams,
  currentPage,
  handlePage,
  refetch,
}) => {
  const intl = useIntl()
  const { ordering, search } = params
  const { rightSideBarStore, currentStorageStore, api } =
    useStores()

  /**
   * * метод для открытя модалки правого сайдбара пользователя
   * @func handleOpenUserSideBar
   */
  const handleOpenUserSideBar = (
    userData: IRowData,
  ): void => {
    rightSideBarStore.open(
      RIGHT_SIDEBAR_TYPES.USER_SIDEBAR,
      { id: userData.id },
    )
  }

  const onRow: TOnRow = (rowData) => ({
    onClick: () => handleOpenUserSideBar(rowData),
  })
  /**
   * * методя рендера колонки id
   * * рисует дропдаун с возможность отвязки пользователя от шкафа
   * @func renderIdColumn
   * @param userId
   * @param userData
   * @returns
   */
  const renderIdColumn = (
    userId: number,
    userData: TUser,
  ) => {
    // метод для отвязки в модалке
    const handleUnbindUserInStorage = () => {
      return api.storage.unbindUserStorage(
        Number(currentStorageStore?.storage?.id),
        { user_id: userId },
      )
    }
    return UserStorageComp.UnlinkStorage({
      handleUnbindMethod: handleUnbindUserInStorage,
      refetch,
      userData,
      type: USER_STORAGE_UNBIND_VARIANT.USER_UNBIND,
    })
  }

  const columns = [
    {
      key: DEFAULT_USER_STORAGE_TABLE_FIELDS.FULL_NAME,
      contentHeader: intl.formatMessage({
        id: 'user.fullname',
        defaultMessage: 'ФИО',
      }),
      headerCellProps: {
        className: style.table__head_fio,
        render: (data: string) =>
          RenderSortButton(
            data,
            ordering,
            handleParams,
            'full_name',
          ),
      },
      bodyCellProps: {
        render: (data: string, rowData: Required<TUser>) =>
          UserComp.FullName(
            data,
            rowData,
            style.table__body_title,
          ),
        className: style['table__body_full-name'],
      },
    },
    {
      key: DEFAULT_USER_STORAGE_TABLE_FIELDS.EMAIL,
      contentHeader: intl.formatMessage({
        id: 'user.email',
        defaultMessage: 'Почта',
      }),
      headerCellProps: {
        className: style.table__head_center,
        render: (data: string) =>
          RenderSortButton(
            data,
            ordering,
            handleParams,
            'email',
          ),
      },
      bodyCellProps: {
        render: (email: string) => (
          <EmailCell email={email} />
        ),
      },
    },
    {
      key: DEFAULT_USER_STORAGE_TABLE_FIELDS.USER_ROLE,
      contentHeader: intl.formatMessage({
        id: 'user.role',
        defaultMessage: 'Роль',
      }),
      headerCellProps: {
        className: style.table__head_center,
        render: (data: string) =>
          RenderSortButton(
            data,
            ordering,
            handleParams,
            'user_role',
          ),
      },
      bodyCellProps: {
        className: style.table__body_role,
        render: UserComp.UserRole,
      },
    },
    {
      key: DEFAULT_USER_STORAGE_TABLE_FIELDS.EQUIPMENT_STATUS,
      contentHeader: intl.formatMessage({
        id: 'user.equipment',
        defaultMessage: 'Оборудование',
      }),
      headerCellProps: {
        colSpan: 2,
        className: style.table__head_equipment,
        render: (data: string) =>
          RenderSortButton(
            data,
            ordering,
            handleParams,
            'equipment_status',
          ),
      },
      bodyCellProps: {
        render: StatusEquipmet,
      },
    },
    {
      key: DEFAULT_USER_STORAGE_TABLE_FIELDS.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        className: style['table__body_drop-down'],
        render: renderIdColumn,
      },
    },
  ]

  return (
    <TableWrapper
      iconSrc={TABLE_WRAPPER_ICON_SRC.USER}
      search={search}
      isLoading={isLoading}
      error={error}
      type={TABLE_PAGE.USER}
      length={users.length}
    >
      <div className={style['storage-user']}>
        <div className={style['storage-user__content']}>
          <Table
            onRow={onRow}
            styles={userTableStyles}
            columns={columns}
            rowsData={users}
          />
        </div>
        <Pagination
          pageSize={params.page_size}
          className={style['storage-user__footer']}
          currentPage={currentPage}
          handleChange={handlePage}
          total={total}
        />
      </div>
    </TableWrapper>
  )
}

export default UsersView
